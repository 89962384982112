/*base code*/
.animated {
	-webkit-animation-duration: 1s;
	animation-duration: 1s;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
}
.animated.infinite {
	-webkit-animation-iteration-count: infinite;
	animation-iteration-count: infinite;
}
.animated.hinge {
	-webkit-animation-duration: 2s;
	animation-duration: 2s;
}
/*the animation definition*/
@-webkit-keyframes slideInLeft {
	0% {
		-webkit-transform: translate3d(-100%, 0, 0);
		transform: translate3d(-100%, 0, 0);
		visibility: visible;
	}
	100% {
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}
}
@keyframes slideInLeft {
	0% {
		-webkit-transform: translate3d(-100%, 0, 0);
		-ms-transform: translate3d(-100%, 0, 0);
		transform: translate3d(-100%, 0, 0);
		visibility: visible;
	}
	100% {
		-webkit-transform: translate3d(0, 0, 0);
		-ms-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}
}
.slideInLeft {
	-webkit-animation-name: slideInLeft;
	animation-name: slideInLeft;
}

/*the animation definition*/
@-webkit-keyframes slideOutRight {
	0% {
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}
	100% {
		visibility: hidden;
		-webkit-transform: translate3d(100%, 0, 0);
		transform: translate3d(100%, 0, 0);
	}
}
@keyframes slideOutRight {
	0% {
		-webkit-transform: translate3d(0, 0, 0);
		-ms-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}
	100% {
		visibility: hidden;
		-webkit-transform: translate3d(100%, 0, 0);
		-ms-transform: translate3d(100%, 0, 0);
		transform: translate3d(100%, 0, 0);
	}
}
.slideOutRight {
	-webkit-animation-name: slideOutRight;
	animation-name: slideOutRight;
}

/* this file for Creo - Ebooks */

/*
class guide 

.CAWrap
  ㄴ:nth-child(1) : 
  ㄴ:nth-child(n + 2) :
  ㄴphonicsCAWrap
  ㄴcreo-a-wrap

*/

/* CreoAwesome, CreoTown */
.CreoBook-con {
  width: 100%;
  display: flex;
  flex-direction: row;

  .Creo-titWrap:first-child {
    height: 100%;
    background: #f0f3f9;
    display: flex;
  }

  .Creo-AW-CT-titWrap {
    height: auto;
    background: #f0f3f9;
    display: flex;

    h2.CreoBookTitle {
      width: auto;
    }
  }

  h2.CreoBookTitle {
    width: 4.5vw;
    font-size: 1rem;
    margin-bottom: 0;
    padding: 1rem 0.5rem 2rem;
    margin: auto 0;
  }

  .ant-card-cover {
    div {
      margin: 2rem auto auto;
    }
  }

  .bookCardCon {
    width: 100%;
    display: flex; /* position: relative; */
    background: #fff;
    z-index: 999;
    flex-direction: column;

    h2.SectionTit {
      font-size: 1.05rem;
      margin: 0.5rem 0 0.5rem;
    }
  }

  .bookCardWrap {
    display: flex;
    border-bottom: 1px solid rgb(222, 222, 222);
    border-top: 0.5px solid rgb(222, 222, 222);
  }

  .imgECc.unit-con {
    /*
        margin-left: 10px;
        margin-right: 0;*/
  }

  .imgECc.unit-con:last-child {
    margin-left: 0;
    margin-right: 0;
  }

  .unit-con {
    width: 100%;
    padding: 1rem 0;
    div.unit-wrap {
      border: 1px solid #929982;
      width: 100%;
      margin: 0.7rem auto;
      border-radius: 10px;
      position: relative;
      left: 0rem;
      top: 0rem;

      h2 {
        text-align: center;
        margin-bottom: 0;
        cursor: pointer;
        padding: 0.5rem 1rem;
        font-size: 0.8em;
        transition: all 0.2s ease-in-out;
        color: #404337;
      }

      h2:hover {
        border-radius: 10px;
        background: #929982;
        color: #fff;
        transition: all 0.2s ease-in-out;
      }
    }
  }

  .unit-con-odd-certain {
    width: 100%;
    padding: 1rem 0;

    div.unit-wrap {
      border: 1px solid #929982;
      width: 60%;
      margin: 0.7rem 0;
      border-radius: 10px;

      h2 {
        text-align: center;
        margin-bottom: 0;
        cursor: pointer;
        padding: 0.3rem;
        font-size: 0.9em;
        transition: all 0.2s ease-in-out;
        color: #404337;
      }

      h2:hover {
        border-radius: 10px;
        background: #929982;
        color: #fff;
        transition: all 0.2s ease-in-out;
      }
    }
  }

  .unit-con-even-certain {
    width: 100%;
    padding: 1rem 0;

    div.unit-wrap {
      border: 1px solid #929982;
      width: 100%;
      margin: 0.7rem 0;
      border-radius: 10px;

      h2 {
        text-align: center;
        margin-bottom: 0;
        cursor: pointer;
        padding: 0.3rem;
        font-size: 0.9em;
        transition: all 0.2s ease-in-out;
        color: #404337;
      }

      h2:hover {
        border-radius: 10px;
        background: #929982;
        color: #fff;
        transition: all 0.2s ease-in-out;
      }
    }
  }

  .imgECc {
    margin: auto;
    width: 100%;

    .ant-card-body {
      padding: 5px;
      min-height: 40px;
      text-align: center;
      border: 0px solid #fff;
      display: flex;
      flex-direction: column;
      width: 100%;
    }

    .ant-card-meta {
      .ant-card-meta-detail {
        .ant-card-meta-description {
          .ant-btn {
            width: 48% !important;
          }
        }
      }
    }
  }
}

.junglePhonicsV {
  .dkZOgq {
    border-bottom: none !important;

    .ant-card-meta-detail {
      border-bottom: none !important;
      .volume-btn {
        justify-content: center;
        padding: 1.5rem 0 2rem !important;
        width: 60%;
        margin-bottom: 0;
      }
    }
  }
}

.ant-col {
  &.ant-col-6 {
    &.CAWrap {
      flex: none;
      &.creo-a-wrap {
        &:nth-child(1) {
          .CreoBook-con {
            .Creo-AW-CT-titWrap {
              width: auto;
              min-width: 10rem;
              display: flex !important;
              align-items: center;
              justify-content: center;

              h2.CreoBookTitle {
                width: auto;
              }
            }
            .bookCardCon {
              .bookCardWrap {
                .ant-card {
                  .ant-card-body:nth-child(1) {
                    padding: 5px 0;
                  }
                }
              }
            }
          }
        }

        &:nth-child(n + 2) {
          .CreoBook-con {
            .Creo-AW-CT-titWrap {
              display: none !important;
            }
          }
        }

        .CreoBook-con {
          .Creo-AW-CT-titWrap {
            display: none;
          }

          .bookCardCon {
            .bookCardWrap {
              .unit-wrap {
                .unit-con {
                  div.unit-wrap {
                    width: 10rem;
                    margin: 0.7rem auto;
                  }
                }
              }
            }
          }
        }

        &:nth-child(n + 2) {
          // width: 23% !important;
        }
      }

      .CreoBook-con {
        .Creo-AW-CT-titWrap {
          width: auto;
          display: flex !important;
          align-items: center;
          justify-content: center;
          h2 {
            display: flex !important;
            align-items: center;
            justify-content: center;
          }
        }

        .bookCardCon {
          .bookCardWrap {
            align-items: center;
            .ant-card {
              &:nth-child(1) {
                .ant-card-body {
                  padding: 5px 0;
                  button {
                    width: auto !important;
                    margin: 0 3px;
                  }
                }
              }

              .ant-card-body:nth-child(1) {
                padding: 5px 0;
              }
            }

            .unit-wrap {
              .unit-con {
                div.unit-wrap {
                }
              }
            }
          }
        }
      }
    }
  }
}

.ant-col.ant-col-6.CAWrap {
  border-right: none !important;
  &.crossover {
    flex: 0 0 18%;
    .CreoBook-con {
      &:nth-child(1) {
        .bookCardCon {
        }
      }
    }

    &:nth-child(n + 1) {
      .bookCardCon {
        .bookCardWrap {
          .ant-card {
            &:nth-child(1) {
              .ant-card {
              }
            }
          }
        }
      }
      div {
        div:last-child {
          .ant-card-cover {
            & ~ .ant-card-body {
              display: none;
            }
          }
        }
      }
    }
  }
}

.ant-col.ant-col-6.CAWrap:nth-child(1) {
  .CA-lv-one-wrap {
    .CreoBook-con:nth-child(1) {
      .bookCardCon {
        width: 18vw !important;
      }
    }
  }
}

.ant-col.ant-col-6.CAWrap:nth-child(n + 2) {
  flex: none;
  .CreoBook-con {
    .imgECc {
      width: 100%;
      margin-left: 0.3rem;
    }

    .imgECc:nth-child(2n) {
    }

    .bookCardWrap {
      border-top: 1px solid rgb(222, 222, 222);
      border-bottom: 1px solid rgb(222, 222, 222);
    }

    .unit-con {
      div.unit-wrap {
        width: 100%;
        margin-left: 0rem;

        position: relative;
        left: 0rem;
        top: 0rem;
      }
    }

    .unit-con.unit-con-otherThings {
      display: none;
    }
  }
}

.ant-col.ant-col-6.CAWrap.phonicsCAWrap {
  flex: none;
  padding-left: 0 !important;
  padding-right: 0 !important;
  .CreoBook-con {
    .imgECc {
      width: 100%;
      margin-left: 0.3rem;
    }

    .imgECc:nth-child(2n) {
    }

    .bookCardWrap {
      border-top: 1px solid rgb(222, 222, 222);
      border-bottom: none;
      /* border-bottom: 1px solid rgb(222,222,222);*/
    }

    .unit-con {
      div.unit-wrap {
        width: 100%;
        margin-left: 0rem;

        position: relative;
        left: 0rem;
        top: -0rem;
      }
    }

    .unit-con-odd-certain {
      width: 100vw;

      div.unit-wrap {
        width: 60%;
        margin-left: 0rem;

        position: relative;
        left: 0rem;
        top: -0rem;
      }
    }

    .unit-con-even-certain {
    }
  }
}
// .ant-col.ant-col-6.CAWrap.winterbookCAWrap{
//     flex: none;
//     padding-left: 0 !important;
//     padding-right: 0 !important;
//     .CreoBook-con{
//         .bookCardCon{
//             width: 20vw !important;
//         }
//             .imgECc{
//                 widtH: 100%;
//                 margin-left: 0.3rem;
//             }

//             .imgECc:nth-child(2n){
//             }

//             .bookCardWrap{
//                 border-top: 1px solid rgb(222,222,222);
//                 border-bottom: none;
//                 /* border-bottom: 1px solid rgb(222,222,222);*/
//             }

//             .unit-con {
//                 div.unit-wrap{
//                     width: 100%;
//                     margin-left: 0rem;

//                     position: relative;
//                     left: 0rem;
//                     top: -0rem;

//                     h2{

//                     }
//                 }
//             }

//             .unit-con-odd-certain{
//                 width: 100vw;

//                 div.unit-wrap{
//                     width: 60%;
//                     margin-left: 0rem;

//                     position: relative;
//                     left: 0rem;
//                     top: -0rem;
//                 }

//             }

//             .unit-con-even-certain{

//             }
//     }
// }

.ant-col.ant-col-6.CAWrap:last-child {
  .CreoBook-con {
    border-right: 1px solid rgb(222, 222, 222);
  }
}
.ant-col.ant-col-6.CAWrap.phonicsCAWrap {
  .CreoBook-con {
    .ddd {
      width: 100% !important;
      .unit-con {
      }
    }
  }

  .CreoBook-con:nth-child(1) {
  }
}
// .ant-col.ant-col-6.CAWrap.winterbookCAWrap{
//     .CreoBook-con{
//         .ddd{
//             width: 100% !important;
//             .unit-con{

//             }
//         }
//     }

//     .CreoBook-con:nth-child(1){

//     }
// }

.ByNBj {
  // bookname in the left box
  width: 8rem !important;
}

.winterbook-con {
  .winterbook-row {
    .ant-col-8 {
      flex: none;

      div {
        > div {
          &:nth-child(n + 1) {
            //  border: 3px solid red;
          }
        }
      }

      &:nth-child(n + 1) {
        div {
          div:last-child {
            .ant-card-cover {
              & ~ .ant-card-body {
                display: none;
              }
            }

            .ant-card {
              .ant-card-bordered {
                &:nth-child(2n - 1) {
                  .ant-card-body {
                    display: none;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1800px) {
  .CreoBook-con .unit-con div.unit-wrap h2 {
    padding: 0.5rem 0.2rem;
    font-size: 0.7rem;
  }
}

@media (max-width: 1920px) {
  .junglePhonicsV {
    .dkZOgq {
      border-bottom: none !important;
      .ant-card-meta-detail {
        border-bottom: none !important;
        .volume-btn {
          justify-content: center !important;
          padding: 1.5rem 0 2rem !important;
          width: 60%;
          .btn-width {
            width: 50% !important;
          }
        }
      }
    }
  }
}

@media (max-width: 1890px) {
  .CreoBook-con h2.CreoBookTitle {
    //  font-size: 0.8rem;
    // width: 4vw;
  }

  .CreoBook-con .imgECc .ant-card-meta .ant-card-meta-detail .ant-card-meta-description .ant-btn {
    width: 49% !important;
  }

  .CreoBook-con h2.CreoBookTitle {
    //  width: 4vw;
    margin-bottom: 0;
    /* padding: 1rem 0.5rem 2rem; */
    margin: auto 0;
    position: relative;
    left: 0rem;
  }

  .CreoBook-con .Creo-AW-CT-titWrap h2.CreoBookTitle {
    //  width: 3vw;
  }

  .ant-col.ant-col-6.CAWrap {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .CreoBook-con .Creo-AW-CT-titWrap {
    width: 4rem;
  }

  .CreoBook-con .Creo-AW-CT-titWrap:nth-child(3n-1) {
    visibility: hidden;
    display: none;
  }
  .CreoBook-con .unit-con div.unit-wrap h2 {
    padding: 0.5rem 0.1rem;
    font-size: 0.6rem;
  }

  .ant-col.ant-col-6.CAWrap.phonicsCAWrap {
    width: 100%;
  }
  // .ant-col.ant-col-6.CAWrap.winterbookCAWrap{
  //     /*max-width: 20vw;*/
  //     width: 100%;
  // }

  .CreoBook-con .phonics-unit-con-two {
    .unit-wrap {
      width: 10%;
    }
  }

  .ant-col.ant-col-6.CAWrap.phonicsCAWrap {
    // width: 24%;
    .bookCardWrap {
      .unit-con {
        margin-left: 0;
        margin-right: 0;
      }
      .phonics-unit-con-two {
        .unit-wrap {
          width: 75% !important;
          margin-left: 0.5rem !important;
          margin-right: auto !important;
        }
      }
    }
  }
  // .ant-col.ant-col-6.CAWrap.winterbookCAWrap{
  //     width: 24%;
  //     .bookCardWrap{
  //         .unit-con{
  //             margin-left: 0;
  //             margin-right: 0;
  //         }
  //         .phonics-unit-con-two{
  //             .unit-wrap{
  //                 width: 75% !important;
  //                 margin-left: .5rem !important;
  //                 margin-right: auto !important;
  //             }
  //         }
  //     }
  // }

  .ant-col.ant-col-6.CAWrap {
    border-right: none !important;

    .CreoBook-con:nth-child(1) {
      .bookCardCon {
      }
    }
  }

  .ant-col.ant-col-6.CAWrap:nth-child(1) {
    .CreoBook-con:nth-child(1) {
      .bookCardCon {
      }
    }
  }

  .ant-col.ant-col-6.CAWrap:nth-child(n + 2) {
    flex: none;
    width: 20%;
  }

  .ant-col.ant-col-6.CAWrap.phonicsCAWrap {
    .phonics-unit-con-two {
      .unit-wrap {
        width: 72% !important;
        margin-left: 1rem !important;
        margin-right: auto !important;
      }
    }
  }
  // .ant-col.ant-col-6.CAWrap.winterbookCAWrap{

  //         .phonics-unit-con-two{
  //             .unit-wrap{
  //                 width: 72% !important;
  //                 margin-left: 1rem !important;
  //                 margin-right: auto !important;
  //             }

  //         }
  // }
}

@media (max-width: 1600px) {
  .ant-col.ant-col-6.CAWrap.phonicsCAWrap {
    //  width: 100%;

    .bookCardWrap {
      .unit-con {
        //    width: 80% !important;
      }
      .phonics-unit-con-two {
        .unit-wrap {
          width: 72% !important;
          margin-left: 1rem !important;
          margin-right: auto !important;
        }
      }
    }
  }
  // .ant-col.ant-col-6.CAWrap.winterbookCAWrap{
  //     width: 100%;

  //     .CreoBook-con{
  //     }

  //     .bookCardWrap{
  //         .unit-con{
  //             width: 80% !important;
  //         }
  //         .phonics-unit-con-two{
  //             .unit-wrap{
  //                 width: 72% !important;
  //                 margin-left: 1rem !important;
  //                 margin-right: auto !important;
  //             }
  //         }
  //     }
  // }
}

@media screen and (max-width: 1366px) {
  .ant-col {
    &.ant-col-6 {
      &.CAWrap {
        &.creo-a-wrap {
          flex: 0 0 22%;
          width: auto;
          // need to check
          &:nth-child(1) {
            .CreoBook-con {
              .Creo-AW-CT-titWrap {
                min-width: auto;
              }
              .bookCardCon {
                .bookCardWrap {
                  .unit-wrap {
                    width: auto;
                  }
                }
              }
            }
          }
        }

        &.crossover {
          flex: 0 0 22%;
          // need to check
          &:nth-child(1) {
            .CreoBook-con {
              .bookCardCon {
                .bookCardWrap {
                  .unit-wrap {
                    width: auto;
                  }
                }
              }
            }
          }
        }

        &:nth-child(n + 2) {
          width: 22%;
        }
      }
    }
  }

  .CreoBook-con {
    h2 {
      &.CreoBookTitle {
        width: 100%;
      }
    }

    .unit-con {
      display: flex;
      align-items: center;
      justify-content: center;
      div.unit-wrap {
        width: auto;
        h2 {
          // font-size: 0.9rem;
        }
      }
    }
  }

  .junglePhonicsV {
    .ant-card-meta {
      .ant-card-meta-detail {
        border-bottom: none !important;
        .volume-btn {
          margin: 0 auto;
          justify-content: center !important;
          align-items: center;
          padding: 1.5rem 0 2rem !important;
          .btn-width {
            width: 50% !important;
          }
        }
      }
    }
  }

  .winterbook-con {
    .winterbook-row {
      height: 100% !important;
      height: auto !important;
      min-height: 300px;
      margin: 1rem 0;

      .ByNBj {
        // bookname in the left box
        width: 8rem !important;
      }

      .ant-col-8 {
        flex: none;
        &:nth-child(n + 1) {
          div {
            div:last-child {
              .ant-card-cover {
                & ~ .ant-card-body {
                  display: none;
                }
              }

              .ant-card {
                .ant-card-bordered {
                  &:nth-child(2n - 1) {
                    .ant-card-body {
                      display: none;
                    }
                  }
                }
              }
            }
          }
        }

        &:nth-child(3n - 1) {
          width: 20%;
          flex: none;
          justify-content: center;
        }
      }

      .unitunit {
        width: 5rem;
      }
    }
  }
}

@media screen and (max-width: 1280px) {
  .ant-col.ant-col-6.CAWrap {
    &.creo-a-wrap {
      flex: none;
    }
  }
}

@media screen and (max-width: 1200px) {
  .CreoBook-con {
    .Creo-AW-CT-titWrap {
      h2 {
        &.CreoBookTitle {
          width: auto;
        }
      }
    }
  }

  .ant-col.ant-col-6.CAWrap:nth-child(1) {
    // from section2 in row
    .CreoBook-con {
      .bookCardCon {
        .bookCardWrap {
          .unit-con {
            div.unit-wrap {
              width: 7rem;
              margin: 0.7rem auto;
            }
          }
        }
      }
    }
  }

  .ant-col.ant-col-6.CAWrap:nth-child(n + 2) {
    // from section2 in row
    .CreoBook-con {
      .bookCardCon {
        .bookCardWrap {
          .unit-con {
            div.unit-wrap {
              width: 7rem;
              margin: 0.7rem auto;
            }
          }
        }
      }
    }
  }

  .ant-col {
    &.ant-col-6 {
      &.CAWrap {
        .CreoBook-con {
          .Creo-AW-CT-titWrap {
            width: auto;
            display: flex !important;
            h2 {
              &.CreoBookTitle {
                width: auto;
                // word-break: break-all;
              }
            }
          }

          h2 {
            &.CreoBookTitle {
              width: auto;
            }
          }

          .bookCardCon {
            &.ddd {
              .bookCardWrap {
                .ant-card {
                  .ant-card-body {
                    .ant-card-meta-detail {
                      .ant-card-meta-description {
                        div {
                          justify-content: center !important;
                        }
                      }
                    }
                  }
                }
                .unit-con {
                  div.unit-wrap {
                    width: calc(100% - 3.5rem);
                    margin: 0.7rem auto;
                  }
                }
              }
            }

            .bookCardWrap {
              .ant-card {
                .ant-card-body {
                  .ant-card-meta-detail {
                    .ant-card-meta-description {
                      div {
                        justify-content: center !important;
                      }
                    }
                  }
                }
              }
              .unit-con {
                div.unit-wrap {
                  width: calc(100% - 3.5rem);
                  margin: 0.7rem auto;
                }
              }
            }
          }
        }

        &.phonicsCAWrap {
          .CreoBook-con {
            .bookCardCon {
              &.ddd {
                // .ddd -> 첫번쨰 블록의 bookcardcon.
                .bookCardWrap {
                  .unit-con {
                    div.unit-wrap {
                      // width: calc(100% - 3rem);
                      margin: 0.7rem auto;
                      width: 7rem;
                    }
                  }
                }
              }

              .bookCardWrap {
                .unit-con {
                  div.unit-wrap {
                    // width: calc(100% - 3.5rem);
                    width: 7rem;
                    margin: 0.7rem auto;
                  }
                }
              }
            }
          }
        }

        &.creo-a-wrap {
          flex: none;
          width: auto;
          &:nth-child(1) {
            .CreoBook-con {
              .Creo-AW-CT-titWrap {
                min-width: auto;
              }
              .bookCardCon {
                .bookCardWrap {
                  .unit-wrap {
                    width: calc(100% - 3rem);
                  }
                }
              }
            }
          }

          &:nth-child(n + 2) {
            .CreoBook-con {
              .Creo-AW-CT-titWrap {
                display: none !important;
              }
            }
          }

          .CreoBook-con {
            .Creo-AW-CT-titWrap {
              display: none;
            }

            .bookCardCon {
              .bookCardWrap {
                .ant-card {
                  .ant-card-body {
                    .ant-card-meta-detail .ant-card-meta-description div {
                      justify-content: center !important;
                    }
                  }
                }
                .unit-wrap {
                  .unit-con {
                    div.unit-wrap {
                      width: 10rem;
                      margin: 0.7rem auto;
                    }
                  }
                }
              }
            }
          }

          &:nth-child(n + 2) {
            // width: 23% !important;
          }
        }
      }
    }
  }

  .CreoBook-con {
    /*
    .ant-col {
      &.ant-col-6 {
        &.CAWrap {
          &.phonicsCAWrap {
            .CreoBook-con {
              .unit-con {
                div.unit-wrap {
                  width: calc(100% - 3rem);
                  margin: 0.7rem auto;
                }
              }
            }
          }
        }

        .unit-con {
          div.unit-wrap {
            width: calc(100% - 3rem);
          }
        }
      }
    }
    */

    .bookCardCon {
      &.ddd {
        .bookCardWrap {
          .unit-con {
            .ant-card-body {
              .unit-wrap {
                width: calc(100% - 3rem);
                margin: 0.7rem auto;
              }
            }
          }
        }
      }

      .bookCardWrap {
        display: block;

        .unit-con {
          width: auto !important;
        }
      }
    }
  }

  .junglePhonicsV {
    .ant-card-meta {
      .ant-card-meta-detail {
        border-bottom: none !important;
        .volume-btn {
          justify-content: center !important;
          padding: 2rem 0 !important;
          margin: 0 auto;
          width: 60%;
        }

        .btn-width {
          width: 50% !important;
        }
      }
    }
  }

  .winterbook-con {
    .winterbook-row {
      height: 100% !important;

      .ant-col-8 {
        flex: none;

        &:nth-child(1) {
          .bqFzuL {
            width: auto;
            min-width: 12rem;
          }

          div {
            div {
              flex-direction: column;
            }
          }
        }

        &:nth-child(n + 1) {
          div {
            div:last-child {
              .ant-card-cover {
                & ~ .ant-card-body {
                  display: none;
                }
              }

              .ant-card {
                .ant-card-bordered {
                  &:nth-child(2n - 1) {
                    .ant-card-body {
                      display: none;
                    }
                  }
                }
              }
            }

            div {
              flex-direction: column;
              justify-content: center;
            }
          }
        }

        &:nth-child(3n - 1) {
          width: auto;
          flex: none;
          justify-content: center;
        }
      }
    }
  }
}

@media screen and (max-width: 850px) {
  .junglePhonicsV .Creo-titWrap {
    height: 100% !important;
  }

  .junglePhonicsV {
    .dkZOgq {
      border-bottom: none !important;
      .ant-card-meta-detail {
        border-bottom: none !important;
        .volume-btn {
          justify-content: center !important;
          padding: 1.5rem 0 2rem !important;
          width: 60%;
          .btn-width {
            width: 70% !important;
          }
        }
      }
    }
  }

  .ant-col {
    &.ant-col-6 {
      max-width: 35%;
      &.CAWrap {
        &.creotown-ebook {
          // creotown tab container
          &:nth-child(1) {
            width: auto;
          }
        }
        // creotown
        &:nth-child(1) {
          width: 100%;
          .CreoBook-con {
            .bookCardCon {
              &.ddd {
              }
              .bookCardWrap {
                .unit-wrap {
                  width: calc(100% - 3rem);
                }
              }
            }
          }
        }

        &:nth-child(n + 2) {
          .CreoBook-con {
            .bookCardCon {
              .bookCardWrap {
                .unit-con {
                  div.unit-wrap {
                    width: 6rem;
                  }
                }
              }
            }
          }
        }

        // jungle phonics, crossover, phonics
        &.phonicsCAWrap {
          .CreoBook-con {
            .bookCardCon.ddd {
              width: 80% !important;
              .bookCardWrap {
                .unit-con {
                  div.unit-wrap {
                    width: 6rem;
                  }
                }
              }
            }
          }

          &:nth-child(1) {
            // border: 3px solid orange !important;
            .CreoBook-con {
              .bookCardCon {
                &.ddd {
                  //  border: 3px solid red !important;
                }
                .bookCardWrap {
                  .unit-wrap {
                    width: calc(100% - 4.5rem);
                  }
                }
              }
            }
          }

          &:nth-child(n + 2) {
            width: 21%;
          }
        }

        &.creo-a-wrap {
          &:nth-child(n + 2) {
            width: auto;
            max-width: 35%;
          }
          .CreoBook-con {
            .Creo-AW-CT-titWrap {
              width: 5rem;
            }

            .bookCardCon {
              & {
                .bookCardWrap {
                  .unit-con {
                    div.unit-wrap {
                      width: 6rem;
                    }
                  }
                }
              }
            }
          }
        }
      }

      .CreoBook-con {
        .Creo-AW-CT-titWrap {
          width: 5rem;
        }
      }
    }
  }

  .winterbook-con {
    .winterbook-row {
      height: auto !important;
    }
  }
}

@media screen and (max-width: 768px) {
  .ant-col {
    &.ant-col-6 {
      &.CAWrap {
        &:nth-child(n + 2) {
          .CreoBook-con {
            .bookCardCon {
              .bookCardWrap {
                .unit-con {
                  div.unit-wrap {
                    width: 6rem;
                  }
                }
              }
            }
          }
        }

        &.phonicsCAWrap {
          .CreoBook-con {
            .bookCardCon {
              &.ddd {
                .bookCardWrap {
                  .unit-con {
                    div.unit-wrap {
                      width: 6rem;
                    }
                  }
                }
              }

              .bookCardWrap {
                .unit-con {
                  div.unit-wrap {
                  }
                }
              }
            }
          }
        }

        &.creo-a-wrap {
          &:nth-child(n + 2) {
            width: 20%;
            max-width: 35%;
          }
          .CreoBook-con {
            .Creo-AW-CT-titWrap {
              width: 5rem;
            }

            .bookCardCon {
              & {
                .bookCardWrap {
                  .unit-con {
                    div.unit-wrap {
                      width: 6rem;
                    }
                  }
                }
              }
            }
          }
        }

        .CreoBook-con {
          .bookCardCon {
            .bookCardWrap {
              .unit-con {
                div.unit-wrap {
                  width: 6rem;
                }
              }
            }
          }
        }
      }
    }
  }
}

.bookstore-book-order-mainblock
{
    max-height: calc(100vh - 140px);
    height: calc(100vh - 140px);
    box-shadow: none !important;
    border-radius: 10px;
    border: 1px solid #ececec;
}
.book-header-two-table h5
{
    margin-bottom: 0.5em;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 600;
    font-size: 18px;
    line-height: 1.4;
}
.book-header-two-table0icon
{
    .ant-anchor-wrapper
    {
        img 
        {
            width: 22px;
            position: relative;
            top: -5px;
        }
        .ant-anchor-link
        {
            padding:0px;
        }
        .ant-anchor-ink
        {
            &:before 
            {
                display: none;
            }
        }
    }
}

.bookstore-header-top
{
    .bookstore-heading
    {
        .mainheader-left
        {
            margin-bottom: 0.5em;
            color: rgba(0, 0, 0, 0.85);
            font-weight: 600;
            font-size: 18px;
            line-height: 1.4;
            display: flex;
            img 
            {
                width: 20px;
                margin-left: 20px;
            }
        }
        .ant-anchor-wrapper
        {
            max-height: initial;
            width: auto;
            .ant-anchor 
            {
                border: 0px; 
            }
            .ant-anchor-ink
            {
                border: 0px;
                padding:0px;
            }
            .ant-anchor-link
            {
                border: 0px;
                padding:0px;
                &::before 
                {
                    display: none;
                }
            }
            .ant-anchor-ink::before
            {
                display: none;
            }
            
        }
    }
    .select-right
    {
        text-align: right;
        .ant-select 
        {
            width: 40%;
            text-align: left;
        }
    }
}

.calendra-outline
{
    display: flex;
    margin-bottom: 10px;
    .calendra-outline-date-bold
    {
        margin-right: 30px;
        font-weight: 700;
        span.anticon.anticon-calendar
        {
            font-weight: 700;
            font-size: 16px;
        }
    }
    .calendra-outline-date
    {
        font-weight: 500;
    }
}

table.booktable-main-tbl,
table.booktable-main-tbl tr,
table.booktable-main-tbl td,
table.booktable-main-tbl th
{
    border: 1px solid #c4cbce;
}
table.booktable-main-tbl
{
    width:100%;
    border-top: 2px solid #989898;
    border-bottom: 2px solid #989898;
    tr 
    {
        th 
        {
            text-align: center;
            background: #fef3e5;
            color: #262a30;
            font-size: 16px;
            padding:4px 10px;
        }
        td 
        {
            text-align: center;
            font-size: 14px;
            padding: 6px;
        }
    }
    tr:nth-child(2)
    {
        th:last-child 
        {
            color: #0067e5; 
        }
    }
    tr:last-child
    {
        background: #fef3e5;
        td 
        {
            color: #0067e5;
            font-weight: 700;
        }
    }
}

.invoice-table-two-btm-one
{
    margin-top: 20px;
}
.invoice-table-two-btm-two
{
    margin-top: 20px;
}
.topheader-image
{
    display: flex;
    justify-content: end;
    img 
    {
        width: 25px;
    }
    .ant-anchor-ink
    {
        &:before 
        {
            display: none;
        }
    }
    .ant-anchor
    {
        display: flex;
    }
}

.booktable-one
{
    width: 100%;
    margin-top:15px;
    tr 
    {
        td 
        {
            font-size: 14px;
            font-weight: 600;
        }
    }
}

.booktable-two
{
    width: 100%;
    margin-top: 15px;
    tr 
    {
        background: #eeeeee;
        td 
        {
            border-right: 1px solid #8f9eaa;
            padding: 10px 20px;
            &:last-child
            {
                border-right:0px;
            }
            .bk-table-one,
            .bk-table-two,
            .bk-table-three,
            .bk-table-four,
            .bk-table-five,
            .bk-table-six
            {
                    display: flex;
            }
            .bk-table-one,
            .bk-table-three,
            .bk-table-five,
            .bk-table-six
            {
                .number-td
                {
                    font-weight: 700;
                    margin-left: 15px;
                }
            }
            .bk-table-two,
            .bk-table-four
            {
                .number-td
                {
                    font-weight: 700;
                }
            }
        }
    } 
}

.booktable-three
{
    width: 100%;
    margin-top: 15px;
    tr 
    {
        th 
        {
            border: 1px solid #c4cbce;
            padding: 10px 12px;
            text-align: left;
        }
    }
    tr 
    {
        background: #e5f6ff;
        td 
        {
            border: 1px solid #c4cbce;
            padding: 10px 20px;
            text-align:right;
            font-weight: 700;
            font-size: 18px;
        }
    }
}
.booktable-three tr td
{
    position: relative;
}
.booktable-three tr td.tbl-one-cl:before
{
    content: '-';
    width: 20px;
    height: 20px;
    position: absolute;
    top: 14px;
    right: -11px;
    z-index: 999999;
    border: 1px solid #dfe3e7;
    border-radius: 50%;
    line-height: 16px;
    text-align: center;
    background:#f2f3f4;
}
.booktable-three tr td.tbl-two-cl:before
{
    content: '=';
    width: 20px;
    height: 20px;
    position: absolute;
    top: 14px;
    right: -11px;
    z-index: 999999;
    border: 1px solid #dfe3e7;
    border-radius: 50%;
    line-height: 16px;
    text-align: center;
    background:#f2f3f4;
}

.booktable-two tr td
{
    position: relative;
}
.booktable-two tr td.bk-table-1t::before
{
    content: 'x';
    width: 20px;
    height: 20px;
    position: absolute;
    top: 14px;
    right: -11px;
    z-index: 999999;
    border: 1px solid #dfe3e7;
    border-radius: 50%;
    line-height: 16px;
    text-align: center;
    background:#f2f3f4;
    font-weight: bold;
}
.booktable-two tr td.bk-table-2t::before
{
    content: '+';
    width: 20px;
    height: 20px;
    position: absolute;
    top: 14px;
    right: -11px;
    z-index: 999999;
    border: 1px solid #dfe3e7;
    border-radius: 50%;
    line-height: 16px;
    text-align: center;
    background:#f2f3f4;
    font-weight: bold;
    font-size: 16px;
}
.booktable-two tr td.bk-table-3t::before
{
    content: 'x';
    width: 20px;
    height: 20px;
    position: absolute;
    top: 14px;
    right: -11px;
    z-index: 999999;
    border: 1px solid #dfe3e7;
    border-radius: 50%;
    line-height: 16px;
    text-align: center;
    background:#f2f3f4;
    font-weight: bold;
}
.booktable-two tr td.bk-table-4t::before
{
    content: '+';
    width: 20px;
    height: 20px;
    position: absolute;
    top: 14px;
    right: -11px;
    z-index: 999999;
    border: 1px solid #dfe3e7;
    border-radius: 50%;
    line-height: 16px;
    text-align: center;
    background:#f2f3f4;
    font-weight: bold;
    font-size: 16px;
}
.booktable-two tr td.bk-table-5t::before
{
    content: '=';
    width: 20px;
    height: 20px;
    position: absolute;
    top: 14px;
    right: -11px;
    z-index: 999999;
    border: 1px solid #dfe3e7;
    border-radius: 50%;
    line-height: 16px;
    text-align: center;
    background:#f2f3f4;
    font-weight: bold;
    font-size: 16px;
}

.invoice-table-two-btm-two
{
    button.ant-btn-dangerous
    {
        background: #fbe5d6;
        border-color: #fbe5d6;
        color: #ff4d4f;
        font-weight: 800;
        border-radius: 6px;
    }
    button.ant-btn-primary
    {
        background: #f2f8ee;
        border-color: #e2f0d9;
        color: #5d774b;
        span.anticon.anticon-shopping-cart
        {
            color: #6e9d4e;
        }
    }
}

.book-order-main-wrapper
{
    overflow: auto;
    height: calc(100vh - 200px);
}
.bookorder-table-layout .ant-table-body
{
    min-height: calc(100vh - 350px) !important;
    height: calc(100vh - 350px) !important;
    overflow: auto !important;
}
.product-title {
    font-size: 18px;
    color: #3465a4;
    text-align: center;
    padding: 50px 0 30px;
}
.product-price {
    text-align: center;
    font-size: 18px;
}

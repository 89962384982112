/* offline / lesson / assign */
.default {
  .sc-jSgupP {
    padding: 12px 2px !important;
  }

  .sc-boeKoK {
    overflow-y: auto !important;
  }

  .calendar-box {
    .btn-schedule {
      margin: 10px auto !important;
    }
  }

  .buttonlisting-dashboard {
    .ant-row,
    .ant-col {
      margin-left: 0 !important;
      margin-right: 0 !important;
    }
  }

  .tblclass-list {
    width: 100%;
    margin-left: 0 !important;
    margin-right: 0 !important;
    .ant-col {
      margin-left: 0 !important;
      margin-right: 0 !important;
    }
    .ant-btn-dangerous {
      /* top: -5% !important; */
    }

    .classroom-table-table {
      .ant-table-header {
        table {
          thead {
            tr {
              th.ant-table-cell:nth-child(6) {
                text-align: center;
              }
            }
          }
        }
      }
    }

    .header-classroom-search {
      display: block;
      .ant-form-horizontal {
        .ant-space {
          gap: 14px !important;

          .ant-space-item:last-child {
            button {
              margin-right: 15px;
            }
          }
        }
      }
    }

    .header-classroom {
      .ant-form {
        .ant-space-align-center {
          margin-top: 10px;
        }
      }

      .ant-typography.header-classroom-title {
        margin-top: 10px;
      }
    }
  }

  .classroom-table-table {
    margin: 0 1rem;
    .ant-table-pagination.ant-pagination {
      margin: 16px auto;
    }

    .ant-table-pagination-right {
      justify-content: center;
    }

    .ant-table-container {
      .ant-table-header {
        table {
          .ant-table-thead {
            tr {
              th.ant-table-cell:last-child {
                text-align: center;
              }
            }
          }
        }
      }

      .ant-table-tbody {
        tr {
          td.ant-table-cell:last-child {
            .ant-btn {
              margin: 0 auto;
              display: block;
            }
          }
        }
      }
    }
  }
}

/* lucid */
.lucid {
  .buttonlisting-dashboard {
    align-items: center;

    .ant-col {
      &.ant-col-2 {
        &:nth-child(2) {
          // border: 2px solid red;
        }
      }
    }
  }
}

/**/
.cleveredu2 {
  .ant-col {
    &.ant-col-2 {
      justify-content: center;
      align-items: center;
      &:nth-child(2) {
      }
    }
  }
}

.testmanagment-testsectionblock .ant-table-wrapper .ant-table-container .ant-table-body {
  min-height: 0 !important;
}

.addnew-testmanagment-testblock .testmanagment-sectiona {
  height: auto !important;
  min-height: auto !important;
}

.testmanagment-testsectionblock {
  .ant-table-wrapper {
    .ant-table-container {
      .ant-table-header {
        table {
          table-layout: auto !important;
        }
        table * {
          text-align: center;
        }
      }

      .ant-table-body {
        table {
          table-layout: auto !important;
        }

        > tr > td {
          width: 5%;
        }
      }
    }
  }
}

.addnew-testmanagment-testblock {
  height: auto !important;
  min-height: auto !important;
  overflow: visible;
}

.test-syllabus-wrapper .test-syllabus-box-listing .test-syllabus-box-c button {
  // margin-top: -100px;
  position: relative;
  // top: -55px;
}

/*** test / syllabus / main   -  syllabus main page   ***/
.syllabus-mailblock {
  overflow: visible !important;
}

.contenttest-table {
  .ant-table-pagination.ant-pagination {
    justify-content: center;
  }
}

/** offline/lesson/create  **/
.calendar-box {
  .tblclass-list-height {
    padding: 30px 30px !important;
  }
}

.essay-editor {
  .DraftEditor-root {
    height: 50vh;
    border-right: 1px solid #eee;
    border-left: 1px solid #eee;
  }
}

.essay-editor-offline {
  .DraftEditor-root {
    height: 35vh;
    border-right: 1px solid #eee;
    border-left: 1px solid #eee;
  }
}

.essay-editor_offline {
  .DraftEditor-root {
    height: 30vh;
    border-right: 1px solid #eee;
    border-left: 1px solid #eee;
  }
}

.default .sc-jSgupP.tblclass-list-height {
  padding: 20px !important;
}

.addnew-testmanagment-testblock.spacetop-testmanagment {
  .ant-table-wrapper {
    .ant-pagination {
      justify-content: center;
    }
  }

  .ant-table-wrapper .ant-table-container .ant-table-body {
    height: auto !important;
  }
}

.syll_btn {
  display: none;
}

/** responsive **/

@media (max-width: 1700px) {
  .ant-row.tblclass-list {
    width: 100%;
  }
}

@media (min-width: 1000px) and (max-width: 1200px) {
  /** /offline/lesson/create  **/

  .calendar-box-two {
    .header-classroom-teacher {
      .header-classroom-search {
        .ant-btn:nth-child(3),
        .ant-btn:nth-child(4) {
          width: 4vw;
          font-size: 0.8rem;
        }

        .ant-btn:nth-child(3) {
          span {
            display: inline-flex;
            margin: 0;
            position: relative;
            left: -15px;
          }
        }
        .ant-btn:nth-child(4) {
          span {
            display: inline-flex;
            margin: 0;
            position: relative;
            left: -5px;
          }
        }
      }
    }
  }
}

@media (max-width: 1240px) {
  .default {
    .buttonlisting-dashboard {
      .ant-typography.sc-hKgILt {
        font-size: 1.45rem !important;
      }
    }
  }

  .addnew-testmanagment-testblock-add .testmanagment-sectiona-testsectionbk {
    height: auto !important;
    min-height: auto !important;

    .ant-form-horizontal {
      width: 55%;
      margin: 0 auto;
      min-height: 300px;
    }
  }

  .addnew-testmanagement {
    .testmanagment-sectiona-testsectionbk {
      .ant-form-horizontal {
        width: 70%;
        margin: 0 auto;
      }
    }
  }

  .testmanagment-testsectionblock {
    .ant-table-wrapper {
      .ant-table-container {
        .ant-table-header {
          table {
            table-layout: auto !important;
          }
        }

        .ant-table-body {
          table {
            table-layout: auto !important;
          }
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .test-syllabus-wrapper .test-syllabus-box-listing .test-syllabus-box-c button {
    // margin-top: 20px;
    position: relative;
    top: 0;
  }

  .test-syllabus-wrapper
    .test-syllabus-box-listing
    .test-syllabus-box-c
    .ant-table-wrapper
    .ant-table
    .ant-table-container
    .ant-table-body {
    height: auto !important;
    min-height: auto !important;
  }
}

@media (max-width: 1000px) {
  .default .calendar-box .btn-schedule {
    margin-right: 0 !important;
    margin-left: auto !important;
  }

  .buttonlisting-dashboard {
    .ant-col-2.calendar-box {
      justify-content: flex-end;
    }

    .ant-typography,
    .ant-col.calendar-box {
      display: inline-flex;
      justify-content: center;
    }

    .ant-col {
      &.ant-col-1 {
        max-width: 10%;
        border: 2px solid red !important;
        &.calendar-box {
          margin-top: 0;
          .btn-schedule {
            margin-top: 0 !important;
          }
        }
      }

      .calendar-box {
        margin-top: 0;
        .btn-schedule {
          margin-top: 0 !important;
        }
      }
    }

    & ~ div {
      .header-classroom-title {
        display: block;
        width: 100%;
      }
    }

    .ant-col.ant-col-19,
    .ant-col.ant-col-1 {
      display: none;
    }

    .ant-col.calendar-box:nth-child(3) {
      width: 20%;
    }

    .ant-typography {
      text-align: center;
      justify-content: center;
    }

    .btn-schedule {
      justify-content: flex-end;
    }

    .ant-typography {
      /* font-size: 1.45rem !important; */
    }

    .sc-jSgupP {
      min-height: auto !important;
      height: auto !important;
    }
  }

  .default .calendar-box {
    justify-content: flex-end;
    margin-top: 0;
  }

  .test-syllabus-wrapper .test-syllabus-box-listing .test-syllabus-box-a .test-syllabus-box-a-tsb,
  .test-syllabus-wrapper .test-syllabus-box-listing .test-syllabus-box-a .test-syllabus-box-b-tsb,
  .test-syllabus-wrapper .test-syllabus-box-listing .test-syllabus-box-a .test-syllabus-box-c-tsb,
  .test-syllabus-wrapper .test-syllabus-box-listing .test-syllabus-box-b .test-syllabus-box-a-tsb,
  .test-syllabus-wrapper .test-syllabus-box-listing .test-syllabus-box-b .test-syllabus-box-b-tsb,
  .test-syllabus-wrapper .test-syllabus-box-listing .test-syllabus-box-b .test-syllabus-box-c-tsb,
  .test-syllabus-wrapper .test-syllabus-box-listing .test-syllabus-box-c .test-syllabus-box-a-tsb,
  .test-syllabus-wrapper .test-syllabus-box-listing .test-syllabus-box-c .test-syllabus-box-b-tsb,
  .test-syllabus-wrapper .test-syllabus-box-listing .test-syllabus-box-c .test-syllabus-box-c-tsb {
    height: auto !important;
    margin: 30px auto;
  }

  .test-syllabus-wrapper .test-syllabus-box-listing .test-syllabus-box-a .test-syllabus-box-a-tsb {
    width: 65vw;
    margin: 0 auto;
  }

  .test-syllabus-box-listing {
    .test-syllabus-box-b {
      .mini-calendar {
        width: 75%;
        margin: 0 auto;
      }
    }
  }
}

@media (max-width: 768px) {
  .default {
    .tblclass-list {
      .header-classroom-search {
        /*
                display: flex;
                flex-direction: row-reverse; */
        display: none;
        .btn-schedule {
          padding: 5.5px;
        }
      }
    }

    .syllabus-dropdown,
    .syllabus-plan-dropdown {
      display: block;
    }

    .header-classroom {
      justify-content: flex-end !important;
      .ant-form-horizontal {
        /*  margin-top: 10px; */
        .ant-space-item:first-child {
          span {
            font-size: 0.8rem !important;
          }
        }
        .ant-space-item:nth-child(2) {
          width: auto !important;
        }
      }

      .ant-typography.header-classroom-title {
        margin-top: 10px;
      }
    }
  }
  .syll_btn {
    display: block;
    width: 20%;
    max-width: 200px;
    margin-left: auto;
    margin-right: 20px;
  }
  .syllabus_subMenu {
  }
}

@media (max-width: 660px) {
  .test-syllabus-box-listing .test-syllabus-box-b .ant-checkbox-group label {
    width: auto !important;
  }

  .test-syllabus-wrapper .test-syllabus-box-listing .test-syllabus-box-b .test-syllabus-box-b-tsb {
    .mini-calander {
      margin: 30px 0;
    }

    .fc .fc-button .fc-icon {
      vertical-align: middle;
      font-size: 1rem;
    }
  }

  .test-syllabus-wrapper .test-syllabus-box-listing .test-syllabus-box-c button {
    margin: 20px 0;
    top: 0;
  }

  .default .tblclass-list .header-classroom-search {
    justify-content: flex-start;
  }

  .default {
    .header-classroom {
      .ant-form-horizontal {
        justify-content: end;
        display: flex;
        margin: 10px 0;
      }
    }
  }
}

@media (max-width: 500px) {
  /*
    .default .tblclass-list .header-classroom .ant-form .ant-space-align-center{
        flex-direction: column;
    }*/

  .default {
    .header-classroom-search {
      .ant-space-item {
        .ant-btn-primary > a:only-child {
          font-size: 0.8rem;
        }
      }
    }
  }

  .default {
    .tblclass-list {
      .header-classroom-search {
        justify-content: center;
      }
    }
    .header-classroom {
      .ant-form {
        .ant-space-align-center {
          /* flex-direction: column; */
        }
      }
    }
  }

  .default .tblclass-list .header-classroom-search .ant-form-horizontal .ant-space .ant-space-item:last-child button {
    margin-right: 0;
  }
}

@media (max-width: 375px) {
  .test-syllabus-wrapper .test-syllabus-box-listing .test-syllabus-box-b .test-syllabus-box-b-tsb {
    .ant-checkbox-group-item {
      margin-right: 2px;
    }
  }
}

.halfmanagment-space {
  margin-bottom: 10px;
}
@media (max-width: 1023px) {
  .classroom-box2,
  .classroom-box1 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .tblclass-classroom-height {
    min-height: auto !important;
  }
  .classroom-box1 {
    margin-top: 15px;
  }
  .classroom-table-table {
    .ant-table-container {
      .ant-table-content {
        overflow: auto hidden;
        table {
          width: 1000px !important;
        }
      }
    }
  }
}
@media (max-width: 767px) {
  .classmodal {
    .ant-col {
      flex: 0 0 100% !important;
      max-width: 100% !important;
      .ant-row {
        display: block;
        margin-right: 0px !important;
        width: 100% !important;
      }
      .ant-select {
        width: 100% !important;
      }
      .ant-picker {
        width: 100% !important;
      }
    }
  }
  .postcode-class-modal {
    width: 90% !important;
    .ant-modal-body > div > div {
      width: 90% !important;
      min-width: 100% !important;
      iframe {
        width: 100% !important;
        min-width: 100% !important;
      }
    }
  }
  .modify-schedule-modal {
    .modify-schedule-modal-a,
    .modify-schedule-modal-b {
      flex: 0 0 100%;
      max-width: 100%;
    }
    .modify-schedule-modal-b {
      table {
        width: 100% !important;
        tr {
          td {
            .fc-daygrid-body {
              width: 100% !important;
            }
          }
        }
      }
    }
  }
  .header-classroom-teacher {
    display: block !important;
    .header-classroom-title {
      width: 100% !important;
    }
    .header-classroom-search {
      .ant-picker,
      .inputsearch-classroom {
        width: 100% !important;
        margin-bottom: 10px;
      }
    }
  }
  .searchbox-teacherdtail {
    margin-bottom: 10px;
  }
  .modify-schedule-modal {
    .detaildatapopup-table {
      .ant-table-container {
        table {
          width: 800px !important;
        }
      }
    }
  }
  ul.submenulist li {
    height: auto !important;
  }
}
@media (max-width: 600px) {
  .classroom-swap-modal {
    width: 90% !important;
    .ant-modal-body {
      .ant-transfer {
        display: block;
        .ant-transfer-list {
        }
        .ant-transfer-operation {
          align-items: center;
          margin: 20px 0px;
          button:nth-child(1) {
            transform: rotate(269deg);
            margin-bottom: 20px;
          }
          button:nth-child(2) {
            transform: rotate(269deg);
          }
        }
        .ant-transfer-list {
        }
      }
    }
  }
  .instructor-information-modal {
    .ant-modal-body {
      form {
        .ant-col {
          flex: 0 0 100%;
          max-width: 100%;
        }
      }
    }
  }
}
@media (max-width: 500px) {
  .header-classroom {
    display: block !important;
    .header-classroom-title {
      width: 100% !important;
    }
    .header-classroom-search {
      .ant-picker,
      .inputsearch-classroom {
        width: 100% !important;
        margin-bottom: 10px;
      }
    }
  }
  .fullwdmodal,
  .instructor-information-modal {
    width: 90% !important;
  }
  .emailidwdth .ant-row .ant-col:nth-child(2) {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100px;
  }
}

@media (max-width: 400px) {
  .modify-schedule-modal-b {
    .fc-toolbar-chunk {
      .fc-toolbar-title {
        font-size: 16px;
      }
    }
  }
}

.statustbl {
  width: 20px;
  height: 20px;
  background: #548235;
  border-radius: 50%;
  margin: 0px auto;
  display: flex;
}
.statustbl1 {
  width: 20px;
  height: 20px;
  background: red;
  border-radius: 50%;
  margin: 0px auto;
  display: flex;
}
.statustbl2 {
  width: 20px;
  height: 20px;
  background: yellow;
  border-radius: 50%;
  margin: 0px auto;
  display: flex;
}

// lucid
.lucid {
  .classroom-box-wrap-height {
    .classroom-box1 {
      .tblclass-classroom-height {
        .ant-typography.ant-typography-secondary {
          text-align: center;
          margin-bottom: 0.5rem;
          display: block;
        }
      }
    }
    .classroom-box2 {
      .tblclass-classroom-height {
        .ant-typography.ant-typography-success {
          color: #edff00;
        }
      }
    }
  }
}

@media (max-width: 1366px) {
  .lucid {
    .classroom-box-wrap-height {
      .classroom-box1 {
        .tblclass-classroom-height {
          .ant-row-bottom {
            .ant-col-md-6 {
              max-width: 100%;
              flex: none;
              margin: 0 auto;
            }

            .ant-col-md-11 {
              flex: none;
              max-width: 100%;
            }

            .ant-col-md-24 {
              max-width: 100%;
              flex: none;
              margin-left: auto;
              display: flex;
              flex-direction: column;
              justify-content: center;
            }
          }
        }
      }

      .classroom-box2 {
        .tblclass-classroom-height {
          .ant-col-24 {
            .ant-table-pagination.ant-pagination {
              justify-content: center;
            }
          }

          .ant-row-end {
            .ant-col-lg-12 {
              .ant-row {
                margin-left: -4rem !important;
              }
            }
          }
        }
      }
    }
  }

  #control-ref {
    .ant-col-xl-8 {
      align-items: center;
      display: flex;
    }
  }

  .ant-modal {
    &.lucid.modify-schedule-modal {
      .ant-modal-content {
        .ant-modal-body {
          .ant-col-12 {
            &.modify-schedule-modal-b {
              .fc {
                &.fc-media-screen.fc-direction-ltr {
                  .fc-header-toolbar.fc-toolbar {
                    .fc-toolbar-chunk {
                      &:last-child {
                        display: flex;
                        flex-direction: row;
                        .fc-button-group {
                          flex-direction: row;
                          button {
                            margin: 0 0.2rem;
                            .fc-icon-chevron-left {
                              &:before {
                                margin: 0.1rem 0;
                                display: block;
                              }
                            }

                            .fc-icon-chevron-right {
                              &:before {
                                margin: 0.1rem 0;
                                display: block;
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
              div:nth-child(2) {
                display: flex;
                justify-content: center;
                flex-direction: column;

                .ant-select-show-search {
                  width: 100% !important;
                  margin-top: 2rem;
                }

                .ant-checkbox-group {
                  margin: 1rem 0;
                  display: flex;
                  justify-content: center;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1200px) {
  .lucid {
    .classroom-box-wrap-height {
      .classroom-box1 {
        .tblclass-classroom-height {
          .ant-row-bottom {
            flex-direction: column;
            align-items: center;
            .ant-col-md-6 {
              max-width: 100%;
              flex: none;
              margin: 0 auto;
              .ant-typography {
                text-align: center;
              }
            }

            .ant-col-md-11 {
              flex: none;
              max-width: 75%;
              margin: 2rem auto;

              .ant-row {
                .halfmanagment-space {
                  margin: 0.4rem auto;
                  width: 100vw;
                }
              }
            }

            .ant-col-md-24 {
              max-width: 100%;
              flex: none;
              margin-left: 5%;
              display: flex;
              flex-direction: column;
              justify-content: center;

              .ant-row {
                &:last-child {
                  .ant-col-12 {
                    &:last-child {
                      padding-left: 0 !important;
                      padding-right: 0 !important;

                      button {
                        margin-right: 2rem !important;
                      }
                    }
                  }
                }
              }

              .ant-col-24 {
                text-align: center;
              }
            }
          }
        }
      }
    }
  }

  .ant-modal {
    &.lucid.modify-schedule-modal {
      .ant-modal-content {
        .ant-modal-body {
          .ant-col-12 {
            &.modify-schedule-modal-a {
              .ant-col-offset-15 {
                margin-left: 57.5%;
                margin-bottom: 3%;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 820px) {
  .lucid {
    .classroom-box-wrap-height {
      .classroom-box1 {
        .tblclass-classroom-height {
          .ant-row-bottom {
            .ant-col-md-11 {
              max-width: 60%;
            }
          }
        }
      }

      .classroom-box2 {
        .tblclass-classroom-height {
          .ant-row-end {
            .ant-col-lg-12 {
              .ant-row {
                margin-left: 4% !important;
              }
            }
          }
        }
      }
    }
  }

  .ant-modal {
    &.lucid {
      &.modify-schedule-modal {
        .ant-modal-content {
          .ant-modal-body {
            .ant-col-12 {
              &.modify-schedule-modal-b {
                div:nth-child(2) {
                  .ant-checkbox-group {
                    margin: 1rem 0 4rem;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

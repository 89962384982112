.zoom-wrapper-main
{
    margin-top: 10px !important;
    .zoom-wrapper-mainblock
    {
        max-height: calc(100vh - 84px);
        height: calc(100vh - 84px);
        .zoom-top-head
        {
            margin-bottom: 15px !important;
        }
        .zoom-top-rightform
        {
            .zoom-top-select
            {
                margin-bottom: 0px !important;
                margin-right: 15px !important;
            }
        }
    }
}

.zoom-manage-add
{
    .zoom-manage-block
    {
        padding:0px 15px;
        .zoom-manage-wrap
        {
            .zoom-manage-box-a,
            .zoom-manage-box-b,
            .zoom-manage-box-c,
            .zoom-manage-box-d
            {
                padding-left: 10px !important;
                padding-right: 10px !important;
                padding-top: 10px !important;
                padding-bottom: 10px !important;
              

                .zoom-manage-box-a-title,
                .zoom-manage-box-b-title,
                .zoom-manage-box-c-title,
                .zoom-manage-box-d-title
                {
                    text-align: left;
                }
            }
            .zoom-manage-box-c
            {
                .zoom-manage-box-c-form-date,
                .zoom-manage-box-c-form-stime,
                .zoom-manage-box-c-form-etime,
                .zoom-manage-box-c-form-title
                {
                    margin-bottom: 10px !important;
                    div 
                    {
                        width: 100% !important;
                    }
                    .ant-picker-large {
                        padding: 4px 10px !important;
                    }
                    
                }
                .fc-scroller
                    {
                        overflow: auto !important;
                    }
            }
            .zoom-manage-box-d
            {
                .zoom-manage-box-d-block
                {
                    .zoom-manage-box-d-form
                    {
                        .zoom-manage-box-d-list
                        {
                            ul.ant-list-items
                            {
                                li 
                                {
                                    padding: 10px !important;
                                }
                            }
                        }
                        button[type="submit"]
                        {
                            height: auto;
                            padding: 5px 10px !important;
                            width: 100px;
                            border-radius: 6px !important
                        }
                    }
                }
            }
        }
    }
}
.zoomvideo-wrapper
{
    .zoomvideo-mailblock
    {
        max-height: calc(100vh - 84px) !important;
        height: calc(100vh - 84px) !important;
        .zoomvideo-topheader
        {
            margin-bottom: 15px !important;
            .zoomvideo-topheader-title,
            .zoomvideo-topheader-selectfield
            {

            }
        }
    }
}
@media (max-width:1023px)
{
    .zoom-wrapper-main .zoom-wrapper-mainblock .zoomtablelist table {
        width: 1200px !important;
    }
    .zoom-manage-add .zoom-manage-block .zoom-manage-wrap .zoom-manage-box-a, 
    .zoom-manage-add .zoom-manage-block .zoom-manage-wrap .zoom-manage-box-b, 
    .zoom-manage-add .zoom-manage-block .zoom-manage-wrap .zoom-manage-box-c, 
    .zoom-manage-add .zoom-manage-block .zoom-manage-wrap .zoom-manage-box-d
    {
        flex: 0 0 100% !important;
        max-width: 100% !important;
        margin-bottom: 20px !important;
    }
    .zoomvideo-wrapper
    {
        .zoomvideo-mailblock
        {
            .zoomvideo-topheader
            {
                .zoomvideo-topheader-title,
                .zoomvideo-topheader-selectfield
                {
                    flex: 0 0 100% !important;
                    max-width: 100% !important;
                    justify-content: end !important;
                }
            }
            .zoomvideo-listing
            {
                .zoomvideo-listing-col
                {
                    padding: 0px !important;
                    .zoomvideo-shadwblock
                    {
                        h5.ant-typography
                        {
                            margin: 0px !important;
                        }
                        .zoomvideo-listing-listblock
                        {
                            padding: 0px !important;
                            .zoomvideo-listing-list
                            {
                                ul.ant-list-items
                                {
                                    li.ant-list-item
                                    {
                                        .ant-list-item-main
                                        {
                                            .ant-list-item-meta
                                            {
                                                margin-bottom: 0px !important;
                                                .ant-list-item-meta-avatar
                                                {
                                                    position: relative;
                                                    span 
                                                    {
                                                        vertical-align: top !important;
                                                    }
                                                    .ant-spin
                                                    {
                                                        margin-left: -120px;
                                                        width: 120px !important;
                                                        position: absolute;
                                                        background: #000;
                                                        height: 90px;
                                                        padding-top: 25px !important;
                                                      
                                                    }
                                                }
                                                .ant-list-item-meta-content
                                                {
                                                    h4
                                                    {
                                                        margin-bottom: 6px !important;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

            }
        }
    }
}
@media (max-width:767px)
{
    .zoom-wrapper-main
    {
        .zoom-wrapper-mainblock
        {
            .zoom-top-head
            {
                .zoom-top-title
                {
                    flex: 100% !important;
                    max-width: 100% !important;
                }
                .zoom-top-rightform
                {
                    flex: 100% !important;
                    max-width: 100% !important;
                    justify-content: end !important;
                    .zoom-top-select
                    {
                        label
                        {
                            margin-right: 15px !important;
                        }
                    }
                    .ant-select
                    {
                        width: 100% !important;
                    }
                }
            }
        }
    }
    
}

@media (max-width:600px)
{
    .zoomvideo-wrapper .zoomvideo-mailblock .zoomvideo-listing .zoomvideo-listing-col .zoomvideo-shadwblock .zoomvideo-listing-listblock .zoomvideo-listing-list ul.ant-list-items li.ant-list-item .ant-list-item-main 
    {
        .ant-list-item-meta
        {
            .ant-list-item-meta-content
            {
                width: 100%;
            }
        }
       
    }  
    .zoomvideo-wrapper .zoomvideo-mailblock .zoomvideo-listing .aant-list-item-extra
    {
        vertical-align: top !important;
        margin: 0px !important;
    }
    .zoomvideo-wrapper .zoomvideo-mailblock .zoomvideo-listing .zoomvideo-listing-col .zoomvideo-shadwblock .zoomvideo-listing-listblock .zoomvideo-listing-list ul.ant-list-items li.ant-list-item .ant-list-item-main .ant-list-item-meta .ant-list-item-meta-content h4
    {
        height: 49px;
        overflow: hidden;
    }
}
@media(max-width:1024px)
{
    .iXPIxR
    {
        height: auto !important;
    }
    
    .listenandrepeatstt-qa
    {
        flex: 0 0 100%;
        max-width: 100%;
        .listenandrepeatstt-qa-box
        {
            height: auto !important;
            max-height: 30vh;
            overflow: auto;
            h4.ant-typography
            {
                padding: 10px;
                border-radius: 5px;
            }
            .questionans
            {
                margin-bottom: 10px !important;
                margin-top: 10px !important;
            }
        }
    }
    .listenandrepeatstt-player
    {
        flex: 0 0 100%;
        max-width: 100%;
        .flip-card-container
        {
            height: auto !important;
            min-height: auto !important;
            .front.type2
            {
                position: static;
                font-size: 22px !important;
                p
                {
                    margin-bottom: 0px;
                }
            }
        }
        
    }
    h4.ant-typography
    {
        font-size: 12px;
    }
    .listenandrepeat-content-listing h4.ant-typography
    {
        
        border: 1px solid #b8d5ef;
        border-radius: 6px;
    }
    .listenandrepeat-content-listing .ant-card-body
    {
        height: auto !important;
        overflow: auto !important;
        max-height: 30vh;
    }
    .starticon span,
    .pauseicon span
    {
        font-size: 40px !important;
    }
    .iconlist
    {
        margin-top: 20px;
        margin-bottom: 100px;
        img
        {
            height: 70px !important;
            width: 70px !important;
        }
    }
    .topheading
    {
        .topheading-inside
        {
            padding-left:0px !important;
            padding-right:0px !important;
            .topheading-main-h2
            {
                .topheading-h2
                {
                    padding-left:0px !important;
                    padding-right:0px !important;
                    h2 
                    {
                        span 
                        {
                            margin:0px;
                        }
                    }
                }
            }
        }
    }
}

@media all and (max-width:1023px) and (orientation : landscape) {
    .listenandrepeat-content-listing .ant-card-body,
    .listenandrepeatstt-qa-box {
        
        overflow: auto !important;
        max-height:100% !important;
    }
}
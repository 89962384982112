body {
  background-color: #f0f2f5;
  line-height: unset;
}

.show-print,
.show-print-flex {
  display: none !important;
}

.board-campusnotice-tablehead {
  background: #edf3fb;
  border-top: 3px solid #a4d0e9;
  padding: 15px;
  height: 55px;
}

.backbtn-top-boarddetails {
  padding-top: 5px;
  text-align: right;
}

.main-layout {
  background: linear-gradient(to right, rgb(197, 246, 250), rgb(170, 231, 236));
  height: 100vh;
}

//  bookr ebook-library container
.elib-layout {
  //  background: #cbc7df;
  background: #644fec;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #f5f5f5;
    visibility: hidden;
  }

  &::-webkit-scrollbar {
    width: 1px;
    background-color: #f5f5f5;
    visibility: hidden;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #fff;
    visibility: hidden;
  }

  &:before {
    content: '';
    background-image: url('../images/cloud_merged2.png');
    background-repeat: no-repeat;
    background-size: contain;
    width: 380px;
    height: 330px;
    position: fixed;
    top: 30%;
    left: -6.5%;
    background-position: center center;
    // transform: rotate(90deg);
  }

  &:after {
    content: '';
    background-image: url('../images/bookr_bunny2.png');
    background-repeat: no-repeat;
    background-size: contain;
    width: 380px;
    height: 350px;
    position: fixed;
    top: 60%;
    right: 0;
    background-position: center center;
  }

  .ant-layout-content {
    min-height: 100vh;
  }

  // part images in bg
  .partimg-bg {
    &.banana {
      width: 60px;
      position: absolute;
      top: 23%;
      left: 6%;
    }

    &.butterfly {
      width: 60px;
      position: absolute;
      right: 3%;
      top: 35%;
      transform: rotate(-80deg);
    }

    &.bird {
      width: 60px;
      position: absolute;
      right: 7%;
      top: 20%;
    }
  }
}

@media (max-height: 1200px) {
  .scrollscroll {
    overflow-x: hidden;
    overflow-y: auto;
  }

  .wordlist-slider .ant-card-body {
    overflow: auto;
    height: calc(100% - 100px);
  }

  .listenword-wrap {
    .ant-card.ant-card-bordered .ant-card-body {
      overflow: auto;
      height: calc(100% - 100px);
    }
  }

  .voca-fluid .ant-card-bordered .ant-card-body {
    overflow: auto;
    height: calc(100% - 100px);
  }

  .tblclass-list {
    margin-bottom: 0px;
  }
}

@media all and (min-height: 501px) and (max-height: 1200px) and (orientation: landscape) {
  .vimeovideo-main {
    .ant-modal-body>div {
      display: inline-block;
      width: 100%;
      height: 100%;
    }

    .ant-modal-body>div>div {
      padding: 0px !important;
      position: relative;
      width: 100% !important;
      height: 100% !important;
    }
  }
}

@media (max-width: 1023px) {
  .vimeovideo-main {
    .ant-modal-body {
      height: auto !important;
    }
  }

  .vimeovideo-main .ant-modal-body>div>div {
    padding: 60% 0 0 0 !important;
  }

  .mainheading-spaces {
    padding-left: 0px !important;
    padding-right: 0px !important;

    .mainheading-spaces-col {
      padding-left: 0px !important;
      padding-right: 0px !important;

      h2.ant-typography {
        font-size: 24px !important;

        span {
          margin-left: 0px !important;
        }
      }
    }
  }
}

@media (max-width: 767px) {

  h2.ant-typography,
  .ant-typography h2 {
    margin-bottom: 0.5em;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 600;
    font-size: 24px;
    line-height: 1.35;
  }

  .mainlogin-wrappper>div {
    width: 100% !important;
    height: auto !important;
    padding: 30px !important;
    border-radius: 6px;
  }
}

@media (max-width: 350px) {
  .mainlogin-wrappper>div {
    padding: 20px !important;
  }
}

@media print {

  html,
  body {
    height: initial !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
  }

  .hide-print {
    display: none;
  }

  .show-print {
    display: block !important;
  }

  .show-print-flex {
    display: flex !important;
  }

  .page-break {
    margin-top: 1rem;
    display: block;
    page-break-before: auto;
  }
}

.transparent-sub-menu-list {
  opacity: 0.9;
}

.fc-view-month .fc-event,
.fc-view-agendaWeek .fc-event,
.fc-content,
.fc-event-title-container {
  font-size: 0;
  overflow: hidden;
  height: 5px;
}

.mini-calander {

  .fc-toolbar,
  .fc-col-header-cell-cushion {
    font-size: 0.9em;
  }

  .fc-toolbar h2 {
    font-size: 16px;
    white-space: normal !important;
  }

  /* click +2 more for popup */
  .fc-more-cell a {
    display: block;
    width: 85%;
    margin: 1px auto 0 auto;
    border-radius: 3px;
    background: grey;
    color: transparent;
    overflow: hidden;
    height: 4px;
  }

  .fc-more-popover {
    width: 100px;
  }

  .fc-daygrid-day-events {
    min-height: 0.8em !important;
  }

  .fc-view-agendaWeek .fc-event-vert {
    font-size: 0;
    overflow: hidden;
    width: 2px !important;
  }

  .fc-agenda-axis,
  .fc-daygrid-day-number {
    width: 20px !important;
    font-size: 0.5em;
  }

  .fc-button-content {
    padding: 0;
  }
}

.pad-4 {
  padding: 4px !important;
}

.ant-select-selection-placeholder {
  color: #787878;
}

.clickRowStyle {
  //background-color: #f2f2f2;
  background-color: #e6f7ff;
}

.file__menu__header .ant-menu-item,
.file__menu__header .ant-menu-submenu {
  height: 100%;
}

.file__menu__header .ant-switch-handle {
  width: 24px;
  height: 24px;
  left: 8px;
}

.file__menu__header .ant-switch-inner {
  margin: 0 7px 0 36px;
  font-size: 18px;
}

.file__menu__header .ant-switch-checked .ant-switch-inner {
  margin: 0 36px 0 7px;
}

.file__menu__header .ant-switch-checked .ant-switch-handle {
  left: calc(100% - 30px - 2px);
}

.file__select_switch {
  background-color: #6f6e6ee6;
  font-size: 18px;
  line-height: 28px;
  height: 30px;
}

.file__select_switch.ant-switch-checked {
  background-color: #3458cc;
}

$new-white: rgb(248, 246, 234);
$text-margin: 10px;

.flip-card-container {
  min-height: 45vh;
  height: 100%;
  margin: 0;
  padding: 0;
  width: 100%;
  position: relative;
  transform-style: preserve-3d;
  box-sizing: border-box;

  @media (max-height: 700px) {
    min-height: 35vh;
  }

  &.flipped {
    .front {
      transform: rotateY(180deg);
    }

    .back {
      transform: rotateY(0deg);
    }
  }

  .front,
  .back {
    box-sizing: border-box;
    height: 100%;
    width: 100%;
    display: block;
    background: $new-white;
    // box-shadow: 10px 10px 5px rgb(95, 77, 99);
    padding: 10px;
    position: absolute;
    border-radius: 10px;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    transform-style: preserve-3d;
    transition: -webkit-transform ease 500ms;
    transition: transform ease 500ms;
    padding: 20px;
    font-size: 40px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .type2 {
    background: #b8d5ef;
  }

  .front {
    z-index: 2;
    transform: rotateY(0deg);
    /* front tile styles go here! */
  }

  .back {
    background: $new-white;
    transform: rotateY(-180deg);
    color: #5797d0;
    /* back tile styles go here! */
  }

  .image-container {
    position: relative;

    .card-image {
      width: 100%;
      border-radius: 10px;
    }

    .title {
      font-size: 34px;
      position: absolute;
      margin-bottom: $text-margin;
      margin-left: $text-margin;
      bottom: 0;
      left: 0;
      color: $new-white;
    }
  }

  .main-area {
    margin-left: $text-margin;
    margin-top: $text-margin;
    margin-right: $text-margin;

    .blog-post {
      height: 100%;

      .date {
        margin-top: $text-margin;
        margin-bottom: 5px;
        font-size: 20px;
      }

      .read-more {
        font-size: 20px;
        position: relative;
        bottom: 0px;
        text-align: right;
      }

      .blog-content {
        height: 90%;
        display: block;
        margin-top: $text-margin;
        margin-bottom: $text-margin;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}

.culp-keyboard-theme {
  background-color: #5b9bd5;

  &.hg-theme-default .hg-button {
    height: 60px;
    color: #5b9bd5;
    font-size: 18px;

    @media (max-height: 700px) {
      height: 40px;
      font-size: 16px;
    }
  }
}

.blink_me {
  animation: blinker 1s linear infinite;
}

.blink_me_slow {
  animation: blinker 3s ease infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}


.border_blink {
  animation: blink-border-animation 1s linear infinite;
  -webkit-animation: blink-border-animation 1s linear infinite;
  -moz-animation: blink-border-animation 1s linear infinite;
  -o-animation: blink-border-animation 1s linear infinite;
}

@keyframes blink-border-animation {
  to {
    background-image: url("../../public/images/LevelUp/study/main/read_arrow_right_orange.png");
  }
}

@-webkit-keyframes blink-border-animation {
  to {
    background-image: url("../../public/images/LevelUp/study/main/read_arrow_right_orange.png");
  }
}

.main-folder-class .ant-checkbox {
  box-shadow: 0px 0px 0px 1px #4c4c4c;
}

.awesome-ebook-tab-nav-list .ant-tabs-nav-list {
  display: none !important;
}

.calendar-box>div,
.calendar-box-two-a,
.calendar-box-two-b,
.subboxone-dashboard-height,
.subboxtwo-dashboard-chat-height,
.tblclass-list-height,
.tblclass-classroom-height,
.homework-result-halfwrapper,
.myinfowrapper-children,
.ticketStatus-tabtwo,
.ticketStatus-tabthree,
.ticketStatus-tabfour,
.testmanagement-mailblock,
.testresult-mainblock,
.syllabus-mailblock,
.assigned-syllabus-halfwrapper,
.assigned-syllabus-halfwrapper,
.syllabus-assigned-halfwrapper,
.board-campusnotice-height,
.learning-halfwrapper,
.addnew-testmanagment-testblock,
.addnew-testmanagment-testblock-add,
.testmanagment-testblock,
.test-syllabus-wrapper-tb,
.pdffullscreen-scroll,
.pdffullscreen-box-scroll,
.test-manage-action-assign-wrapper,
.testdetails-mainblock,
.vtube-mainblock,
.shadowblock-a,
.shadowblock-b,
.zoom-manage-add .zoom-manage-block,
.zoom-wrapper-mainblock,
.elibrary-main-block,
.books-assign-testblock,
.main-folder-class,
.setting-main-maininfo,
.class-result-halfwrap,
.homeresult-halfwrapper,
.placement-test-halfwrapper,
.dashboard-todoblock-listing,
.campus-halfwrapper,
.credit-halfwrapper {
  box-shadow: none !important;
  border-radius: 10px;
  border: 1px solid #ececec;
}

.calendar-box>div,
.calendar-box-two,
.subboxone-dashboard-height,
.subboxtwo-dashboard-chat-height,
.tblclass-list-height,
.tblclass-classroom-height,
.homework-result-halfwrapper,
.myinfowrapper-children,
.ticketStatus-tabtwo,
.ticketStatus-tabthree,
.ticketStatus-tabfour,
.assigned-syllabus-halfwrapper,
.syllabus-assigned-halfwrapper,
.board-campusnotice-height,
.learning-halfwrapper,
.setting-main-maininfo,
.class-result-halfwrap,
.homeresult-halfwrapper,
.placement-test-halfwrapper,
.dashboard-todoblock-listing,
.campus-halfwrapper,
.credit-halfwrapper {
  height: calc(100vh - 128px) !important;
  min-height: calc(100vh - 128px) !important;
  // overflow: auto;
  // overflow-x: hidden;
}

.test-syllabus-wrapper-tb,
.pdffullscreen-scroll,
.pdffullscreen-box-scroll,
.test-manage-action-assign-wrapper,
.test-manage-action-assign-wrapper-testblock,
.testdetails-mainblock,
.vtube-mainblock,
.zoom-wrapper-mainblock,
.zoom-manage-add .zoom-manage-block,
.elibrary-main-block,
.books-assign-testblock,
.main-folder-class {
  height: calc(100vh - 84px) !important;
  min-height: calc(100vh - 84px) !important;
  overflow: auto;
  overflow-x: hidden;
}

.zoom-manage-add .zoom-manage-block {

  .zoom-manage-box-a,
  .zoom-manage-box-b,
  .zoom-manage-box-c,
  .zoom-manage-box-d {
    height: calc(100vh - 84px) !important;
    overflow: auto;
  }
}

.credit-halfwrapper {
  .ant-table-wrapper {
    .ant-table {
      .ant-table-container {
        .ant-table-body {
          height: calc(100vh - 320px) !important;
          max-height: calc(100vh - 320px) !important;
          overflow: auto !important;
        }
      }
    }
  }
}

.placement-test-halfwrapper {
  .ant-table-wrapper {
    .ant-table {
      .ant-table-container {
        .ant-table-body {
          height: calc(100vh - 270px) !important;
          max-height: calc(100vh - 270px) !important;
          overflow: auto !important;
        }
      }
    }
  }
}

.campus-halfwrapper {
  .ant-table-wrapper {
    .ant-table {
      .ant-table-container {
        .ant-table-body {
          height: calc(100vh - 340px) !important;
          max-height: calc(100vh - 340px) !important;
          overflow: auto !important;
        }
      }
    }
  }
}

.homeresult-halfwrapper {
  .ant-table-wrapper {
    .ant-table {
      .ant-table-container {
        .ant-table-body {
          height: calc(100vh - 270px) !important;
          max-height: calc(100vh - 270px) !important;
          overflow: auto !important;
        }
      }
    }
  }
}

.class-result-halfwrap {
  .ant-table-wrapper {
    .ant-table {
      .ant-table-container {
        .ant-table-body {
          height: calc(100vh - 370px) !important;
          max-height: calc(100vh - 370px) !important;
          overflow: auto !important;
        }
      }
    }
  }
}

.setting-wrapper-halfwrap {
  .ant-table-wrapper {
    .ant-table {
      .ant-table-container {
        .ant-table-body {
          height: calc(100vh - 310px) !important;
          max-height: calc(100vh - 310px) !important;
          overflow: auto !important;
        }
      }
    }
  }
}

.elibrary-main-block {
  .ant-table-wrapper {
    .ant-table {
      .ant-table-container {
        .ant-table-body {
          height: calc(100vh - 266px) !important;
          max-height: calc(100vh - 266px) !important;
          overflow: auto !important;
        }
      }
    }
  }
}

.zoomvideo-mailblock {
  .zoomvideo-listing-col {
    .zoomvideo-shadwblock {
      .zoomvideo-listing-listblock {
        height: calc(100vh - 230px) !important;
        min-height: calc(100vh - 230px) !important;
        overflow: auto;
      }
    }
  }
}

.zoom-wrapper-mainblock {
  .ant-table-wrapper {
    .ant-table {
      .ant-table-container {
        .ant-table-body {
          height: calc(100vh - 280px) !important;
          max-height: calc(100vh - 280px) !important;
          overflow: auto !important;
        }
      }
    }
  }
}

.shadowblock-a,
.shadowblock-b {
  margin-top: 10px;
  height: calc(100vh - 154px) !important;
  min-height: calc(100vh - 154px) !important;
  overflow: auto;
  overflow-x: hidden;

  .listblock-shadowblock-a {
    height: calc(100vh - 260px) !important;
    min-height: calc(100vh - 260px) !important;
    overflow: auto;
  }

  .listblock-shadowblock-b {
    height: calc(100vh - 210px) !important;
    min-height: calc(100vh - 210px) !important;
    overflow: auto;
  }
}

.zoom-manage-box-a {
  .zoom-manage-box-a-block {
    overflow: auto !important;

    ul.ant-list-items {
      height: calc(100vh - 140px) !important;
      min-height: calc(100vh - 140px) !important;
      overflow: auto;
    }
  }
}

.zoom-manage-box-b {
  .zoom-manage-box-b-block {
    overflow: auto !important;

    .ant-list {
      height: calc(100vh - 140px) !important;
      min-height: calc(100vh - 140px) !important;
      overflow: auto;
    }
  }
}

.zoom-manage-box-c,
.zoom-manage-box-d {

  .zoom-manage-box-c-block,
  .zoom-manage-box-d-block {
    overflow: auto !important;
  }
}

.testdetails-mainblock {
  .ant-table-wrapper {
    .ant-table {
      .ant-table-container {
        .ant-table-body {
          // height: calc(100vh - 250px) !important;
          max-height: calc(100vh - 300px) !important;
          overflow: auto !important;
        }
      }
    }
  }
}

.test-manage-action-assign-wrapper {
  padding: 10px;

  .test-manage-action-assign-scroll {
    height: calc(100vh - 114px) !important;
    min-height: calc(100vh - 114px) !important;
    overflow: auto;

    ul.ant-list-items {
      height: calc(100vh - 180px) !important;
      min-height: calc(100vh - 180px) !important;
      overflow: auto;
    }
  }

  .calanderform-test {
    .ant-row {
      margin-bottom: 10px;
    }
  }
}

.pdffullscreen-box-scroll {
  height: calc(100vh - 94px) !important;
  min-height: calc(100vh - 94px) !important;
  overflow: auto;
  overflow-x: hidden;
}

.questionblock-section {
  height: calc(100vh - 230px) !important;
  min-height: calc(100vh - 230px) !important;
  overflow: auto;
  overflow-x: hidden;
}

.testmanagment-testblock {
  height: calc(100vh - 131px) !important;
  min-height: calc(100vh - 131px) !important;
  overflow: auto;
  overflow-x: hidden !important;

  .testmanagment-testsectionblock,
  .testmanagment-testsectionblock-two {
    height: calc(100vh - 161px) !important;
    min-height: calc(100vh - 161px) !important;
    overflow: auto !important;
    overflow-x: hidden !important;

    .ant-table-wrapper {
      .ant-table {
        .ant-table-container {
          .ant-table-body {
            height: calc(100vh - 292px) !important;
            max-height: calc(100vh - 292px) !important;
            overflow: auto !important;
          }
        }
      }
    }
  }
}

.testmanagment-sectionb-tsb {
  overflow: auto !important;
  overflow-x: hidden !important;
  height: calc(100vh - 161px) !important;
  min-height: calc(100vh - 161px) !important;

  .ant-table-wrapper {
    .ant-table {
      .ant-table-container {
        .ant-table-body {
          height: calc(100vh - 230px) !important;
          max-height: calc(100vh - 230px) !important;
          overflow: auto !important;
        }
      }
    }
  }
}

.addnew-testmanagment-testblock-add {
  height: calc(100vh - 131px) !important;
  min-height: calc(100vh - 131px) !important;
  overflow: auto;
  overflow-x: hidden !important;

  .testmanagment-sectiona-testsectionbk {
    overflow: auto !important;
    height: calc(100vh - 161px) !important;
    min-height: calc(100vh - 161px) !important;

    form.ant-form {
      padding-right: 30px;

      .ant-select,
      .ant-upload {
        width: 100% !important;

        button {
          width: 100%;
        }
      }
    }
  }
}

.addnew-testmanagment-testblock {
  height: calc(100vh - 84px) !important;
  min-height: calc(100vh - 84px) !important;
  overflow: auto;

  .testmanagment-sectiona {
    height: calc(100vh - 110px) !important;
    min-height: calc(100vh - 110px) !important;
    overflow: auto !important;
  }

  .formsearchhidden {
    border: 1px solid #bdbdbd;
    margin-bottom: 10px;
    padding: 10px;
    border-radius: 6px;
  }
}

.testmanagment-testsectionblock {
  .ant-table-wrapper {
    .ant-table-container {
      .ant-table-header {
        table {
          thead {
            tr {
              th {
                padding: 10px;
                cursor: default;
              }
            }
          }
        }
      }

      .ant-table-body {
        height: calc(100vh - 274px) !important;
        max-height: calc(100vh - 274px) !important;
        overflow: auto !important;
      }
    }
  }
}

.board-campusnotice-height {
  .ant-table-wrapper {
    .ant-table-container {
      .ant-table-body {
        height: calc(100vh - 310px) !important;
        max-height: calc(100vh - 310px) !important;
        overflow: auto !important;
      }
    }
  }
}

.learning-halfwrapper {
  .ant-table-wrapper {
    .ant-table-container {
      .ant-table-body {
        height: calc(100vh - 247px) !important;
        max-height: calc(100vh - 247px) !important;
        overflow: auto !important;
      }
    }
  }
}

.campusnotice-section {
  height: calc(100vh - 391px) !important;
  min-height: calc(100vh - 391px) !important;
  overflow: auto;
}

.syllabus-assigned-halfwrapper {
  .ant-table-wrapper {
    .ant-table-container {
      .ant-table-body {
        height: calc(100vh - 251px) !important;
        max-height: calc(100vh - 251px) !important;
        overflow: auto !important;
      }
    }
  }
}

.assigned-syllabus-halfwrapper {
  .ant-table-wrapper {
    .ant-table-container {
      .ant-table-body {
        height: calc(100vh - 322px) !important;
        max-height: calc(100vh - 322px) !important;
        overflow: auto !important;
      }
    }
  }
}

.syllabus-mailblock {
  height: calc(100vh - 84px) !important;
  min-height: calc(100vh - 84px) !important;
  overflow: auto;

  .ant-table-wrapper {
    .ant-table-container {
      .ant-table-body {
        height: calc(100vh - 260px) !important;
        max-height: calc(100vh - 260px) !important;
        overflow: auto !important;
      }
    }
  }
}

.testresult-mainblock {
  height: calc(100vh - 154px) !important;
  min-height: calc(100vh - 154px) !important;
  overflow: auto;

  .ant-table-wrapper {
    .ant-table-container {

      .ant-table-body,
      .ant-table-content {
        height: calc(100vh - 273px) !important;
        max-height: calc(100vh - 273px) !important;
        overflow: auto !important;
      }
    }
  }
}

.testmanagement-mailblock {
  height: calc(100vh - 134px) !important;
  min-height: calc(100vh - 134px) !important;
  overflow: auto;

  .ant-table-wrapper {
    .ant-table-container {
      .ant-table-body {
        height: calc(100vh - 202px) !important;
        max-height: calc(100vh - 202px) !important;
        overflow: auto !important;
      }
    }
  }
}

.testmanagement-mailblock {
  .ant-table-wrapper {
    .ant-table-container {
      .ant-table-body {
        height: calc(100vh - 300px) !important;
        max-height: calc(100vh - 300px) !important;
        overflow: auto !important;
      }
    }
  }
}

.ticketStatus-tabthree {
  .ant-table-wrapper {
    margin-top: 10px;

    .ant-table-container {
      .ant-table-body {
        height: calc(100vh - 491px) !important;
        max-height: calc(100vh - 491px) !important;
        overflow: auto !important;
      }
    }
  }
}

.ticketStatus-tabtwo,
.ticketStatus-tabfour {
  .ant-table-wrapper {
    .ant-table-container {
      .ant-table-body {
        height: calc(100vh - 253px) !important;
        max-height: calc(100vh - 253px) !important;
        overflow: auto !important;
      }
    }
  }
}

.myinfowrapper-children {
  .tickettable {
    margin-top: 10px;
  }

  .ant-table-wrapper {
    .ant-table-container {
      .ant-table-body {
        height: calc(100vh - 421px) !important;
        max-height: calc(100vh - 421px) !important;
        overflow: auto !important;
      }
    }
  }
}

.homework-result-halfwrapper {
  .ant-table-wrapper {
    .ant-table-container {
      .ant-table-body {
        height: calc(100vh - 210px) !important;
        max-height: calc(100vh - 210px) !important;
        overflow: auto !important;
      }
    }
  }
}

.tblclass-classroom-height {
  .ant-table-wrapper {
    .ant-table-container {
      .ant-table-body {
        height: calc(100vh - 290px) !important;
        max-height: calc(100vh - 290px) !important;
        overflow: auto !important;
      }
    }
  }
}

.tblclass-list-height {
  .ant-table-wrapper {
    .ant-table-container {
      .ant-table-body {
        height: calc(100vh - 295px) !important;
        max-height: calc(100vh - 295px) !important;
        overflow: auto !important;
      }
    }
  }
}

.subboxone-dashboard-height {
  .subboxone-dashboard-btn {
    margin-bottom: 10px;
  }

  .ant-table-wrapper {
    .ant-table-container {
      .ant-table-body {
        height: calc(100vh - 320px) !important;
        max-height: calc(100vh - 320px) !important;
        overflow: auto !important;
      }
    }
  }
}

.calendar-box-two-a {
  .ant-table-wrapper {
    .ant-table-container {
      .ant-table-body {
        min-height: calc(50vh - 120px) !important;
        height: calc(50vh - 120px) !important;
        overflow: auto !important;
      }
    }
  }
}

.calendar-box-two-b {
  .ant-table-wrapper {
    height: calc(50vh - 124px);

    .ant-table-container {
      .ant-table-body {
        height: calc(50vh - 210px) !important;
        max-height: calc(50vh - 210px) !important;
        overflow: auto !important;
      }
    }
  }
}

.fc .fc-button-primary {
  padding-top: 0px !important;
  padding-left: 5px;
  padding-right: 5px;
}

.fc .fc-button-primary:focus {
  box-shadow: none !important;
}

.fc .fc-button-primary:not(:disabled):active,
.fc .fc-button-primary:not(:disabled).fc-button-active {
  background-color: transparent;
}

.calendar-box-two {
  .ant-table-body {
    tbody {
      tr {
        td {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
}

.calendar-box {
  table {
    thead {
      .fc-scroller-harness {
        .fc-scroller {
          overflow: auto !important;
        }
      }
    }

    tbody {
      .fc-scroller-harness {
        .fc-scroller {
          overflow: auto !important;
        }
      }
    }
  }
}

.buttonlisting-wrap {
  button {
    line-height: 22px;
  }

  a {
    button {
      line-height: 22px;
    }
  }
}

.subboxtwo-dashboard-chat-height {
  #MessageBox {
    overflow-y: auto;
    overflow-x: auto;
    min-height: calc(100vh - 310px) !important;
    height: calc(100vh - 310px) !important;
  }
}

.spacetop-testmanagment,
.main-folder-class {
  margin-top: 10px;
}

/**/
.main-folder-class {
  .ant-menu-overflow {
    &.submenulist {
      li {
        &:nth-child(1) {
          .ant-menu-title-content {
            button {
              border: 1px solid magenta;
            }
          }
        }
      }
    }
  }
}

.test-syllabus-wrapper {
  .books-assign-testblock .test-syllabus-box-listing {
    .test-syllabus-box-c {
      .ant-table-wrapper {
        .ant-table {
          .ant-table-container {
            .ant-table-body {
              height: calc(100vh - 370px) !important;
              max-height: calc(100vh - 370px) !important;
              overflow: auto !important;
            }
          }
        }
      }
    }

    .test-syllabus-box-b {
      .ant-form-item {
        margin-bottom: 10px;
      }
    }
  }
}

.test-syllabus-box-b-tsb,
.zoom-manage-box-c {
  .fc .fc-button-primary {
    padding-top: 5px !important;
  }
}

.backbutton-pv {
  color: #fff;
  cursor: pointer;
  text-align: center;
  position: absolute;
  top: 0px;
  width: 50%;
  z-index: 99;
  margin: 0px auto;
  left: 0;
  right: 0;
  padding: 2px;
  font-weight: 600;
  border-radius: 4px;
}

.homeresult-selectfiled {
  .ant-select {
    width: 40% !important;
    text-align: left;
    margin-right: 10px;
  }

  .homeresult-inputfield {
    width: 40%;
  }
}

.smart-eclass .calendar-box-two-a .ant-table-wrapper .ant-table-container .ant-table-body {
  min-height: calc(50vh - 150px) !important;
  height: calc(50vh - 150px) !important;
  overflow: auto !important;
}

.smart-eclass .calendar-box-two-b .ant-table-wrapper {
  height: calc(50vh - 140px);
}

.smart-eclass .calendar-box-two-b .ant-table-wrapper .ant-table-container .ant-table-body {
  height: calc(50vh - 230px) !important;
  max-height: calc(50vh - 230px) !important;
  overflow: auto !important;
}

.sneakersedu .calendar-box-two-a .ant-table-wrapper .ant-table-container .ant-table-body {
  min-height: calc(50vh - 150px) !important;
  height: calc(50vh - 150px) !important;
  overflow: auto !important;
}

.sneakersedu .calendar-box-two-b .ant-table-wrapper {
  height: calc(50vh - 140px);
}

.sneakersedu .calendar-box-two-b .ant-table-wrapper .ant-table-container .ant-table-body {
  height: calc(50vh - 230px) !important;
  max-height: calc(50vh - 230px) !important;
  overflow: auto !important;
}

.learning-halfwrapper.learning-hs .ant-table-wrapper .ant-table-container .ant-table-body {
  height: calc(100vh - 280px) !important;
  max-height: calc(100vh - 280px) !important;
  overflow: auto !important;
}

.smart-eclass {
  .ant-layout-sider {
    background: #1864ab;
  }

  .ant-layout-sider-children {
    .ant-menu.ant-menu-dark {
      background: #1864ab;
    }

    .ant-menu-title-content {
      font-size: 13px;
    }
  }
}

.ant-menu-submenu.smart-eclass {
  ul.ant-menu-vertical {
    background: #1864ab !important;
  }
}

.sneakersedu {
  .ant-layout-sider {
    background: #1864ab;
  }

  .ant-layout-sider-children {
    .ant-menu.ant-menu-dark {
      background: #1864ab;
    }

    .ant-menu-title-content {
      font-size: 13px;
    }
  }
}

.ant-menu-submenu.sneakersedu {
  ul.ant-menu-vertical {
    background: #1864ab !important;
  }
}

.question-extractor {
  .mce-content-body {
    width: 100% !important;
    padding: 10px;
  }
}

.creo .calendar-box-two-a .ant-table-wrapper .ant-table-container .ant-table-body {
  min-height: calc(50vh - 150px) !important;
  height: calc(50vh - 150px) !important;
  overflow: auto !important;
}

.creo .calendar-box-two-b .ant-table-wrapper {
  height: calc(50vh - 140px);
}

.creo .calendar-box-two-b .ant-table-wrapper .ant-table-container .ant-table-body {
  height: calc(50vh - 230px) !important;
  max-height: calc(50vh - 230px) !important;
  overflow: auto !important;
}

.cleveredu2,
.classboom,.intoreading {
  .company_logo {
    background: #fff;
    margin: 0;
    padding: 15px 18px;
    height: 64px;
  }

  .main-layout {
    background: #0000ff5c;
  }

  .ant-layout-sider {
    background: #644fec;
  }

  .ant-layout-sider-children {
    .ant-menu.ant-menu-dark {
      background: #644fec;
    }

    .ant-menu-title-content {
      // font-size: 13px;
      color: #fff;

      a {
        color: #fff;
      }
    }
  }

  .ant-menu-item-active>span a,
  .ant-menu-submenu-active .ant-menu-title-content {
    // font-weight: 900;
    color: #fff;
  }
}

.ant-menu-submenu.cleveredu2,
.ant-menu-submenu.classboom,.ant-menu-submenu.intoreading {
  ul.ant-menu-vertical {
    background: #644fec !important;
    color: #fff;
    // .ant-menu-title-content {
    //   color: #fff;
    //   a {
    //     color: #fff;
    //   }
    // }
  }
}

.haveenglish,
.semtle {
  .company_logo {
    background: #fff;
    margin: 0;
    padding: 15px 18px;
    height: 64px;
  }

  .main-layout {
    background: #0000ff5c;
  }

  .ant-layout-sider {
    background: #644fec;
  }

  .ant-layout-sider-children {
    .ant-menu.ant-menu-dark {
      background: #644fec;
    }

    .ant-menu-title-content {
      // font-size: 13px;
      color: #fff;

      a {
        color: #fff;
      }
    }
  }

  .ant-menu-item-active>span a,
  .ant-menu-submenu-active .ant-menu-title-content {
    // font-weight: 900;
    color: #fff;
  }
}

.ant-menu-submenu.haveenglish,
.ant-menu-submenu.semtle {
  ul.ant-menu-vertical {
    background: #644fec !important;
    color: #fff;
    // .ant-menu-title-content {
    //   color: #fff;
    //   a {
    //     color: #fff;
    //   }
    // }
  }
}

.w_english {
  .company_logo {
    background: #fff;
    margin: 0;
    padding: 15px 18px;
    height: 64px;
  }

  .main-layout {
    background: rgb(233, 251, 248);
  }

  .ant-layout-sider {
    background: rgb(17, 81, 70);
  }

  .ant-layout-sider-children {
    .ant-menu.ant-menu-dark {
      background: rgb(17, 81, 70);
    }

    .ant-menu-title-content {
      color: #fff;

      a {
        color: #fff;
      }
    }
  }

  .ant-menu-item-active>span a,
  .ant-menu-submenu-active .ant-menu-title-content {
    // font-weight: 900;
    color: #fff;
  }
}

.ant-menu-submenu.w_english {
  ul.ant-menu-vertical {
    background: rgb(17, 81, 70) !important;
    color: #fff;
    // .ant-menu-title-content {
    // color: #fff;
    // a {
    // color: #fff;
    // }
    // }
  }
}

.creo {
  .main-layout {
    background: #8cb0df;
  }

  .ant-layout-sider {
    background: #dbe3e5;
  }

  .ant-layout-sider-children {
    .ant-menu.ant-menu-dark {
      background: #dbe3e5;
    }

    .ant-menu-title-content {
      font-size: 13px;
    }
  }

  .ant-menu-item>span a,
  .ant-menu-title-content {
    color: rgb(0, 0, 0) !important;
    font-weight: 600;
  }

  .ant-menu-item-active>span a,
  .ant-menu-submenu-active .ant-menu-title-content {
    font-weight: 900;
  }
}

.ant-menu-submenu.creo {
  ul.ant-menu-vertical {
    background: #dbe3e5 !important;
  }
}

.price-management-tbl .ant-input-group-wrapper {
  padding: 0 15px;
}

.price-management-tbl .ant-table-cell label {
  padding: 0 10px;
  vertical-align: middle;
}

.price-management-tbl .ant-table-cell .ant-input-wrapper.ant-input-group {
  margin-bottom: 10px;

  input[type='number'] {
    text-align: right;
  }
}

.price-management-tbl .ant-table-cell button {
  margin-right: 10px;
}

.price-management-tbl {
  .ant-table-wrapper {

    .ant-table-header>table>thead>tr>th,
    .ant-table-body>table>tbody>tr>td {
      text-align: center;
    }
  }
}

.campus-day-price {
  text-align: center;
  padding: 15px;
}

table.custome_table {
  margin: 10px 0;
}

table.custome_table label {
  //margin-right: 20px;
}

table.custome_table tr {
  margin: 10px 0;
  line-height: 40px;
}

table.custome_table button {
  margin: 0 0 0 6px;
}

.custome_table tr td label {
  padding: 0 5px !important;
}

.custome_table .ant-form-item {
  margin-right: 0 !important;
}

table.mytable_custome tr td {
  padding: 4px !important;
}

.row_text {
  display: flex;
  justify-content: center;
  align-items: center;
}

.row_text {
  border: none !important;
}

// .mytable_custome tr td:nth-child(3)  {
//   display: flex;
//   justify-content: center;
// }
.jungleCard {
  .coverCard {
    .ant-card-body {
      display: none;
    }
  }
}

.junglePhonicsV {
  .Creo-titWrap {
    height: 130% !important;
  }

  .CreoBook-con {
    // border-right :0px solid rgb(222, 222, 222) !important;
  }
}

.volume-btn {
  margin-left: 0;
}

.btn-width {
  width: 58% !important;

  @media (max-width: 1920px) {
    width: 70% !important;
  }

  @media (max-width: 1500px) {
    width: 90% !important;
  }
}

.lucid {
  .main-layout {
    background: linear-gradient(to right, #f8ecdc, #f8ecdc);
    height: 100vh;
  }

  .ant-layout-sider {
    background: #fff;

    .ant-layout-sider-children {
      display: flex;
      flex-direction: column;

      .ant-menu-root {
        flex: 1;
        background: #9b0000;
      }
    }
  }

  .company_logo {
    background: #fff;
  }

  .ant-layout-sider-children {
    .ant-menu.ant-menu-dark {
      background: #9b0000;
      color: #fff;
    }

    .ant-menu-title-content {
      font-size: 13px;
      color: #fff;

      a {
        color: #fff;
      }
    }
  }

  .ant-btn-primary {
    border-color: rgb(155, 0, 0) !important;
    background: rgb(155, 0, 0) !important;
    color: #fff !important;

    a {
      color: #fff !important;
    }
  }

  .ant-btn:hover {
    color: rgb(155, 0, 0);
    border-color: rgb(155, 0, 0);
    font-weight: bold;
  }

  .ant-btn:focus {
    color: rgb(155, 0, 0);
    border-color: rgb(155, 0, 0);
  }

  .ant-btn-primary .ant-typography-success {
    color: #fff !important;
  }

  .ant-btn-primary:hover {
    color: #fff !important;
    font-weight: bold;
  }

  .ant-menu-title-content {
    font-weight: bold;

    img {
      width: 35px !important;
      height: 35px !important;
    }
  }

  .lucid-btn {
    border: 1px solid rgb(155, 0, 0);
    background-color: rgb(155, 0, 0);
    color: #fff;
    margin: 0 8px;
    padding: 0 11px;
  }

  .ant-btn.ant-btn-primary {
    &:hover {
      color: rgb(155, 0, 0);
      border: 1px solid rgb(155, 0, 0);
    }

    &:focus {
      border: 1px solid rgb(155, 0, 0);
      background-color: rgb(155, 0, 0);
      color: #fff;
    }
  }

  .calendar-box-two-b {
    .ant-table-wrapper {
      border-top: 3px solid rgb(155, 0, 0) !important;
    }
  }

  .ant-table-wrapper thead[class*='ant-table-thead'] th {
    background: #f8ecdc !important;
  }

  .ant-table-cell {
    a {
      color: rgb(155, 0, 0);
    }
  }

  .tr td a {
    color: rgb(155, 0, 0);
  }

  .ant-table-wrapper {
    border-top: 3px solid #9b0000 !important;
  }

  .dasboard-list {
    display: flex;
    justify-content: space-between;
    padding: 11px 0 0;

    span {
      border-radius: 10px;
      width: 25px;
      height: 18px;
      text-align: center;
      color: #fff;
      margin-right: 5px;
    }
  }

  .cmp-btn button {
    margin: 0 5px;
    font-size: 16px;
    color: #000;
    font-weight: 900;
    background-color: #fff;
    border: 1px solid #fff;

    &:hover {
      color: #fff;
      border: 1px solid rgb(155, 0, 0);
      background-color: rgb(155, 0, 0);
    }

    &:focus {
      border: 1px solid rgb(155, 0, 0);
      background-color: rgb(155, 0, 0);
      color: #fff;
    }
  }

  .homeworkheadertable {
    thead[class*='ant-table-thead'] th {
      border-left: unset;
    }
  }
}

.ant-menu-submenu.lucid {
  ul.ant-menu-vertical {
    background: #9b0000 !important;
    color: #fff;

    a {
      color: #fff;
    }
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.fullwdmodal.uniquecode {

  .ant-col.ant-col-18,
  .ant-col.ant-col-12 {
    flex: 0 0 100%;
    max-width: 100%;
    padding-bottom: 0 !important;
  }

  .ant-form label {
    padding-top: 5px;
    color: #9b0000;
  }
}

.schedule-popup {
  .ant-modal-body {
    padding: 24px 24px 10px;
  }

  .ant-modal-footer.cust-footer {
    display: block;
  }

  .ant-modal-footer {
    display: none;
  }
}

html,
body {
  height: 100%;
}

#app {
  align-items: center;
  background-color: white;
  display: flex;
  flex: 0 0 auto;
  flex-direction: row;
  height: 100%;
  justify-content: center;
}

/* Switch */
.switch {
  display: inline-block;

  span {
    position: relative;
    width: 60px;
    height: 34px;
    float: left;

    input {
      display: none;

      &:checked+.slider {
        background-color: #218d96;
      }

      &:checked+.slider:before {
        transform: translateX(26px);
      }

      &:focus+.slider {
        box-shadow: 0 0 1px #218d96;
      }
    }
  }

  label {
    line-height: 34px;
    margin-left: 12px;
    cursor: pointer;
  }
}

.slider {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  border: 0;
  outline: none;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 34px;
  cursor: pointer;

  &:before {
    position: absolute;
    content: '';
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: 0.4s;
    border-radius: 50%;
  }
}

.cust-schedule-table {
  .ant-table-body {
    colgroup {
      display: none;
    }

    tr td button {
      margin: 0 5px;
    }
  }
}

.day-dropdown .container {
  box-shadow: none !important;
  border: 1px solid #d9d9d9 !important;
  border-radius: 2px !important;

  .add-more {
    display: none !important;
  }

  .tags {
    padding: 0 0px 0 10px !important;

    .tag {
      margin: 0px 4px 6px 0px !important;
      font-size: 10px !important;

      p {
        margin: 0px 2px 0px 0px !important;
      }

      .tag-close-icon {
        svg {
          height: 8px;
        }
      }
    }
  }
}

.day-dropdown .ant-col.ant-col-12 {
  max-width: 100% !important;

  h3 {
    padding-left: 8px !important;
    font-size: 16px !important;
    color: #d9d9d9 !important;
    margin-bottom: 0 !important;
  }

  .ant-col.ant-col-18 {
    max-width: 100% !important;
  }

  .ant-col.ant-col-14.ant-form-item-control {
    max-width: 100% !important;
  }
}

.system-no .ant-col.ant-col-14.ant-form-item-control {
  max-width: 100% !important;
}

.day-dropdown {
  .ant-col.ant-col-12 {
    .ant-row:first-child {
      width: 350px;

      .ant-col.ant-col-18 {
        .ant-row.ant-form-item {
          width: unset !important;
        }
      }
    }
  }

  button.display-text-button {
    min-height: 40px !important;
  }
}

.meta-exam ul {
  justify-content: center;
}

.time-dropdown .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: 40px;
}

.update-schedule {
  .day-dropdown .ant-col.ant-col-12 .ant-row:first-child .ant-col.ant-col-18 .ant-row.ant-form-item {
    cursor: not-allowed;
    opacity: 0.6;

    button {
      cursor: not-allowed !important;
      background: #f5f5f5 !important;
    }
  }

  .day-dropdown .container .tags {
    cursor: not-allowed !important;
    background: #f5f5f5 !important;
  }
}

.mainblock-elibrary {

  .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
  .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    text-align: center;
  }
}

//
.cleveredu2,
.classboom,.intoreading {
  .manage-action-wrapper-main {
    .toparea-main {
      .ant-space {
        &.ant-space-horizontal {
          .ant-space-item {
            .ant-select {
              &.ant-select-show-search {
                width: auto !important;
              }
            }
          }
        }
      }
    }
  }
}

.modal__goal_set {
  .ant-modal-content {
    width: 380px;
    padding: 45px 50px;
    background-color: rgba(255, 255, 255, 0.93);
    border-top-right-radius: 50px;
    border-bottom-left-radius: 50px;
  }

  .close_btn {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 20px;
    height: 19px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    background-image: url(../images/icon_close.png);
    background-color: transparent;
  }

  .ant-modal-header {
    display: none;
  }

  .ant-modal-body {
    padding: 0;
  }

  .alert__title {
    padding: 10px 0;
    border-top: 1px solid #676767;
    border-bottom: 1px solid #676767;
    font-size: 30px;
    font-weight: 700;
    text-align: center;
    color: #676767;
  }

  .number_select {
    margin: 35px 0 50px;
    height: 39px;
  }

  .form_input_modal {
    width: 115px;
    color: #6eb2c3;
    font-size: 25px;
    font-weight: 700;
    text-align: center;
    height: 40px;
    border: 1px solid #afafaf;
    background-color: #fff;
    border-radius: 14px;
  }

  .number_name {
    color: rgba(0, 0, 0, 0.5);
    font-size: 25px;
    font-weight: 700;
    margin-left: 9px;
  }

  .btn_num {
    width: 40px;
    height: 40px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    border-radius: 50%;
    margin-left: 9px;
    cursor: pointer;
  }

  .btn_submit {
    display: flex;
    justify-content: center;
  }

  .btn_color_red {
    height: 45px;
    line-height: 45px;
    padding: 0 15px;
    border-radius: 20px;
    font-size: 21px;
    width: auto;
    color: #fff;
    background-color: #fd7468;
    text-align: center;
  }
}

.level-up-layout {
  .main-layout {
    background: #ffda00;
    height: 100vh;
    overflow-y: auto;
  }

  .ant-layout-sider {
    background: #ff7e1d;

    .ant-layout-sider-children {
      display: flex;
      flex-direction: column;

      .ant-menu-root {
        flex: 1;
        background: #ff7e1d;
      }
    }
  }

  .company_logo {
    background: #ff7e1d;
  }

  .ant-layout-sider-children {
    .ant-menu.ant-menu-dark {
      background: #ff7e1d;
      color: #fff;
    }

    .ant-menu-title-content {
      font-size: 13px;
      color: #fff;

      a {
        color: #fff;
      }
    }
  }

  .ant-btn-primary {
    border-color: #ff7e1d !important;
    background: #ff7e1d !important;
    color: #fff !important;

    a {
      color: #fff !important;
    }
  }

  .ant-btn:hover {
    color: #ff7e1d;
    border-color: #ff7e1d;
  }

  .ant-btn:focus {
    color: #ff7e1d;
    border-color: #ff7e1d;
  }

  .ant-btn-primary .ant-typography-success {
    color: #fff !important;
  }

  .ant-btn-primary:hover {
    color: #fff !important;
    font-weight: bold;
  }

  .ant-menu-title-content {
    font-weight: bold;

    img {
      width: 35px !important;
      height: 35px !important;
    }
  }

  .ant-btn.ant-btn-primary {
    &:hover {
      color: #ff7e1d;
    }

    &:focus {
      background-color: #ff7e1d;
      color: #fff;
    }
  }

  .calendar-box-two-b {
    .ant-table-wrapper {
      border-top: 3px solid #ff7e1d !important;
    }
  }

  .ant-table-wrapper thead[class*='ant-table-thead'] th {
    background: #f8ecdc !important;
    // white-space: nowrap;
  }

  .ant-table-cell {
    a {
      color: #ff7e1d;
    }
  }

  .tr td a {
    color: #ff7e1d;
  }

  .ant-table-wrapper {
    // border-top: 3px solid #ff7e1d !important;
  }

  .dasboard-list {
    display: flex;
    justify-content: space-between;
    padding: 11px 0 0;

    span {
      border-radius: 10px;
      width: 25px;
      height: 18px;
      text-align: center;
      color: #fff;
      margin-right: 5px;
    }
  }

  .homeworkheadertable {
    thead[class*='ant-table-thead'] th {
      border-left: unset;
    }
  }

  .level-up-library-con {
    background-color: #fff;
    position: relative;
    margin-top: 20px;
    padding: 10px;
    padding-top: 20px;
    border-radius: 5px;

    .library__content {
      margin-top: 10px;
    }

    .lexile-select {
      border: 1px solid #afafaf;
      height: 32px;
      line-height: 32px;
      padding: 0 10px;
    }
  }

  .mainExternal-dashboard-con {
    .external-content-wrap {
      height: auto;
      background: transparent;
    }

    .external-content-wrap.fluency-con .fluency-bg {
      margin-top: 10px;

      .fluency-wrap {
        align-items: self-start;

        .control-box {
          align-items: center;
        }

        .fluency-left .sentence-wrap {
          height: 60vh;
          border: 3px solid #ffbd00;
        }

        .fluency-left .control-box .pager {
          border: 3px solid #ffbd00;
          padding: 0.3rem 1rem;
        }

        .fluency-right .right .top-header {
          background: #ffbd00;
        }

        .fluency-right .result-con .result-time-wrap h3:first-child {
          background: #ffbd00;
        }
      }
    }
  }
}

//10-7-23-paperTestPopup css
.paperTestPopup {
  .ant-modal-content {
    border-radius: 15px;

    .ant-modal-header {
      border-bottom: unset;
      border-radius: 15px;
    }

    .ant-modal-footer {
      border-top: unset;
    }

    table {
      width: 100%;
      border: 1px solid #efa443;

      tr {
        text-align: center;
        color: #d0262f;

        th,
        td {
          padding: 15px 0;
        }

        td {
          border: 1px solid #efa443;
        }

        &:first-child {
          background-color: #d6e8f5;
        }

        &:last-child {
          background-color: #d6e8f5;
          color: #000;
        }
      }
    }
  }

  .download-pdf-btn {
    background-color: transparent;
    border: 0;
    cursor: pointer;
  }
}

.test-asign {
  label {
    width: 125px;
  }

  .ant-form-item-control {
    justify-content: flex-start;

    .ant-form-item-control-input-content {
      flex: none;

      input {
        width: 80px;
        color: #000;
      }

      span {
        padding: 0 10px;
        color: #1890ff;
      }
    }
  }
}


//========== 12-July-2023 Style============//
.daily-test-wrap {
  // background-color: #fff;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0 auto;

  .daily-sub-wrap {
    background-color: #fff;
    width: 85vw;
    height: 85vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    box-shadow: 0px 0px 10px 4px #ccc;
    border-radius: 40px;
    margin-top: 21px;
  }

  .daily-sub-wrap h1 div {
    color: #00225e;
    font-size: 56px;
    font-weight: 600;
    line-height: 85px;
    text-align: center;
  }

  .start-btn {
    border: none;
    background: none;
    box-shadow: none;
  }

  .start-btn span {
    color: #fff;
    background-color: #f8bd2d;
    padding: 8px 42px;
    font-size: 18px;
    border-radius: 25px;
    box-shadow: 1px 1px 7px 2px #ccc;
    font-weight: 600;
  }

  .daily-group-content {
    /* margin-top: 10%; */
    margin-bottom: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 15px 15px;
    text-align: center;
  }
}

.header-top-wrap {
  display: flex;
  justify-content: end;
  align-items: center;
  padding: 15px 0;
  width: 85vw;

  .header-top-item {
    padding-right: 20px;

    p {
      &:first-child {
        color: #f0a849;

        span {
          color: #ff6600;
        }
      }

      &.test-no {
        color: #509dd3;
        font-weight: 900;
      }

      &.date-info {
        color: #509dd3;
        font-weight: 300;
      }

      &.time-info {
        color: #00225e;
      }
    }
  }
}

.daily-test-quiz {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0 auto;

  .daily-sub-wrap {
    background-color: #fff;
    width: 85vw;
    // height: 85vh;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    box-shadow: 0px 0px 10px 4px #ccc;
    border-radius: 40px;
    margin-top: 21px;
    margin-bottom: 20px;

    .img-quize {
      display: flex;

      img {
        width: 100%;
        padding: 50px;
        height: 100%;
      }
    }
  }

  .daily-content {
    width: 100%;
    padding: 50px;
  }

  .daily-content-top {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;

    h1 {
      font-size: 26px;
      font-weight: 600;
    }

    .daily-num {
      display: flex;
      align-items: center;

      div {
        font-size: 15px;
        color: #2196f3;
        display: flex;
        letter-spacing: 2px;
        margin-left: 9px;
        margin-bottom: 0.5em;
      }
    }
  }

  .specker-questionb-content {
    display: flex;
    align-items: center;

    img.btnForDictation {
      width: 53px;
      height: 53px;
      border-radius: 50%;
      border: 1px solid #ffa226;
      padding: 14px 10px;
    }

    .specker-sub-wrap {
      width: 100%;
      padding: 20px 30px;
      margin-left: 20px;
      border: 1px solid #f9d95a;
      background-color: #f1da0e29;
      border-radius: 10px;
      display: flex;
      align-items: center;

      p {
        margin-bottom: 0;
        font-size: 17px;
        font-weight: 600;
        color: #605f5f;
      }
    }
  }

  .quize-option-content {
    display: flex;
    margin-top: 30px;
    margin-left: 70px;

    span {
      width: 4%;
      border-radius: 50%;
      border: 1px solid #2196f3;
      padding: 0;
      font-size: 23px;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 47px;
      background-color: #fff;
      color: #2196f3;
      z-index: 111;
    }

    .quize-option-sub-wrap {
      width: 100%;
      padding: 17px 30px 17px 60px;
      margin-left: -40px;
      border: 1px solid #2196f3;
      background-color: rgb(3 169 244 / 13%);
      border-radius: 50px;
      display: flex;
      align-items: center;
      height: 47px;

      p {
        margin-bottom: 0;
        font-size: 17px;
        font-weight: 600;
        color: #605f5f;
      }
    }
  }

  .img-test-quize {
    padding: 50px;


    .img-wrapper,
    .img-quize .daily-content {
      width: 50%;
    }

    // img.btnForDictation {
    //   width: 11%;
    // }
  }

  .quize-option-content {
    span {
      width: 46px;

    }
  }

  .img-wrapper {
    img {
      width: 100%;
      padding: 50px;
      height: 90% !important;
      border: 1px solid #2196f3;
    }
  }
}

.ant-layout:has(.daily-test-wrap) .main-layout {
  background: transparent !important;
}

@media (max-width: 675px) {
  .daily-test-quiz .daily-content-top h1 {
    font-size: 20px;
  }

  .daily-test-quiz .specker-questionb-content .specker-sub-wrap p,
  .daily-test-quiz .quize-option-content .quize-option-sub-wrap p {
    font-size: 15px;
  }

  .daily-test-quiz .quize-option-content span {
    font-size: 20px;
    height: 43px;
    width: 44px;
  }

  .daily-test-quiz .quize-option-content {
    margin-top: 20px;
    margin-left: 60px;
  }

  .daily-test-quiz .quize-option-content .quize-option-sub-wrap {
    height: 44px;
  }

  .daily-test-quiz .specker-questionb-content .specker-sub-wrap {
    // padding: 4px 30px;
    padding: 12px 30px;

  }
}

@media (max-width: 768px) {
  .daily-test-wrap .daily-sub-wrap h1 div {
    color: #00225e;
    font-size: 40px;
  }

  .daily-test-wrap .daily-sub-wrap .header-top-wrap,
  .daily-test-quiz .daily-sub-wrap .header-top-wrap {
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    flex-wrap: wrap;
  }
}

@media (max-width: 547px) {
  .daily-test-quiz .daily-content-top .daily-num {
    align-items: baseline;
  }

  .daily-test-quiz .daily-content-top {
    flex-wrap: wrap;
  }
}

@media (max-width: 575px) {
  .daily-test-quiz .quize-option-content {
    margin-left: 0;
  }
}


//========== 12-July-2023 End============
.enter-score-btn {
  color: #0067e5;
  border: 1px solid #0067e5;
  margin-right: 15px;
}

.add-student-btn {
  border-color: #8bc34a;
  color: #8bc34a;
  margin-left: 10px;

  .del-text {
    color: rgb(235, 55, 55);
  }
}

.table-btn-wrapper {
  display: flex;
  justify-content: space-between;

  .btn-save {
    background-color: #416cb8;
    border-radius: 50px;
    color: #fff;
    padding: 2px 22px;
  }

  .btn-auto-download {
    border: 1px solid rgb(174, 187, 58);
    color: rgb(174, 187, 58);
  }
}

// .add-student-btn {
//   color: #54823c00;
//   border: 1px solid #54823c00;
//   span {
//     color:#ff4b5200;
//   }
// }
.booktable-main-tbl {
  &.test-result-main-tbl {
    border-top: 3px solid #a4d0e9;

    tr {

      th {
        background: #edf3fb;
        padding: 8px;
      }

      td {
        padding: 8px !important;
        background-color: #fff;

        input {
          border: 1px dashed #089bef;
        }
      }

      background-color: #fff;
    }
  }
}

// Custome Css
#assignTable.assign-table tr:last-child td {
  color: #0067e5;
  font-weight: 700;
}

table.booktable-main-tbl.test-result-main-tbl.mytable_custome tr:last-child td {
  // color: rgba(0, 0, 0, 0.85);
  // font-weight: normal;
}

table.booktable-main-tbl.test-result-main-tbl.mytable_custome tr:nth-child(2) th {
  color: rgba(7, 56, 37, 0.85);
  font-weight: normal;
}

.assigned-syllabus-halfwrapper {
  .time_retake {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0 15px;
    margin: 0 0 15px;

    .right_content {
      display: flex;
      align-items: center;
      gap: 0 15px;

      span {
        font-size: 14px;
        font-weight: 500;
        color: rgb(0, 103, 229);

        label {
          font-weight: 600;
        }
      }

      .print_img {
        width: 30px;
        height: 30px;

        img {
          max-width: 100%;
        }
      }
    }
  }

}

// =========== 4 aug ================
.cust-daily-test-list,
.cust-assigned-list,
.cust-assigned-student-test,
.cust-daily-class-ans {
  .excel-img {
    margin-left: 10px;

    img {
      height: 26px;
      width: 26px;
    }
  }

  .headitems-select {
    margin-bottom: 0 !important;
  }

  .assigned-syllabus-top-fields {
    padding: 0 !important;
  }

  .cust-header-content {
    margin-bottom: 20px;
  }
}

.cust-assigned-list-popup {
  .assign-upload-btn-grp {
    display: flex;
    align-items: center;
    justify-content: center;

    .upload-btn {
      margin-right: 10px;

      button {
        border: 1px solid #0067e5;
        color: #0067e5;
      }
    }

    .view-btn {
      border: 1px solid #8bc34a;
      color: #8bc34a;
    }
  }

  .ant-modal-title {
    font-weight: 600;
  }
}

.stud-add-del-modal {
  .stud-add-del-btn {
    margin-top: 15px;
    display: flex;
    justify-content: end;

    .add-btn {
      margin-right: 10px;
      background-color: #0067e5;
      border: 1px solid #0067e5;
      color: white;
    }

    .del-btn {
      background-color: #eb3737;
      border: 1px solid #eb3737;
      color: white;
    }
  }
}

table.booktable-main-tbl tr th {
  font-size: 14px !important;
}

//Responsive
.daily-test-quiz .specker-questionb-content .specker-sub-wrap p,
.daily-test-quiz .quize-option-content .quize-option-sub-wrap p {
  word-break: break-all;
}

// X-Large devices (large desktops, less than 1400px)
@media (max-width: 1399.98px) {
  .daily-test-quiz .specker-questionb-content img.btnForDictation {
    padding: 10px !important;
  }

  .daily-test-quiz .daily-sub-wrap .img-quize img {
    padding: 10px;
  }

  .daily-test-quiz .daily-content {
    padding: 30px;
  }

  .daily-test-quiz .daily-sub-wrap .img-quize {
    align-items: center;
  }
}

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {
  .daily-test-quiz .daily-sub-wrap .img-quize {
    display: block;
  }

  .daily-test-quiz .img-test-quize .img-wrapper,
  .daily-test-quiz .img-test-quize .img-quize .daily-content {
    width: 100%;
  }
}

// Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) {
  .daily-test-quiz .specker-questionb-content img.btnForDictation {
    width: 40px;
    height: 40px;
    padding: 8px !important;
  }

  .daily-test-quiz .daily-content {
    padding: 15px;
  }
}

// X-Small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
  .daily-test-wrap .daily-sub-wrap h1 div {
    font-size: 32px;
    line-height: 55px;
  }

  .daily-test-wrap .start-btn span {
    padding: 6px 32px;
    font-size: 16px;
  }

  .daily-test-quiz .specker-questionb-content {
    display: block;
  }

  .detaction-block {
    display: flex;
    justify-content: center;
    margin-bottom: 8px;
  }

  .daily-test-quiz .specker-questionb-content .specker-sub-wrap {
    margin-left: 0 !important;
  }

  .header-top-wrap {
    padding: 15px 15px;
  }

  .daily-test-quiz .specker-questionb-content img.btnForDictation {
    width: 30px;
    height: 30px;
    padding: 5px !important;
  }

  .daily-test-quiz .img-test-quize {
    padding: 20px;
  }

  .daily-test-quiz .img-test-quize .img-wrapper {
    margin-bottom: 15px;
  }

  .daily-test-quiz .daily-content {
    padding: 10px !important;
  }

  .daily-test-quiz .specker-questionb-content .specker-sub-wrap {
    padding: 10px 30px;
  }
}

.student-ans-sheet {
  table.booktable-main-tbl tr:last-child td {
    color: rgba(0, 0, 0, 0.85);
    font-weight: normal;
  }
}

.test-pointer {
  cursor: pointer;
  color: #0067e5 !important;
}



.disable-text-selection {
  -moz-user-select: none;
  /* firefox */
  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE*/
  user-select: none;
  /* Standard syntax */
}

.level-up-layout .inputsearch-classroom-wrap .ant-btn-primary {
  background-color: #D3D3D3 !important;
  border: 1px solid #D3D3D3 !important;
}

.cust-assign-list {
  min-height: 690px !important;
}

.cust-book-list {
  justify-content: unset !important;
  gap: 15px;
  align-items: center;
}

.cust-book-list .ant-image img {
  width: 180px;
  height: 170px;
}


.assign-result-wrap .cust-tble .ant-table.ant-table-small .ant-table-tbody>tr>td {
  // white-space: nowrap;
}

.assign-book .ant-form-item {
  margin-bottom: 15px;
}

.select-menu .ant-select.ant-select-in-form-item {
  width: 110px !important;
}

.select-menu .ant-form-horizontal .ant-form-item-control {
  margin-right: 10px !important;
}

@media (max-width: 1500px) {

  .cust-tble,
  .cust-extra-reader,
  .assign-result,
  .book-report,
  .cust-assign-book {
    table {
      width: 1300px !important;
    }
  }

  .cust-assign-list {
    .assign-table-list {

      table {
        width: 1300px !important;
      }
    }
  }
}

@media (max-width: 1280px) {
  .cust-assign-list-main {
    width: 100%;
    min-width: 803px;
  }

  .cust-book-description {
    min-width: 950px !important;
  }

  .cust-teacher-resource {
    min-width: 200px !important;
  }
}

@media (max-width: 991px) {
  .cust-teacher-resource {
    min-width: 150px !important;
  }
}

.cust-my-page {
  overflow: auto;
}

.cust-portfolio {
  height: unset !important;
}

@media (max-width: 1280px) {
  .cust-my-page {
    .cust-inner-bg {
      // width: 110%;
    }
  }
}

@media (max-width: 1024px) {
  .cust-portfolio-wrap {
    height: 80% !important;
  }

}

@media (max-height: 750px) {
  .cust-main-wrap {
    // height: 100vh!important;
  }
}

//  24-01-2024
.table-btns {
  display: flex;
  align-items: center;
  gap: 10px;

  .ant-btn.ant-btn-default {
    height: 32px;
    padding: 4px 15px;
    font-size: 14px;
  }
}

.ai-chat-history {
  .user-detail {
    text-align: end;
  }

  .user-msg {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  .ai-msg {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}
@media(max-width:1023px)
{
    
    .listenword-txt
    {
        /*justify-content: flex-start;*/
        justify-content: flex-end !important;
        h4
        {
            &.ant-typography
            {
                width: auto;
                float: left;
                position: relative;
                top: -8px;
                span 
                {
                    font-size: 12px;
                }
            }
        }
        span.trigger
        {
            float: right;
            font-size: 25px !important;
            transform: rotate(180deg);
            padding: 0px !important;
            position: relative;
            top: -6px;
        }
    }
    .fordesktoponly
    {
        display: none !important;
    }
    .searchkeyboard
    {
        padding-left: 0px;
        padding-right: 0px;
        width: 100%;
        max-width: 100%;
        flex: 1;
        margin:20px;
        width: 100%;
        overflow: hidden;
        h4.ant-typography
        {
            font-size: 16px;
        }
        .textfield
        {
            max-width: inherit !important;
            flex: 1 !important;
            border-radius: 6px !important;
            padding-left: 10px !important;
            padding-right: 10px !important;
            min-width: 120px;
            max-width: 120px;
            float: left;
            height: 35px;
            h4.ant-typography
            {
                margin-bottom: 40px;
            }
        }
    }
    .btnkeyboard
    {
        margin:0px !important;
        padding: 0px !important;
        border: 0px !important;
        
        .keykey
        {
            padding-left: 12px;
            padding-right: 12px;
            max-width: fit-content;
            flex: 1;
            padding: 0px !important;
            margin-right: 15px;
            .ant-card-bordered
            {
                .ant-card-body
                {
                    padding: 15px !important;
                }
            }
        }
    }
    .searchtextbox
    {
        width:80%;
        display: inline-block !important;
        height: auto !important;
        padding-right: 0px !important;
       
    }
    .listenword-heading-main
    {
        padding-left: 0px;
        padding-right: 0px;
    }
    .listenword-heading
    {
        h2
        {
            span
            {
                margin-left: 0px !important;
            }
        }
    }
    .searchtextbox-btn
    {
        top: 100px;
    }
    .listenword-txt
    {
        h4:nth-child(1)
        {
            margin-left: 0px !important;
        }
    }
}
@media(max-width:620px)
{
    .listenword-txt
    {
        justify-content: left !important;
    }
    .listenword-heading
    {
        flex: 0 0 100%;
        max-width: 100%;
    }
    .listenword-txt
    {
        flex: 0 0 100%;
        max-width: 100%;
    }
    .listenword-txt span.trigger
    {
        position: absolute;
        right: 0px;
        top: -16px; 
    }
}
@media(max-width:600px)
{
    .listenword-txt h2 span
    {
        margin-left: 0px;
    }
    .btnkeyboard .keykey {
        flex: auto;
        width: 45%;
        max-width: 45%;
        padding: 0px 0px !important;
        margin-right: 5%;
        margin-bottom: 15px;
        &:nth-child(2n + 2)
        {
            margin-right: 0px;
        }
    }
    .tail__btn
    {
        border: 0px;
    }
    .tail__process
    {
        padding: 0px;
        float: left;
    }
    .tail__process li
    {
        width: 50px;
        margin-right: 40px;
    }
    .tail__process li::after
    {
        top: 14px;
        right: -32px;
    }
    .searchtextbox {
        width: 70%;
     
    }
}
@media(max-width:500px)
{
    .searchkeyboard .textfield {
        min-width: 110px !important;
        max-width: 110px !important;
    }
    .searchtextbox {
        width: 100%;
        margin-top:20px;
        margin-bottom: 100px !important;
        position: relative;
        .searchtextbox-btn
        {
            position: absolute;
            right: 0px;
            bottom: -70px;
            width: auto !important;
            float: none !important;
            button
            {
                margin: 0px;
                float: none;
                right: 0px;
                padding: 6px 20px;
                border-radius: 6px;
                width: auto !important;
                float: none !important;
                right: inherit !important;
            }
        }
    }
    .searchtextbox-btn
    {
        top: inherit;
    }
 
}
@media(max-width:473px)
{
    .searchtextbox {
        width: 100% !important;
    }
    .searchkeyboard .textfield {
        min-width: 45% !important;
        max-width: 45% !important;
    }
}
@media(max-width:400px)
{
    .searchkeyboard .textfield {
        min-width: 42% !important;
        max-width: 42% !important;
    }
    
    .kfBvkK {
        font-size: 16px !important;
    }
    .searchkeyboard .textfield
    {
        div
        {
            font-size: 16px !important;  
        }
    }
}
@media(max-width:430px)
{
    .searchkeyboard .textfield
    {
        min-width: 90%;
    }
    .searchtextbox {
        width: 60%;
    }
}
@media(max-width:370px)
{
   
}


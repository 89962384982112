.testmanagmentdelete-btn
{
    margin-left: 10px;
}
.contenttest-wrapper
{
    margin-top: 10px !important;
}
.wrapcaccelsave-btn button:nth-child(1)
{
    margin-right: 15px;
}
.wrapcaccelsave-btn button:nth-child(2)
{
    margin-left: 15px;
}
.testpdfupload
{
    button
    {
        margin-bottom:10px;
    }
}
.mini-calander
{
    .fc-scrollgrid-sync-table
    {
        tr
        {
            td 
            {
                a 
                {
                    width: auto !important;
                    font-size: 16px !important;
                }
            }
        }
    }
}
.datetime-wrap
{
    .date-calander,
    .time-calander
    {
        width: 100% !important;
        padding: 4px 11px !important;
        input 
        {
            font-size: 14px !important;
        }
    }
}

.test-manage-action-assign-scroll
{
    overflow-x: auto !important;
    overflow-y: auto !important;
    padding: 15px !important;
    height: auto !important;
    max-height:inherit  !important;
}
.test-manage-action-assign-a,
.test-manage-action-assign-b,
.test-manage-action-assign-c
{
    padding: 0px !important;
}

.test-result-wrapper
{
    margin-top: 10px;
}
.test-result-wrapper
{
    .result-test-title
    {
        margin:15px 0px !important;
        margin-top: 23px !important;
        h5 
        {
            margin: 0px;
        } 
    }
    .resultbtntop
    {
        margin:15px 0px;
    }
}
.fordesktoponly-result
{
    display: block;
}
.formobileonly-result
{
    display: none;
}
.btnformobileonly-add
{
    display: none;
    width: 100%;
}

.btnfordesktoponly-add
{
    display: block;
}
.openboxlayout
{
    display: none;
}
.testmanagment-sectionb.syllabusarow
{
    position: relative;
    padding-left:50px !important;
    .syllabusarow-arrow
    {
        position:absolute;
        left: 0px;
        top:48%;
    }
}
.test-syllabus-wrapper
{
    > div
    {
       /* height:calc(100vh - 90px) !important; */
       height: calc(100vh - 8rem) !important;
    }
    padding-left: 0px !important;
    padding-right: 0px !important;
    .test-syllabus-box-listing
    {
           margin-left: 0px !important;
           margin-right: 0px !important; 
        .test-syllabus-box-a,
        .test-syllabus-box-b,
        .test-syllabus-box-c
        {
            padding:10px;
    
            .test-syllabus-box-a-tsb,
            .test-syllabus-box-b-tsb,
            .test-syllabus-box-c-tsb
            {
                height: calc(100vh - 166px) !important;
                max-height: calc(100vh - 166px) !important;
                overflow: auto !important;
            }
            .test-syllabus-box-b-tsb
            {
                .ant-row.ant-form-item
                {
                    margin-bottom: 10px; 
                }
            }
        }
        .test-syllabus-box-c
        {
         
            .ant-table-wrapper
            {
              .ant-table
              {
                .ant-table-container
                {
                  .ant-table-body
                  {/*
                    height: calc(100vh - 300px) !important;
                    max-height: calc(100vh - 300px) !important;*/
                    height: calc(80vh - 200px) !important;
                    overflow: auto !important;
                  }
                }
              }
            }
            button
            {
                // margin-top: 20px;
            }
        }
    }
}

.syllabus-add-btn-desktop
{
    display: block;
}
.syllabus-add-btn-mobile
{
    display: none;
}
@media (max-width:1200px)
{

}

@media (max-width:1023px)
{
    .contenttest-wrapper
    {
        .contenttest-table
        {
            .ant-table-container
            {
                table 
                {
                    width:1200px !important;
                }
            }
        }
    }
    .testmanagment-sectionb,
    .testmanagment-sectiona
    {
        flex: 0 0 100%;
        max-width: 100%;
    }
    .test-manage-action-assign-a,
    .test-manage-action-assign-b,
    .test-manage-action-assign-c
    {
        flex: 0 0 100%;
        max-width: 100%;
    }
    .btnformobileonly-add
    {
        display: block;
    }
    .btnfordesktoponly-add
    {
        display: none;
    }
    .pdffullscreen.active
    {
        .pdffullscreen-scroll
        {
            display: block;
        }
    }
    .pdffullscreen.inactive
    {
        .pdffullscreen-scroll
        {
            display: none;
        }
    }
    .pdffullscreen-box.active
    {
        .pdffullscreen-box-scroll
        {
            display: block;
        }
    }
    .pdffullscreen-box.inactive
    {
        .pdffullscreen-box-scroll
        {
            display: none;
        }
    }
    .testmanagment-sectionb.syllabusarow
    {
        position: relative;
        padding-left:0px !important;
        .syllabusarow-arrow
        {
            position: static;
            left: 0px;
            top: 0 !important;
            width: 100%;
            text-align: center;
            span 
            {
                transform: rotate(90deg);
            }
        }
    }
    .test-syllabus-box-listing
    {
           margin-left: 0px !important;
           margin-right: 0px !important; 
        .test-syllabus-box-a,
        .test-syllabus-box-b,
        .test-syllabus-box-c
        {
            padding:20px;
            flex: 0 0 100% !important;
                max-width: 100% !important;
        }
        .test-syllabus-box-b
        {
            table 
            {
                width: 100% !important;
            }
            
        }
    }
    .testmanagment-sectionb.syllabusarow
    {
        padding:20px !important;
        &.syllabustbl
        {

        }
    }


}
@media (max-width:990px)
{
    .contenttest-wrapper
    {
        .contenttest-title,
        .contenttest-headitems
        {
            flex: 0 0 100% !important;
            max-width: 100% !important;
        }
        .contenttest-headitems
        {
            justify-content: end !important;
        }
        .contenttest-title-result,
        .contenttest-headitems-result
        {
            flex: 0 0 50% !important;
            max-width: 50% !important;
            margin-bottom: 20px;
        }
    }
    .testresult-table
    {
        
        .ant-table-container
        {
            .ant-table-content
            {
                overflow:auto;
                table
                {
                    width:1200px !important;
                }
            }
           
        }
    }
    .formobile-hide
    {
        display: none;
    }
    .test-result-wrapper
    {
        .result-title-wrap
        {
            flex: 0 0 100% !important;
            max-width: 100% !important;
            .result-title-a,
            .result-title-b
            {
                flex: 0 0 50% !important;
                max-width: 50% !important;
            }
        }
    }
    .fordesktoponly-result
    {
        display: none;
    }
    .formobileonly-result
    {
        display: block;
    }
    .resultbtntop
    {
        flex-flow:inherit !important;
        width: 100%;
        .resultbtntop-a
        {
            flex: 0 0 50% !important;
            max-width: 50% !important;
            margin-left: 10px;
        }
        .resultbtntop-b
        {
            flex: 0 0 50% !important;
            max-width: 50% !important;
            text-align:right;
            button 
            {
                margin-right: 20px;
            }
        }
    }
    .resultbtntop-wrap
    {
            flex: 0 0 100% !important;
            max-width: 100% !important;
    }
    .contenttest-table
    {
        .ant-table-container
        {
            .ant-table-body
            {
                max-height: calc(100vh - 280px) !important;
            }
        }
    }
    .addnew-testmanagment,
    .testpreview-wrapper
    {
        max-height: calc(100vh - 85px) !important;
        overflow: auto;
    }
    .syllabus-add-btn-desktop
    {
        display: none;
    }
    .syllabus-add-btn-mobile
    {
        display: block;
        margin-top: 30px !important;
    }
}
@media (max-width:700px)
{
    .contenttest-wrapper
    {
        .contenttest-headitems
        {
            display: block !important;
            float: left;
            .headitems-select
            {
                float: left;
            }
            .headitems-search
            {
                float: left;
                width: auto !important;
                width: 34% !important;
            }
            a.headitems-add
            {
                float: left;
            }
            button.testmanagmentdelete-btn
            {
                float: left;
            }
        }
        
    }
    .testpreview-wrapper
    {
        .pdffullscreen,
        .pdffullscreen-box
        {
            flex: 0 0 100% !important;
            max-width: 100% !important;
        }
        .pdffullscreen .react-pdf__Document
        {
            overflow:auto;
        }
    }

}
@media (max-width:600px)
{
    .testmanagment-sectionb
    {
        margin-top: 100px;
    }
    .contenttest-wrapper
    {
        .contenttest-headitems
        {
          
            .headitems-select
            {
                width:48% !important;
                margin-bottom: 20px !important;
                margin-right: 4% !important;
                .ant-select 
                {
                    width: 100% !important;
                    
                }
                .ant-form-item-label
                {
                    width: 100% !important;
                    text-align: left;
                    padding: 0 0 8px !important;
                }
            }
            .headitems-search
            {
                width:48% !important;
                padding: 0px !important;
                margin-bottom: 20px !important;
                margin-top: 40px !important;
            }
            a.headitems-add
            {
                width:48% !important;
                margin: 0px !important;
                margin-right: 4% !important;
                margin-bottom: 20px !important;
                button
                {
                    width: 100%;;
                }
            }
            button.testmanagmentdelete-btn
            {
                width:48% !important;
                margin: 0px !important;
                margin-bottom: 20px !important;
            }
        }
        
    }

    .test-result-wrapper 
    {
        .result-title-wrap 
        {
            .result-title-a,
            .result-title-b,
            .result-title-a-mb,
            .result-title-b-mb,
            .result-title-c-mb
            {
                flex: 0 0 100% !important;
                max-width: 100% !important;   
            }
            .result-title-b-mb
            {
                margin-left: 10px;
                h5 
                {
                    span 
                    {
                        margin-left: 0px !important;
                    }
                }
            }
        }
    }
    .contenttest-table
    {
        .ant-table-container
        {
            .ant-table-body
            {
                max-height: calc(100vh - 370px) !important;
            }
        }
    }
    
    .openboxlayout
    {
        text-align: right;
        background:#ececec;
        display: block;
        span.anticon 
        {
            
            text-align: revert;
            margin: 0px;
            padding: 0px;
            svg 
            {
                padding: 0px;
                fill: #3b99ff; 
            }
        }
    }
    .testmanagment-sectionb.syllabusarow
    {
        margin-top:20px;
    }
    .testmanagment-sectionb.syllabusarow .syllabusarow-arrow
    {
        margin-bottom:40px;
    }
    .test-syllabus-box-listing
    {
        .test-syllabus-box-b
        {
            > div 
            {
                max-height: inherit !important;
            }
           
            .ant-checkbox-group
            {
                label
                {
                    width: 25% !important;
                    margin-top: 10px;
                    margin-bottom: 10px;
                }
            }
        }
    }

}
@media (max-width:500px)
{
    .contenttest-wrapper 
    {
        .contenttest-title-result,
        .contenttest-headitems-result
        {
            flex: 0 0 100% !important;
            max-width: 100% !important;
            margin-bottom: 20px;
            justify-content: end !important;
        }
        .contenttest-title-result
        {
            margin-bottom: 0px !important;
        }
    }
        
}
.dashboard-top-btn {
  margin: 5px;
}
.calendar-box-two-b {
  padding: 12px;
  background: #fff;
  box-shadow: 2px 1px 5px 5px #dddddd;
}
.calendar-box-two-a {
  padding: 6px;
  background: #fff;
  margin-bottom: 10px;
  box-shadow: 2px 1px 5px 5px #dddddd;
}
.subboxone-dashboard-height,
.subboxtwo-dashboard-chat-height {
  padding: 24px;
  background: #fff;
}
.subboxone-dashboard-btn {
  display: flex;
  justify-content: flex-end;
}
.subboxone-dashboard-cbtn {
  margin-left: 10px;
  border: 1px solid #52c419;
  span {
    color: #52c419;
  }
}

@media (max-width: 1200px) {
  .modallistingdashboard {
    width: 100% !important;
    .ant-modal-body {
      overflow: auto;
    }
  }
}
@media (max-width: 999px) {
  .calendar-box {
    flex: 0 0 100%;
    max-width: 100%;
    margin-top: 30px;
    table {
      width: 100% !important;
    }
  }
  .calendar-box-two {
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 0px;
  }
  .subboxtwo-dashboard-chat,
  .subboxone-dashboard {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .subboxtwo-dashboard-chat {
    order: 0;
    margin-top: 30px;
  }
  .subboxone-dashboard {
    order: 1;
    margin-bottom: 30px;
  }
  .subboxone-dashboard-btn {
    margin: 20px 0px;
  }
}

.studystatus-haeding .studystatus-date {
  color: #333333;
  display: inline-block;
}
.button-edit {
  float: right;
}
.studystatus-haeding .studystatus {
  display: inline-block;
  font-weight: bold;
}
.calendar-box-two-b h5 {
  display: inline-block;
}

.iconcommontext {
  font-size: 8px;
  font-weight: 700;
  width: 50px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &.icon_userlesson {
    color: #11783d;
  }

  &.icon_assignedtests {
    color: #ff7f00;
  }

  &.icon_zoomschedule {
    color: #e599f7;
  }

  &.icon_offlineLesson {
    color: #11783d !important;
  }

  &.icon_phonicsLesson {
    color: #ffce54;
  }

  &.icon_externalStudy {
    color: #6e93c1;
  }

  &.icon_externalStudyStudent {
    color: #ff0000;
  }

  &.icon_aiTopicUserAssignment {
    color: #1890ff;
  }

  &.icon_externalStudyBookr {
    color: orangered;
  }
}

.smart-eclass,.englishtap {
  .btn-blue {
    border-radius: 3px !important;
    position: relative;
    &.icon-smile {
      background-color: #374ce8 !important;
      &::before {
        position: absolute;
        background-image: url(../images/smile-happy.png);
        top: 3px;
        left: 10px;
        width: 19px;
        height: 19px;
        content: '';
      }
    }
    &.icon-sad {
      background-color: #c0c0c0 !important;
      &::before {
        position: absolute;
        background-image: url(../images/smile-sad.png);
        top: 3px;
        left: 10px;
        width: 19px;
        height: 19px;
        content: '';
      }
    }
  }
  .studing-list {
    background-color: #e599f7 !important;
  }
  .study-complete {
    background-color: #2312a4 !important;
  }
  .study-before {
    background-color: #2312a4 !important;
  }
  .study-complete-gray {
    background-color: #e3543d !important;
  }
  .study-online {
    background-color: #e3543d !important;
  }
  .zoom-Schedule {
    color: #fa8221;
    background-color: transparent !important;
    border: 1px solid #fa8221;
  }
  .calendar-box {
    .fc-toolbar-chunk {
      div {
        .fc-toolbar-title {
          font-size: 24px;
          color: #333333;
          margin-left: 20px;
          margin-right: 20px;
        }
        button {
          padding: 0px;
          font-size: 24px;
          position: relative;
          top: -9px;
          color: #e0e0e0;
          border: 0px;
          background: transparent;
          &:hover,
          &:focus,
          &:active {
            color: #333333 !important;
            border: 0px;
            background: transparent !important;
            background-color: transparent !important;
            border-color: transparent !important;
            box-shadow: none !important;
          }
          span {
            font-size: 24px;
            height: auto;
            line-height: 0px;
          }
          &.fc-button-primary {
            &.fc-prev-button {
            }
            &.fc-next-button {
            }
          }
        }
      }
      button {
        &.fc-todayButton-button {
          background: #5ca0e4;
          background-color: #5ca0e4;
          text-transform: uppercase;
          border: 0px;
          font-weight: 600;
          padding: 5px 20px;
          position: relative;
          padding-left: 30px !important;
          &:after {
            background: url(../images/check-icon.png);
            background-repeat: no-repeat;
            position: absolute;
            content: '';
            left: 9px;
            top: 4px;
            width: 15px;
            height: 15px;
            background-position: center;
          }
          &:hover,
          &:focus,
          &:active {
            border: 0px;
            background: #5ca0e4;
            background-color: #5ca0e4;
            box-shadow: none !important;
          }
        }
      }
    }
    .fc-dayGridMonth-view {
      table {
        &.fc-scrollgrid {
          thead {
            .fc-scrollgrid-section {
              td {
                table.fc-col-header {
                  tbody {
                    tr {
                      th {
                        background: #00a9b0;
                        padding: 6px;
                        letter-spacing: 1px;
                        text-transform: uppercase;
                        a {
                          color: #fff;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          tbody {
            tr {
              td {
                &.fc-daygrid-day {
                  &.fc-day-sun {
                    .fc-daygrid-day-frame {
                      .fc-daygrid-day-top {
                        a {
                          color: #fa3421;
                        }
                      }
                    }
                  }
                }
                &.fc-day-other {
                  background: #f4f4f4;
                }
                background: #fff;
                .fc-daygrid-day-frame {
                  .fc-daygrid-day-top {
                    justify-content: flex-end;
                    a {
                      font-size: 15px;
                      font-weight: 700;
                      color: #777777;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .buttonlisting-dashboard {
    button {
      margin: 0px !important;
      margin-left: 15px !important;
      padding: 0px !important;
      text-shadow: none;
      font-size: 14px;
      color: #333;
      text-transform: uppercase;
      padding: 6px 15px 6px 45px !important;
      border: 0px;
      border-radius: 4px;
      font-weight: 600;
      position: relative;
      background-color: #ebebeb;
      box-shadow: 4px 4px 2px #2b8793;
      height: auto;

      &.btn-schedule:after {
        background: url(../images/icon-two.png);
        left: -1px;
        top: 0px;
        position: absolute;
        content: '';
        width: 40px;
        height: 34px;
        background-size: 40%;
        background-repeat: no-repeat;
        background-color: #e33a4d;
        padding: 10px;
        border-radius: 6px 0px 0px 6px;
        background-position: 12px;
      }
      &.btn-messageicon:after {
        background: url(../images/icon-three.png);
        left: -1px;
        top: 0px;
        position: absolute;
        content: '';
        width: 40px;
        height: 34px;
        background-size: 40%;
        background-repeat: no-repeat;
        background-color: #f3a236;
        padding: 10px;
        border-radius: 6px 0px 0px 6px;
        background-position: 12px;
      }
      &.btn-ebook:after {
        background: url(../images/icon-one.png);
        left: -1px;
        top: 0px;
        position: absolute;
        content: '';
        width: 40px;
        height: 34px;
        background-size: 40%;
        background-repeat: no-repeat;
        background-color: #b5ce3e;
        padding: 10px;
        border-radius: 6px 0px 0px 6px;
        background-position: 12px;
      }
      &.ant-btn-primary {
        background: #fff !important;
        span {
          color: #1890ff !important;
        }
        &.order:after {
          background-color: #fab005 !important;
        }
        &.content:after {
          background-color: #d9480f !important;
        }
      }
    }
    button.ant-btn-primary.btn-schedule {
      background-color: #5ca0e4;
      span {
        color: #fff;
      }
    }
    button.ant-btn-primary.btn-messageicon {
      background-color: #5ca0e4;
      span {
        color: #fff;
      }
    }
    button.ant-btn-primary.btn-ebook {
      background-color: #5ca0e4;
      span {
        color: #fff;
      }
    }
  }
  .iconcalander {
    // width: 8px !important;
    // height: 8px !important;
    // position: relative;
    // text-indent: -9999px;
    // padding: 6px !important;
    // background-size: 8px;
    // background-repeat: no-repeat;
    // background-position: center;

    &.icon_userlesson {
      // background-image: url(../images/star-icon.png);
      // background-color: #11783d !important;
      background-color: #e599f7 !important;
    }
    &.icon_assignedtests {
      //background-image: url(../images/test-icon.png);
      background-color: #ff7f00 !important;
    }
    &.icon_zoomschedule {
      background-image: url(../images/online-class-icon.png);
      background-color: #3c79f6 !important;
    }

    &.icon_externalStudyStudent {
      background-color: #ff0000;
    }

    &.icon_aiTopicUserAssignment {
      color: #1890ff;
    }
  }

  .iconcommontext {
    font-size: 8px;
    font-weight: 700;
    width: 50px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    &.icon_userlesson {
      color: #e599f7;
    }
    &.icon_assignedtests {
      color: #ff7f00;
    }
    &.icon_zoomschedule {
      color: #e599f7;
    }
    &.icon_offlineLesson {
      color: #11783d !important;
    }

    &.icon_phonicsLesson {
      color: #ffce54;
    }
    &.icon_externalStudy {
      color: #6e93c1;
    }

    &.icon_externalStudyStudent {
      color: #ff0000;
    }

    &.icon_aiTopicUserAssignment {
      color: #1890ff;
    }

    &.icon_externalStudyBookr {
      color: orangered;
    }
  }
  .studystatus-haeding {
    padding: 10px 10px !important;

    // .studystatus {
    //   color: #004a88;
    //   font-size: 20px;
    //   display: inline-block;
    //   font-weight: bold;
    // }
    // .studystatus-date {
    //   color: #333333;
    //   font-size: 20px;
    //   display: inline-block;
    // }
  }
  .calendar-box-two-a {
    table {
      tbody {
        tr {
          td {
            padding: 12px 8px;
            &:nth-child(2) {
              font-weight: 600;
              color: #444444;
              font-size: 12px;
            }
          }
        }
      }
    }
    .ant-table-container {
      .ant-table-body {
        // border-bottom: 1px solid #030000;
        // border-top: 2px solid #030000;

        table {
          tbody.ant-table-tbody {
            tr {
              &[aria-hidden='true'] {
                display: none;
              }
              td {
                div {
                  padding: 4px;
                  font-size: 12px;
                }
              }
              &:nth-child(even) {
              }
              &:nth-child(odd) {
                background: #fcfcfc;
              }
            }
          }
        }
      }
    }
  }

  .calendar-box-two-b {
    // h5 {
    //   color: #004a88;
    //   font-size: 20px;
    //   display: inline-block;
    //   font-weight: bold;
    //   padding-left: 0px !important;
    // }
    // .ant-table-wrapper {
    //   border: 0px !important;
    // }
    .ant-table-container {
      //border-bottom: 2px solid #444444;
      //border-top: 2px solid #444444;
      //   .ant-table-header {
      //     table {
      //       thead.ant-table-thead {
      //         tr {
      //           th {
      //             background: #fcfcfc !important;
      //             border: 0px !important;
      //             font-weight: 700;
      //           }
      //         }
      //       }
      //     }
      //   }
      //   .ant-table-body {
      //     table {
      //       tbody {
      //         tr {
      //           td {
      //             font-weight: 700;
      //           }
      //         }
      //       }
      //     }
      //   }
    }
  }
  .button-edit {
    float: right;

    button {
      background: #5ca0e4;
      color: #fff;
      font-weight: 600;
      padding: 5px 10px;
      border: 0px;
      padding-left: 25px;
      position: relative;
      cursor: pointer;
      border-radius: 6px;
      &:before {
        background: url(../images/icon-edit.png);
        left: 6px;
        top: 9px;
        position: absolute;
        content: '';
        width: 18px;
        height: 19px;
        background-size: 70%;
        background-repeat: no-repeat;
      }
    }
  }
}

.sneakersedu {
  .btn-blue {
    border-radius: 3px !important;
    position: relative;
    &.icon-smile {
      background-color: #374ce8 !important;
      &::before {
        position: absolute;
        background-image: url(../images/smile-happy.png);
        top: 3px;
        left: 10px;
        width: 19px;
        height: 19px;
        content: '';
      }
    }
    &.icon-sad {
      background-color: #c0c0c0 !important;
      &::before {
        position: absolute;
        background-image: url(../images/smile-sad.png);
        top: 3px;
        left: 10px;
        width: 19px;
        height: 19px;
        content: '';
      }
    }
  }
  .studing-list {
    background-color: #e599f7 !important;
  }
  .study-complete {
    background-color: #2312a4 !important;
  }
  .study-before {
    background-color: #2312a4 !important;
  }
  .study-complete-gray {
    background-color: #e3543d !important;
  }
  .study-online {
    background-color: #e3543d !important;
  }
  .zoom-Schedule {
    color: #fa8221;
    background-color: transparent !important;
    border: 1px solid #fa8221;
  }
  .calendar-box {
    .fc-toolbar-chunk {
      div {
        .fc-toolbar-title {
          font-size: 24px;
          color: #333333;
          margin-left: 20px;
          margin-right: 20px;
        }
        button {
          padding: 0px;
          font-size: 24px;
          position: relative;
          top: -9px;
          color: #e0e0e0;
          border: 0px;
          background: transparent;
          &:hover,
          &:focus,
          &:active {
            color: #333333 !important;
            border: 0px;
            background: transparent !important;
            background-color: transparent !important;
            border-color: transparent !important;
            box-shadow: none !important;
          }
          span {
            font-size: 24px;
            height: auto;
            line-height: 0px;
          }
          &.fc-button-primary {
            &.fc-prev-button {
            }
            &.fc-next-button {
            }
          }
        }
      }
      button {
        &.fc-todayButton-button {
          background: #5ca0e4;
          background-color: #5ca0e4;
          text-transform: uppercase;
          border: 0px;
          font-weight: 600;
          padding: 5px 20px;
          position: relative;
          padding-left: 30px !important;
          &:after {
            background: url(../images/check-icon.png);
            background-repeat: no-repeat;
            position: absolute;
            content: '';
            left: 9px;
            top: 4px;
            width: 15px;
            height: 15px;
            background-position: center;
          }
          &:hover,
          &:focus,
          &:active {
            border: 0px;
            background: #5ca0e4;
            background-color: #5ca0e4;
            box-shadow: none !important;
          }
        }
      }
    }
    .fc-dayGridMonth-view {
      table {
        &.fc-scrollgrid {
          thead {
            .fc-scrollgrid-section {
              td {
                table.fc-col-header {
                  tbody {
                    tr {
                      th {
                        background: #00a9b0;
                        padding: 6px;
                        letter-spacing: 1px;
                        text-transform: uppercase;
                        a {
                          color: #fff;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          tbody {
            tr {
              td {
                &.fc-daygrid-day {
                  &.fc-day-sun {
                    .fc-daygrid-day-frame {
                      .fc-daygrid-day-top {
                        a {
                          color: #fa3421;
                        }
                      }
                    }
                  }
                }
                &.fc-day-other {
                  background: #f4f4f4;
                }
                background: #fff;
                .fc-daygrid-day-frame {
                  .fc-daygrid-day-top {
                    justify-content: flex-end;
                    a {
                      font-size: 15px;
                      font-weight: 700;
                      color: #777777;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .buttonlisting-dashboard {
    button {
      margin: 0px !important;
      margin-left: 15px !important;
      padding: 0px !important;
      text-shadow: none;
      font-size: 14px;
      color: #333;
      text-transform: uppercase;
      padding: 6px 15px 6px 45px !important;
      border: 0px;
      border-radius: 4px;
      font-weight: 600;
      position: relative;
      background-color: #ebebeb;
      box-shadow: 4px 4px 2px #2b8793;
      height: auto;

      &.btn-schedule:after {
        background: url(../images/icon-two.png);
        left: -1px;
        top: 0px;
        position: absolute;
        content: '';
        width: 40px;
        height: 34px;
        background-size: 40%;
        background-repeat: no-repeat;
        background-color: #e33a4d;
        padding: 10px;
        border-radius: 6px 0px 0px 6px;
        background-position: 12px;
      }
      &.btn-messageicon:after {
        background: url(../images/icon-three.png);
        left: -1px;
        top: 0px;
        position: absolute;
        content: '';
        width: 40px;
        height: 34px;
        background-size: 40%;
        background-repeat: no-repeat;
        background-color: #f3a236;
        padding: 10px;
        border-radius: 6px 0px 0px 6px;
        background-position: 12px;
      }
      &.btn-ebook:after {
        background: url(../images/icon-one.png);
        left: -1px;
        top: 0px;
        position: absolute;
        content: '';
        width: 40px;
        height: 34px;
        background-size: 40%;
        background-repeat: no-repeat;
        background-color: #b5ce3e;
        padding: 10px;
        border-radius: 6px 0px 0px 6px;
        background-position: 12px;
      }
      &.ant-btn-primary {
        background: #fff !important;
        span {
          color: #1890ff !important;
        }
        &.order:after {
          background-color: #fab005 !important;
        }
        &.content:after {
          background-color: #d9480f !important;
        }
      }
    }
    button.ant-btn-primary.btn-schedule {
      background-color: #5ca0e4;
      span {
        color: #fff;
      }
    }
    button.ant-btn-primary.btn-messageicon {
      background-color: #5ca0e4;
      span {
        color: #fff;
      }
    }
    button.ant-btn-primary.btn-ebook {
      background-color: #5ca0e4;
      span {
        color: #fff;
      }
    }
  }
  .iconcalander {
    // width: 8px !important;
    // height: 8px !important;
    // position: relative;
    // text-indent: -9999px;
    // padding: 6px !important;
    // background-size: 8px;
    // background-repeat: no-repeat;
    // background-position: center;

    &.icon_userlesson {
      // background-image: url(../images/star-icon.png);
      // background-color: #11783d !important;
      background-color: #e599f7 !important;
    }
    &.icon_assignedtests {
      //background-image: url(../images/test-icon.png);
      background-color: #ff7f00 !important;
    }
    &.icon_zoomschedule {
      background-image: url(../images/online-class-icon.png);
      background-color: #3c79f6 !important;
    }
    &.icon_externalStudyStudent {
      background-color: #ff0000;
    }

    &.icon_aiTopicUserAssignment {
      color: #1890ff;
    }
  }

  .iconcommontext {
    font-size: 8px;
    font-weight: 700;
    width: 50px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    &.icon_userlesson {
      color: #11783d;
    }
    &.icon_assignedtests {
      color: #ff7f00;
    }
    &.icon_zoomschedule {
      color: #e599f7;
    }
    &.icon_offlineLesson {
      color: #11783d !important;
    }

    &.icon_phonicsLesson {
      color: #ffce54;
    }
    &.icon_externalStudy {
      color: #6e93c1;
    }

    &.icon_externalStudyStudent {
      color: #ff0000;
    }

    &.icon_aiTopicUserAssignment {
      color: #1890ff;
    }

    &.icon_externalStudyBookr {
      color: orangered;
    }
  }
  .studystatus-haeding {
    padding: 10px 10px !important;

    // .studystatus {
    //   color: #004a88;
    //   font-size: 20px;
    //   display: inline-block;
    //   font-weight: bold;
    // }
    // .studystatus-date {
    //   color: #333333;
    //   font-size: 20px;
    //   display: inline-block;
    // }
  }
  .calendar-box-two-a {
    table {
      tbody {
        tr {
          td {
            padding: 12px 8px;
            &:nth-child(2) {
              font-weight: 600;
              color: #444444;
              font-size: 12px;
            }
          }
        }
      }
    }
    .ant-table-container {
      .ant-table-body {
        // border-bottom: 1px solid #030000;
        // border-top: 2px solid #030000;

        table {
          tbody.ant-table-tbody {
            tr {
              &[aria-hidden='true'] {
                display: none;
              }
              td {
                div {
                  padding: 4px;
                  font-size: 12px;
                }
              }
              &:nth-child(even) {
              }
              &:nth-child(odd) {
                background: #fcfcfc;
              }
            }
          }
        }
      }
    }
  }

  .calendar-box-two-b {
    // h5 {
    //   color: #004a88;
    //   font-size: 20px;
    //   display: inline-block;
    //   font-weight: bold;
    //   padding-left: 0px !important;
    // }
    // .ant-table-wrapper {
    //   border: 0px !important;
    // }
    .ant-table-container {
      //border-bottom: 2px solid #444444;
      //border-top: 2px solid #444444;
      //   .ant-table-header {
      //     table {
      //       thead.ant-table-thead {
      //         tr {
      //           th {
      //             background: #fcfcfc !important;
      //             border: 0px !important;
      //             font-weight: 700;
      //           }
      //         }
      //       }
      //     }
      //   }
      //   .ant-table-body {
      //     table {
      //       tbody {
      //         tr {
      //           td {
      //             font-weight: 700;
      //           }
      //         }
      //       }
      //     }
      //   }
    }
  }
  .button-edit {
    float: right;

    button {
      background: #5ca0e4;
      color: #fff;
      font-weight: 600;
      padding: 5px 10px;
      border: 0px;
      padding-left: 25px;
      position: relative;
      cursor: pointer;
      border-radius: 6px;
      &:before {
        background: url(../images/icon-edit.png);
        left: 6px;
        top: 9px;
        position: absolute;
        content: '';
        width: 18px;
        height: 19px;
        background-size: 70%;
        background-repeat: no-repeat;
      }
    }
  }
}

.creo {
  .btn-blue {
    border-radius: 3px !important;
    position: relative;
  }

  .studing-list {
    background-color: #c7b2ce !important;
  }

  .study-complete {
    background-color: #2312a4 !important;
  }

  .study-before {
    background-color: #2312a4 !important;
  }

  .study-complete-gray {
    background-color: #e3543d !important;
  }

  .study-online {
    background-color: #e3543d !important;
  }

  .zoom-Schedule {
    color: #fa8221;
    background-color: transparent !important;
    border: 1px solid #fa8221;
  }
  .offline-schedule-list {
    background-color: #8cb0df !important;
  }

  .calendar-box {
    .fc-col-header-cell {
      .fc-scrollgrid-sync-inner a {
        color: #002060;
        text-transform: uppercase;
        font-weight: 800;
      }
    }
    .fc-toolbar-chunk {
      div {
        .fc-toolbar-title {
          font-size: 24px;
          color: #333333;
          margin-left: 30px;
          margin-right: 30px;
        }

        button {
          padding: 0px;
          font-size: 24px;
          position: relative;
          top: -9px;
          color: #e0e0e0;
          border: 0px;
          background: transparent;

          &:hover,
          &:focus,
          &:active {
            color: #333333 !important;
            border: 0px;
            background: transparent !important;
            background-color: transparent !important;
            border-color: transparent !important;
            box-shadow: none !important;
          }

          span {
            font-size: 24px;
            height: auto;
            line-height: 0px;
          }

          &.fc-button-primary {
            &.fc-prev-button {
            }

            &.fc-next-button {
            }
          }
        }
      }

      button {
        &.fc-todayButton-button {
          background: #dbe3e5;
          background-color: #dbe3e5;
          text-transform: uppercase;
          color: #333333;
          border: 0px;
          font-weight: 900;
          padding: 5px 20px;
          position: relative;
          padding-left: 30px !important;

          &:after {
            background: url(../images/check-icon.png);
            filter: invert(100%);
            background-repeat: no-repeat;
            position: absolute;
            content: '';
            left: 9px;
            top: 4px;
            width: 15px;
            height: 15px;
            background-position: center;
          }

          &:hover,
          &:focus,
          &:active {
            border: 0px;
            background: #cacdce;
            background-color: #cacdce;
            box-shadow: none !important;
          }
        }
      }
    }

    .fc-dayGridMonth-view {
      table {
        &.fc-scrollgrid {
          thead {
            .fc-scrollgrid-section {
              td {
                table.fc-col-header {
                  tbody {
                    tr {
                      th {
                        background: #00a9b0;
                        padding: 6px;
                        letter-spacing: 1px;
                        text-transform: uppercase;

                        a {
                          color: #fff;
                        }
                      }
                    }
                  }
                }
              }
            }
          }

          tbody {
            tr {
              td {
                &.fc-daygrid-day {
                  &.fc-day {
                    .fc-daygrid-day-frame {
                      .fc-daygrid-day-top {
                        a {
                          color: #002060;
                        }
                      }
                    }
                  }
                  &.fc-day-sun {
                    .fc-daygrid-day-frame {
                      .fc-daygrid-day-top {
                        a {
                          color: #fa3421;
                        }
                      }
                    }
                  }
                }

                &.fc-day-other {
                  background: #f4f4f4;
                }

                background: #fff;

                .fc-daygrid-day-frame {
                  .fc-daygrid-day-top {
                    justify-content: flex-end;

                    a {
                      font-size: 15px;
                      font-weight: 700;
                      color: #777777;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .buttonlisting-wrap {
    display: none;
  }
  .iconcalander {
    // width: 8px !important;
    // height: 8px !important;
    // position: relative;
    // text-indent: -9999px;
    // padding: 6px !important;
    // background-size: 8px;
    // background-repeat: no-repeat;
    // background-position: center;

    &.icon_userlesson {
      // background-image: url(../images/star-icon.png);
      // background-color: #11783d !important;
      background-color: #c7b2ce !important;
    }

    &.icon_assignedtests {
      //background-image: url(../images/test-icon.png);
      background-color: #ff7f00 !important;
    }

    &.icon_zoomschedule {
      background-image: url(../images/online-class-icon.png);
      background-color: #3c79f6 !important;
    }

    &.icon_offlineLesson {
      background-color: #8cb0df !important;
    }
    &.icon_externalStudyStudent {
      background-color: #ff0000;
    }

    &.icon_aiTopicUserAssignment {
      color: #1890ff;
    }
  }

  .iconcommontext {
    font-size: 8px;
    font-weight: 700;
    width: 50px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &.icon_userlesson {
      color: #c7b2ce;
    }
    &.icon_offlineLesson {
      color: #8cb0df !important;
    }
    &.icon_phonicsLesson {
      color: #ffce54;
    }

    &.icon_assignedtests {
      color: #ff7f00;
    }

    &.icon_zoomschedule {
      color: #c7b2ce;
    }
    &.icon_externalStudy {
      color: #6e93c1;
    }

    &.icon_externalStudyStudent {
      color: #ff0000;
    }

    &.icon_aiTopicUserAssignment {
      color: #1890ff;
    }

    &.icon_externalStudyBookr {
      color: orangered;
    }
  }

  .studystatus-haeding {
    padding: 10px 10px !important;

    // .studystatus {
    //   color: #004a88;
    //   font-size: 20px;
    //   display: inline-block;
    //   font-weight: bold;
    // }
    // .studystatus-date {
    //   color: #333333;
    //   font-size: 20px;
    //   display: inline-block;
    // }
  }

  .calendar-box-two-a {
    table {
      tbody {
        tr {
          td {
            padding: 12px 8px;

            &:nth-child(2) {
              font-weight: 600;
              color: #444444;
              font-size: 12px;
            }
          }
        }
      }
    }

    .ant-table-container {
      .ant-table-body {
        // border-bottom: 1px solid #030000;
        // border-top: 2px solid #030000;

        table {
          tbody.ant-table-tbody {
            tr {
              &[aria-hidden='true'] {
                display: none;
              }

              td {
                div {
                  padding: 4px;
                  font-size: 12px;
                }
              }

              &:nth-child(even) {
              }

              &:nth-child(odd) {
                background: #fcfcfc;
              }
            }
          }
        }
      }
    }
  }

  .calendar-box-two-b {
    h5 {
      font-weight: bold;
    }
    // .ant-table-wrapper {
    //   border: 0px !important;
    // }
    .ant-table-container {
      //border-bottom: 2px solid #444444;
      //border-top: 2px solid #444444;
      //   .ant-table-header {
      //     table {
      //       thead.ant-table-thead {
      //         tr {
      //           th {
      //             background: #fcfcfc !important;
      //             border: 0px !important;
      //             font-weight: 700;
      //           }
      //         }
      //       }
      //     }
      //   }
      //   .ant-table-body {
      //     table {
      //       tbody {
      //         tr {
      //           td {
      //             font-weight: 700;
      //           }
      //         }
      //       }
      //     }
      //   }
    }
  }

  .button-edit {
    float: right;

    button {
      background: #dbe3e5;
      background-color: #dbe3e5;
      color: #333333;
      font-weight: 600;
      padding: 5px 10px;
      border: 0px;
      padding-left: 25px;
      position: relative;
      cursor: pointer;
      border-radius: 6px;

      &:before {
        background: url(../images/icon-edit.png);
        filter: invert(1);
        left: 6px;
        top: 9px;
        position: absolute;
        content: '';
        width: 18px;
        height: 19px;
        background-size: 70%;
        background-repeat: no-repeat;
      }
    }
  }
}

.lucid {
  .iconcalander {
    // width: 8px !important;
    // height: 8px !important;
    // position: relative;
    // text-indent: -9999px;
    // padding: 6px !important;
    // background-size: 8px;
    // background-repeat: no-repeat;
    // background-position: center;

    &.icon_userlesson {
      // background-image: url(../images/star-icon.png);
      // background-color: #11783d !important;
      background-color: #ff0000 !important;
    }

    &.icon_assignedtests {
      //background-image: url(../images/test-icon.png);
      background-color: #000066 !important;
    }

    &.icon_zoomschedule {
      background-image: url(../images/online-class-icon.png);
      background-color: #3c79f6 !important;
    }

    &.icon_offlineLesson {
      background-color: #8cb0df !important;
    }
    &.icon_assignedBooks {
      background-color: #ff9900;
    }

    &.icon_externalStudy {
      background-color: #6e93c1;
    }

    &.icon_externalStudyStudent {
      background-color: #ff0000;
    }

    &.icon_aiTopicUserAssignment {
      color: #1890ff;
    }

    &.icon_externalStudyBookr {
      background-color: orangered;
    }
  }
  .iconcommontext {
    font-size: 8px;
    font-weight: 700;
    width: 50px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &.icon_userlesson {
      color: #ff0000;
    }

    &.icon_offlineLesson {
      color: #7030a0 !important;
    }

    &.icon_phonicsLesson {
      color: #ffce54;
    }

    &.icon_assignedtests {
      color: #000066;
    }

    &.icon_zoomschedule {
      color: #3c79f6;
    }

    &.icon_assignedBooks {
      color: #ff9900;
    }
    &.icon_externalStudy {
      color: #6e93c1;
    }

    &.icon_externalStudyStudent {
      color: #ff0000;
    }

    &.icon_aiTopicUserAssignment {
      color: #1890ff;
    }

    &.icon_externalStudyBookr {
      color: orangered;
    }
  }

  .studystatus-haeding {
    padding: 10px 10px !important;

    // .studystatus {
    //   color: #004a88;
    //   font-size: 20px;
    //   display: inline-block;
    //   font-weight: bold;
    // }
    // .studystatus-date {
    //   color: #333333;
    //   font-size: 20px;
    //   display: inline-block;
    // }
  }
}

@media (max-width: 600px) {
  .calendar-box-two-b {
    .ant-table-wrapper {
      .ant-spin-container {
        overflow: auto;
        .ant-table {
          width: 600px;
        }
      }
    }
  }
  .iconcommontext {
    display: none;
  }
  .buttonlisting-wrap {
    button {
      padding: 6.4px 12px;
      font-size: 14px;
      margin-right: 10px !important;
      &.btn-ebook {
        margin-right: 0px !important;
      }
    }
  }

  .fc-prev-button {
    font-size: 1.3em !important;
  }
  .fc-next-button {
    font-size: 1.3em !important;
  }
  .calendar-box {
    .fc-header-toolbar {
      display: block;
      .fc-toolbar-chunk {
        display: block;
        width: 100%;
        text-align: center;
        margin-bottom: 10px;
      }
    }
  }
  .smart-eclass {
    .buttonlisting-dashboard {
      .buttonlisting-wrap {
        display: block;
        width: 100%;
        button {
          margin-left: 0px !important;
          margin-right: 10px !important;
          padding: 6px 12px 6px 28px !important;
          font-size: 12px;
          &.btn-ebook {
            margin-right: 0px !important;
          }
          &:after {
            width: 25px;
            height: 30px;
            left: 0px;
            top: 0px;
            background-position: 8px;
          }
        }
      }
    }
  }

  .sneakersedu {
    .buttonlisting-dashboard {
      .buttonlisting-wrap {
        display: block;
        width: 100%;
        button {
          margin-left: 0px !important;
          margin-right: 10px !important;
          padding: 6px 12px 6px 28px !important;
          font-size: 12px;
          &.btn-ebook {
            margin-right: 0px !important;
          }
          &:after {
            width: 25px;
            height: 30px;
            left: 0px;
            top: 0px;
            background-position: 8px;
          }
        }
      }
    }
  }
}

.fc-popover-body {
  .iconcommontext {
    width: auto !important;
  }
}

// offline/lesson/assign
@media (max-width: 1400px) {
  .main-layout {
    main {
      & > div {
        .ant-table-wrapper {
          &.classroom-table-table {
            overflow: auto;
            height: 90%;
          }
        }
      }
    }
  }

  .buttonlisting-dashboard {
    & ~ div {
      .assign-danger-btn {
      }
    }
  }
}

@media (max-width: 1100px) {
  .testmanagment-testsectionblock .ant-spin-container {
    overflow: auto;
  }
  .testmanagment-testsectionblock .ant-table-container {
    width: 1200px;
  }
}
@media (max-width: 1000px) {
  section.ant-layout.ant-layout-has-sider {
    main.ant-layout-content {
      overflow: auto;
      > div.ant-row {
        width: 1000px;
      }
    }
  }
  .bookcredit-top-title,
  .ordershipping-top-rightform,
  .orderadmin-top-title,
  .orderadmin-top-rightform {
    flex: 100%;
    max-width: 100%;
  }
  .ordershipping-top-rightform,
  .orderadmin-top-rightform {
    display: flex;
    justify-content: flex-start !important;
  }
  .settingicon-table {
    margin-top: 20px;
  }
  .orderadmin-top-rightform .purchase-select-option .ant-select,
  .ordershipping-wrapper-mainblock .purchase-select-option .ant-select {
    width: 80px !important;
  }
  .orderadmin-top-rightform input[type='text'],
  .ordershipping-wrapper-mainblock input[type='text'] {
    width: 120px;
  }
  .orderadmin-imagesec .ant-affix {
    position: static;
  }
  .orderadmin-top-rightform {
    margin-bottom: 10px;
  }
  .bookstore-book-order-mainblock {
    .bookstore-sec-one,
    .bookstore-sec-two {
      width: 100%;
      max-width: 100%;
      flex: 100%;
    }
  }
  .bookstore-book-order-mainblock,
  .bookstore-tableright .ant-table-body,
  .bookstore-book-order-mainblock {
    min-height: inherit !important;
    max-height: inherit !important;
    height: auto !important;
    overflow: auto;
  }
  .data-list-table-dv {
    height: auto;
  }
  .bookstore-book-order-mainblock {
    .bookstore-book-order-mainblock-a,
    .bookstore-book-order-mainblock-b {
      width: 100%;
      max-width: 100%;
      flex: 100%;
      border: 0px !important;
    }
    .book-order-main-wrapper {
      height: auto;
    }
    .bookstore-book-order-mainblock-b {
      margin-top: 50px !important;
    }
    .bookorder-table-layout .ant-table-body {
      min-height: inherit !important;
      max-height: inherit !important;
      height: auto !important;
      overflow: auto;
    }
    .booktable-main-tbl-main,
    .booktable-tbl {
      width: 100%;
      overflow: auto;
    }
    .booktable-main-tbl-main table,
    .booktable-tbl table {
      width: 1200px;
    }
  }
}
@media (max-width: 999px) {
  .orderadmin-imagesec a img {
    width: 25px !important;
  }
  .ant-layout {
    overflow: auto !important;
  }
  .zoom-manage-add .zoom-manage-block {
    .zoom-manage-box-a,
    .zoom-manage-box-b,
    .zoom-manage-box-c,
    .zoom-manage-box-d {
      height: auto !important;
      overflow: auto;
    }
  }
  .calendar-box-two-a,
  .calendar-box-two-b,
  .calendar-box > div,
  .calendar-box-two,
  .calendar-box-two-b .ant-table-wrapper .ant-table-container .ant-table-body,
  .calendar-box-two-a .ant-table-wrapper .ant-table-container .ant-table-body,
  .subboxtwo-dashboard-chat-height,
  .subboxone-dashboard-height,
  .subboxone-dashboard-height .ant-table-wrapper .ant-table-container .ant-table-body,
  .tblclass-list-height .ant-table-wrapper .ant-table-container .ant-table-body,
  .tblclass-classroom-height,
  .tblclass-classroom-height .ant-table-wrapper .ant-table-container .ant-table-body,
  .tblclass-list-height .ant-table-wrapper .ant-table-container .ant-table-body,
  .homework-result-halfwrapper .ant-table-wrapper .ant-table-container .ant-table-body,
  .ticketStatus-tabtwo .ant-table-wrapper .ant-table-container .ant-table-body,
  .ticketStatus-tabfour .ant-table-wrapper .ant-table-container .ant-table-body,
  .myinfowrapper-children .ant-table-wrapper .ant-table-container .ant-table-body,
  .ticketStatus-tabthree .ant-table-wrapper .ant-table-container .ant-table-body,
  .addnew-testmanagment-testblock .testmanagment-sectiona,
  .testmanagment-testsectionblock .ant-table-wrapper .ant-table-container .ant-table-body,
  .test-manage-action-assign-wrapper .test-manage-action-assign-scroll,
  .testmanagment-sectionb-tsb,
  .testmanagment-sectionb-tsb .ant-table-wrapper .ant-table .ant-table-container .ant-table-body,
  .addnew-testmanagment-testblock-add .testmanagment-sectiona-testsectionbk,
  .syllabus-mailblock .ant-table-wrapper .ant-table-container .ant-table-body,
  .addnew-testmanagment,
  .testpreview-wrapper,
  .testmanagment-testblock,
  .testmanagment-testblock .testmanagment-testsectionblock,
  .testmanagment-testblock .testmanagment-testsectionblock-two,
  .testmanagment-testblock .testmanagment-testsectionblock .ant-table-wrapper .ant-table .ant-table-container .ant-table-body,
  .testmanagment-testblock .testmanagment-testsectionblock-two .ant-table-wrapper .ant-table .ant-table-container .ant-table-body,
  .test-syllabus-wrapper > div,
  .test-syllabus-wrapper .test-syllabus-box-listing .test-syllabus-box-a .test-syllabus-box-a-tsb,
  .test-syllabus-wrapper .test-syllabus-box-listing .test-syllabus-box-b .test-syllabus-box-b-tsb,
  .test-syllabus-wrapper .test-syllabus-box-listing .test-syllabus-box-c .test-syllabus-box-b-tsb,
  .test-syllabus-wrapper .test-syllabus-box-listing .test-syllabus-box-c .ant-table-wrapper .ant-table .ant-table-container .ant-table-body,
  .calendar-box > div,
  .calendar-box-two,
  .calendar-box-two-a,
  .calendar-box-two-b,
  .board-campusnotice-height .ant-table-wrapper .ant-table-container .ant-table-body,
  .tblclass-list-height,
  .homeresult-halfwrapper,
  .homeresult-halfwrapper .ant-table-wrapper .ant-table .ant-table-container .ant-table-body,
  .placement-test-halfwrapper .ant-table-wrapper .ant-table .ant-table-container .ant-table-body,
  .myinfowrapper-children,
  .ticketStatus-tabtwo,
  .ticketStatus-tabthree,
  .ticketStatus-tabfour,
  .myinfowrapper-children,
  .placement-test-halfwrapper,
  .testmanagement-mailblock,
  .testdetails-mainblock,
  .testdetails-mainblock .ant-table-wrapper .ant-table .ant-table-container .ant-table-body,
  .testmanagement-mailblock .ant-table-wrapper .ant-table-container .ant-table-body,
  .testresult-mainblock .ant-table-wrapper .ant-table-container .ant-table-body,
  .testresult-mainblock,
  .syllabus-mailblock,
  .syllabus-mailblock .ant-table-wrapper .ant-table-container .ant-table-body,
  .shadowblock-a,
  .shadowblock-b,
  .shadowblock-a .listblock-shadowblock-a,
  .shadowblock-b .listblock-shadowblock-a .shadowblock-a .listblock-shadowblock-b,
  .shadowblock-b .listblock-shadowblock-b,
  .zoom-wrapper-main .zoom-wrapper-mainblock,
  .zoom-wrapper-mainblock .ant-table-wrapper .ant-table .ant-table-container .ant-table-body,
  .zoom-manage-box-b .zoom-manage-box-b-block .ant-list,
  .zoom-manage-add .zoom-manage-block,
  .test-syllabus-wrapper
    .books-assign-testblock
    .test-syllabus-box-listing
    .test-syllabus-box-c
    .ant-table-wrapper
    .ant-table
    .ant-table-container
    .ant-table-body,
  .setting-wrapper-halfwrap .ant-table-wrapper .ant-table .ant-table-container .ant-table-body,
  .setting-wrapper-halfwrap,
  .learning-halfwrapper,
  .learning-halfwrapper .ant-table-wrapper .ant-table-container .ant-table-body,
  .addnew-testmanagment-testblock,
  .campus-halfwrapper,
  .campus-halfwrapper .ant-table-wrapper .ant-table-container .ant-table-body,
  .credit-halfwrapper,
  .credit-halfwrapper .ant-table-wrapper .ant-table .ant-table-container .ant-table-body,
  .smart-eclass .calendar-box-two-a .ant-table-wrapper .ant-table-container .ant-table-body,
  .smart-eclass .calendar-box-two-b .ant-table-wrapper,
  .smart-eclass .calendar-box-two-b .ant-table-wrapper .ant-table-container .ant-table-body,
  .sneakersedu .calendar-box-two-a .ant-table-wrapper .ant-table-container .ant-table-body,
  .sneakersedu .calendar-box-two-b .ant-table-wrapper,
  .sneakersedu .calendar-box-two-b .ant-table-wrapper .ant-table-container .ant-table-body,
  .learning-halfwrapper.learning-hs .ant-table-wrapper .ant-table-container .ant-table-body .settingpayadmin-wrapper-mainblock,
  .settingpayadmin-wrapper-mainblock .ant-table-wrapper .ant-table-container .ant-table-body,
  .ordershipping-wrapper-mainblock,
  .ordershipping-wrapper-mainblock .ant-table-wrapper .ant-table-container .ant-table-body,
  .orderadmin-table-table,
  .orderadmin-table-table .ant-table-wrapper .ant-table-container .ant-table-body,
  .settingpayadmin-table-table,
  .settingpayadmin-table-table .ant-table-wrapper .ant-table-container .ant-table-body,
  .test-syllabus-wrapper.book-credit-assign .test-syllabus-wrapper-tb,
  .bookcredit-wrapper-mainblock,
  .bookstore-book-order-mainblock,
  .bookstore-tableright .ant-table-body,
  .orderadmin-wrapper-mainblock,
  .orderadmin-wrapper-mainblock .ant-table-wrapper .ant-table-container .ant-table-body,
  .bookcredit-table-table .ant-table-container .ant-table-body {
    min-height: inherit !important;
    max-height: inherit !important;
    height: auto !important;
    overflow: auto;
  }
  .test-manage-action-assign-wrapper .test-manage-action-assign-scroll ul.ant-list-items,
  .zoom-manage-box-a .zoom-manage-box-a-block ul.ant-list-items {
    height: 300px !important;
    min-height: 300px !important;
  }
  .tblclass-list-height .ant-spin-container,
  .classroom-table-table .ant-spin-container,
  .homework-result-halfwrapper .ant-spin-container,
  .syllabus-assigned-halfwrapper .ant-spin-container,
  .ant-modal-body .detaildatapopup-table .ant-spin-container,
  .testresult-table .ant-spin-container,
  .testmanagment-testsectionblock .ant-spin-container,
  .board-campusnotice-height .ant-spin-container,
  .homeresult-halfwrapper .ant-spin-container,
  .placement-test-halfwrapper .ant-spin-container,
  .setting-wrapper-halfwrap .ant-spin-container,
  .learning-halfwrapper .ant-spin-container,
  .class-result-halfwrap .ant-spin-container,
  .campus-halfwrapper .ant-spin-container,
  .credit-halfwrapper .ant-spin-container,
  .settingpayadmin-wrapper-mainblock .ant-spin-container,
  .ordershipping-wrapper-mainblock .ant-spin-container,
  .orderadmin-table-table .ant-spin-container,
  .settingpayadmin-table-table .ant-spin-container,
  .bookcredit-table-table .ant-spin-container,
  .bookstore-tableright .ant-spin-container,
  .bookorder-table-layout .ant-spin-container,
  .orderadmin-table-table .ant-spin-container {
    overflow: auto;
  }
  .tblclass-list-height .ant-table-container,
  .classroom-table-table .ant-table-container,
  .homework-result-halfwrapper .ant-table-container,
  .syllabus-assigned-halfwrapper .ant-table-container,
  .ant-modal-body .detaildatapopup-table .ant-table-container,
  .testresult-table .ant-table-container,
  .testmanagment-testsectionblock .ant-table-container,
  .board-campusnotice-height .ant-table-container,
  .homeresult-halfwrapper .ant-table-container,
  .placement-test-halfwrapper .ant-table-container,
  .setting-wrapper-halfwrap .ant-table-container,
  .learning-halfwrapper .ant-table-container,
  .class-result-halfwrap .ant-table-container,
  .campus-halfwrapper .ant-table-container,
  .credit-halfwrapper .ant-table-container,
  .settingpayadmin-wrapper-mainblock .ant-table-container,
  .ordershipping-wrapper-mainblock .ant-table-container,
  .orderadmin-table-table .ant-table-container,
  .settingpayadmin-table-table .ant-table-container,
  .bookcredit-table-table .ant-table-container,
  .bookstore-tableright .ant-table-container,
  .tablebookstore-1s table,
  .tablebookstore-2s table,
  .tablebookstore-3s table,
  .tablebookstore-4s table,
  .orderadmin-table-table .ant-table-container,
  .bookorder-table-layout .ant-table-container {
    width: 1200px;
  }
  .bookstore-book-order-wrapper-main .ant-affix {
    position: static;
  }
  .tablebookstore-1s,
  .tablebookstore-2s,
  .tablebookstore-3s,
  .tablebookstore-4s {
    width: 100%;
    overflow: auto;
  }
  .table-t-three tr th {
    width: 200px !important;
    display: block;
  }
  .bookcredit-top-rightform {
    max-width: 100%;
    min-width: 100%;
  }
  h4.ant-typography {
    font-size: 16px !important;
  }
  .syllabus-assigned-halfwrapper-btn {
    max-width: 50%;
  }
  .modify-schedule-modal-a,
  .modify-schedule-modal-b,
  .credit-halfwrapper-col {
    display: block;
    -ms-flex: 0 0 100% !important;
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
  .awesome-ebook-tab-nav-list,
  .ebook-ae-a {
    width: 1200px;
  }
  .ebook-ae-overflow {
    overflow: auto !important;
    width: 100% !important;
  }
}
@media (max-width: 767px) {
  .homework-result-halfwrapper,
  .syllabus-assigned-halfwrapper,
  .board-campusnotice-height,
  .setting-main-maininfo {
    height: calc(100vh - 140px) !important;
    min-height: calc(100vh - 140px) !important;
    overflow: auto;
    overflow-x: hidden;
  }
  .header-top-right .ant-menu-submenu-title .username {
    width: 70px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .header-classroom-search .ant-picker {
    margin: 10px 0px;
  }
  .tblclass-list-height-a,
  .tblclass-list-height-b {
    display: block;
    width: 100%;
    margin-left: 0px !important;
  }
  .tblclass-list-height-d {
    margin-left: 0px !important;
  }
  .tblclass-list-height-c {
    float: left;
    width: 50% !important;
    margin-left: 0px !important;
    margin-bottom: 10px;
  }
  .homework-result-dv,
  .syllabus-assigned-halfwrapper-dv,
  .board-campusnotice-height-dv,
  .homeresult-dv {
    display: block !important;
  }
  .homework-result-dv {
    height: auto !important;
  }
  .board-campusnotice-top-fields {
    margin-bottom: 10px;
  }
  .homeresult-selectfiled {
    width: 100% !important;
    .ant-select {
      width: 47% !important;
      text-align: left;
      margin-right: 6% !important;
    }

    .homeresult-inputfield {
      width: 47% !important;
    }
  }
  .vtube-mainblock-sec-b,
  .vtube-mainblock-sec-a {
    width: 100% !important;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .learning-halfwrapper-top-header {
    display: block !important;
  }
  .assigned-syllabus-top-fields {
    width: 100% !important;
  }
  .addnew-testmanagment-testblock-add .testmanagment-sectiona-testsectionbk form.ant-form {
    padding-right: 0px !important;
  }
  .fieldsection-vtube,
  .vtube-title-sec {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .homework-result-bk {
    margin-bottom: 10px !important;
  }
  .boarddetail-a {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
    width: 100% !important;
    &.board-b {
      font-weight: 600;
      &.topspmb {
        margin-top: 15px;
      }
    }
  }
  .boarddetail-fullwidth {
    width: 100% !important;
    display: block !important;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 15px;
  }
  .bookcredit-top-rightform {
    display: block !important;
    .purchase-select-option {
      margin-bottom: 15px;
    }
  }
  .bookcredit-top-rightform input[type='text'] {
    width: 55%;
    margin-top: 15px;
  }
}

@media (max-width: 600px) {
  .purchase-select-option,
  .purchase-select-option {
    width: 100%;
    margin-bottom: 10px;
  }
  .orderadmin-top-rightform .purchase-select-option .ant-select,
  .ordershipping-wrapper-mainblock .purchase-select-option .ant-select,
  .orderadmin-top-rightform .purchase-select-option .ant-select,
  .ordershipping-wrapper-mainblock .purchase-select-option .ant-select {
    width: 46% !important;
    margin-right: 4% !important;
  }
  .orderadmin-top-rightform {
    display: block !important;
  }
  .bookstore-header-top .bookstore-heading,
  .bookstore-header-top .select-right,
  .orderadmin-wrapper-main .orderadmin-wrapper-main-a,
  .orderadmin-wrapper-main .orderadmin-imagesec,
  .ordershipping-wrapper-main-a,
  .orderadmin-imagesec {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .bookstore-header-top .select-right {
    text-align: left !important;
    margin-bottom: 20px !important;
  }
  .orderadmin-imagesec {
    margin-bottom: 20px;
  }
  .ordershipping-wrapper-main .ordershipping-top-rightform {
    display: block !important;
  }
}

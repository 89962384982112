.meta-close {
  position: absolute;
  right: 4rem;
  top: 0.5rem;
  cursor: pointer;

  h2 {
    font-size: 2rem;
  }
}

.ant-tooltip-content {
  .ant-tooltip-arrow-content {
    background-color: /* #ffb055; */ #fabe78;
  }
  .ant-tooltip-inner {
    background-color: #fabe78;
    color: #333;
    font-weight: 600;
  }
}

.ant-modal.userInfo-modal {
  width: 80% !important;
}

.meta-con.meta-full-con {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.meta-con {
  /*
  width: calc(100% - 15rem);
  height: calc(100vh - 10rem);*/
  width: 90%;
  height: calc(100vh - 7rem);
  background: #c6edfd;
  color: #1561a0;

  .arrow-wrap {
    position: absolute;
    bottom: 8rem;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: start;
    align-self: center;
    width: calc(60% - 11vw);

    .guide-arrow {
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 35px 60.6px 35px 0;
      border-color: transparent #fabe78 transparent transparent;
      line-height: 0px;
      _border-color: #ffffff #fabe78 #ffffff #ffffff;
      _filter: progid:DXImageTransform.Microsoft.Chroma(color='#ffffff');
      position: relative;

      .arrow-body {
        background: #fabe78;
        color: #333;
        width: 100%;
        min-width: 150px;
        height: 45px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        z-index: 2;
        top: -1.5rem;
        left: 2.5rem;
        font-weight: 600;
        font-size: 1.2rem;
      }
    }
  }

  &.guide-con {
    width: 100%;
    min-height: 100vh;

    .meta-header.test-header {
      .ant-tooltip.ant-tooltip-placement-bottomRight {
        .ant-tooltip-content {
          .ant-tooltip-inner {
            span {
              font-size: 1.2rem;
              line-height: 1.4;
              font-weight: 600;
            }
          }
        }
      }

      .ant-tooltip.ant-tooltip-placement-bottom {
        .ant-tooltip-content {
          .ant-tooltip-inner {
            span {
              font-size: 1.2rem;
              line-height: 1.4;
              font-weight: 600;
            }
          }
        }
      }
    }

    .guideNum {
      font-weight: 600;
      color: #edffad;
      font-size: 1.1rem;
      margin-left: 0.7rem;
      margin-right: 1rem;
      text-shadow: -1px -1px 0 #b25252, 1px -1px 0 #b25252, -1px 1px 0 #b25252, 1px 1px 0 #b25252;
      &:after {
        /*
        border: 1px solid red;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        display: block;
        position: absolute;
        top: 0rem;
        left: 0rem;
        content: '';*/
      }
    }

    .exam-type-con {
      .test-wrap {
        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(4) {
          position: relative;
          top: 0;
        }
      }
    }

    .test-wrap {
      .arrow-wrap {
        position: relative;
        left: 0;
        top: 2rem;
        bottom: 0;
        transform: rotate(-45deg);
        z-index: 9999;
        .guide-arrow {
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 35px 60.6px 35px 0;
          border-color: transparent #fabe78 transparent transparent;
          line-height: 0px;
          _border-color: #ffffff #fabe78 #ffffff #ffffff;
          _filter: progid:DXImageTransform.Microsoft.Chroma(color='#ffffff');
          position: relative;

          .arrow-body {
            background: #fabe78;
            color: #333;
            width: 100%;
            min-width: 150px;
            height: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            z-index: 2;
            top: -1.6rem;
            left: 3rem;
            font-weight: 600;
            font-size: 1.2rem;
          }
        }
      }
    }

    .readingtest-con {
      .readingtest-wrap {
        .reading-a-con {
          .reading-a-wrap {
            .reading-a {
              .listeningAnswerArrow {
                &.arrow-wrap {
                  position: absolute;
                  left: 50%;
                  top: 57%;
                  transform: translate(-29%, -10%) rotate(0deg);
                  z-index: 9999;
                  .guide-arrow {
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: 45px 77.9px 45px 0;
                    border-color: transparent #fabe78 transparent transparent;
                    line-height: 0px;
                    _border-color: #ffffff #fabe78 #ffffff #ffffff;
                    _filter: progid:DXImageTransform.Microsoft.Chroma(color='#ffffff');
                    position: relative;
                    bottom: 45%;
                    right: 39%;
                    transform: translate(-40%, -18%);

                    .arrow-body {
                      background: #fabe78;
                      color: #333;
                      width: 100%;
                      min-width: 300px;
                      height: 50px;
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      position: relative;
                      z-index: 2;
                      top: -1.6rem;
                      left: 4rem;
                      font-weight: 600;
                      font-size: 1.2rem;
                    }
                  }
                }
              }

              .arrow-wrap {
                position: absolute;
                right: 15%;
                top: 40%;
                transform: translate(-29%, -10%) rotate(0deg);
                z-index: 9999;
                .guide-arrow {
                  width: 0;
                  height: 0;
                  border-style: solid;
                  border-width: 45px 77.9px 45px 0;
                  border-color: transparent #fabe78 transparent transparent;
                  line-height: 0px;
                  _border-color: #ffffff #fabe78 #ffffff #ffffff;
                  _filter: progid:DXImageTransform.Microsoft.Chroma(color='#ffffff');
                  position: relative;
                  bottom: 16%;
                  right: 39%;
                  transform: translate(-40%, -18%);

                  .arrow-body {
                    background: #fabe78;
                    color: #333;
                    width: 100%;
                    min-width: 300px;
                    height: 50px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    position: relative;
                    z-index: 2;
                    top: -1.6rem;
                    left: 4rem;
                    font-weight: 600;
                    font-size: 1.2rem;
                  }
                }
              }
            }
          }
        }
      }
    }

    .process-btn-con {
      .arrow-wrap {
        position: absolute;
        right: 5%;
        bottom: -5%;
        transform: translate(-29%, -10%) rotate(0deg);
        z-index: 9999;
        .guide-arrow {
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 45px 0 45px 77.9px;
          border-color: transparent transparent transparent #fabd78;
          line-height: 0px;
          _border-color: #000000 #000000 #000000 #fabd78;
          _filter: progid:DXImageTransform.Microsoft.Chroma(color='#000000');
          position: relative;
          bottom: 16%;
          right: 39%;
          transform: translate(-40%, -18%);

          .arrow-body {
            background: #fabe78;
            color: #333;
            width: 100%;
            min-width: 350px;
            height: 45px;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            z-index: 2;
            top: -1.5rem;
            left: -25rem;
            font-weight: 600;
            font-size: 1.2rem;
          }
        }
      }
    }

    .playBtnArrow {
      &.arrow-wrap {
        position: absolute;
        left: 43%;
        top: 4%;
        transform: translate(-29%, -10%) rotate(-27deg);
        z-index: 9999;
        .guide-arrow {
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 45px 77.9px 45px 0;
          border-color: transparent #fabe78 transparent transparent;
          line-height: 0px;
          _border-color: #ffffff #fabe78 #ffffff #ffffff;
          _filter: progid:DXImageTransform.Microsoft.Chroma(color='#ffffff');
          position: relative;
          bottom: 16%;
          right: 39%;
          transform: translate(-40%, -18%);

          .arrow-body {
            background: #fabe78;
            color: #333;
            width: 100%;
            min-width: 400px;
            height: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            z-index: 2;
            top: -1.6rem;
            left: 4rem;
            font-weight: 600;
            font-size: 1.2rem;
          }
        }
      }
    }
  }

  .meta-burger-list {
    display: none;
    width: 15rem;
    position: absolute;
    z-index: 2;
    height: 100vh;
    border: 1px solid #ddd;
    background: #c6edfd;
    overflow-y: auto;
    padding: 10px 0;

    .ant-list-header {
      div {
        font-weight: 700;
        text-align: center;
        .close-header {
          cursor: pointer;
          float: right;
          margin-right: 20px;
          color: #000000;
        }
      }
    }
    .ant-list-item {
      font-weight: 700;
    }

    .ant-list-item.done {
      color: #1561a0;
      background-color: #ebebeb;
    }
  }
  .meta-burger-list.active {
    display: block;
    left: 0px;
  }

  .btn {
    background: #c6edfe;
    color: #1462a1;
    border-radius: 30px;
    width: 150px;
    max-width: 100%;
    text-align: center;
    padding: 1rem;
    cursor: pointer;

    a {
      color: #1462a1;
      text-decoration: none;
      font-weight: 600;
      font-size: 1.3rem;
      text-align: center;
      margin: 0 auto;
    }
  }

  .fixed_btn {
    position: relative !important;
    bottom: 0;
    margin: 1rem auto;
  }

  .fixed_btm_btn {
    position: relative;
    bottom: 5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 14rem;
    height: 6rem;
    border-color: transparent;

    span {
      font-size: 1.8rem;
      font-weight: 700;
      letter-spacing: 0.05rem;
    }
  }

  .meta-header {
    position: relative;
    text-align: center;
    width: 100%;
    margin: 1rem auto;
    display: block;

    &.test-header {
      position: absolute;
    }

    h1.main-tit {
      text-align: center;
      margin-bottom: 0;
    }

    .typeLogo {
      width: 30%;
      margin-right: 1rem;
    }

    div.phoneNum {
      position: absolute;
      right: 0;
      margin-right: auto;
    }
  }

  h1.main-tit {
    font-size: 2.4rem;
    color: #1561a0;
  }

  .inner-header {
    /*    width: calc(100% - 22rem);
    display: flex;
    position: relative; */
    position: relative;
    width: calc(100% - 10rem);
    display: flex;

    margin: 1rem 0;

    h2 {
      font-size: 1.7rem;
      color: #1561a0;
      font-weight: 600;
    }

    .date-wrap {
      margin-left: auto;
    }
  }

  &.register-con {
    height: auto;
    margin: 3rem auto;
    #student-register {
      width: 100%;
      max-width: 750px;
      margin: 1rem auto 1rem;
      display: flex;
      justify-content: center;

      tbody {
        tr {
          td {
            display: block;
            &:nth-child(2) {
              margin-left: 0rem;
            }

            .ant-form-item {
              display: block;
              margin-bottom: 0.5rem;
              margin-right: 8rem;
            }

            .alert-label-noTime {
              bottom: -3rem;
            }
          }

          &:nth-child(1) {
            td:nth-child(2) {
              .ant-form-item-control {
                .ant-form-item-control-input-content {
                  input.partInput {
                    &:nth-child(1) {
                      width: 130px;
                    }
                    &:nth-child(2) {
                      width: 150px;
                    }
                  }
                }
              }
            }
          }

          &:nth-child(2) {
            td:nth-child(2) {
              .ant-form-item-control {
                .ant-form-item-control-input-content {
                  input.partInput {
                    &:nth-child(1) {
                      width: 90px;
                    }
                  }
                }
              }
            }
          }

          &:nth-child(3) {
            td:nth-child(2) {
              .ant-form-item-control {
                .plainInput.gradeInput {
                  width: 40%;
                }
              }
            }
          }
        }
      }

      .ant-form-item-label > label {
        font-size: 1rem;
        font-weight: 600;
      }

      .ant-form-item-label > label::before {
        content: '●';
        font-size: 1.5rem;
        color: #1462a1;
        margin: 0 0.2rem 3px 0.2rem;
      }

      .divided-form {
        width: 100%;
        max-width: 1100px;
        display: flex;
        flex-direction: row;

        &:nth-child(4) {
          width: 500px;
          left: 1rem;
          position: relative;
        }

        .ant-col-xs-24 {
          margin-left: 3px;
        }

        .ant-form-item {
          /*
          width: 45%;
          max-width: 600px;
          margin: 1rem 0.5rem;*/

          width: 100%;
          max-width: 600px;
          margin: 1rem 0.5rem;
        }

        .common-form.threeInput-form {
          input.partInput {
            width: 23%;
          }
        }

        .ant-form-item-label > label {
          font-size: 1.2rem;
        }

        .ant-col-sm-16 {
          width: 100%;
        }

        input.plainInput {
          width: 55%;
          text-align: center;
        }

        &:nth-child(3) {
          .common-form {
            &:nth-child(2) {
              .ant-form-item {
                position: relative;
                left: -5rem;
              }
            }
          }
        }
      }

      .threeInput-form {
        input.partInput {
          width: 20rem;
        }
      }

      .common-form {
        margin: 1rem auto;
        width: 600px;
      }

      .plain-form {
        /*
        width: 64%;
        max-width: 800px;*/
        width: 90%;
        max-width: 1100px;
        display: flex;
        /* justify-content: center; */

        .ant-form-item {
          max-width: 500px;
          width: 100%;
        }

        .ant-row .ant-form-item {
        }

        .ant-col-sm-8 {
          flex: none;
          width: 100%;
          max-width: 165px;
          text-align: left;
        }

        .ant-form-horizontal .ant-form-item-control {
          margin-left: 24px;
        }

        .ant-form-item-control-input-content {
          width: 100%;
          max-width: 500px;
        }
      }

      .partInput_wrap {
        margin-left: 0rem;
        width: 202px;
        max-width: 100%;
      }

      .ex-span {
        position: absolute;
        width: 100%;
        max-width: 120px;
        margin-left: 10px;
        margin-top: 5px;
      }

      input.plainInput {
        width: 50%;
        max-width: 400px;
        text-align: center;
      }

      input.partInput {
        /* width: 50px;
        margin: 0 1rem 0 0rem;*/
        text-align: center;

        width: 120px;
        margin: 0 7px 0 0rem;

        &:nth-child(1) {
          width: 90px;
        }

        &:nth-child(2),
        &:nth-child(3) {
          width: 70px;
        }
      }

      .btn {
        margin: 3rem auto;
        background: #c6edfe;
        color: #1462a1;
        border-radius: 30px;
        width: 150px;
        max-width: 100%;
        text-align: center;
        padding: 1rem;
        cursor: pointer;

        position: absolute;
        bottom: -1.5rem;
        left: 42rem;
      }

      .btn a {
        color: #1462a1;
        text-decoration: none;
        font-weight: 600;
        font-size: 1.3rem;
        text-align: center;
        margin: 0 auto;
      }
    }
  }

  .userInfo-wrap {
    width: 100%;
    max-width: 1200px;
    display: flex;
    flex-direction: column;
    margin: 0 auto 0;
  }

  .confirm_btn {
    background: #c6edfe;
    color: #1561a0;
    width: 10%;
    height: 60px;
    border-color: #fff;
    margin: 2rem auto;

    span {
      font-size: 1.2rem;
      font-weight: 600;
    }

    &.start_btn {
      width: 10%;
    }

    &.finished_btn {
      width: 15%;
    }
  }

  .confirm_btn.fixed_btn {
    position: fixed;
    bottom: 6.5rem;
  }

  /** survey.js start **/
  .survey-header {
    margin-top: 2rem;
  }

  .survey-wrap {
    width: 900px;
    margin: 1rem auto;

    div {
      display: block;
      flex-direction: row;
    }

    h2.survey-q {
      font-size: 1.4rem;
      margin-bottom: 0.5rem;

      span.circle-num {
        background: #c1d4fe;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        display: inline-block;
        text-align: center;
        margin-bottom: 0;
        padding: 0.5rem 0.5rem;
        margin-right: 1rem;
        color: #1561a0;
      }
    }

    .survey-a {
      margin-left: 5rem;
      width: auto;

      span.ant-radio + * {
        font-size: 1rem;
        color: #1561a0;
      }

      .ant-radio {
        top: 0;
      }

      .ant-radio-inner {
        transform: scale(1.5);
      }
    }

    .survey-sub-q {
      margin-left: 4.5rem;
      margin-top: 1.5rem;
      margin-bottom: 1.5rem;
      h2 {
        width: 89%;
        margin-top: 0.5rem;
      }

      .survey-a {
        margin-left: 0;
        width: auto;
      }

      .partInput.survey-a {
        width: 10%;
        margin: 0 0.5rem;
        max-width: 50px;
        text-align: center;
        font-size: 1rem;
        & + span {
          align-items: center;
          align-self: center;
          max-width: 40px;
          font-size: 1rem;
        }
      }

      &:nth-child(3) {
        .survey-a {
          //   width: 30%;
        }
      }
    }
  }

  .survey-wrap.single-survey {
    display: block;
    align-items: center;
  }

  /** MetaLogin.js start **/

  .inner-header.login-header {
    width: 100%;
    text-align: center;
    margin-top: 0;
    margin-bottom: 0rem;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    top: 0;

    h2 {
      font-size: 3rem;
    }

    .login-top-logo {
      width: 60%;
      max-width: 400px;
    }
  }

  .metalogin-wrap {
    border: 1px solid #bbb;
    width: 50rem;
    height: 22rem;
    /* width: calc(100% - 40rem);*/
    border-radius: 30px;
    padding: 3rem 6rem;
    margin: 0rem 0 0rem;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h1 {
      text-align: center;
      font-size: 2.4rem;
      margin-bottom: 3rem;
    }

    .loginForm-wrap {
      padding: 1rem 3rem;
      width: calc(80% - 6rem);
    }

    .ant-form-item {
      width: 100%;
    }

    .ant-form-item-label {
      width: 25%;
      font-weight: 600;
      padding-right: 1rem;
    }

    .ant-form-item-label > label {
      font-size: 1.2rem;
    }

    .ant-form-item-control-input {
      width: 100%;
      max-width: 100%;

      .partInput {
        width: 45%;
        text-align: center;

        &:nth-child(1) {
          width: 30%;
        }
      }
    }

    .confirm_btn {
      width: 20%;
      margin: 0 auto;
      display: block;
    }

    .btn {
      margin: 1rem auto;
      background: #c6edfe;
      color: #1462a1;
      border-radius: 30px;
      width: 150px;
      max-width: 100%;
      text-align: center;
      padding: 1rem;
      cursor: pointer;
    }

    a {
      color: #1462a1;
      text-decoration: none;
      font-weight: 600;
      font-size: 1.3rem;
      text-align: center;
      margin: 0 auto;
    }
  }

  .login-btm-logo {
    position: absolute;
    bottom: 1rem;
    right: 2rem;
    width: 20%;
  }

  .report-footer-con {
    display: flex;
    margin: 1rem auto 0.5rem;
    flex-direction: row;
    width: 75%;
    font-size: 1.2rem;
    // position: absolute;
    bottom: 0;

    h5 {
      margin-bottom: 0;
    }

    h5:nth-child(1) {
      margin-right: auto;
    }
    h5:nth-child(2) {
      color: #2d75b3;
      margin-left: auto;
      font-weight: 400;
    }
  }

  /** MetaExamType.js **/

  .meta-header.type-header {
    display: flex;
    flex-direction: row;
    margin: 0rem auto;
    top: 0;
    position: relative;
    left: 0;
    transform: translate(0%, 0%);
    align-items: center;
    width: 1300px;

    h2 {
      font-size: 1.1rem;
      letter-spacing: 0.1rem;
      margin-bottom: 0;
    }
  }

  .exam-type-con {
    display: flex;
    flex-direction: row;
    height: calc(100vh - 20rem);
    align-items: center;
    justify-content: space-between;
    width: 1200px;
    .test-wrap {
      display: flex;
      flex-direction: column;
      margin: 0 3rem;
      cursor: pointer;

      h3 {
        font-size: 2rem;
        text-align: center;
        margin: 1rem 0;
      }

      .meta-chkImg {
        width: 30px;
        max-width: 100%;
        margin: 1rem auto 0;
        display: block;
        cursor: pointer;
      }

      &.no-chk {
        margin: 0.5rem 3rem 3rem;
      }

      &.ant-tooltip-open {
        div {
          a {
            h3 {
              margin: 0.3rem 0 1rem;
            }
          }
        }

        .ant-tooltip-open {
          img {
            width: 35px;
            margin: 0.5rem auto 0;
          }
        }
      }
    }
  }

  /** ReadingInfo.js **/

  .type-reading-con {
    width: 100%;
    padding: 2rem 0;
    height: 30rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
    h1,
    h3 {
      color: #1561a0;
      text-align: center;
    }

    h1 {
      font-size: 3.5rem;
      font-weight: 800;
      color: #1561a0;
    }

    h3 {
      font-size: 2rem;
      color: #1561a0;
    }
  }

  /** metasamplequestion.js  **/
  .sample-q-con {
    width: 100%;
    max-width: 1300px;
    /*    width: calc(100% - 30rem);*/
    margin: 3rem auto 0;
    background: #fff;
    position: relative;

    .meta-header.test-header {
      padding: 0rem 2.8rem 0 5rem;
      h2 {
        font-size: 1rem;
      }

      .time-wrap {
        h2 {
          width: 70px;
          margin-bottom: 0;
        }
      }

      .question-wrap {
        h2 {
          width: 80px;
          margin-bottom: 0;
        }
      }
    }

    .meta-header.readingTest-header {
      margin: 1rem auto 0;
    }

    .readingtest-con {
      height: auto;

      .readingtest-wrap.passage-con {
        font-size: 1rem;
        height: calc(50vh - 7rem);

        img {
          height: 100%;
        }
      }

      .readingtest-wrap {
        .reading-q-con {
          h1 {
            font-size: 1rem;
            margin-bottom: 0;
          }
        }

        .reading-a-con {
          .reading-a-wrap {
            margin: 1rem 0;
          }
        }
      }
    }

    .process-btn-con {
      width: 100%;
      display: flex;
      margin: 1rem 0 1.5rem;
    }
  }

  /** arrow **/
  .metaexam-arrow {
    position: absolute;
    display: block;
    width: 10%;
    height: 20vh;
    background-color: #333;
    pointer-events: none;
    position: absolute;
    left: -6rem;

    h3 {
      color: #fff;
    }
    .metaexam-arrow-square {
      position: absolute;
      top: 1rem;
      right: 0;
      bottom: 0;
      left: 6rem;
      display: block;
      width: 30px;
      height: 30px;
      margin: auto;
      background-color: #333;
      content: '';
      pointer-events: auto;
      transform: rotate(45deg);
    }
  }
  .metaexam-arrow.time-explain {
    left: 40%;

    .metaexam-arrow-square {
      position: absolute;
      top: 0rem;
      right: 0;
      bottom: 2rem;
      left: 6rem;
      display: block;
      width: 30px;
      height: 30px;
      margin: auto;
      background-color: #333;
      content: '';
      pointer-events: auto;
      transform: rotate(45deg);
    }
  }
  .metaexam-arrow.q-explain {
    left: 50%;
  }

  .metaexam-arrow.context-explain {
  }

  .metaexam-arrow.question-box-explain {
    left: 60%;
  }
  .metaexam-arrow.answer-explain {
    left: 70%;
  }

  /** sampleTest start **/
  .sampleTest-con.readingtest-con {
    img {
      &:before {
        display: block;
        height: 30vh;
        border: 2px dashed orange;
        width: 100%;
        padding: 0.3rem;
      }
    }
  }
  /** sampleTest end  **/

  /** readingtest.js **/
  .meta-header.test-header {
    width: 100%;
    display: flex;
    background: #e3f6fd;
    margin: 0 auto;
    padding: 1rem 10rem 1rem 7rem;
    top: 0;

    div {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    h2 {
      width: 70px;
      color: #1561a0;
      max-width: 100%;
      font-size: 1.5rem;
      text-align: left;
      font-weight: 700;
    }

    h2.num {
      background: #fff;
      text-align: center;
      width: 110px;
      padding: 0.5rem 0;
      border-radius: 10px;
      margin: 0rem 0rem 0rem -1rem;
    }

    .burger-wrap {
      img {
        cursor: pointer;
      }
      h2 {
        width: 150px;
        max-width: 100%;
        margin-left: 15px;
        margin-bottom: 0;
      }
    }

    .time-wrap {
      h2 {
        width: 100px;
        margin-bottom: 0;
      }
    }

    .question-wrap {
      margin-left: 2.5rem;
      h2 {
        width: 120px;
        margin-right: 1.3rem;
        margin-bottom: 0;
      }
    }
  }

  .meta-header.readingTest-header {
    width: calc(100% - 7rem);
    margin: 1rem auto 0;
    h1.main-tit {
      text-align: left;
      font-size: 1.4rem;
      font-weight: 700;
      color: #333;
    }
  }

  .readingtest-con {
    display: flex;
    width: calc(100% - 6rem);
    height: calc(100vh - 10rem);
    justify-content: center;
    align-items: start;

    .readingtest-wrap.passage-con {
      border: 3px solid #bdefff;
      width: 50%;
      overflow: auto scroll;
      height: calc(50vh - 2rem);
      padding: 1rem;
      line-height: 2;
      margin: 1rem 3rem 1rem 1rem;
      font-size: 1.1rem;

      p {
        color: #333;
        // text-align: center;
      }

      img {
        width: auto;
        height: 100%;
        max-width: 100%;
        max-height: 100%;
        display: block;
        margin: auto;
        object-fit: contain;
      }
    }

    .readingtest-wrap.passage-con.reading {
      overflow: auto;
    }

    .readingtest-wrap {
      width: 45%;
      // overflow: auto scroll;
      height: 100%;
      .reading-q-con {
        border: 2px solid #bdefff;
        margin: 1rem 0 0;
        padding: 1rem 1rem;

        display: flex;
        align-items: center;

        h1 {
          font-size: 1.2rem;
          color: #333;
          margin-bottom: 0;
        }

        img {
          max-width: 100%;
        }
      }

      .reading-a-con {
        display: flex;
        flex-direction: column;
        .reading-a-wrap {
          border: 2px solid #bdefff;
          width: 100%;
          display: flex;
          margin: 1rem 0;
          align-items: center;
          cursor: pointer;
          word-break: break-all;

          h3 {
            text-align: left;
            font-size: 1rem;
            margin-bottom: 0;
            margin-left: 1rem;
            margin-top: 0rem;
            color: #333;
          }
          .reading-a-num {
            max-width: 100%;
            //  border-right: 2px solid #bdefff;
            padding: 0.7rem 1rem;
            position: relative;
            h3 {
              text-align: center;
              font-size: 1.2rem;
              margin-bottom: 0;
              margin-top: 0;
              margin-left: 0;
              color: #333;
            }
            .checkedImg {
              position: absolute;
              top: -1rem;
              left: -0.5rem;
            }
          }

          .reading-a {
            border-left: 2px solid #bdefff;
            padding: 1rem;
          }
        }
      }
    }
  }

  .process-btn-con {
    width: 100%;
    display: flex;
    img {
      cursor: pointer;
    }
  }

  /* metaFinished */
  .meta-header.onlyTxt-header {
    position: relative;
    top: 3rem;
    width: 50%;
  }

  .finished-con {
    margin-top: 0rem;
    h1 {
      margin: 0rem 0 1rem;
    }
  }
}

/* MetaReport.js */
.meta-report-con {
  width: 100%;
  height: 100%;
  min-height: 100vh;

  .lexile-logo-con {
    background: #c0e5fc;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3rem 0;
    margin-bottom: 2rem;

    img {
      width: 100%;
      max-width: 800px;
    }
  }

  .userInfo-con {
    width: 100%;
    display: flex;
    .userInfo {
      display: inline-flex;
      flex-direction: column;
      &.left {
        margin-right: auto;
        width: 30%;
        display: flex;
        flex-direction: row;
        align-items: end;
        margin-bottom: 1rem;
        h2 {
          margin: 0 1rem 0 1rem;
          color: #2e76b3;
        }

        .ant-row {
          .ant-col {
            border: 1px solid #b3dbfc;
            padding: 0.7rem;
            text-align: center;
            background: #fff;
            color: #2e76b3;
          }
        }

        .user-table {
          display: table;
          div {
            display: table-cell;
          }
        }
      }

      &.right {
        margin-left: auto;
        width: 15%;
        justify-content: end;

        .ant-row {
          .ant-col {
            border: 1px solid #b3dbfc;
            padding: 0.7rem;
            text-align: center;
            background: #fff;
            color: #2e76b3;
          }
        }

        .user-table {
          display: table;
          div {
            display: table-cell;
          }
        }
      }
    }
  }

  .report-top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0.5rem 0 3rem;
    background: #fff;
    padding: 4rem 1rem 2rem;
    border: 1px solid #b3dbfc;
    .part-con {
      width: 50%;
      display: flex;
      justify-content: center;

      &:nth-child(1) {
        flex-direction: column;
        position: relative;
        left: 7rem;
        top: -3rem;
        transform: translate(10px, 10px);
        text-align: center;

        img {
          width: 60%;
          position: relative;
          top: -7.5rem;
          left: 3rem;
        }

        h1 {
          font-size: 3.5rem;
          font-weight: 700;
          position: relative;
          top: -0.5rem;
          margin-bottom: 0;
        }

        h4 {
          font-size: 1.2rem;
          margin-top: 1rem;
          color: #2e76b3;
        }
      }

      &:nth-child(2) {
        img {
          width: 100%;
          max-width: 450px;
        }

        div.donut-wrap {
          position: relative;

          div {
            position: absolute;
            top: 53.5%;
            left: 52%;
            transform: translate(-50%, -50%);
            h3 {
              font-size: 1.7rem;
              color: #2d75b3;
              margin-bottom: 0;
            }

            h2 {
              font-size: 3.8rem;
              font-weight: 700;
            }
          }
        }
      }

      .ant-progress {
        width: 300px !important;
        height: 300px !important;
        .ant-progress-inner {
          width: 100% !important;
          height: 100% !important;
        }
      }

      .ant-progress-circle .ant-progress-text {
        width: 65%;
        top: 53%;
        font-weight: 600;
        line-height: 1.65;
      }
    }
    .ant-table-content {
      table {
        table-layout: fixed !important;
        text-align: center;
      }
    }
  }
  /* report top end*/
  .report-middle-new {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 2rem 8.5rem 0rem;

    .card-con {
      display: flex;
      flex-direction: row;
      width: 100%;
      margin: 1rem 0;
      border: 1px solid #ddd;

      &:nth-child(1) {
        background: #fcbf15;
      }
      &:nth-child(2) {
        background: #09a5e2;
      }
      &:nth-child(3) {
        background: #90ce4f;
      }
      &:nth-child(4) {
        background: #cf88d8;
      }

      .bg {
        padding: 1rem;
        margin: 1rem 0;
        width: 40%;
        flex-direction: row;
        display: flex;
        justify-content: center;

        div.subject-con {
          flex-direction: row;
          display: flex;
          background: #fff;
          justify-content: space-between;
          align-items: center;
          border-radius: 20px;
          width: 80%;
          padding: 1rem 3rem;
          h3 {
            font-size: 1.5rem;
            margin-bottom: 0;
          }
        }
      }

      div.progress-con {
        background: #fff;
        width: 60%;
        flex-direction: column;
        display: flex;
        margin-left: 1rem;
        padding: 1rem 2rem;

        .progress-wrap {
          flex-direction: row;
          display: flex;
          align-items: center;
          span {
            padding: 0.5rem 0.3rem;
            width: 15%;
            font-weight: 600;
            max-width: 100px;
            font-size: 1.2rem;
          }

          &.ur-score {
            color: #2d75b3;
          }
          &.avg-score {
            color: #b68a17;
          }
          &.avg-dev {
            color: #759b5d;
          }

          .ant-progress-inner {
            border-radius: 0;
            .ant-progress-bg {
              border-radius: 0;
            }
          }
        }
      }
    }
  }

  .bookUrl-con {
    padding: 2rem 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 2rem auto;

    div {
      border: 1px solid #b3dbfc;
      padding: 2rem 2rem 2.5rem;
      background: #fff;
      width: 100%;
      max-width: 1360px;
      display: flex;
      flex-direction: column;
      margin: 0 auto;
    }

    * {
      margin-left: auto;
    }

    img {
      margin: 0rem 3rem 0rem auto;
      max-width: 200px;
      width: 100%;
    }

    a {
      margin-top: 0rem;
      margin-right: 2rem;
    }
  }

  .result-table-con {
    .ant-table {
      width: 100%;
      max-width: 1360px;
      border: 1px solid #b3dbfc;
    }
    .ant-table-thead > tr > th,
    .ant-table-tbody > tr > td {
      text-align: center;
    }

    .ant-table-thead > tr > th {
      background: #67b7fb;
      color: #fff;
      font-size: 1.3rem;
    }

    .ant-table-tbody > tr > td {
      font-weight: 600;
      font-size: 1.2rem;
    }

    .ant-table-tbody > tr > td:nth-child(1),
    .ant-table-tbody > tr > td:nth-child(6) {
      color: #2e76b3;
    }

    .ant-table-tbody > tr > td:nth-child(2),
    .ant-table-tbody > tr > td:nth-child(3),
    .ant-table-tbody > tr > td:nth-child(4) {
      color: #528233;
    }

    .ant-table-tbody > tr > td:nth-child(5) {
      color: red;
    }

    .ant-table-thead
      > tr
      > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
      background-color: rgba(255, 255, 255, 0.5);
    }
  }

    .result-table-con-pre {
      .ant-table {
        width: 100%;
        max-width: 1360px;
        border: 1px solid #219bff;
      }
  
      .ant-table-thead>tr>th,
      .ant-table-tbody>tr>td {
        text-align: center;
        border: 1px solid #219bff;
      }
  
      .ant-table-thead>tr>th {
        background: #fff;
        color: #1a85e1;
        font-size: 1rem;
        font-weight: 500;
      }
  
      .ant-table-tbody>tr>td {
        background: #bddefb;
        color: #494949;
        font-weight: 500;
        font-size: 1rem;
      }
  
    }

  .report-footer-con {
    display: flex;
    margin: 1rem auto;
    flex-direction: row;
    width: 100%;
    max-width: 1360px;

    h5:nth-child(1) {
      margin-right: auto;
    }
    h5:nth-child(2) {
      color: #2d75b3;
      margin-left: auto;
    }
  }
}

.meta-report-con.pre {
  background-color: #eaf9f4;
  .lexile-logo-con {
    background-color: #d4fcf2;
  }
}

/** mediaquery **/

@media (aspect-ratio: 16/10) {
  .meta-con {
    .metalogin-wrap {
      width: 70rem;
      height: 35rem;

      .ant-form-item {
        align-items: center;
      }

      .btn {
        width: 250px;
      }

      a {
        font-size: 2.3rem;
      }
    }

    .inner-header {
      width: calc(90% - 10rem);
      display: flex;
      position: relative;
      top: 0;
      left: 0;
      margin: 1rem auto;
      transform: translate(0, 0px);
      h2 {
        font-size: 3rem;
      }
    }

    .meta-header h1.main-tit {
      // font-size: 4rem;
    }

    /*
    #student-register {
      max-width: 1600px;

      .ant-input-search.plainInput {
        input {
          
          font-size: 1.8rem; 
        }
      }
      tbody {
        tr {
          td {
            .ant-form-item {
              margin-right: 4rem;
              margin-bottom: 7rem;
              align-items: center;

              .ant-form-item-control {
                .ant-form-item-control-input-content {
                  input.partInput {
                    width: 120px;
                    font-size: 1.8rem;
                  }
                }
              }
            }

            .ant-picker-input > input {
              font-size: 1.8rem;
            }
          }
        }

        tr:nth-child(1) {
          td:nth-child(2) {
            .ant-form-item-control {
              .ant-form-item-control-input-content {
                input.partInput {
                  &:nth-child(1) {
                    width: 200px;
                    font-size: 1.8rem;
                  }

                  &:nth-child(2) {
                    width: 280px;
                    font-size: 1.8rem;
                  }
                }
              }
            }
          }
        }

        tr:nth-child(2) {
          td:nth-child(1) {
            .ant-form-item-control {
              .ant-form-item-control-input-content {
                input.partInput {
                  &:nth-child(1) {
                    width: 150px;
                    font-size: 1.8rem;
                  }
                }
              }
            }
          }

          td:nth-child(2) {
            .ant-form-item-control {
              .ant-form-item-control-input-content {
                input.partInput {
                  &:nth-child(1) {
                    width: 110px;
                    font-size: 1.8rem;
                  }
                }
              }
            }
          }
        }
      }

      input.plainInput {
        width: 85%;
        max-width: 400px;
        text-align: center;
        font-size: 1.8rem;
      }

      .ant-form-item-label > label {
        font-size: 2rem;
      }

      .ant-form-item:nth-child(2) {
        margin: 1rem 0 3rem;
      }
    } */

    .confirm_btn.fixed_btn {
      position: absolute;
      bottom: 15.5rem;
    }

    .fixed_btn {
    }

    .btn {
      max-width: 100%;
      text-align: center;
      padding: 3rem 1rem;
      a {
        position: relative;
      }

      &.request_btn {
        a {
          font-size: 2rem;
          position: relative;
          bottom: 2rem;
        }
      }
    }

    .survey-wrap {
      h2.survey-q {
        font-size: 2.4rem;
        span.circle-num {
          width: 100px;
          height: 100px;
          padding: 1.5rem 0.5rem;
        }
      }

      .ant-radio-wrapper {
        align-items: center;
      }

      .survey-sub-q {
        margin-left: 7.5rem;
        h2 {
          font-size: 2rem;
        }

        .partInput.survey-a {
          width: auto;
          font-size: 2rem;
          max-width: 100px;
        }

        .partInput.survey-a + span {
          align-items: center;
          align-self: center;
          max-width: 100px;
          font-size: 2rem;
        }
      }

      .survey-a {
        span.ant-radio + * {
          font-size: 1.65rem;
          color: #1561a0;
          margin: 0 1rem 0 0.5rem;
        }

        .ant-radio {
          transform: scale(1.5);
        }
      }
    }

    .meta-header.type-header {
      margin: 0.5rem auto 0;
      top: 10rem;
      h2 {
        font-size: 2.3rem;
      }
    }
    .exam-type-con {
      .test-wrap {
        margin: 0 7rem;
        a {
          img {
            transform: scale(1.8);
            margin: 0 auto;
            display: block;
          }
          h3 {
            font-size: 3rem;
            text-align: center;
            margin: 8rem auto 0;
          }
        }

        .meta-chkImg {
          width: 50px;
        }
      }
    }

    .type-reading-con {
      h1 {
        font-size: 6.5rem;
      }

      h3 {
        font-size: 3rem;
      }
    }

    .meta-header.readingTest-header {
      h1.main-tit {
        font-size: 2.4rem;
      }
    }

    .sample-q-con {
      max-width: 2000px;
      .meta-header.test-header {
        padding: 1rem 2.8rem 1rem 5rem;

        .burger-wrap {
          h2 {
            width: 450px;
          }
        }
        h2 {
          font-size: 1.6rem;
        }

        .time-wrap {
          h2 {
            width: 100px;
          }
        }

        .question-wrap {
          h2 {
            width: 120px;
          }
        }
      }

      .readingtest-con {
        .readingtest-wrap {
          &.passage-con {
            font-size: 2rem;
          }
          .reading-q-con {
            h1 {
              font-size: 2rem;
            }
          }
          .reading-a-con {
            .reading-a-wrap {
              align-items: center;
              .reading-a-num {
                width: 80px;
                padding: 1rem;
                h3 {
                  font-size: 2.5rem;
                }
              }
              h3 {
                font-size: 2rem;
              }
            }
          }
        }
      }
    }

    &.meta-full-con {
      .ant-tooltip-placement-topRight {
        .ant-tooltip-arrow {
          transform: scale(5.5);
          bottom: -2rem;
        }
      }
      .ant-tooltip-inner {
        font-size: 2.2rem;
        min-width: 500px;
        text-align: center;
        padding: 1rem;
      }
    }

    .meta-header.test-header {
      width: 100%;
      display: flex;
      background: #e3f6fd;
      margin: 0 auto;
      padding: 2rem 10rem 2rem 10rem;

      h2 {
        font-size: 2rem;
      }

      .burger-wrap {
        h2 {
          width: 250px;
        }
      }
      .time-wrap {
        h2 {
          width: 125px;
        }
      }

      .question-wrap {
        h2 {
          width: 150px;
        }
      }
    }

    .readingtest-con {
      .readingtest-wrap.passage-con {
      }

      .readingtest-wrap {
        .reading-q-con {
          padding: 1rem 1rem;

          h1 {
            font-size: 1.15rem;
          }
        }

        .reading-a-con {
          .reading-a-wrap {
            .reading-a-num {
              width: 100px;
              h3 {
                font-size: inherit;
              }
            }

            .reading-a {
              h3 {
                font-size: inherit;
              }
            }
          }
        }
      }
    }

    .report-footer-con {
      font-size: 2rem;
    }
  }
}

@media screen and (device-width: 2560px) and (max-width: 2560px) and (aspect-ratio: 16 / 10) {
  .meta-con.meta-full-con {
    .btn {
      a {
        bottom: 2rem;
      }
    }

    .btn.enter_btn {
      margin: 2rem auto;
      a {
        bottom: 0;
      }
    }

    .exam-type-con {
      height: 100%;
    }

    .type-reading-con {
      height: auto;
    }
  }

  .meta-con {
    .btn {
      a {
        bottom: 0;
      }

      &.confirm_btn {
        span {
          font-size: 2rem;
        }
      }
    }

    .survey-wrap {
      h2.survey-q span.circle-num {
        width: 90px;
        height: 90px;
      }
      .survey-a {
        margin-left: 8rem;
      }

      .survey-sub-q {
        margin-top: 2.5rem;
        margin-bottom: 2.5rem;
        &:nth-child(3) {
          .survey-a {
          }
        }
      }
    }

    &.register-con {
      #student-register {
        height: auto;
        max-width: 1500px;

        tbody {
          tr {
            td {
              .ant-form-item {
                margin-bottom: 1.5rem;
              }
            }
          }
        }
        input {
          font-size: 1.4rem;
        }

        tbody tr:nth-child(1) td:nth-child(2) .ant-form-item-control .ant-form-item-control-input-content input.partInput:nth-child(1) {
          width: 170px;
        }

        tbody tr:nth-child(2) td:nth-child(2) .ant-form-item-control .ant-form-item-control-input-content input.partInput:nth-child(1) {
          width: 110px;
        }

        tbody tr:nth-child(2) td:nth-child(2) .ant-form-item-control .ant-form-item-control-input-content input.partInput:nth-child(1) {
          width: 110px;
        }

        .ant-form-item .ant-select,
        .ant-form-item .ant-cascader-picker {
          font-size: 1.4rem;
        }

        tr {
          td {
            width: 1000px;
            .ant-form-item {
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }

          &:nth-child(1) {
            td {
              &:nth-child(2) {
                .ant-form-item-control .ant-form-item-control-input-content input.partInput:nth-child(1) {
                  // width: 120px;
                }
              }
            }
          }

          &:nth-child(2) {
            td {
              &:nth-child(2) {
                .ant-form-item-control .ant-form-item-control-input-content {
                  input.partInput:nth-child(1) {
                    width: 100px;
                  }
                  input.partInput:nth-child(2),
                  input.partInput:nth-child(3) {
                    width: 120px;
                  }
                }
              }
            }
          }
        }

        .ant-form-item-label > label {
          font-size: 1.4rem;
        }

        .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
          height: 50px;
          padding: 0.5rem 11px;
        }

        .ant-input-search.plainInput {
          input {
            height: 50px;
            font-size: 1.4rem;
          }
        }

        .ant-input-search-button {
          width: 50px;
          height: 50px;
        }
      }
    }

    .metalogin-wrap {
      h1 {
        font-size: 2.6rem;
        margin-bottom: 3rem;
      }

      .ant-form-item-label > label {
        font-size: 1.8rem;
      }

      .ant-form-item-control-input {
        .partInput {
          font-size: 1.7rem;
        }
      }
    }

    .meta-header.test-header {
      .burger-wrap {
        img {
          width: 18%;
          margin-right: 1rem;
        }
      }
      h2 {
        font-size: 2.3rem;
      }

      .time-wrap {
        h2 {
          margin: auto 0;
        }
      }

      .question-wrap {
        width: 300px;
        h2 {
          width: 170px;
          margin: auto 0;
        }
      }

      .ant-tooltip {
        .ant-tooltip-content {
          .ant-tooltip-inner {
            span {
              font-size: 1.6rem;
            }
          }

          .ant-tooltip-arrow-content {
            transform: translateY(6.53553391px) rotate(45deg) scale(2.5);
          }
        }
      }
    }

    .sample-q-con {
      .meta-header.test-header {
        .burger-wrap {
          img {
            width: 8%;
          }
        }

        .question-wrap {
          margin-left: -0.5rem;
        }

        .time-wrap {
          h2 {
            width: 80px;
          }
        }
      }
    }

    .readingtest-con {
      .readingtest-wrap {
        &.passage-con {
          font-size: 2.1rem;
          padding: 1rem 2rem;
        }

        .reading-q-con {
          h1 {
            padding: 1rem 1rem;
            font-size: 2rem;
          }
        }

        .reading-a-con {
          .reading-a-wrap {
            align-items: center;
            .reading-a-num {
              h3 {
                font-size: 2rem;
              }
            }
            .reading-a {
              padding: 1rem;
              h3 {
                font-size: 2rem;
              }
            }
          }
        }
      }
    }

    .meta-header {
      &.type-header {
        top: 0;
        width: 1900px;
        div {
          h2 {
            margin-top: 0;
          }
        }
      }
    }

    .exam-type-con {
      .test-wrap {
        div {
          img {
            transform: scale(2);
          }
          h3 {
          }
        }
      }
    }

    .exam-type-con {
      width: auto;
      .test-wrap {
        margin: 0 8rem;
        /* a {
          img {
            transform: scale(1.5);
          }
        }*/

        div {
          width: 200px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }

        img {
          transform: scale(2);
        }

        h3 {
          font-size: 3rem;
          margin: 7rem 0 1rem;
        }

        .meta-chkImg {
          width: 35px;
        }

        &.no-chk {
          margin: -1rem 6rem 3rem;
        }
      }
    }

    .meta-header.onlyTxt-header {
      top: 0;
    }
  }
}

@media screen and (device-width: 1920px) and (max-width: 1920px) and (aspect-ratio: 16 / 10) {
  .meta-con {
    .metalogin-wrap {
      width: 60rem;
      height: 30rem;
      padding: 3rem 0;

      .ant-form-item {
        align-items: center;
      }

      h1 {
        font-size: 2.4rem;
        margin-bottom: 3rem;
      }

      .ant-form-item-label > label {
        font-size: 1.6rem;
      }

      .ant-form-item-control-input .partInput {
        font-size: 1.6rem;
      }

      .btn {
        width: 250px;
      }

      a {
        font-size: 2.3rem;
      }
    }

    .inner-header {
      width: calc(90% - 30rem);
      display: flex;
      margin: 1rem auto;
      h2 {
        font-size: 2rem;
      }
    }

    .meta-header h1.main-tit {
      font-size: 3rem;
      margin: 1rem auto;
    }

    &.register-con {
      #student-register {
        .ant-input-search.plainInput {
          input {
            font-size: 1.2rem;
          }
        }

        .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
        .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
          font-size: 1.2rem;
        }
        tbody {
          tr {
            td {
              .ant-form-item {
                margin-right: 4rem;
                margin-bottom: 1rem;
                align-items: center;

                .ant-form-item-control {
                  .ant-form-item-control-input-content {
                    input.partInput {
                      width: 120px;
                      font-size: 1.2rem;
                    }
                  }
                }
              }

              .ant-picker-input > input {
                font-size: 1.2rem;
              }
            }
          }

          tr:nth-child(1) {
            td:nth-child(2) {
              .ant-form-item-control {
                .ant-form-item-control-input-content {
                  input.partInput {
                    &:nth-child(1) {
                      width: 180px;
                      font-size: 1.2rem;
                    }

                    &:nth-child(2) {
                      width: 200px;
                      font-size: 1.2rem;
                    }
                  }
                }
              }
            }
          }

          tr:nth-child(2) {
            td:nth-child(1) {
              .ant-form-item-control {
                .ant-form-item-control-input-content {
                  input.partInput {
                    &:nth-child(1) {
                      width: 150px;
                      font-size: 1.2rem;
                    }

                    &:nth-child(2),
                    &:nth-child(3) {
                      font-size: 1.2rem;
                    }
                  }
                }
              }
            }

            td:nth-child(2) {
              .ant-form-item-control {
                .ant-form-item-control-input-content {
                  input.partInput {
                    &:nth-child(1) {
                      width: 110px;
                      font-size: 1.2rem;
                    }
                  }
                }
              }
            }
          }
        }

        input.plainInput {
          text-align: center;
          font-size: 1.2rem;
        }

        .ant-form-item-label > label {
          font-size: 1.2rem;
        }

        .ant-form-item:nth-child(2) {
          margin: 1rem 0 3rem;
        }
      }
    }

    .confirm_btn.fixed_btn {
    }

    .fixed_btn {
    }

    .btn {
      width: 250px;
      max-width: 100%;
      text-align: center;
      padding: 2rem 1rem;
      a {
        font-size: 1.7rem;
        position: relative;
        bottom: 0;
      }

      &.request_btn {
        a {
          font-size: 1.7rem;
          position: relative;
          bottom: 0rem;
        }
      }

      &.confirm_btn {
        padding: 0rem 1rem;
        span {
          font-size: 2rem;
        }
      }
    }

    .survey-wrap {
      margin-top: -4rem;
      h2.survey-q {
        font-size: 1.4rem;
        span.circle-num {
          width: 60px;
          height: 60px;
          padding: 1rem 0.5rem;
        }
      }

      .ant-radio-wrapper {
        align-items: center;
      }

      .survey-sub-q {
        margin-left: 4rem;
        h2 {
          font-size: 1.4rem;
        }

        .survey-a {
          margin-left: 0;
          width: 100%;
        }

        .partInput.survey-a {
          width: auto;
          font-size: 1.4rem;
          max-width: 60px;
        }

        .partInput.survey-a + span {
          align-items: center;
          align-self: center;
          max-width: 100px;
          font-size: 1.4rem;
        }

        &:nth-child(3) {
          .survey-a {
            width: 30%;
          }
        }
      }

      .survey-a {
        width: auto;
        span.ant-radio + * {
          font-size: 1.4rem;
          color: #1561a0;
          margin: 0 1rem 0 0.5rem;
        }

        .ant-radio {
          transform: scale(1);
        }
      }
    }

    .survey-wrap.single-survey {
      margin-top: 0;
    }

    .meta-header.type-header {
      margin: 0.5rem auto 0;
      top: 0;
      h2 {
        font-size: 1.6rem;
      }
    }

    .exam-type-con {
      .test-wrap {
        margin: 0 4rem;

        div {
          img {
            transform: scale(1.5);
          }
          h3 {
            font-size: 2rem;
            text-align: center;
            margin: 3rem 0 0;
          }
        }

        a {
          img {
            transform: scale(1.4);
            margin: 0 auto;
            display: block;
          }
          h3 {
            font-size: 3rem;
            text-align: center;
            margin: 4rem auto 0;
          }
        }

        .meta-chkImg {
          width: 50px;
        }
      }
    }

    .type-reading-con {
      h1 {
        font-size: 5.5rem;
      }

      h3 {
        font-size: 2.3rem;
      }
    }

    .meta-header.readingTest-header {
      h1.main-tit {
        font-size: 2.4rem;
      }
    }

    .sample-q-con {
      max-width: 1500px;
      .meta-header.test-header {
        padding: 1rem 2.8rem 1rem 5rem;

        .burger-wrap {
          h2 {
            width: 450px;
          }
        }
        h2 {
          font-size: 1.6rem;
        }

        .time-wrap {
          h2 {
            width: 100px;
          }
        }

        .question-wrap {
          h2 {
            width: 120px;
          }
        }
      }

      .readingtest-con {
        .readingtest-wrap {
          &.passage-con {
            font-size: 1.8rem;
          }
          .reading-q-con {
            h1 {
              font-size: 1.8rem;
            }
          }
          .reading-a-con {
            .reading-a-wrap {
              margin: 0.5rem 0;
              align-items: center;
              .reading-a-num {
                width: 80px;
                padding: 1rem;
                h3 {
                  font-size: 2rem;
                }
              }
              h3 {
                font-size: 2rem;
              }
            }
          }
        }
      }
    }

    &.meta-full-con {
      .btn {
        a {
          bottom: 0;
        }
      }

      .ant-tooltip-placement-topRight {
        .ant-tooltip-arrow {
          transform: scale(5.5);
          bottom: -2rem;
        }
      }
      .ant-tooltip-inner {
        font-size: 1.5rem;
        min-width: 300px;
        text-align: center;
        padding: 1rem;
      }
    }

    .meta-header.test-header {
      width: 100%;
      display: flex;
      background: #e3f6fd;
      margin: 0 auto;
      padding: 1rem 12rem 1rem 11rem;

      h2 {
        font-size: 2rem;
      }

      .burger-wrap {
        h2 {
          width: 250px;
        }
      }
      .time-wrap {
        h2 {
          width: 125px;
        }
      }

      .question-wrap {
        margin-left: 4rem;
        h2 {
          margin-left: 1rem;
          margin-bottom: 0;
          width: 150px;
        }
      }
    }

    .readingtest-con {
      .readingtest-wrap.passage-con {
        font-size: 1.6rem;
        height: calc(44vh - 2rem);
      }

      .readingtest-wrap {
        .reading-q-con {
          padding: 1rem 1rem;

          h1 {
            font-size: 1.8rem;
          }
        }

        .reading-a-con {
          .reading-a-wrap {
            .reading-a-num {
              width: 100px;
              h3 {
                font-size: 1.8rem;
              }
            }

            .reading-a {
              h3 {
                font-size: 1.4rem;
              }
            }
          }
        }
      }
    }

    .report-footer-con {
      font-size: 2rem;
    }

    &.guide-con {
      .meta-header {
        &.readingTest-header {
          h1.main-tit {
            font-size: 2rem;
          }
        }
      }

      .readingtest-con {
        .readingtest-wrap {
          .reading-q-con {
            h1 {
              font-size: 1.4rem !important;
            }
          }
        }
      }
    }
  }
}

@media screen and (device-width: 2560px) and (max-width: 2560px) and (aspect-ratio: 16 / 9) {
  .meta-con {
    .inner-header {
      /*
      top: 1rem;
      width: 100%;
      max-width: 1700px;*/

      top: 1rem;
      //  width: 95%;
      padding: 0 2rem;

      h2 {
        font-size: 2rem;
      }
    }

    .meta-header.onlyTxt-header {
      top: 0;
    }

    .inner-header.login-header {
      h2 {
        font-size: 4rem;
      }
    }

    h1.main-tit {
      font-size: 4rem;
      margin-top: 4rem;
    }

    .metalogin-wrap {
      height: 100%;
      max-height: 500px;
      width: auto;
      margin: 0rem 0 2rem;

      h1 {
        font-size: 3rem;
      }

      .ant-form-item-control-input {
        .partInput:nth-child(1) {
          width: 14rem;
        }
      }
      .ant-form-item {
        width: 100%;

        &:nth-child(3) {
          .ant-form-item-control-input .partInput:nth-child(1) {
            .ant-picker-input {
              padding-right: 0rem;
              input {
                font-size: 1.7rem;
                width: 100%;
                padding: 0 0.1rem 0 0.5rem;
              }

              .ant-picker-suffix {
                font-size: 1.5rem;
              }
            }
          }
        }
      }

      .ant-form-item-label {
        width: 20%;
        text-align: left;
        margin-left: 1.4rem;
      }

      .ant-form-item-label > label {
        margin-top: 0;
        font-size: 1.6rem;
        margin-right: 0.7rem;
      }

      .ant-form-item-control-input {
        width: 100%;
        max-width: 100%;
      }

      .ant-form-item-control {
        width: 38%;
      }

      .ant-form-item-control-input-content {
        flex: none;
        max-width: initial;

        input.partInput {
          width: 14rem;
          font-size: 1.6rem;
        }
      }

      .btn {
        width: 100%;
        max-width: 300px;
        margin: 3rem auto 0;
        display: flex;
        justify-content: center;
        align-items: center;
        a {
          font-size: 2rem;
        }
      }
    }

    .report-footer-con {
      display: flex;
      margin: 1rem auto 1.5rem;
      flex-direction: row;
      width: 85%;
      position: absolute;
      bottom: 0;
      left: 7%;
      font-size: 1.5rem;
    }

    &.register-con {
      #student-register {
        width: 100%;
        max-width: 1600px;
        margin: 1rem auto;
        display: flex;

        tbody {
          tr {
            td {
              .ant-form-item {
                align-items: center;
                margin-bottom: 1rem;
                margin-right: 12rem;
              }
            }

            &:nth-child(1) {
              td:nth-child(2) {
                .ant-form-item-control {
                  .ant-form-item-control-input-content {
                    input.partInput {
                      &:nth-child(1) {
                        width: 180px;
                      }
                      &:nth-child(2) {
                        width: 200px;
                      }
                    }
                  }
                }
              }
            }

            &:nth-child(2) {
              td:nth-child(2) {
                .ant-form-item-control {
                  .ant-form-item-control-input-content {
                    input.partInput {
                      &:nth-child(1) {
                        width: 100px;
                      }
                    }
                  }
                }
              }
            }

            &:nth-child(3) {
              td:nth-child(2) {
                .ant-form-item-control {
                  .plainInput.gradeInput {
                  }
                }
              }
            }
          }
        }

        .ant-form-item-label > label {
          font-size: 1.2rem;
          font-weight: 600;
        }

        .ant-form-item-label > label::before {
          content: '●';
          font-size: 1.5rem;
          color: #1462a1;
          margin: 0 0.2rem 3px 0.2rem;
        }

        .divided-form {
          width: 100%;
          max-width: 1100px;
          display: flex;
          flex-direction: row;

          &:nth-child(4) {
            width: 500px;
            left: 1rem;
            position: relative;
          }

          .ant-col-xs-24 {
            margin-left: 3px;
          }

          .ant-form-item {
            /*
            width: 45%;
            max-width: 600px;
            margin: 1rem 0.5rem;*/

            width: 100%;
            max-width: 600px;
            margin: 1rem 0.5rem;
          }

          .common-form.threeInput-form {
            input.partInput {
              width: 23%;
            }
          }

          .ant-form-item-label > label {
            font-size: 1.2rem;
          }

          .ant-col-sm-16 {
            width: 100%;
          }

          input.plainInput {
            width: 55%;
            text-align: center;
          }

          &:nth-child(3) {
            .common-form {
              &:nth-child(2) {
                .ant-form-item {
                  position: relative;
                  left: -5rem;
                }
              }
            }
          }
        }

        .threeInput-form {
          input.partInput {
            width: 20rem;
          }
        }

        .common-form {
          margin: 1rem auto;
          width: 600px;
        }
        .plain-form {
          /*
          width: 64%;
          max-width: 800px;*/
          width: 90%;
          max-width: 1100px;
          display: flex;
          /* justify-content: center; */

          .ant-form-item {
            max-width: 500px;
            width: 100%;
          }

          .ant-row .ant-form-item {
          }

          .ant-col-sm-8 {
            flex: none;
            width: 100%;
            max-width: 165px;
            text-align: left;
          }

          .ant-form-horizontal .ant-form-item-control {
            margin-left: 24px;
          }

          .ant-form-item-control-input-content {
            width: 100%;
            max-width: 500px;
          }
        }

        .partInput_wrap {
          margin-left: 0rem;
          width: 202px;
          max-width: 100%;
        }

        .ex-span {
          position: absolute;
          width: 100%;
          max-width: 120px;
          margin-left: 10px;
          margin-top: 5px;
        }

        input.plainInput {
          width: 50%;
          max-width: 400px;
          text-align: center;
        }

        input.partInput {
          /* width: 50px;
          margin: 0 1rem 0 0rem;*/
          text-align: center;

          width: 120px;
          margin: 0 7px 0 0rem;

          &:nth-child(1) {
            width: 90px;
          }

          &:nth-child(2),
          &:nth-child(3) {
            width: 110px;
          }
        }
      }
    }

    /* userinfo */
    .userInfo-wrap {
      max-width: 2000px;
      margin: 4rem auto 0;
    }

    #student-register {
      max-width: 1700px;
      height: 100%;

      tbody {
        tr {
          td {
            .ant-input-search.plainInput {
              input {
                font-size: 1.1rem;
              }
            }
          }
        }
      }

      .ant-form-item-label > label {
        font-size: 2rem;
      }

      .common-form {
        margin: 2rem auto;
        width: 900px;
        align-items: center;
        justify-content: center;
      }

      .plain-form {
        width: 98%;
        max-width: 1800px;

        .ant-col-sm-8 {
          max-width: 280px;
        }

        .ant-form-item {
          max-width: 1500px;
          align-items: center;
        }

        .ant-form-item-control-input-content {
          width: 100%;
          max-width: 1500px;
        }
      }

      input.partInput {
        /*
        width: 150px;
        margin: 0 7px 0 0rem;
        max-width: 100%;
        font-size: 2rem;*/
        width: 250px;
        margin: -5px 7px 0 0rem;
        max-width: 100%;
        font-size: 1.1rem;
      }

      .partInput_wrap {
        width: 100%;
        max-width: 100%;
        display: flex;
      }

      .divided-form {
        max-width: 1800px;
        width: 100%;
        justify-content: space-around;

        .ant-form-item {
          width: 100%;
          max-width: 900px;
        }

        .ant-form-item-label > label {
          font-size: 1.1rem;
        }

        .ant-col-sm-16 {
          width: 100%;
          max-width: 900px;
        }

        &:nth-child(3) {
          .common-form {
            &:nth-child(2) {
              .ant-form-item {
                left: -8.5rem;
              }
            }
          }
        }

        &:nth-child(4) {
          width: 770px;
          left: 2rem;
        }
      }

      input.plainInput {
        margin-top: -0rem;
        font-size: 1.1rem;
        width: 100%;
      }

      .ex-span {
        max-width: 200px;
        font-size: 1.3rem;
        position: relative;
      }

      .ant-picker {
        width: 100%;
        max-width: 400px;
        font-size: 1.1rem;

        .ant-picker-input > input {
          font-size: 1.1rem;
        }

        .ant-picker-panel-container {
          font-size: 1.1rem;
        }
      }
    }

    .btn {
      width: 250px;
      max-width: 100%;
      height: 100px;
      max-height: 100%;
      align-items: center;
      margin: 1rem auto;
      a {
        font-size: 2rem;
      }

      &.fixed_btn {
        bottom: 3rem;
        display: flex;
        align-items: center;
      }

      &.fixed_btm_btn {
        bottom: 7rem;
      }

      &.confirm_btn {
        span {
          font-size: 2.2rem;
        }
      }
    }

    /*survey */
    .survey-wrap {
      height: auto;
      max-height: 100%;
      margin-top: 2rem;
      h2.survey-q {
        font-size: 2rem;
        display: block;
        justify-content: center;
        align-items: center;
        font-weight: 600;
        span.circle-num {
          width: 80px;
          height: 80px;
          padding: 1rem 1rem;
        }
      }

      .survey-sub-q {
        margin-left: 6rem;

        h2 {
          font-size: 1.7rem;
        }

        .partInput.survey-a {
          width: 15%;
          margin: 0 0.5rem;
          max-width: 200px;
          text-align: center;
          font-size: 1.5rem;

          & + span {
            align-items: center;
            align-self: center;
            font-size: 1.5rem;
            max-width: 50px;
          }
        }
      }

      .survey-a {
        .ant-radio-wrapper {
          margin: 0 10px;
        }
        span.ant-radio {
          & + * {
            font-size: 1.5rem;
            padding: 10px 20px;
          }
        }

        .ant-radio-inner {
          transform: scale(2);
        }
      }

      &.single-survey {
        height: 40%;
        max-height: 150px;
        margin-top: 0;
      }
    }

    /* metaexam type */
    .meta-header.type-header {
      width: 100%;
      max-width: 1900px;
      h2 {
        font-size: 2rem;
        margin-top: 0;
      }
    }

    .exam-type-con {
      .test-wrap {
        margin: 0 6rem;
        /* a {
          img {
            transform: scale(1.5);
          }
        }*/

        img {
          transform: scale(1.5);
        }

        h3 {
          font-size: 2.5rem;
          margin: 5rem 0 1rem;
        }

        .meta-chkImg {
          width: 35px;
        }

        &.no-chk {
          margin: -1rem 6rem 3rem;
        }
      }
    }

    .meta-header.onlyTxt-header {
      top: 8rem;
    }
    /* sample question */
    .sample-q-con {
      height: calc(100vh - 26rem);
      max-height: 100%;
      width: calc(90% - 3rem);
      max-width: 2000px;

      .meta-header.test-header {
        .burger-wrap {
          h2 {
            font-size: 2.4rem;
          }
        }
      }

      .meta-header.readingTest-header h1.main-tit {
        font-size: 2rem;
        margin-top: 0;
        margin-bottom: -2rem;
      }

      .readingtest-con {
        .readingtest-wrap.passage-con {
          font-size: 2rem;
        }

        .readingtest-wrap {
          .reading-q-con {
            h1 {
              font-size: 1.8rem;
            }
          }
        }
      }
    }

    /* test sheet - readingtest.js */
    .meta-header.test-header {
      padding: 1rem 14.8rem 1rem 17rem;
      .burger-wrap {
        img {
          width: 13%;
          margin-right: 20px;
        }
        h2 {
          width: 300px;
          font-size: 2rem;
        }
      }
    }

    .meta-header.readingTest-header {
      h1.main-tit {
        font-size: 1.8rem;
        margin-top: 1rem;
      }
    }

    .sample-q-con {
      .meta-header.test-header {
        h2 {
          font-size: 1.5rem;
        }
        .time-wrap {
          h2 {
            width: 90px;
          }
        }
        .question-wrap {
          h2 {
            width: 110px;
          }
        }
      }
    }

    .meta-header.test-header {
      h2 {
        font-size: 1.8rem;
        margin-bottom: 0;

        &.num {
          margin: 0.5rem 0rem 0.5rem 0rem;
        }
      }

      .time-wrap {
        h2 {
          width: 95px;
        }
      }
    }

    .readingtest-con {
      .readingtest-wrap.passage-con {
        font-size: 1.8rem;
        padding: 2rem;
      }

      .readingtest-wrap {
        .reading-q-con {
          padding: 2rem;
          h1 {
            font-size: 2.5rem;
          }
        }

        .reading-a-con {
          .reading-a-wrap {
            height: 80px;
            max-height: 100%;
            .reading-a {
              display: flex;
              align-items: center;
              h3 {
                margin-top: 0;
                font-size: 1.8rem;
              }
            }

            .reading-a-num {
              padding: 1rem 2rem;
              width: 80px;
              h3 {
                font-size: 2rem;
              }

              .checkedImg {
                width: 80%;
                max-width: 80px;
              }
            }
          }
        }
      }
    }
    .ant-tooltip-inner {
      font-size: 1.5rem;
    }
  }
  /*metacon finished */

  /* MetaReport.js */
  .meta-report-con {
    width: 100%;
    height: 100%;

    .lexile-logo-con {
      background: #c0e5fc;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 4rem 0;
      margin-bottom: 2rem;

      img {
        width: 100%;
        max-width: 900px;
      }
    }

    .userInfo-con {
      width: 100%;
      display: flex;
      .userInfo {
        display: inline-flex;
        flex-direction: column;
        &.left {
          margin-right: auto;
          width: 40%;
          display: flex;
          flex-direction: row;
          align-items: end;
          margin-bottom: 1rem;
          h2 {
            margin: 0 1rem 0 1rem;
            color: #2e76b3;
            font-size: 2rem;
          }
        }

        &.right {
          margin-left: auto;
          width: 20%;
          justify-content: end;
          font-size: 2rem;

          .ant-row {
            .ant-col {
              border: 1px solid #b3dbfc;
              padding: 1rem;
              text-align: center;
              background: #fff;
              color: #2e76b3;
            }

            .ant-col-4 {
            }
          }

          .user-table {
            display: table;
            div {
              display: table-cell;
            }
          }
        }
      }
    }

    .report-top {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin: 0.5rem 0 3rem;
      background: #fff;
      padding: 12rem 1rem;
      border: 1px solid #b3dbfc;
      .part-con {
        width: 50%;
        display: flex;
        justify-content: center;

        &:nth-child(1) {
          flex-direction: column;
          position: relative;
          left: 7rem;
          transform: translate(10px, 10px);
          text-align: center;

          img {
            width: 70%;
            position: relative;
            top: -14.5rem;
            left: 3rem;
          }

          h1 {
            font-size: 5.5rem;
            font-weight: 700;
            position: relative;
            top: -4.5rem;
            margin-bottom: 0;
          }

          h4 {
            font-size: 2.5rem;
            margin-top: 1rem;
            color: #2e76b3;
            position: relative;
            top: -3rem;
          }
        }

        &:nth-child(2) {
          img {
            width: 100%;
            max-width: 650px;
          }
          div.donut-wrap {
            position: relative;
            left: -2rem;
            div {
              position: absolute;
              top: 54.5%;
              left: 52%;
              transform: translate(-50%, -50%);
              h3 {
                font-size: 2.3rem;
                color: #2d75b3;
                margin-bottom: 0;
              }

              h2 {
                font-size: 5rem;
                font-weight: 700;
              }
            }
          }
        }

        .ant-progress {
          width: 300px !important;
          height: 300px !important;
          .ant-progress-inner {
            width: 100% !important;
            height: 100% !important;
          }
        }
      }
      .ant-table-content {
        table {
          table-layout: fixed !important;
          text-align: center;
        }
      }
    }
    /* report top end*/
    .report-middle-new {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 2rem 8.5rem 0rem;

      .card-con {
        display: flex;
        flex-direction: row;
        width: 100%;
        margin: 1rem 0;
        border: 1px solid #ddd;

        &:nth-child(1) {
          background: #fcbf15;
        }
        &:nth-child(2) {
          background: #09a5e2;
        }
        &:nth-child(3) {
          background: #90ce4f;
        }
        &:nth-child(4) {
          background: #cf88d8;
        }

        .bg {
          padding: 1rem;
          margin: 1rem 0;
          width: 40%;
          flex-direction: row;
          display: flex;
          justify-content: center;

          div.subject-con {
            flex-direction: row;
            display: flex;
            background: #fff;
            justify-content: space-between;
            align-items: center;
            border-radius: 20px;
            width: 80%;
            padding: 1rem 3rem;
            h3 {
              font-size: 2.5rem;
              margin-bottom: 0;
            }
          }
        }

        div.progress-con {
          background: #fff;
          width: 60%;
          flex-direction: column;
          display: flex;
          margin-left: 1rem;
          padding: 1rem 2rem;

          .progress-wrap {
            flex-direction: row;
            display: flex;
            align-items: center;
            margin: 0.8rem;

            span {
              padding: 0.5rem 0.3rem;
              width: 15%;
              font-weight: 600;
              max-width: 300px;
              font-size: 1.7rem;
            }

            &.ur-score {
              color: #2d75b3;
            }
            &.avg-score {
              color: #b68a17;
            }
            &.avg-dev {
              color: #759b5d;
            }

            .ant-progress-inner {
              border-radius: 0;
              .ant-progress-bg {
                border-radius: 0;
              }
            }
          }
        }
      }

      /* 이하 기존 소스*/
      .part-con {
        width: 100%;
        display: flex;
        justify-content: center;

        &:nth-child(1) {
          flex-direction: column;
          position: relative;
          left: 1.5rem;
          transform: translate(10px, 10px);
        }
        .card-con {
          .bg {
            padding: 1rem;
            margin: 1rem 0;
            width: 100%;
            flex-direction: row;
            display: flex;
            div {
              background: #fff;
              width: 100%;
              padding: 1rem 2rem;
              text-align: center;
              display: flex;
              justify-content: space-between;
              border-radius: 20px;
              h3 {
                font-size: 1.5rem;
                margin-bottom: 0;
              }
            }

            &:nth-child(1) {
              background: #fcbf15;
            }
            &:nth-child(2) {
              background: #09a5e2;
            }
            &:nth-child(3) {
              background: #90ce4f;
            }
            &:nth-child(4) {
              background: #cf88d8;
            }

            .progress-con {
              margin: 1rem 0;
              padding: 1rem;
              background: #fff;
              border: 1px solid #ddd;
              .progress-wrap {
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 0.5rem 0;
                span {
                  padding: 0.5rem 0.3rem;
                  width: 15%;
                  font-weight: 600;
                  max-width: 100px;
                }

                &.ur-score {
                  color: #2d75b3;
                }
                &.avg-score {
                  color: #b68a17;
                }
                &.avg-dev {
                  color: #759b5d;
                }
              }
            }
          }
        }

        &.charts {
          display: flex;
          flex-direction: column;
          width: 45%;
          padding: 0 1rem;
          .progress-con {
            margin: 1rem 0;
            padding: 1rem;
            background: #fff;
            border: 1px solid #ddd;
            .progress-wrap {
              display: flex;
              align-items: center;
              justify-content: center;
              margin: 0.5rem 0;
              span {
                padding: 0.5rem 0.3rem;
                width: 15%;
                font-weight: 600;
                max-width: 100px;
              }

              &.ur-score {
                color: #2d75b3;
              }
              &.avg-score {
                color: #b68a17;
              }
              &.avg-dev {
                color: #759b5d;
              }
            }
          }
        }
      }
    }

    .bookUrl-con {
      padding: 2rem 2rem;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 2rem auto;

      div {
        border: 1px solid #b3dbfc;
        padding: 2rem 2rem 4rem;
        background: #fff;
        width: 100%;
        max-width: 2020px;
        display: flex;
        flex-direction: column;
        margin: 0 auto;
      }

      * {
        margin-left: auto;
      }

      img {
        margin: 0rem 3rem 0rem auto;
        max-width: 400px;
        width: 100%;
      }

      a {
        margin-top: 0rem;
        font-size: 1.4rem;
        position: relative;
        left: -2rem;
      }
    }

    .result-table-con {
      .ant-table {
        width: 100%;
        max-width: 2020px;
        border: 1px solid #b3dbfc;
      }
      .ant-table-thead > tr > th,
      .ant-table-tbody > tr > td {
        text-align: center;
      }

      .ant-table-thead > tr > th {
        background: #67b7fb;
        color: #fff;
        font-size: 2.3rem;
      }

      .ant-table-tbody > tr > td {
        font-weight: 600;
        font-size: 2.2rem;
      }

      .ant-table-tbody > tr > td:nth-child(1),
      .ant-table-tbody > tr > td:nth-child(6) {
        color: #2e76b3;
      }

      .ant-table-tbody > tr > td:nth-child(2),
      .ant-table-tbody > tr > td:nth-child(3),
      .ant-table-tbody > tr > td:nth-child(4) {
        color: #528233;
      }

      .ant-table-tbody > tr > td:nth-child(5) {
        color: red;
      }

      .ant-table-thead
        > tr
        > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
        background-color: rgba(255, 255, 255, 0.5);
      }
    }

    .report-footer-con {
      display: flex;
      margin: 1rem auto;
      flex-direction: row;
      width: 100%;
      max-width: 2020px;
      font-size: 1.8rem;

      h5:nth-child(1) {
        margin-right: auto;
      }
      h5:nth-child(2) {
        color: #2d75b3;
        margin-left: auto;
      }
    }
  }
}

@media screen and (device-width: 1920px) and (max-width: 1920px) and (aspect-ratio: 16 / 9) {
  .meta-con {
    .inner-header {
      top: 1rem;
      width: 100%;
      max-width: 1700px;

      h2 {
        font-size: 2.6rem;
      }
    }

    .inner-header.login-header {
      h2 {
        font-size: 3rem;
      }
    }

    h1.main-tit {
      font-size: 3rem;
      margin-top: 0rem;
    }

    .metalogin-wrap {
      width: 60rem;
      height: 25rem;
      padding: 3rem 0;

      .ant-form-item {
        align-items: center;
      }

      h1 {
        font-size: 2.4rem;
        margin-bottom: 3rem;
      }

      .ant-form-item-label > label {
        font-size: 1.6rem;
      }

      .ant-form-item-control-input .partInput {
        font-size: 1.6rem;
      }

      .btn {
        width: 250px;
      }

      a {
        font-size: 2.3rem;
      }
    }

    .inner-header {
      width: calc(90% - 30rem);
      display: flex;
      margin: 1rem auto;
      h2 {
        font-size: 2rem;
      }
    }

    .report-footer-con {
      display: flex;
      margin: 1rem auto 1.5rem;
      flex-direction: row;
      width: 75%;
      font-size: 1.2rem;
    }

    &.register-con {
      .meta-header {
        margin: 2rem auto;
        h1 {
          font-size: 2.4rem;
        }
      }
    }

    #student-register {
      width: 100%;
      max-width: 1150px;
      margin: 6rem auto;
      display: flex;

      tbody {
        tr {
          td {
            &:nth-child(2) {
              margin-left: 1rem;
            }

            .ant-form-item {
              align-items: center;
              margin-bottom: 3rem;
              margin-right: 8rem;
            }
          }

          &:nth-child(1) {
            td:nth-child(2) {
              .ant-form-item-control {
                .ant-form-item-control-input-content {
                  input.partInput {
                    &:nth-child(1) {
                      width: 150px;
                    }
                    &:nth-child(2) {
                      width: 150px;
                    }
                  }
                }
              }
            }
          }

          &:nth-child(2) {
            td:nth-child(2) {
              .ant-form-item-control {
                .ant-form-item-control-input-content {
                  input.partInput {
                    &:nth-child(1) {
                      width: 80px;
                    }
                  }
                }
              }
            }
          }

          &:nth-child(3) {
            td:nth-child(2) {
              .ant-form-item-control {
                .plainInput.gradeInput {
                  width: 40%;
                }
              }
            }
          }
        }
      }

      .ant-form-item-label > label {
        font-size: 1.2rem;
        font-weight: 600;
      }

      .ant-form-item-label > label::before {
        content: '●';
        font-size: 1.5rem;
        color: #1462a1;
        margin: 0 0.2rem 3px 0.2rem;
      }

      .divided-form {
        width: 100%;
        max-width: 1100px;
        display: flex;
        flex-direction: row;

        &:nth-child(4) {
          width: 500px;
          left: 1rem;
          position: relative;
        }

        .ant-col-xs-24 {
          margin-left: 3px;
        }

        .ant-form-item {
          /*
          width: 45%;
          max-width: 600px;
          margin: 1rem 0.5rem;*/

          width: 100%;
          max-width: 600px;
          margin: 1rem 0.5rem;
        }

        .common-form.threeInput-form {
          input.partInput {
            width: 23%;
          }
        }

        .ant-form-item-label > label {
          font-size: 1.2rem;
        }

        .ant-col-sm-16 {
          width: 100%;
        }

        input.plainInput {
          width: 55%;
          text-align: center;
        }

        &:nth-child(3) {
          .common-form {
            &:nth-child(2) {
              .ant-form-item {
                position: relative;
                left: -5rem;
              }
            }
          }
        }
      }

      .threeInput-form {
        input.partInput {
          width: 20rem;
        }
      }

      .common-form {
        margin: 1rem auto;
        width: 600px;
      }

      .plain-form {
        width: 90%;
        max-width: 1100px;
        display: flex;

        .ant-form-item {
          max-width: 500px;
          width: 100%;
        }

        .ant-row .ant-form-item {
        }

        .ant-col-sm-8 {
          flex: none;
          width: 100%;
          max-width: 165px;
          text-align: left;
        }

        .ant-form-horizontal .ant-form-item-control {
          margin-left: 24px;
        }

        .ant-form-item-control-input-content {
          width: 100%;
          max-width: 500px;
        }
      }

      .partInput_wrap {
        margin-left: 0rem;
        width: 202px;
        max-width: 100%;
      }

      .ex-span {
        position: absolute;
        width: 100%;
        max-width: 120px;
        margin-left: 10px;
        margin-top: 5px;
      }

      input.plainInput {
        width: 50%;
        max-width: 200px;
        text-align: center;
      }

      input.partInput {
        /* width: 50px;
        margin: 0 1rem 0 0rem;*/
        text-align: center;

        width: 120px;
        margin: 0 7px 0 0rem;

        &:nth-child(1) {
          width: 120px;
        }

        &:nth-child(2),
        &:nth-child(3) {
          width: 100px;
        }
      }

      .btn {
        margin: 3rem auto;
        background: #c6edfe;
        color: #1462a1;
        border-radius: 30px;
        width: 150px;
        max-width: 100%;
        text-align: center;
        padding: 1rem;
        cursor: pointer;

        position: absolute;
        bottom: -1.5rem;
        left: 42rem;
      }

      .btn a {
        color: #1462a1;
        text-decoration: none;
        font-weight: 600;
        font-size: 1.3rem;
        text-align: center;
        margin: 0 auto;
      }
    }

    /* userinfo */
    .userInfo-wrap {
      max-width: 1500px;
      margin: 1rem auto 0;
    }

    /* */
    &.register-con {
      #student-register {
        max-width: 1300px;
        height: 100%;

        .ant-form-item-label > label {
          font-size: 1rem;
        }

        .common-form {
          margin: 1rem auto;
        }

        .plain-form {
          width: 100%;
          max-width: 1800px;

          .ant-col-sm-8 {
            max-width: 200px;
          }

          .ant-form-item {
            max-width: 1350px;
            align-items: center;
          }

          .ant-form-item-control-input-content {
            width: 100%;
            max-width: 1500px;
          }
        }

        input.partInput {
          width: 150px;
          margin: 0 7px 0 0rem;
          max-width: 100%;
          font-size: 1rem;
        }

        .partInput_wrap {
          width: 100%;
          max-width: 100%;
          display: flex;
        }

        .divided-form {
          max-width: 1250px;
          width: 100%;
          justify-content: space-around;

          .ant-form-item {
            width: 100%;
            max-width: 600px;
            left: -6rem;
          }

          .ant-form-item-label > label {
            font-size: 1.35rem;
          }

          .ant-col-sm-16 {
            width: 100%;
            max-width: 400px;
          }

          &:nth-child(4) {
            left: 2.3rem;
            width: 530px;
          }
        }

        input.plainInput {
          font-size: 1rem;
        }

        .ex-span {
          max-width: 200px;
          font-size: 1rem;
          position: relative;
        }

        .ant-picker {
          width: 100%;
          max-width: 400px;
          font-size: 1rem;
        }

        .ant-picker-input > input {
          font-size: 1rem;
        }
      }
    }

    .btn {
      a {
        font-size: 1.2rem;
      }

      &.fixed_btn {
        bottom: 0;
        display: flex;
        align-items: center;
      }

      &.confirm_btn {
        span {
          font-size: 1.4rem;
        }
      }
    }

    /*survey*/
    .survey-wrap {
      margin-top: 0;
      width: 100%;
      max-width: 750px;
      h2.survey-q {
        font-size: 1.6rem;
        display: flex;
        justify-content: start;
        align-items: center;
        font-weight: 600;
        span.circle-num {
          width: 70px;
          height: 70px;
          padding: 1rem 1rem;
        }
      }

      .survey-sub-q {
        margin-left: 5rem;

        h2 {
          font-size: 1.4rem;
          width: auto;
        }

        .partInput.survey-a {
          width: 10%;
          margin: 0 0.5rem;
          max-width: 200px;
          text-align: center;
          font-size: 1.5rem;

          & + span {
            align-items: center;
            align-self: center;
            font-size: 1.2rem;
            max-width: 50px;
          }
        }

        &:nth-child(3) {
          .survey-a {
          }
        }
      }

      .survey-a {
        margin-left: 5rem;
        .ant-radio-wrapper {
          margin: 0 10px;
          align-items: center;
          justify-content: center;
        }
        span.ant-radio {
          & + * {
            font-size: 1.2rem;
            padding: 10px 10px;
          }
        }

        .ant-radio-inner {
          transform: scale(2);
        }
      }

      &.single-survey {
        height: 40%;
        max-height: 150px;
        margin-top: 0;
      }
    }

    /* metaexam type */
    .meta-header.type-header {
      width: 100%;
      max-width: 1400px;
      margin: 0 auto 0;
      h2 {
        font-size: 1.6rem;
        margin-top: 1rem;
        position: relative;
        top: 0;
      }
    }
    .exam-type-con {
      height: auto;
      .test-wrap {
        margin: 0 4rem;
        div {
          img {
            transform: scale(1.4);
          }
        }

        h3 {
          font-size: 2.5rem;
          margin: 5rem 0 1rem;
        }

        .meta-chkImg {
          width: 50px;
        }

        &.no-chk {
          margin: -1rem 4.5rem 3rem;
        }
      }
    }

    /* sample question */
    .sample-q-con {
      height: calc(100vh - 26rem);
      max-height: 100%;
      width: calc(90% - 3rem);
      max-width: 1350px;

      .meta-header.test-header {
        .burger-wrap {
          h2 {
            font-size: 1.5rem;
          }
          img {
            width: 13%;
            margin-right: 10px;
          }
        }

        .question-wrap {
          h2 {
            width: 100px;
          }
        }
      }

      .meta-header.readingTest-header {
        margin: 6rem auto 0;
      }
      .meta-header.readingTest-header h1.main-tit {
        font-size: 1.6rem;
      }
      .readingtest-con {
        .readingtest-wrap {
          .reading-a-con {
            .reading-a-wrap {
              max-height: 50px;
              height: 100%;
              align-items: center;
              .reading-a-num {
                width: 70px;
                padding: 0.5rem;
                h3 {
                  font-size: 1.35rem;
                }
              }
              .reading-a {
                h3 {
                  font-size: 1.3rem;
                }
              }
            }
          }
        }
      }
    }
    .btn.confirm_btn {
      /*margin: 4rem auto 0;*/
      a {
        font-size: 1.8rem;
      }

      &.finished_btn {
        width: 25%;
      }
    }

    .fixed_btm_btn {
      bottom: 2rem;
    }

    /* test sheet - readingtest.js */
    .meta-header.test-header {
      .burger-wrap {
        img {
          width: 12%;
          margin-right: 20px;
          margin-top: 0;
        }
        h2 {
          width: 100%;
          font-size: 2rem;
          margin-top: 0;
        }
      }

      div.time-wrap {
        margin-right: 1rem;
        h2 {
          width: 95px;
        }
      }

      div.question-wrap {
        margin-left: 1rem;
      }
    }

    .meta-header.readingTest-header {
      h1.main-tit {
        font-size: 1.8rem;
        margin-top: 1rem;
      }
    }

    .readingtest-con {
      .readingtest-wrap.passage-con {
        font-size: 1.5rem;
        padding: 1.5rem;
      }

      .readingtest-wrap {
        .reading-q-con {
          padding: 1rem 1rem;
          h1 {
            font-size: 1.5rem;
          }
        }

        .reading-a-con {
          .reading-a-wrap {
            height: 60px;
            max-height: 100%;
            align-items: center;
            margin: 1rem 0;
            .reading-a {
              display: flex;
              align-items: center;
              h3 {
                margin-top: 0;
                font-size: 1.3rem;
              }
            }

            .reading-a-num {
              padding: 1rem 2rem;
              /* width: 80px; */
              h3 {
                font-size: 1.4rem;
              }

              .checkedImg {
                width: 80%;
                max-width: 80px;
              }
            }
          }
        }
      }
    }
    .ant-tooltip-inner {
      font-size: 1.5rem;
    }
    /*metacon finished */
  }

  .meta-report-con {
    .report-top {
      .part-con {
        &:nth-child(1) {
          h1 {
            left: -3rem;
            position: relative;
          }

          h4 {
            left: -3rem;
            position: relative;
          }
        }
        &:nth-child(2) {
          div.donut-wrap {
            position: relative;
            left: -2rem;
            div {
              position: absolute;
              top: 54.5%;
              left: 52%;
              transform: translate(-50%, -50%);
              h3 {
                font-size: 1.7rem;
                color: #2d75b3;
                margin-bottom: 0;
              }

              h2 {
                font-size: 3rem;
                font-weight: 700;
              }
            }
          }
        }
      }
    }

    .report-footer-con {
      display: flex;
      margin: 1rem auto;
      flex-direction: row;
      width: 100%;
      max-width: 1360px;
      font-size: 1.3rem;

      h5:nth-child(1) {
        margin-right: auto;
      }
      h5:nth-child(2) {
        color: #2d75b3;
        margin-left: auto;
      }
    }
  }
}

@media screen and (device-width: 1680px) and (max-width: 1680px) and (aspect-ratio: 16 / 10) {
  .meta-con {
    .metalogin-wrap {
      .ant-form-item {
        align-items: center;
      }

      h1 {
        font-size: 3rem;
        margin-bottom: 4rem;
      }

      .ant-form-item-label {
        width: 25%;
      }

      .ant-form-item-label > label {
        font-size: 1.45rem;
      }

      .ant-form-item-control-input .partInput {
        font-size: 1.2rem;

        &:nth-child(1) {
        }
      }

      .ant-form-item-control-input {
      }

      .btn {
        width: 250px;
      }

      a {
        font-size: 2.3rem;
      }
    }

    .inner-header {
      width: calc(90% - 25rem);
      display: flex;
      margin: 1rem auto;
      h2 {
        font-size: 2rem;
      }
    }

    .meta-header h1.main-tit {
      font-size: 3rem;
    }
    &.register-con {
      #student-register {
        max-width: 1150px;

        .ant-input-search.plainInput input {
          font-size: 1.2rem;
        }
        tbody {
          tr {
            td {
              .ant-form-item {
                margin-right: 4rem;
                align-items: center;

                .ant-form-item-control {
                  .ant-form-item-control-input-content {
                    input.partInput {
                      width: 100px;
                      font-size: 1.1rem;
                    }
                  }
                }
              }

              .ant-picker-input > input {
                font-size: 1.1rem;
              }

              .alert-label-noTime {
                bottom: -3rem;
              }
            }
          }

          tr:nth-child(1) {
            td:nth-child(2) {
              .ant-form-item-control {
                .ant-form-item-control-input-content {
                  input.partInput {
                    &:nth-child(1) {
                      width: 130px;
                      font-size: 1.1rem;
                    }

                    &:nth-child(2) {
                      width: 150px;
                      font-size: 1.1rem;
                    }
                  }
                }
              }
            }
          }

          tr:nth-child(2) {
            td:nth-child(1) {
              .ant-form-item-control {
                .ant-form-item-control-input-content {
                  input.partInput {
                    &:nth-child(1) {
                      width: 110px;
                      font-size: 1.1rem;
                    }
                  }
                }
              }
            }

            td:nth-child(2) {
              .ant-form-item-control {
                .ant-form-item-control-input-content {
                  input.partInput {
                    &:nth-child(1) {
                      width: 90px;
                      font-size: 1.1rem;
                    }
                  }
                }
              }
            }
          }

          tr:nth-child(3) {
            td:nth-child(2) {
              .ant-form-item-control {
                .plainInput.gradeInput {
                  font-size: 1.1rem;
                }
                .ant-form-item-control-input-content {
                }
              }
            }
          }
        }

        input.plainInput {
          width: 55%;
          max-width: 400px;
          text-align: center;
          font-size: 1.1rem;
        }

        .ant-form-item-label > label {
        }

        .ant-form-item:nth-child(2) {
          margin: 1rem 0 3rem;
        }
      }
    }

    .confirm_btn.fixed_btn {
      position: absolute;
      bottom: 8.5rem;
    }

    .fixed_btn {
    }

    .btn {
      width: 250px;
      max-width: 100%;
      text-align: center;
      padding: 1rem 1rem;
      a {
        font-size: 1.7rem;
        position: relative;
        bottom: 0;
      }

      &.request_btn {
        a {
          font-size: 1.7rem;
          position: relative;
          bottom: 0rem;
        }
      }
    }

    .survey-wrap {
      width: calc(90% - 3rem);
      h2.survey-q {
        font-size: 1.6rem;
        span.circle-num {
          width: 70px;
          height: 70px;
          padding: 1.2rem 0.5rem;
        }
      }

      .ant-radio-wrapper {
        align-items: center;
      }

      .survey-sub-q {
        margin-left: 6rem;
        margin-top: 2rem;
        margin-bottom: 0rem;
        h2 {
          font-size: 1.4rem;
        }

        .partInput.survey-a {
          width: auto;
          font-size: 1.4rem;
          max-width: 60px;
        }

        .partInput.survey-a + span {
          align-items: center;
          align-self: center;
          max-width: 100px;
          font-size: 1.4rem;
        }

        &:nth-child(3) {
          .survey-a {
            width: 30%;
          }
        }
      }

      .survey-a {
        width: auto;
        span.ant-radio + * {
          width: 109px;
          font-size: 1.4rem;
          color: #1561a0;
          margin: 0rem 0 0rem;
          text-align: center;
        }

        .ant-radio {
          transform: scale(1);
        }
      }
    }

    .survey-wrap.single-survey {
      margin-top: 0;
    }

    .meta-header.type-header {
      margin: 0.5rem auto 0;
      top: 0;
      width: 1200px;
      h2 {
        font-size: 1.3rem;
      }
    }

    .exam-type-con {
      width: 1000px;
      .test-wrap {
        margin: 0;
        a {
          img {
            transform: scale(1.2);
            margin: 0 auto;
            display: block;
          }
          h3 {
            font-size: 2rem;
            text-align: center;
            margin: 4rem auto 0;
          }
        }

        .meta-chkImg {
          width: 40px;
        }
      }
    }

    .type-reading-con {
      h1 {
        font-size: 5.5rem;
      }

      h3 {
        font-size: 2.3rem;
      }
    }

    .meta-header.readingTest-header {
      h1.main-tit {
        font-size: 2rem;
      }
    }

    .sample-q-con {
      max-width: 1500px;
      .meta-header.test-header {
        padding: 1rem 2.8rem 1rem 5rem;

        .burger-wrap {
          h2 {
            width: 450px;
          }
        }
        h2 {
          font-size: 1.6rem;
        }

        .time-wrap {
          h2 {
            width: 100px;
          }
        }

        .question-wrap {
          h2 {
            width: 120px;
          }
        }
      }

      .readingtest-con {
        .readingtest-wrap {
          &.passage-con {
            font-size: 1.4rem;
          }
          .reading-q-con {
            h1 {
              font-size: 1.4rem;
            }
          }
          .reading-a-con {
            .reading-a-wrap {
              margin: 0.5rem 0;
              align-items: center;
              .reading-a-num {
                width: 80px;
                padding: 1rem;
                h3 {
                  font-size: 1.4rem;
                }
              }
              h3 {
                font-size: 1.4rem;
              }
            }
          }
        }
      }
    }

    .readingtest-con {
      .readingtest-wrap {
        &.passage-con {
          font-size: 1.8rem;
        }
        .reading-q-con {
          h1 {
            font-size: 1.8rem;
          }
        }
        .reading-a-con {
          .reading-a-wrap {
            margin: 1rem 0;
            align-items: center;
            .reading-a-num {
              width: 80px;
              padding: 1rem;
              h3 {
                font-size: 2rem;
              }
            }
            h3 {
              font-size: 2rem;
            }
          }
        }
      }
    }

    &.meta-full-con {
      .btn {
        a {
          bottom: 0.5rem;
        }

        &.enter_btn {
          a {
            bottom: 0rem;
          }
        }

        &.confirm_btn.finished_btn {
          width: 25%;
          a {
            bottom: 0.5rem;
          }
        }

        &.start_btn {
          a {
            bottom: 0.5rem;
          }
        }
      }

      .ant-tooltip-placement-topRight {
        .ant-tooltip-arrow {
          transform: scale(5.5);
          bottom: -2rem;
        }
      }
      .ant-tooltip-inner {
        font-size: 1.5rem;
        min-width: 300px;
        text-align: center;
        padding: 1rem;
      }
    }

    .meta-header.test-header {
      width: 100%;
      display: flex;
      background: #e3f6fd;
      margin: 0 auto;
      padding: 1rem 12rem 1rem 11rem;

      h2 {
        font-size: 1.6rem;
      }

      .burger-wrap {
        h2 {
          width: 250px;
        }
        img {
          width: 10%;
        }
      }
      .time-wrap {
        h2 {
          width: 90px;
        }
      }

      .question-wrap {
        h2 {
          width: 130px;
        }
      }
    }

    .readingtest-con {
      .readingtest-wrap.passage-con {
        font-size: 1.4rem;
      }

      .readingtest-wrap {
        .reading-q-con {
          padding: 1rem 1rem;

          h1 {
            font-size: 1.5rem;
          }
        }

        .reading-a-con {
          .reading-a-wrap {
            margin: 0.8rem 0;
            .reading-a-num {
              width: 100px;

              h3 {
                font-size: 1.4rem;
              }
            }

            .reading-a {
              h3 {
                font-size: 1.2rem;
              }
            }
          }
        }
      }
    }

    .report-footer-con {
      font-size: 1rem;
    }
  }
}

@media screen and (device-width: 1600px) and (max-width: 1600px) and (aspect-ratio: 16 / 9) {
  .meta-con {
    .inner-header {
      top: 1rem;
      width: 100%;
      max-width: 1700px;

      h2 {
        font-size: 2.6rem;
      }
    }

    .inner-header.login-header {
      margin: 1rem 0 0;
      h2 {
        font-size: 3rem;
      }
    }

    h1.main-tit {
      font-size: 2.5rem;
      margin-top: 2rem;
    }

    &.register-con {
      height: auto;
      margin: 3rem auto;
      h1.main-tit {
        margin-top: 0rem;
        margin-bottom: 0;
      }
    }

    .metalogin-wrap {
      height: 100%;
      max-height: 380px;
      max-width: 1300px;
      padding: 2rem 1rem;
      margin: 1rem 0 5rem;

      h1 {
        font-size: 2.4rem;
        margin: 0rem auto 2rem;
      }

      .ant-form-item {
        width: 100%;
        max-width: 600px;
      }

      .ant-form-item-label {
        width: 175px;
        text-align: left;
        max-width: 100%;
      }

      .ant-form-item-label > label {
        margin-top: 0.5rem;
        font-size: 1.5rem;
      }

      .ant-form-item-control {
        max-width: 70%;
      }

      .ant-form-item-control-input {
        width: 100%;
        max-width: 100%;
      }

      .ant-form-item-control-input-content {
        flex: none;

        input.partInput {
          font-size: 1.3rem;

          &:nth-child(1) {
            width: 45%;
          }
        }
      }

      .btn {
        width: 100%;
        max-width: 200px;
        height: 50%;
        max-height: 65px;
        margin: 2rem auto 0;
        a {
          font-size: 1.7rem;
          position: relative;
          top: 0rem;
        }
      }
    }

    .arrow-wrap {
      position: absolute;
      bottom: 3%;
      right: 27%;
      transform: translate(-29%, -10%);
      .guide-arrow {
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 45px 77.9px 45px 0;
        border-color: transparent #fabe78 transparent transparent;
        line-height: 0px;
        _border-color: #ffffff #fabe78 #ffffff #ffffff;
        _filter: progid:DXImageTransform.Microsoft.Chroma(color='#ffffff');
        position: relative;
        bottom: 16%;
        right: 39%;
        transform: translate(-40%, -18%);

        .arrow-body {
          background: #fabe78;
          color: #333;
          width: 100%;
          min-width: 300px;
          height: 50px;
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
          z-index: 2;
          top: -1.6rem;
          left: 4rem;
          font-weight: 600;
          font-size: 1.2rem;
        }
      }
    }

    /* userinfo */
    .userInfo-wrap {
      max-width: 1600px;
      margin: 1rem auto 0;
    }

    &.register-con {
      #student-register {
        max-width: 1100px;
        height: 100%;
        margin: 0 auto 0;

        tbody {
          tr {
            td {
              .ant-form-item {
                margin-right: 4rem;
                margin-bottom: 0.5rem;
              }
            }

            &:nth-child(3) {
              td:nth-child(2) {
                .ant-form-item-control {
                  .plainInput.gradeInput {
                    font-size: 1rem;
                  }
                }
              }
            }
          }
        }

        .ant-form-item-label > label {
          font-size: 1rem;
        }

        .ant-input-search.plainInput {
          width: 50%;
          input {
            font-size: 1.2rem;
          }
        }

        .common-form {
          margin: 0.5rem auto;
        }

        .plain-form {
          width: 100%;
          max-width: 1800px;

          .ant-col-sm-8 {
            max-width: 200px;
          }

          .ant-col-sm-16 {
            max-width: 73%;
          }

          .ant-form-item {
            max-width: 1350px;
            align-items: center;
          }

          .ant-form-item-control-input-content {
            width: 100%;
            max-width: 1500px;
          }
        }

        input.partInput {
          width: 150px;
          margin: 0 7px 0 0rem;
          max-width: 100%;
          font-size: 1rem;
        }

        .partInput_wrap {
          width: 100%;
          max-width: 100%;
          display: flex;
        }

        .divided-form {
          max-width: 1100px;
          width: 100%;
          justify-content: space-around;

          .ant-form-item {
            width: 100%;
            max-width: 550px;
          }

          .ant-form-item-label > label {
            font-size: 1.5rem;
          }

          .ant-col-sm-16 {
            width: 100%;
            max-width: 400px;
            max-width: 73%;
          }
        }

        input.plainInput {
          margin-top: 0rem;
          font-size: 1rem;
        }

        .ex-span {
          max-width: 200px;
          font-size: 1rem;
          position: relative;
        }

        .ant-picker {
          width: 100%;
          max-width: 200px;
          font-size: 1rem;
        }

        .ant-picker-input > input {
          font-size: 1rem;
        }
      }
    }

    .btn {
      width: 100%;
      max-width: 200px;
      height: 100%;
      max-height: 70px;
      a {
        font-size: 1.2rem;
      }

      &.fixed_btn {
        bottom: 0;
        display: flex;
        align-items: center;
      }

      &.fixed_btm_btn {
        bottom: 10rem;
      }

      &.confirm_btn {
        span {
          font-size: 2rem;
        }
      }
    }

    /*survey*/
    .survey-wrap {
      margin-top: 0.5rem;

      width: 100%;
      max-width: 1150px;
      h2.survey-q {
        font-size: 1.4rem;
        display: flex;
        align-items: center;
        font-weight: 600;
        span.circle-num {
          width: 60px;
          height: 60px;
          padding: 1rem 1rem;
        }
      }

      .survey-sub-q {
        margin-left: 4.8rem;
        margin-top: 1rem;
        margin-bottom: 0.5rem;
        h2 {
          font-size: 1.2rem;
          width: 87%;
        }

        &:nth-child(3) {
          .survey-a {
            justify-content: end;
            margin-right: 0;
          }
        }

        .partInput.survey-a {
          margin: 0 0.5rem;
          max-width: 50px;
          text-align: center;
          font-size: 1.5rem;

          & + span {
            width: auto;
            align-items: center;
            align-self: center;
            font-size: 1.1rem;
            max-width: 50px;
          }
        }

        .survey-a {
          width: 85%;
        }
      }

      .survey-a {
        .ant-radio-wrapper {
          margin: 0 0px;
          align-items: center;
          justify-content: center;
        }
        span.ant-radio {
          & + * {
            font-size: 1.1rem;
            padding: 10px 20px;
          }
        }

        .ant-radio-inner {
          transform: scale(1.2);
        }
      }

      &.single-survey {
        height: 40%;
        max-height: 250px;
        margin-top: 0;
      }
    }

    /* metaexam type */
    .meta-header.type-header {
      width: 100%;
      max-width: 1200px;
      margin: 0 auto;
      h2 {
        font-size: 1.6rem;
        margin-top: 0;
        position: relative;
        top: 0;
      }
    }

    .exam-type-con {
      .test-wrap {
        margin: 0 2.5rem;
        div {
          img {
            transform: scale(1);
          }
        }

        h3 {
          font-size: 2rem;
          margin: 1rem 0 1rem;
        }

        .meta-chkImg {
          width: 40px;
          margin: 0 auto;
        }

        &.no-chk {
          margin: 0rem 2.5rem 3rem;
        }
      }
    }

    .meta-header.onlyTxt-header {
      position: absolute;
      top: 7rem;
    }

    /* sample question */
    .sample-q-con {
      height: calc(100vh - 20rem);
      max-height: 100%;
      width: calc(90% - 3rem);
      max-width: 1500px;

      .process-btn-con {
        width: 100%;
        display: flex;
        margin: 1rem 0 5.5rem;
      }

      .meta-header.test-header {
        .burger-wrap {
          img {
            width: 10%;
            margin-right: 10px;
            margin-top: 0;
          }
          h2 {
            font-size: 1.5rem;
          }
        }
      }

      .meta-header.readingTest-header h1.main-tit {
        font-size: 1.6rem;
      }
      .readingtest-con {
        .readingtest-wrap {
          .reading-a-con {
            .reading-a-wrap {
              max-height: 40px;
              height: 100%;
              align-items: center;
              .reading-a-num {
                width: 50px;
                padding: 0.5rem;
                h3 {
                  font-size: 1.3rem;
                }
              }
              .reading-a {
                h3 {
                  font-size: 1rem;
                }
              }
            }
          }
        }
      }
    }
    .btn.confirm_btn {
      margin: 1rem auto 0;
      align-items: center;
      max-height: 50px;
      a {
        font-size: 1.5rem;
      }
    }

    /* test sheet - readingtest.js */
    .meta-header.test-header {
      margin: 0 auto;
      padding: 1rem 5rem;
      .burger-wrap {
        img {
          width: 10%;
          margin-right: 10px;
          margin-top: 0;
        }
        h2 {
          width: 300px;
          font-size: 2rem;
          margin-bottom: 0.25rem;
          margin-top: 0;
        }
      }

      .time-wrap {
        h2 {
          margin-bottom: 0;
        }
      }

      .question-wrap {
        h2 {
          margin-bottom: 0;
        }
      }

      h2.num {
        margin: 0 0 0 -1rem;
      }
    }

    .meta-header.readingTest-header {
      h1.main-tit {
        font-size: 1.5rem;
        margin-top: 0rem;
      }
    }

    .readingtest-con {
      .readingtest-wrap.passage-con {
        font-size: 1.3rem;
        padding: 1.5rem;
      }

      .readingtest-wrap {
        .reading-q-con {
          padding: 1rem 1rem;
          h1 {
            font-size: 1.5rem;
          }
        }

        .reading-a-con {
          .reading-a-wrap {
            height: 50px;
            max-height: 100%;
            align-items: center;
            margin: 0.8rem 0;
            .reading-a {
              display: flex;
              align-items: center;
              h3 {
                margin-top: 0;
                font-size: 1.4rem;
              }
            }

            .reading-a-num {
              padding: 0.3rem 2rem;
              width: 70px;
              h3 {
                font-size: 1.3rem;
              }

              .checkedImg {
                width: 80%;
                max-width: 80px;
              }
            }
          }
        }
      }
    }
    .ant-tooltip-inner {
      font-size: 1rem;
    }

    .btn.confirm_btn {
      margin: 1rem auto 0;
      align-items: center;
      max-height: 70px;
      a {
        font-size: 1.5rem;
      }
    }
    /*metacon finished */
    /* guide con start */
    &.guide-con {
      .test-wrap {
        .arrow-wrap {
          position: absolute;
          left: 47%;
          top: 36%;
          transform: translate(-29%, -10%) rotate(-20deg);
          .guide-arrow {
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 45px 77.9px 45px 0;
            border-color: transparent #fabe78 transparent transparent;
            line-height: 0px;
            _border-color: #ffffff #fabe78 #ffffff #ffffff;
            _filter: progid:DXImageTransform.Microsoft.Chroma(color='#ffffff');
            position: relative;
            bottom: 16%;
            right: 39%;
            transform: translate(-40%, -18%);

            .arrow-body {
              background: #fabe78;
              color: #333;
              width: 100%;
              min-width: 300px;
              height: 50px;
              display: flex;
              justify-content: center;
              align-items: center;
              position: relative;
              z-index: 2;
              top: -1.6rem;
              left: 4rem;
              font-weight: 600;
              font-size: 1.2rem;
            }
          }
        }
      }
    }
  }

  /* MetaReport.js */
  .meta-report-con {
    width: 100%;
    height: 100%;

    .lexile-logo-con {
      background: #c0e5fc;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 3rem 0;
      margin-bottom: 2rem;

      img {
        width: 100%;
        max-width: 600px;
      }
    }

    .userInfo-con {
      width: 100%;
      display: flex;
      .userInfo {
        display: inline-flex;
        flex-direction: column;
        &.left {
          margin-right: auto;
          width: 40%;
          display: flex;
          flex-direction: row;
          align-items: end;
          margin-bottom: 0.3rem;
          h2 {
            margin: 0 1rem 0 1rem;
            color: #2e76b3;
            font-size: 1rem;
          }
        }

        &.right {
          margin-left: auto;
          width: 20%;
          justify-content: end;
          font-size: 1rem;

          .ant-row {
            .ant-col {
              border: 1px solid #b3dbfc;
              padding: 0.3rem;
              text-align: center;
              background: #fff;
              color: #2e76b3;
            }

            .ant-col-4 {
            }
          }

          .user-table {
            display: table;
            div {
              display: table-cell;
            }
          }
        }
      }
    }

    .report-top {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin: 0.5rem 0 3rem;
      background: #fff;
      padding: 5rem 1rem;
      border: 1px solid #b3dbfc;
      .part-con {
        width: 40%;
        display: flex;
        justify-content: center;

        &:nth-child(1) {
          flex-direction: column;
          position: relative;
          left: 8rem;
          top: -1rem;
          transform: translate(10px, 10px);
          text-align: center;

          img {
            width: 85%;
            position: relative;
            top: -7.5rem;
            left: -1rem;
          }

          h1 {
            font-size: 3rem;
            font-weight: 700;
            position: relative;
            top: -1.5rem;
            left: 0rem;
            margin-bottom: 0;
          }

          h4 {
            font-size: 1.3rem;
            margin-top: 1rem;
            position: relative;
            left: 0;
            top: -1rem;
            color: #2e76b3;
          }
        }

        &:nth-child(2) {
          div.donut-wrap {
            position: relative;
            left: -2rem;
            div {
              position: absolute;
              top: 55.5%;
              left: 52%;
              transform: translate(-50%, -50%);
              h3 {
                font-size: 1.5rem;
                color: #2d75b3;
                margin-bottom: 0;
              }

              h2 {
                font-size: 3rem;
                font-weight: 700;
              }
            }
          }
        }

        .ant-progress {
          width: 300px !important;
          height: 300px !important;
          .ant-progress-inner {
            width: 100% !important;
            height: 100% !important;
          }
        }
      }
      .ant-table-content {
        table {
          table-layout: fixed !important;
          text-align: center;
        }
      }
    }
    /* report top end*/
    .report-middle-new {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 2rem 0rem 0rem;

      .card-con {
        display: flex;
        flex-direction: row;
        width: 100%;
        margin: 1rem 0;
        border: 1px solid #ddd;

        &:nth-child(1) {
          background: #fcbf15;
        }
        &:nth-child(2) {
          background: #09a5e2;
        }
        &:nth-child(3) {
          background: #90ce4f;
        }
        &:nth-child(4) {
          background: #cf88d8;
        }

        .bg {
          padding: 1rem;
          margin: 1rem 0;
          width: 40%;
          flex-direction: row;
          display: flex;
          justify-content: center;

          div.subject-con {
            flex-direction: row;
            display: flex;
            background: #fff;
            justify-content: space-between;
            align-items: center;
            border-radius: 20px;
            width: 80%;
            padding: 1rem 3rem;
            h3 {
              font-size: 1.5rem;
              margin-bottom: 0;
            }
          }
        }

        div.progress-con {
          background: #fff;
          width: 60%;
          flex-direction: column;
          display: flex;
          margin-left: 1rem;
          padding: 1rem 2rem;

          .progress-wrap {
            flex-direction: row;
            display: flex;
            align-items: center;

            span {
              padding: 0.5rem 0.3rem;
              width: 20%;
              font-weight: 600;
              max-width: 300px;
              font-size: 1.1rem;
            }

            &.ur-score {
              color: #2d75b3;
            }
            &.avg-score {
              color: #b68a17;
            }
            &.avg-dev {
              color: #759b5d;
            }

            .ant-progress-inner {
              border-radius: 0;
              .ant-progress-bg {
                border-radius: 0;
              }
            }
          }
        }
      }
    }

    .bookUrl-con {
      padding: 2rem 0rem;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 2rem auto;

      div {
        border: 1px solid #b3dbfc;
        padding: 2rem 2rem 4rem;
        background: #fff;
        width: 100%;
        max-width: 2020px;
        display: flex;
        flex-direction: column;
        margin: 0 auto;
      }

      * {
        margin-left: auto;
      }

      img {
        margin: 1rem 3rem 0rem auto;
        max-width: 200px;
        width: 100%;
      }

      a {
        margin-top: 0rem;
      }
    }

    .result-table-con {
      .ant-table {
        width: 100%;
        max-width: 2020px;
        border: 1px solid #b3dbfc;
      }
      .ant-table-thead > tr > th,
      .ant-table-tbody > tr > td {
        text-align: center;
      }

      .ant-table-thead > tr > th {
        background: #67b7fb;
        color: #fff;
        font-size: 1.3rem;
      }

      .ant-table-tbody > tr > td {
        font-weight: 600;
        font-size: 1.2rem;
      }

      .ant-table-tbody > tr > td:nth-child(1),
      .ant-table-tbody > tr > td:nth-child(6) {
        color: #2e76b3;
      }

      .ant-table-tbody > tr > td:nth-child(2),
      .ant-table-tbody > tr > td:nth-child(3),
      .ant-table-tbody > tr > td:nth-child(4) {
        color: #528233;
      }

      .ant-table-tbody > tr > td:nth-child(5) {
        color: red;
      }

      .ant-table-thead
        > tr
        > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
        background-color: rgba(255, 255, 255, 0.5);
      }
    }

    .report-footer-con {
      display: flex;
      margin: 1rem auto;
      flex-direction: row;
      width: 100%;
      max-width: 1320px;
      font-size: 1rem;

      h5:nth-child(1) {
        margin-right: auto;
      }
      h5:nth-child(2) {
        color: #2d75b3;
        margin-left: auto;
      }
    }
  }
}

@media screen and (device-width: 1600px) and (max-width: 1600px) and (aspect-ratio: 16/9) {
  .meta-con {
    .survey-wrap {
      width: calc(90% - 4rem);

      .survey-sub-q {
        &:nth-child(3) {
          margin-right: 2rem;

          .survey-a {
            justify-content: end;
          }
        }
      }
    }

    #student-register {
      tbody {
        tr {
          &:nth-child(1) {
            td {
              &:nth-child(2) {
                .ant-form-item-control {
                  .ant-form-item-control-input-content {
                    input.partInput {
                      &:nth-child(1) {
                        width: 140px;
                      }
                    }
                  }
                }
              }
            }
          }
          &:nth-child(2) {
            td {
              &:nth-child(2) {
                .ant-form-item-control {
                  .ant-form-item-control-input-content {
                    input.partInput {
                      &:nth-child(1) {
                        width: 60px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1921px) {
  .meta-con {
  }
}

@media screen and (device-width: 1920px) and (max-width: 1920px) {
  .meta-con {
    .inner-header.login-header {
      text-align: center;
      margin-bottom: 1rem;
      margin-left: auto;
      margin-right: auto;
    }

    #student-register {
      .userInfo-wrap {
        .divided-form {
          input.partInput {
            width: 45%;
          }
        }
      }
    }
  }
}

@media (max-width: 1800px) {
  .meta-con {
    .inner-header {
      top: 10rem;
    }
    &.guide-con {
      .process-btn-con {
        a {
          img {
            width: 70%;
          }
        }
      }
    }
  }
}

@media (max-width: 1500px) {
  .meta-con .inner-header.login-header {
  }

  .meta-con .report-footer-con {
    display: flex;
    margin: 1rem auto 0rem;
    flex-direction: row;
    width: 85%;
    position: relative;
    bottom: 0px;
    left: 0;
    font-size: 1.2rem;
  }

  .meta-con {
    .inner-header {
      width: calc(100% - 3rem);
      h2 {
      }
    }
  }
}

@media screen and (max-width: 1536px) {
  .meta-con {
    &.register-con {
      height: 100%;
      margin: 3rem auto;
    }

    #student-register {
      width: 100%;
      max-width: 1000px;
      display: flex;

      tbody {
        tr {
          td {
            &:nth-child(2) {
              margin-left: 1rem;
            }

            .ant-form-item {
              align-items: center;
              margin-bottom: 2rem;
              margin-right: 2rem;
            }
          }

          &:nth-child(1) {
            td:nth-child(2) {
              .ant-form-item-control {
                .ant-form-item-control-input-content {
                  input.partInput {
                    &:nth-child(1) {
                      width: 110px;
                    }
                    &:nth-child(2) {
                      width: 150px;
                    }
                  }
                }
              }
            }
          }

          &:nth-child(2) {
            td:nth-child(2) {
              .ant-form-item-control {
                .ant-form-item-control-input-content {
                  input.partInput {
                    &:nth-child(1) {
                      width: 60px;
                    }
                  }
                }
              }
            }
          }

          &:nth-child(3) {
            td:nth-child(2) {
              .ant-form-item-control {
                .plainInput.gradeInput {
                  width: 40%;
                }
              }
            }

            td {
              .ant-form-item-control-input {
                width: 250px;
              }
            }
          }
        }
      }

      .ant-form-item-label > label {
        font-size: 1.2rem;
        font-weight: 600;
      }

      .ant-form-item-label > label::before {
        content: '●';
        font-size: 1.5rem;
        color: #1462a1;
        margin: 0 0.2rem 3px 0.2rem;
      }

      .divided-form {
        width: 100%;
        max-width: 1100px;
        display: flex;
        flex-direction: row;

        &:nth-child(4) {
          width: 500px;
          left: 1rem;
          position: relative;
        }

        .ant-col-xs-24 {
          margin-left: 3px;
        }

        .ant-form-item {
          /*
          width: 45%;
          max-width: 600px;
          margin: 1rem 0.5rem;*/

          width: 100%;
          max-width: 600px;
          margin: 1rem 0.5rem;
        }

        .common-form.threeInput-form {
          input.partInput {
            width: 23%;
          }
        }

        .ant-form-item-label > label {
          font-size: 1.2rem;
        }

        .ant-col-sm-16 {
          width: 100%;
        }

        input.plainInput {
          width: 55%;
          text-align: center;
        }

        &:nth-child(3) {
          .common-form {
            &:nth-child(2) {
              .ant-form-item {
                position: relative;
                left: -5rem;
              }
            }
          }
        }
      }

      .threeInput-form {
        input.partInput {
          width: 20rem;
        }
      }

      .common-form {
        margin: 1rem auto;
        width: 600px;
      }
      .plain-form {
        /*
        width: 64%;
        max-width: 800px;*/
        width: 90%;
        max-width: 1100px;
        display: flex;
        /* justify-content: center; */

        .ant-form-item {
          max-width: 500px;
          width: 100%;
        }

        .ant-row .ant-form-item {
        }

        .ant-col-sm-8 {
          flex: none;
          width: 100%;
          max-width: 165px;
          text-align: left;
        }

        .ant-form-horizontal .ant-form-item-control {
          margin-left: 24px;
        }

        .ant-form-item-control-input-content {
          width: 100%;
          max-width: 500px;
        }
      }

      .partInput_wrap {
        margin-left: 0rem;
        width: 202px;
        max-width: 100%;
      }

      .ex-span {
        position: absolute;
        width: 100%;
        max-width: 120px;
        margin-left: 10px;
        margin-top: 5px;
      }

      input.plainInput {
        width: 50%;
        max-width: 400px;
        text-align: center;
      }

      input.partInput {
        /* width: 50px;
        margin: 0 1rem 0 0rem;*/
        text-align: center;

        width: 120px;
        margin: 0 7px 0 0rem;

        &:nth-child(1) {
          width: 90px;
        }

        &:nth-child(2),
        &:nth-child(3) {
          width: 80px;
        }
      }

      .btn {
        margin: 3rem auto;
        background: #c6edfe;
        color: #1462a1;
        border-radius: 30px;
        width: 150px;
        max-width: 100%;
        text-align: center;
        padding: 1rem;
        cursor: pointer;

        position: absolute;
        bottom: -1.5rem;
        left: 42rem;
      }

      .btn a {
        color: #1462a1;
        text-decoration: none;
        font-weight: 600;
        font-size: 1.3rem;
        text-align: center;
        margin: 0 auto;
      }
    }

    .start_btn {
      margin: 0.5rem auto;
    }

    .survey-wrap h2.survey-q,
    .survey-wrap .survey-sub-q h2 {
      font-size: 1.3rem;
    }

    .survey-wrap {
      .survey-wrap {
        h2.survey-q {
          font-size: 1.3rem;
        }
      }
      .survey-sub-q {
        .survey-a {
          width: 75%;
        }
      }
    }

    /* metaexam type */
    .meta-header.type-header {
      width: 1100px;
      margin-bottom: 0.5rem;
    }

    .exam-type-con {
      width: auto;
      .test-wrap {
        margin: 0 2rem;
      }
    }

    .meta-header.test-header {
      .time-wrap {
        h2 {
          width: 80px;
        }
      }
      .question-wrap {
        h2 {
          width: 95px;
        }
      }

      .burger-wrap {
        h2 {
          width: 150px;
        }
      }

      h2 {
        font-size: 1.2rem;
        margin: 0;
        &.num {
          width: 95px;
          margin: 0;
        }
      }

      div.time-wrap {
        margin-right: 1rem;
      }
    }

    .meta-header.type-header {
      max-width: 90%;
    }

    .readingtest-con {
      .readingtest-wrap {
        .reading-a-con {
          .reading-a-wrap {
            margin: 0.9rem 0;
          }
        }
      }
    }
  }

  .meta-con .meta-header.test-header {
    padding: 0.5rem 4rem 0.5rem 4rem;
  }

  /* report */
  .meta-report-con {
    .userInfo-con {
      .userInfo.left {
        width: 32%;
        h2 {
        }
      }

      .userInfo.right {
        width: 25%;
      }
    }
  }

  .meta-con .sample-q-con {
    max-width: 1000px;
    margin: 0 auto;

    .readingtest-con {
      .readingtest-wrap {
        .reading-a-con {
          .reading-a-wrap {
            margin: 0.3rem 0;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1536px) and (aspect-ratio: 16 / 9) {
  .meta-con {
    &.register-con {
      #student-register tbody tr:nth-child(3) td .ant-form-item-control-input {
        width: 100%;
      }
    }
  }
}

@media screen and (max-width: 1440px) {
  .meta-con {
    .inner-header {
      width: 70%;
      top: 10rem;
      h2 {
        font-size: 1.6rem;
      }
    }

    &.guide-con {
      .test-wrap {
        .arrow-wrap {
          position: absolute;
          left: 20rem;
          top: 20rem;
          width: 100px;
          height: 100px;
        }
      }
    }

    &.register-con {
      height: auto;
      margin: 3rem auto;

      .meta-header {
        margin: 2rem auto;
      }

      #student-register tbody tr td .alert-label-noTime {
        bottom: -3rem;
      }
    }

    #student-register {
      .divided-form {
        width: 100%;
      }
    }

    .survey-wrap {
      h2.survey-q {
        font-size: 1.2rem;

        span.circle-num {
          width: 40px;
          height: 40px;
        }
      }

      .survey-sub-q {
        h2 {
          font-size: 1rem;
        }

        .survey-a {
          width: 75%;
          span.ant-radio + * {
            font-size: 1rem;
          }
        }

        .partInput.survey-a {
          font-size: 1rem;
          & + span {
            font-size: 1rem;
          }
        }
      }

      .survey-a span.ant-radio + * {
        font-size: 1rem;
      }
    }

    .fixed_btm_btn {
      /*
      bottom: 8rem; */
    }
  }

  .meta-report-con {
    .report-top {
      .part-con {
        &:nth-child(1) {
          left: 2rem;
          top: -2rem;
        }
      }
    }

    .report-middle-new {
      padding: 2rem 3rem 0;
      .card-con {
        .bg {
          div.subject-con {
            padding: 1rem;
          }
        }

        div.progress-con {
          .progress-wrap {
            span {
              font-size: 0.9rem;
            }
          }
        }
      }
    }

    .bookUrl-con {
      padding: 2rem 3rem;
    }

    .result-table-con {
      padding: 0 3rem 0;
    }

    .report-footer-con {
      max-width: 75%;
    }
  }
}

@media screen and (device-width: 1440px) and (max-width: 1440px) and (aspect-ratio: 16 / 10) {
  .meta-con {
    .inner-header.login-header {
      position: relative;
      margin-bottom: 2rem;
      .login-top-logo {
        width: 50%;
        max-width: 300px;
      }
    }

    .metalogin-wrap {
      width: 50rem;
      height: 25rem;
      margin: 0rem 0 3rem;
      .ant-form-item {
        align-items: center;
      }

      h1 {
        font-size: 2rem;
        margin-bottom: 3rem;
      }
      .ant-form-item-label > label {
        font-size: 1.2rem;
        width: 120px;
      }

      .ant-form-item-control {
        width: 60%;
      }

      .ant-form-item-control-input .partInput {
        font-size: 1.2rem;
        &:nth-child(1) {
          width: 45%;
        }
      }

      .btn {
        width: 250px;
      }

      a {
        font-size: 2.3rem;
      }
    }

    .inner-header {
      width: calc(90% - 20rem);
      display: flex;
      margin: 1rem auto;
      h2 {
        font-size: 1.4rem;
      }
    }

    .meta-header h1.main-tit {
      font-size: 2rem;
    }

    #student-register {
      max-width: 1200px;
      margin: 2rem auto;
      tbody {
        tr {
          td {
            .ant-form-item {
              margin-right: 4rem;
              margin-bottom: 5rem;
              align-items: center;

              .ant-form-item-control {
                .ant-form-item-control-input-content {
                  input.partInput {
                    width: 90px;
                    font-size: 1rem;
                  }
                }
              }
            }

            .ant-picker-input > input {
              font-size: 1rem;
            }

            .ant-form-item .ant-select,
            .ant-form-item .ant-cascader-picker {
              font-size: 1rem;
            }
          }
        }

        tr:nth-child(1) {
          td:nth-child(2) {
            .ant-form-item-control {
              .ant-form-item-control-input-content {
                input.partInput {
                  &:nth-child(1) {
                    width: 150px;
                    font-size: 1rem;
                  }

                  &:nth-child(2) {
                    width: 180px;
                    font-size: 1rem;
                  }
                }
              }
            }
          }
        }

        tr:nth-child(2) {
          td:nth-child(1) {
            .ant-form-item-control {
              .ant-form-item-control-input-content {
                input.partInput {
                  &:nth-child(1) {
                    width: 110px;
                    font-size: 1rem;
                  }
                }
              }
            }
          }

          td:nth-child(2) {
            .ant-form-item-control {
              .ant-form-item-control-input-content {
                input.partInput {
                  &:nth-child(1) {
                    width: 90px;
                    font-size: 1rem;
                  }
                }
              }
            }
          }
        }
      }

      input.plainInput {
        width: 60%;
        max-width: 400px;
        text-align: center;
        font-size: 1rem;
      }

      .ant-form-item-label > label {
        font-size: 1.1rem;
      }

      .ant-form-item:nth-child(2) {
        margin: 1rem 0 3rem;
      }
    }

    .confirm_btn.fixed_btn {
      position: absolute;
      bottom: 8.5rem;
    }

    .btn {
      width: 200px;
      max-width: 100%;
      text-align: center;
      padding: 1rem 1rem;
      a {
        font-size: 1.4rem;
        position: relative;
        bottom: 0;
      }

      .fixed_btn {
        bottom: 0;
      }

      &.request_btn {
        a {
          font-size: 1.4rem;
          position: relative;
          bottom: 0.1rem;
        }
      }
    }

    .survey-wrap {
      width: 93%;
      h2.survey-q {
        font-size: 1.2rem;
        span.circle-num {
          width: 40px;
          height: 40px;
          padding: 0.5rem 0.5rem;
        }
      }

      .ant-radio-wrapper {
        align-items: center;
      }

      .survey-sub-q {
        margin-left: 4rem;
        h2 {
          font-size: 1.1rem;
        }

        .partInput.survey-a {
          width: auto;
          font-size: 1.4rem;
          max-width: 60px;
        }

        .partInput.survey-a + span {
          align-items: center;
          align-self: center;
          max-width: 100px;
          font-size: 1.2rem;
        }
      }

      .survey-a {
        width: auto;
        span.ant-radio + * {
          width: 100px;
          font-size: 1.2rem;
          color: #1561a0;
          margin: 0rem 0 0rem;
          text-align: center;
        }

        .ant-radio {
          transform: scale(1);
        }
      }
    }

    .survey-wrap.single-survey {
      margin-top: 0;
    }

    .meta-header.type-header {
      margin: 0.5rem auto 0;
      top: 0;
      width: 1000px;
      h2 {
        font-size: 1.2rem;
      }
    }
    .exam-type-con {
      .test-wrap {
        margin: 0 3rem;
        a {
          img {
            transform: scale(1);
            margin: 0 auto;
            display: block;
          }
          h3 {
            font-size: 1.6rem;
            text-align: center;
            margin: 1rem auto 0;
          }
        }

        .meta-chkImg {
          width: 30px;
        }
      }
    }

    .type-reading-con {
      h1 {
        font-size: 5rem;
      }

      h3 {
        font-size: 1.6rem;
      }
    }

    .meta-header.readingTest-header {
      h1.main-tit {
        font-size: 1.4rem;
      }
    }

    .sample-q-con {
      max-width: 1500px;
      .meta-header.test-header {
        padding: 0rem 2.8rem 0rem 5rem;

        .burger-wrap {
          h2 {
            width: 450px;
            font-size: 1.4rem;
          }
        }
        h2 {
          font-size: 1.4rem;
        }

        .time-wrap {
          h2 {
            width: 100px;
            font-size: 1.4rem;
          }
        }

        .question-wrap {
          h2 {
            font-size: 1.4rem;
            width: 120px;
          }
        }
      }

      .readingtest-con {
        .readingtest-wrap {
          &.passage-con {
            font-size: 1.4rem;
          }
          .reading-q-con {
            h1 {
              font-size: 1.2rem;
            }
          }
          .reading-a-con {
            .reading-a-wrap {
              margin: 0.4rem 0;
              align-items: center;
              .reading-a-num {
                width: 60px;
                padding: 0.8rem;
                h3 {
                  font-size: 1.4rem;
                }
              }
              h3 {
                font-size: 1.4rem;
                margin-top: 0;
              }
            }
          }
        }
      }
    }

    .readingtest-con {
      .readingtest-wrap {
        &.passage-con {
          font-size: 1.8rem;
        }
        .reading-q-con {
          h1 {
            font-size: 1.8rem;
          }
        }
        .reading-a-con {
          .reading-a-wrap {
            margin: 1rem 0;
            align-items: center;
            .reading-a-num {
              width: 80px;
              padding: 1rem;
              h3 {
                font-size: 2rem;
              }
            }
            h3 {
              font-size: 2rem;
            }
          }
        }
      }
    }

    &.meta-full-con {
      .btn {
        a {
          bottom: 0rem;
        }
      }

      .ant-tooltip-placement-topRight {
        .ant-tooltip-arrow {
          transform: scale(5.5);
          bottom: -2rem;
        }
      }
      .ant-tooltip-inner {
        font-size: 1rem;
        min-width: 250px;
        text-align: center;
        padding: 1rem;
      }
    }

    .meta-header.test-header {
      width: 100%;
      display: flex;
      background: #e3f6fd;
      margin: 0 auto;
      padding: 1rem 12rem 1rem 11rem;

      h2 {
        font-size: 1.4rem;
      }

      .burger-wrap {
        h2 {
          width: 250px;
        }
      }
      .time-wrap {
        h2 {
          width: 80px;
        }
      }

      .question-wrap {
        width: 250px;
        h2 {
          width: 120px;
        }
      }
    }

    .readingtest-con {
      .readingtest-wrap.passage-con {
        font-size: 1.4rem;
      }

      .readingtest-wrap {
        .reading-q-con {
          padding: 1rem 1rem;

          h1 {
            font-size: 1.2rem;
          }
        }

        .reading-a-con {
          .reading-a-wrap {
            align-items: center;
            margin: 0.5rem 0;
            .reading-a-num {
              width: 60px;
              padding: 1rem 1rem;
              h3 {
                font-size: 1.4rem;
              }
            }

            .reading-a {
              h3 {
                font-size: 1rem;
              }
            }
          }
        }
      }
    }

    .meta-header.onlyTxt-header {
      h1.main-tit {
        font-size: 2.5rem;
      }
    }
    .report-footer-con {
      font-size: 1rem;
    }
  }
}

@media screen and (device-width: 1920px) and (max-width: 1920px) and (aspect-ratio: 16/10) {
}

@media screen and (device-width: 1680px) and (max-width: 1680px) and (aspect-ratio: 16 / 10) {
  .meta-con {
    .inner-header.login-header {
      position: relative;
      margin-bottom: 2rem;
      .login-top-logo {
        width: 100%;
        max-width: 350px;
      }
    }

    .metalogin-wrap {
      width: 60rem;
      height: 30rem;
      margin: 0rem 0 3rem;
    }

    .meta-header.test-header {
      padding: 1rem 8rem 1rem 9rem;
    }

    .confirm_btn.start_btn {
      width: 15%;
    }
  }
}

@media screen and (device-width: 1440px) and (max-width: 1440px) and (aspect-ratio: 16 / 10) {
  .meta-con {
    .meta-header.test-header {
      padding: 1rem 8rem 1rem 9rem;
    }

    .metalogin-wrap {
      width: 40rem;
      height: 25rem;
    }

    &.register-con {
      #student-register {
        width: auto;

        .ant-input-search.plainInput {
          width: 50%;
          input {
            font-size: 1.1rem;
          }
        }

        tbody {
          tr {
            td {
              .ant-form-item {
                margin-right: 0.5rem;
                margin-bottom: 1rem;
              }
            }
          }
        }
      }
    }

    .exam-type-con {
      width: 1000px;
      .test-wrap {
        margin: 0;
      }
    }

    .sample-q-con {
      max-width: 1100px;
      .readingtest-con {
        .readingtest-wrap {
          .reading-q-con {
            padding: 1rem 1rem;
          }
          .reading-a-con {
            .reading-a-wrap {
              margin: 0.4rem 0;
              .reading-a-num {
                h3 {
                  font-size: 1.2rem;
                }
              }
              .reading-a {
                h3 {
                  font-size: 1.2rem;
                }
              }
            }
          }
        }
      }
    }

    .meta-header.test-header {
      .burger-wrap {
        img {
          width: 10%;
        }
      }
    }

    .readingtest-con {
      .readingtest-wrap {
        .reading-a-con {
          .reading-a-wrap {
            margin: 0.5rem 0;
            .reading-a-num {
              h3 {
              }
            }
            .reading-a {
              h3 {
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (device-width: 1440px) and (max-width: 1400px) and (aspect-ratio: 16/9) {
  .meta-con {
    .inner-header {
      top: 1rem;
      width: 100%;
      max-width: 1700px;

      h2 {
        font-size: 2.6rem;
      }
    }

    .inner-header.login-header {
      margin: 1rem 0 0;
      h2 {
        font-size: 3rem;
      }
    }

    h1.main-tit {
      font-size: 2.5rem;
      margin-top: 2rem;
    }

    .metalogin-wrap {
      height: 100%;
      max-height: 450px;
      max-width: 1300px;
      width: 60%;

      padding: 2rem 1rem;
      margin: 2rem 0 5rem;

      h1 {
        font-size: 3rem;
        margin: 1rem auto 2rem;
      }

      .ant-form-item {
        width: 100%;
        max-width: 600px;
      }

      .ant-form-item-label {
        width: 210px;
        text-align: left;
        max-width: 100%;
      }

      .ant-form-item-label > label {
        margin-top: 0.5rem;
        font-size: 1.5rem;
      }

      .ant-form-item-control-input {
        width: 100%;
        max-width: 100%;
      }
      .ant-form-item-control-input-content {
        flex: none;

        input.partInput {
          font-size: 1.5rem;
        }
      }

      .btn {
        width: 100%;
        max-width: 250px;
        height: 50%;
        max-height: 70px;
        margin: 3rem auto 0;
        a {
          font-size: 2rem;
          position: relative;
          top: 0rem;
        }
      }
    }

    /* userinfo */
    .userInfo-wrap {
      max-width: 1600px;
      margin: 1rem auto 0;
    }
    #student-register {
      max-width: 1700px;
      height: 100%;
      margin: 1rem auto;

      .ant-form-item-label > label {
        font-size: 1.5rem;
      }

      .common-form {
        margin: 0.5rem auto;
      }

      .plain-form {
        width: 100%;
        max-width: 1800px;

        .ant-col-sm-8 {
          max-width: 200px;
        }

        .ant-col-sm-16 {
          max-width: 73%;
        }

        .ant-form-item {
          max-width: 1350px;
          align-items: center;
        }

        .ant-form-item-control-input-content {
          width: 100%;
          max-width: 1500px;
        }
      }

      input.partInput {
        width: 150px;
        margin: 0 7px 0 0rem;
        max-width: 100%;
        font-size: 1.5rem;
      }

      .partInput_wrap {
        width: 100%;
        max-width: 100%;
        display: flex;
      }

      .divided-form {
        max-width: 1100px;
        width: 100%;
        justify-content: space-around;

        .ant-form-item {
          width: 100%;
          max-width: 550px;
        }

        .ant-form-item-label > label {
          font-size: 1.5rem;
        }

        .ant-col-sm-16 {
          width: 100%;
          max-width: 400px;
          max-width: 73%;
        }
      }

      input.plainInput {
        margin-top: -0.5rem;
        font-size: 1.5rem;
        width: 100%;
      }

      .ex-span {
        max-width: 200px;
        font-size: 1.3rem;
        position: relative;
      }

      .ant-picker {
        width: 100%;
        max-width: 400px;
        font-size: 2rem;
      }

      .ant-picker-input > input {
        font-size: 1.3rem;
      }
    }

    .btn {
      width: 100%;
      max-width: 200px;
      height: 100%;
      max-height: 70px;
      a {
        font-size: 1.8rem;
      }

      &.fixed_btn {
        bottom: 10rem;
        display: flex;
        align-items: center;
      }

      &.fixed_btm_btn {
        bottom: 10rem;
      }
    }

    /*survey*/
    .survey-wrap {
      height: 50%;
      max-height: 600px;
      margin-top: 1.5rem;

      width: 100%;
      max-width: 1150px;
      h2.survey-q {
        font-size: 1.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 600;
        span.circle-num {
          width: 60px;
          height: 60px;
          padding: 1rem 1rem;
        }
      }

      .survey-sub-q {
        margin-left: 4.8rem;

        h2 {
          font-size: 1.2rem;
          width: 87%;
        }

        .partInput.survey-a {
          width: 10%;
          margin: 0 0.5rem;
          max-width: 200px;
          text-align: center;
          font-size: 1.5rem;

          & + span {
            width: 10%;
            align-items: center;
            align-self: center;
            font-size: 1.5rem;
            max-width: 50px;
          }
        }
      }

      .survey-a {
        .ant-radio-wrapper {
          margin: 0 0px;
          align-items: center;
          justify-content: center;
        }
        span.ant-radio {
          & + * {
            font-size: 1.3rem;
            padding: 10px 20px;
          }
        }

        .ant-radio-inner {
          transform: scale(2);
        }
      }

      &.single-survey {
        height: 40%;
        max-height: 250px;
        margin-top: -10rem;
      }
    }

    /* metaexam type */
    .meta-header.type-header {
      width: 100%;
      max-width: 1200px;
      margin: 0 auto;
      h2 {
        font-size: 1.6rem;
        margin-top: 1rem;
        position: relative;
        top: 2rem;
      }
    }
    .exam-type-con {
      .test-wrap {
        margin: 0 2.5rem;
        a {
          img {
            transform: scale(1);
          }
        }

        h3 {
          font-size: 2.5rem;
          margin: 1rem 0 1rem;
        }

        .meta-chkImg {
          width: 50px;
          margin: 0 auto;
        }

        &.no-chk {
          margin: 0rem 2.5rem 3rem;
        }
      }
    }
    /* sample question */
    .sample-q-con {
      height: calc(100vh - 20rem);
      max-height: 100%;
      width: calc(90% - 3rem);
      max-width: 1500px;

      .process-btn-con {
        width: 100%;
        display: flex;
        margin: 1rem 0 5.5rem;
      }

      .meta-header.test-header {
        .burger-wrap {
          img {
            width: 10%;
            margin-right: 5px;
          }
          h2 {
            font-size: 1.5rem;
          }
        }
      }

      .meta-header.readingTest-header h1.main-tit {
        font-size: 1.6rem;
      }
      .readingtest-con {
        .readingtest-wrap {
          .reading-a-con {
            .reading-a-wrap {
              max-height: 40px;
              height: 100%;
              align-items: center;
              .reading-a-num {
                width: 50px;
                padding: 0.5rem;
                h3 {
                  font-size: 1.3rem;
                }
              }
              .reading-a {
                h3 {
                  font-size: 1rem;
                }
              }
            }
          }
        }
      }
    }
    .btn.confirm_btn {
      margin: 1rem auto 0;
      align-items: center;
      max-height: 50px;
      a {
        font-size: 1.5rem;
      }
    }
    /* test sheet - readingtest.js */
    .meta-header.test-header {
      margin: 0 auto;
      padding: 0 5rem;
      .burger-wrap {
        img {
          width: 10%;
          margin-right: 10px;
        }
        h2 {
          width: 300px;
          font-size: 2rem;
        }
      }
    }

    .meta-header.readingTest-header {
      h1.main-tit {
        font-size: 1.5rem;
        margin-top: 0rem;
      }
    }

    .readingtest-con {
      .readingtest-wrap.passage-con {
        font-size: 1.3rem;
        padding: 1.5rem;
      }

      .readingtest-wrap {
        .reading-q-con {
          padding: 1rem 1rem;
          h1 {
            font-size: 1.3rem;
          }
        }

        .reading-a-con {
          .reading-a-wrap {
            height: 40px;
            max-height: 100%;
            align-items: center;
            margin: 0.8rem 0;
            .reading-a {
              display: flex;
              align-items: center;
              h3 {
                margin-top: 0;
                font-size: 1.3rem;
              }
            }

            .reading-a-num {
              padding: 0.3rem;
              width: 70px;
              h3 {
                font-size: 1.5rem;
              }

              .checkedImg {
                width: 80%;
                max-width: 80px;
              }
            }
          }
        }
      }
    }
    .ant-tooltip-inner {
      font-size: 1rem;
    }

    .btn.confirm_btn {
      margin: 1rem auto 0;
      align-items: center;
      max-height: 70px;
      a {
        font-size: 1.5rem;
      }
    }
    /*metacon finished */
  }
}

/**/

@media screen and (max-width: 1336px) and (orientation: landscape) {
  .meta-con {
    &.guide-con {
      .meta-header {
        &.type-header {
          max-width: 100%;
          width: 1000px;
        }
      }
      .arrow-wrap {
        width: calc(55% - 11vw);
      }

      .exam-type-con {
        width: auto;
      }
    }

    #student-register {
      tbody {
        tr {
          &:nth-child(3) {
            td {
              &:nth-child(2) {
                .ant-form-item-control {
                  .plainInput.gradeInput {
                    width: 40%;
                  }
                }
              }
            }
          }
          td {
            .ant-form-item .ant-select,
            .ant-form-item .ant-cascader-picker {
              width: auto;
            }

            .ant-form-item {
              .ant-select {
                &.gradeInput {
                  label {
                    position: absolute;
                    bottom: -3rem;
                    width: 100%;
                    left: 0;
                  }
                }
              }
            }

            .ant-form-item-control {
              .ant-form-item-control-input {
                .ant-form-item-control-input-content {
                  .ant-select {
                    label {
                      position: absolute;
                      bottom: -3rem;
                      width: 100%;
                      left: 0;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .survey-wrap {
      .survey-sub-q {
        .survey-a {
          width: 80%;
          span.ant-radio {
            & + * {
              font-size: 0.9rem;
            }
          }
        }

        .partInput.survey-a {
          & + span {
            font-size: 0.9rem;
          }
        }
      }
    }

    .report-footer-con {
      margin: 1rem auto 0;
    }
  }
}

@media screen and (device-width: 1500px) and (min-width: 1500px) {
  .meta-con {
    .metalogin-wrap {
      .ant-form-item-control-input {
        .partInput {
          width: 40%;
        }
      }
    }
  }
}

@media screen and (max-width: 1366px) {
  .meta-con {
    .inner-header.login-header {
      margin-top: 1rem;
      margin-bottom: 2rem;
      .login-top-logo {
        max-width: 300px;
        margin-bottom: 0;
      }
    }

    .metalogin-wrap {
      width: 45rem;
      margin: 0rem 0 2rem;
    }

    &.register-con {
      .inner-header {
        h2 {
          font-size: 1.2rem;
        }
      }
      #student-register {
        display: flex;
        flex-direction: row;
        margin: 0 auto 3rem;

        input.plainInput {
          font-size: 1rem;
          max-width: 100%;
          width: 120px;
        }

        input.partInput {
          font-size: 1rem;
          &:nth-child(1) {
            width: 90px;
          }

          &:nth-child(2),
          &:nth-child(3) {
            width: 70px;
          }
        }

        .ant-picker {
          font-size: 1rem;
        }

        .ant-picker-input > input {
          font-size: 1rem;
        }

        .ant-form-item-control-input-content {
          width: 250px;

          .ant-form-item .ant-select,
          .ant-form-item .ant-cascader-picker {
            width: 230px;
          }
        }

        .ant-form-item-label > label {
          font-size: 1rem;
        }

        tbody {
          display: flex;
          margin: 0 auto;
          flex-direction: column;
          align-items: inherit;
          justify-content: center;
          tr {
            &:nth-child(1) {
              td {
                &:nth-child(2) {
                  .ant-form-item-control {
                    .ant-form-item-control-input-content {
                      input.partInput {
                        &:nth-child(1) {
                          width: 120px;
                        }
                      }
                    }
                  }
                }
              }
            }
            td {
              display: block;
              width: 400px;
              //   width: 100%;

              &:nth-child(2) {
                margin-left: 0;
              }

              .ant-form-item {
                margin-right: 0;
                margin-bottom: 1rem;
              }

              .ant-form-item-label {
                width: 100%;
                text-align: left;
                margin-bottom: 0.5rem;
              }
            }
          }
        }
      }
    }

    &.register-con {
      height: auto;
      margin: 3rem auto;
      /* survey */
      .survey-wrap {
        margin: 0.5rem auto;
        width: 100%;
        max-width: 1150px;

        div {
          display: block;
        }

        &.single-survey {
          display: block;
        }
        h2.survey-q {
          font-size: 1rem;
          justify-content: start;
          align-items: center;
          font-weight: 600;
          span.circle-num {
            width: 40px;
            height: 40px;
            padding: 0.5rem 1rem;
          }
        }

        .survey-sub-q {
          margin-left: 3.3rem;
          flex-direction: column;
          h2 {
            font-size: 0.9rem;
            width: auto;
            margin-left: 0;
          }

          .survey-a {
            width: 100%;
            margin-left: 0;
          }

          .partInput.survey-a {
            width: 45px;
            margin: 0 0.5rem;
            max-width: 200px;
            text-align: center;
            font-size: 1rem;

            & + span {
              align-items: center;
              align-self: center;
              font-size: 1rem;
              max-width: 50px;
            }
          }

          &:nth-child(3) {
            .survey-a {
              display: flex;
              margin-right: 0;
            }
          }
        }

        .survey-a {
          width: 100%;
          margin-left: 3.3rem;
          .ant-radio-wrapper {
            margin: 0 0px;
            align-items: center;
            justify-content: center;
          }
          span.ant-radio {
            & + * {
              font-size: 0.9rem;
            }
          }

          .ant-radio-inner {
            transform: scale(1.3);
          }
        }
      }
    }
  }
}

@media screen and (device-width: 1366px) and (max-width: 1366px) {
  .meta-con {
    .report-footer-con {
      margin: 1rem 0;
      left: 11%;
      width: 80%;
    }

    h1.main-tit {
      font-size: 2.4rem;
    }

    .userInfo-wrap {
      margin: 0 auto;
    }

    #student-register {
      margin: 2rem auto;
      .common-form {
        margin: 0.5rem auto;
      }

      .divided-form:nth-child(4) {
        left: 0.5rem;
      }
    }

    .survey-wrap {
      h2.survey-q {
        font-size: 1.2rem;
        span.circle-num {
          width: 40px;
          height: 40px;
        }
      }

      .survey-sub-q {
        .survey-a {
          span.ant-radio + * {
            font-size: 0.9rem;
          }
        }
      }
    }

    #student-register {
      width: 100%;
      max-width: 1000px;
      display: flex;

      tbody {
        tr {
          td {
            &:nth-child(2) {
              margin-left: 1rem;
            }

            .ant-form-item {
              align-items: center;
              margin-bottom: 2rem;
              margin-right: 2rem;
            }
          }

          &:nth-child(1) {
            td:nth-child(2) {
              .ant-form-item-control {
                .ant-form-item-control-input-content {
                  input.partInput {
                    &:nth-child(1) {
                      width: 110px;
                    }
                    &:nth-child(2) {
                      width: 150px;
                    }
                  }
                }
              }
            }
          }

          &:nth-child(2) {
            td:nth-child(2) {
              .ant-form-item-control {
                .ant-form-item-control-input-content {
                  input.partInput {
                    &:nth-child(1) {
                      width: 60px;
                    }
                  }
                }
              }
            }
          }

          &:nth-child(3) {
            td:nth-child(2) {
              .ant-form-item-control {
                .plainInput.gradeInput {
                  width: 40%;
                }
              }
            }
          }
        }
      }

      .ant-form-item-label > label {
        font-size: 1rem;
        font-weight: 600;
      }

      .ant-form-item-label > label::before {
        content: '●';
        font-size: 1.5rem;
        color: #1462a1;
        margin: 0 0.2rem 3px 0.2rem;
      }

      .divided-form {
        width: 100%;
        max-width: 1100px;
        display: flex;
        flex-direction: row;

        &:nth-child(4) {
          width: 500px;
          left: 1rem;
          position: relative;
        }

        .ant-col-xs-24 {
          margin-left: 3px;
        }

        .ant-form-item {
          /*
          width: 45%;
          max-width: 600px;
          margin: 1rem 0.5rem;*/

          width: 100%;
          max-width: 600px;
          margin: 1rem 0.5rem;
        }

        .common-form.threeInput-form {
          input.partInput {
            width: 23%;
          }
        }

        .ant-form-item-label > label {
          font-size: 1.2rem;
        }

        .ant-col-sm-16 {
          width: 100%;
        }

        input.plainInput {
          width: 55%;
          text-align: center;
        }

        &:nth-child(3) {
          .common-form {
            &:nth-child(2) {
              .ant-form-item {
                position: relative;
                left: -5rem;
              }
            }
          }
        }
      }

      .threeInput-form {
        input.partInput {
          width: 20rem;
        }
      }

      .common-form {
        margin: 1rem auto;
        width: 600px;
      }
      .plain-form {
        /*
        width: 64%;
        max-width: 800px;*/
        width: 90%;
        max-width: 1100px;
        display: flex;
        /* justify-content: center; */

        .ant-form-item {
          max-width: 500px;
          width: 100%;
        }

        .ant-row .ant-form-item {
        }

        .ant-col-sm-8 {
          flex: none;
          width: 100%;
          max-width: 165px;
          text-align: left;
        }

        .ant-form-horizontal .ant-form-item-control {
          margin-left: 24px;
        }

        .ant-form-item-control-input-content {
          width: 100%;
          max-width: 500px;
        }
      }

      .partInput_wrap {
        margin-left: 0rem;
        width: 202px;
        max-width: 100%;
      }

      .ex-span {
        position: absolute;
        width: 100%;
        max-width: 120px;
        margin-left: 10px;
        margin-top: 5px;
      }

      input.plainInput {
        width: 50%;
        max-width: 400px;
        text-align: center;
      }

      input.partInput {
        /* width: 50px;
        margin: 0 1rem 0 0rem;*/
        text-align: center;

        width: 120px;
        margin: 0 7px 0 0rem;

        &:nth-child(1) {
          width: 90px;
        }

        &:nth-child(2),
        &:nth-child(3) {
          width: 80px;
        }
      }

      .btn {
        margin: 3rem auto;
        background: #c6edfe;
        color: #1462a1;
        border-radius: 30px;
        width: 150px;
        max-width: 100%;
        text-align: center;
        padding: 1rem;
        cursor: pointer;

        position: absolute;
        bottom: -1.5rem;
        left: 42rem;
      }

      .btn a {
        color: #1462a1;
        text-decoration: none;
        font-weight: 600;
        font-size: 1.3rem;
        text-align: center;
        margin: 0 auto;
      }
    }

    .start_btn {
      margin: 0.5rem auto;
    }

    /* metaexamtype */
    .exam-type-con {
      .test-wrap {
        margin: 0 2rem;
      }
    }

    &.meta-full-con {
      .report-footer-con {
        left: 0;
      }

      .fixed_btm_btn {
        bottom: 0;
      }
    }

    .meta-header.test-header {
      .time-wrap {
        h2 {
          width: 80px;
          margin-bottom: 0;
        }
      }
      .question-wrap {
        h2 {
          width: 95px;
          margin-bottom: 0;
        }
      }

      .burger-wrap {
        h2 {
          width: 150px;
        }
      }

      h2 {
        font-size: 1.2rem;

        &.num {
          width: 95px;
          margin: 0;
        }
      }

      div.time-wrap {
        margin-right: 1rem;
      }
    }

    .readingtest-con {
      .readingtest-wrap {
        .reading-a-con {
          .reading-a-wrap {
            margin: 0.9rem 0;
          }
        }
      }
    }
  }

  .meta-con .meta-header.test-header {
    padding: 1rem 8rem 1rem 9rem;
  }

  /* report */
  .meta-report-con {
    .userInfo-con {
      .userInfo.left {
        width: 32%;
        h2 {
        }
      }

      .userInfo.right {
        width: 25%;
      }
    }
  }

  .meta-con .sample-q-con {
    max-width: 1000px;
    margin: 0 auto;

    .readingtest-con {
      .readingtest-wrap {
        .reading-a-con {
          .reading-a-wrap {
            margin: 0.3rem 0;
          }
        }
      }
    }
  }
}

@media screen and (device-width: 1300px) and (max-width: 1300px) and (aspect-ratio: 16/9) {
  .meta-con {
    .inner-header {
      top: 1rem;
      width: 100%;
      max-width: 1700px;

      h2 {
        font-size: 2.6rem;
      }
    }

    .inner-header.login-header {
      margin: 1rem 0 0;
      h2 {
        font-size: 2.6rem;
      }
    }

    h1.main-tit {
      font-size: 2.5rem;
      margin-top: 2rem;
    }

    .metalogin-wrap {
      height: 100%;
      max-height: 400px;
      max-width: 700px;
      width: 100%;

      padding: 2rem 1rem;
      margin: 0rem 0 1rem;

      h1 {
        font-size: 2.3rem;
        margin: 0rem auto 1.5rem;
      }

      .ant-form-item {
        width: 100%;
        max-width: 530px;
      }

      .ant-form-item-label {
        width: 180px;
        text-align: left;
        max-width: 100%;
      }

      .ant-form-item-label > label {
        margin-top: 0.5rem;
        font-size: 1.3rem;
      }

      .ant-form-item-control-input {
        width: 100%;
        max-width: 100%;
      }
      .ant-form-item-control-input-content {
        flex: none;

        input.partInput {
          font-size: 1.5rem;
        }
      }

      .btn {
        width: 100%;
        max-width: 250px;
        height: 50%;
        max-height: 60px;
        margin: 1rem auto 0;
        a {
          font-size: 1.5rem;
          position: relative;
          top: 0rem;
        }
      }
    }

    /* userinfo */
    .userInfo-wrap {
      max-width: 1600px;
      margin: 1rem auto 0;
    }
    #student-register {
      max-width: 1700px;
      height: 100%;
      margin: 1rem auto;

      .ant-form-item-label > label {
        font-size: 1.3rem;
      }

      .common-form {
        margin: 0.2rem auto;
      }

      .plain-form {
        width: 100%;
        max-width: 1800px;

        .ant-col-sm-8 {
          max-width: 200px;
        }

        .ant-col-sm-16 {
          max-width: 73%;
        }

        .ant-form-item {
          max-width: 1350px;
          align-items: center;
        }

        .ant-form-item-control-input-content {
          width: 100%;
          max-width: 1500px;
        }
      }

      input.partInput {
        width: 150px;
        margin: 0 7px 0 0rem;
        max-width: 100%;
        font-size: 1.5rem;
      }

      .partInput_wrap {
        width: 100%;
        max-width: 100%;
        display: flex;
      }

      .divided-form {
        max-width: 1100px;
        width: 100%;
        justify-content: space-around;

        .ant-form-item {
          width: 100%;
          max-width: 550px;
        }

        .ant-form-item-label > label {
          font-size: 1.3rem;
        }

        .ant-col-sm-16 {
          width: 100%;
          max-width: 240px;
        }
      }

      input.plainInput {
        margin-top: -0.5rem;
        font-size: 1.25rem;
        width: 100%;
      }

      .ex-span {
        max-width: 200px;
        font-size: 1rem;
        position: relative;
      }

      .ant-picker {
        width: 100%;
        max-width: 200px;
        font-size: 1rem;
      }

      .ant-picker-input > input {
        font-size: 1.3rem;
      }

      .ant-input-search.plainInput {
        width: 55%;
      }
    }

    .btn {
      width: 100%;
      max-width: 150px;
      height: 100%;
      max-height: 55px;
      a {
        font-size: 1.5rem;
      }

      &.fixed_btn {
        bottom: 1rem;
        display: flex;
        align-items: center;
      }
    }

    /*survey*/
    .survey-wrap {
      height: 50%;
      max-height: 600px;
      margin-top: 1.5rem;

      width: 100%;
      max-width: 1150px;
      h2.survey-q {
        font-size: 1.3rem;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 600;
        span.circle-num {
          width: 50px;
          height: 50px;
          padding: 0.8rem;
        }
      }

      .survey-sub-q {
        margin-left: 4.8rem;
        margin-top: 0.5rem;
        margin-bottom: 0.3rem;
        h2 {
          font-size: 1.2rem;
          width: 82%;
        }

        .partInput.survey-a {
          width: 10%;
          margin: 0 0rem;
          max-width: 200px;
          text-align: center;
          font-size: 1.2rem;
          padding: 0;
          height: 40px;

          & + span {
            width: 10%;
            align-items: center;
            align-self: center;
            font-size: 1.1rem;
            max-width: 50px;
          }
        }
      }

      .survey-a {
        .ant-radio-wrapper {
          margin: 0 5px;
          align-items: center;
          justify-content: center;
        }
        span.ant-radio {
          & + * {
            font-size: 1rem;
            padding: 10px 10px;
          }
        }

        .ant-radio-inner {
          transform: scale(1.3);
        }
      }

      &.single-survey {
        height: 45%;
        max-height: 250px;
        margin-top: 0;
      }
    }

    /* metaexam type */
    .meta-header.type-header {
      width: 100%;
      max-width: 850px;
      margin: 0 auto;
      h2 {
        font-size: 1.3rem;
        margin-top: 1rem;
        position: relative;
        top: 0rem;
      }
    }
    .exam-type-con {
      .test-wrap {
        margin: 0 1rem;
        a {
          img {
            transform: scale(1);
          }
        }

        h3 {
          font-size: 1.5rem;
          margin: 1rem 0 1rem;
        }

        .meta-chkImg {
          width: 35px;
          margin: 0 auto;
        }

        &.no-chk {
          margin: 0rem 1rem 2rem;
        }
      }
    }

    /* */
    .meta-header.onlyTxt-header {
      font-size: 2rem;
      margin-top: -1rem;
    }
    .finished-con {
      h1 {
        margin: -3rem 0 2rem;
      }

      h3 {
        font-size: 1.3rem;
      }
    }

    /* sample question */
    .sample-q-con {
      height: calc(100vh - 18rem);
      max-height: 100%;
      width: calc(90% - 3rem);
      max-width: 1500px;

      .meta-header.test-header {
        .burger-wrap {
          img {
            width: 8%;
            margin-right: 10px;
            margin-top: 0;
          }
          h2 {
            font-size: 1rem;
          }
        }
      }

      .meta-header.test-header div.time-wrap {
        padding-left: 2rem;
      }

      .meta-header.test-header div.question-wrap {
        padding: 0.5rem;
      }
      .meta-header.test-header {
        padding: 0rem 5rem;
        height: calc(10vh - 2rem);
      }
      .meta-header.readingTest-header {
        margin: 4rem auto 0rem;
        h1.main-tit {
          font-size: 1rem;
        }
      }
      .readingtest-con {
        .readingtest-wrap {
          .reading-q-con {
            margin: 0;
            padding: 1rem 1rem;
            h1 {
              font-size: 0.8rem;
            }
          }
          .reading-a-con {
            .reading-a-wrap {
              max-height: 30px;
              height: 100%;
              align-items: center;
              .reading-a-num {
                width: 30px;
                padding: 0rem;
                h3 {
                  font-size: 0.7rem;
                }
              }
              .reading-a {
                h3 {
                  font-size: 1rem;
                }
              }
            }
          }

          &.passage-con {
            margin: 0rem 3rem 1rem 1rem;
          }
        }
      }

      .process-btn-con {
        img {
          width: 100%;
          max-width: 70px;
        }
      }
    }
    .btn.confirm_btn.start_btn {
      margin: 0.5rem auto 0;
      align-items: center;
      max-height: 45px;
      a {
        font-size: 1.5rem;
      }
    }

    /* test sheet - readingtest.js */
    .meta-header.test-header {
      margin: 0 auto;
      padding: 0 5rem;
      .burger-wrap {
        img {
          width: 8%;
          margin-right: 10px;
          margin-top: 0;
        }
        h2 {
          width: 300px;
          font-size: 1.3rem;
        }
      }
      h2 {
        font-size: 1.1rem;
        width: 75px;
        padding: 0.2rem 0;
        margin: 0.5rem 0rem 0.5rem -1rem;
      }
      h2.num {
        padding: 0.2rem 0;
        margin: 0.5rem 0rem 0.5rem -1rem;
      }

      .question-wrap {
        h2 {
          width: 90px;
          margin: 0 0.5rem 0 1rem;
        }
      }

      .time-wrap {
        h2.num {
          width: 75px;
          padding: 0.2rem 0;
          margin: 0rem 0rem 0rem -1rem;
        }
      }

      .question-wrap h2 {
        width: 60px;
        margin: 0 0.7rem 0 1.3rem;
      }
    }

    .meta-header.readingTest-header {
      h1.main-tit {
        font-size: 1.2rem;
        margin-top: 1rem;
      }
    }

    .readingtest-con {
      height: calc(100vh - 20rem);
      .readingtest-wrap.passage-con {
        font-size: 1.1rem;
        padding: 1.2rem;
      }

      .readingtest-wrap {
        .reading-q-con {
          padding: 1rem 1rem;
          h1 {
            font-size: 1.1rem;
          }
        }

        .reading-a-con {
          .reading-a-wrap {
            height: 40px;
            max-height: 100%;
            align-items: center;
            margin: 0.35rem 0;
            .reading-a {
              display: flex;
              align-items: center;
              h3 {
                margin-top: 0;
                font-size: 1rem;
              }
            }

            .reading-a-num {
              padding: 0.3rem;
              width: 50px;
              h3 {
                font-size: 1.2rem;
              }

              .checkedImg {
                width: 80%;
                max-width: 80px;
              }
            }
          }
        }
      }
    }

    .process-btn-con {
      margin: 0 1rem 1rem 1rem;
      width: calc(100% - 3rem);
      img {
        max-width: 90px;
      }
    }
    .ant-tooltip-inner {
      font-size: 1rem;
    }

    .btn.confirm_btn {
      margin: 1rem auto 0;
      align-items: center;
      max-height: 70px;
      a {
        font-size: 1.5rem;
      }
    }
    /*metacon finished */
  }

  /* MetaReport.js */
  .meta-report-con {
    width: 100%;
    height: 100%;

    .lexile-logo-con {
      background: #c0e5fc;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 3rem 0;
      margin-bottom: 2rem;

      img {
        width: 100%;
        max-width: 550px;
      }
    }

    .userInfo-con {
      width: 100%;
      display: flex;
      .userInfo {
        display: inline-flex;
        flex-direction: column;
        &.left {
          margin-right: auto;
          width: 40%;
          display: flex;
          flex-direction: row;
          align-items: end;
          margin-bottom: 1rem;
          h2 {
            margin: 0 1rem 0 1rem;
            color: #2e76b3;
            font-size: 1rem;
          }
        }

        &.right {
          margin-left: auto;
          width: 20%;
          justify-content: end;
          font-size: 1rem;

          .ant-row {
            .ant-col {
              border: 1px solid #b3dbfc;
              padding: 0.3rem;
              text-align: center;
              background: #fff;
              color: #2e76b3;
            }

            .ant-col-4 {
            }
          }

          .user-table {
            display: table;
            div {
              display: table-cell;
            }
          }
        }
      }
    }

    .report-top {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin: 0.5rem 0 3rem;
      background: #fff;
      padding: 5rem 1rem;
      border: 1px solid #b3dbfc;
      .part-con {
        width: 40%;
        display: flex;
        justify-content: center;

        &:nth-child(1) {
          flex-direction: column;
          position: relative;
          left: 5rem;
          top: -1rem;
          transform: translate(10px, 10px);
          text-align: center;

          img {
            width: 85%;
            position: relative;
            top: -7.5rem;
            left: -0.5rem;
          }

          h1 {
            font-size: 2.5rem;
            font-weight: 700;
            position: relative;
            top: -0.5rem;
            left: 0rem;
            margin-bottom: 0;
          }

          h4 {
            font-size: 1.3rem;
            margin-top: 1rem;
            position: relative;
            left: 0;
            color: #2e76b3;
          }
        }

        &:nth-child(2) {
          div.donut-wrap {
            position: relative;
            left: -2rem;
            div {
              position: absolute;
              top: 55.5%;
              left: 52%;
              transform: translate(-50%, -50%);
              h3 {
                font-size: 1.2rem;
                color: #2d75b3;
                margin-bottom: 0;
              }

              h2 {
                font-size: 3rem;
                font-weight: 700;
              }
            }
          }
        }
        .ant-progress {
          width: 300px !important;
          height: 300px !important;
          .ant-progress-inner {
            width: 100% !important;
            height: 100% !important;
          }
        }
      }
      .ant-table-content {
        table {
          table-layout: fixed !important;
          text-align: center;
        }
      }
    }
    /* report top end*/
    .report-middle-new {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 2rem 0rem 0rem;

      .card-con {
        display: flex;
        flex-direction: row;
        width: 100%;
        margin: 1rem 0;
        border: 1px solid #ddd;

        &:nth-child(1) {
          background: #fcbf15;
        }
        &:nth-child(2) {
          background: #09a5e2;
        }
        &:nth-child(3) {
          background: #90ce4f;
        }
        &:nth-child(4) {
          background: #cf88d8;
        }

        .bg {
          padding: 1rem;
          margin: 1rem 0;
          width: 40%;
          flex-direction: row;
          display: flex;
          justify-content: center;

          div.subject-con {
            flex-direction: row;
            display: flex;
            background: #fff;
            justify-content: space-between;
            align-items: center;
            border-radius: 20px;
            width: 80%;
            padding: 1rem 3rem;
            h3 {
              font-size: 1.5rem;
              margin-bottom: 0;
            }
          }
        }

        div.progress-con {
          background: #fff;
          width: 60%;
          flex-direction: column;
          display: flex;
          margin-left: 1rem;
          padding: 1rem 2rem;

          .progress-wrap {
            flex-direction: row;
            display: flex;
            align-items: center;

            span {
              padding: 0.5rem 0.3rem;
              width: 20%;
              font-weight: 600;
              max-width: 300px;
              font-size: 1.1rem;
            }

            &.ur-score {
              color: #2d75b3;
            }
            &.avg-score {
              color: #b68a17;
            }
            &.avg-dev {
              color: #759b5d;
            }

            .ant-progress-inner {
              border-radius: 0;
              .ant-progress-bg {
                border-radius: 0;
              }
            }
          }
        }
      }
    }

    .bookUrl-con {
      padding: 2rem 0rem;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 2rem auto;

      div {
        border: 1px solid #b3dbfc;
        padding: 2rem 2rem 2rem;
        background: #fff;
        width: 100%;
        max-width: 2020px;
        display: flex;
        flex-direction: column;
        margin: 0 auto;
      }

      * {
        margin-left: auto;
      }

      img {
        margin: 0rem 3rem 0rem auto;
        max-width: 200px;
        width: 100%;
      }

      a {
        margin-top: 0rem;
      }
    }

    .result-table-con {
      .ant-table {
        width: 100%;
        max-width: 2020px;
        border: 1px solid #b3dbfc;
      }
      .ant-table-thead > tr > th,
      .ant-table-tbody > tr > td {
        text-align: center;
      }

      .ant-table-thead > tr > th {
        background: #67b7fb;
        color: #fff;
        font-size: 1.1rem;
      }

      .ant-table-tbody > tr > td {
        font-weight: 600;
        font-size: 1rem;
      }

      .ant-table-tbody > tr > td:nth-child(1),
      .ant-table-tbody > tr > td:nth-child(6) {
        color: #2e76b3;
      }

      .ant-table-tbody > tr > td:nth-child(2),
      .ant-table-tbody > tr > td:nth-child(3),
      .ant-table-tbody > tr > td:nth-child(4) {
        color: #528233;
      }

      .ant-table-tbody > tr > td:nth-child(5) {
        color: red;
      }

      .ant-table-thead
        > tr
        > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
        background-color: rgba(255, 255, 255, 0.5);
      }
    }

    .report-footer-con {
      display: flex;
      margin: 1rem auto;
      flex-direction: row;
      width: 100%;
      max-width: 1000px;
      font-size: 1rem;

      h5:nth-child(1) {
        margin-right: auto;
      }
      h5:nth-child(2) {
        color: #2d75b3;
        margin-left: auto;
      }
    }
  }
}

/*

@media screen and (min-width: 1301px) {
  .meta-con {
    &.guide-con {
      .meta-header.test-header {
        top: 0;
        padding: 0rem 6rem 0rem 8rem;
      }
      .readingtest-con {
        .readingtest-wrap {
          .reading-q-con {
            h1 {
              font-size: 1rem;
            }
          }
          .reading-a-con {
            .reading-a-wrap {
              margin: 0.5rem 0;
              .reading-a-num {
                padding: 0.5rem 1rem;
              }
            }
          }
        }
      }
    }
  }
}

*/

@media screen and (device-width: 1300px) and (max-width: 1300px) {
  .meta-con {
    .survey-wrap {
      h2.survey-q {
        font-size: 1.3rem;
        span.circle-num {
          padding: 0.8rem 0.5rem;
        }
      }

      .survey-sub-q {
        margin: 0.5rem 0;
      }

      .survey-a {
        justify-content: right;

        .ant-radio-wrapper {
          align-items: center;
        }
      }
    }

    .survey-wrap.single-survey {
    }

    .confirm_btn.fixed_btn {
      bottom: 6.5rem;
    }

    .meta-header.type-header {
      max-width: 950px;
      margin: 0 auto;
      top: 0;
    }

    .exam-type-con {
      .test-wrap {
        margin: 0 2rem;
      }

      .test-wrap.no-chk {
        margin: 0.3rem 2rem 3rem;
      }
    }

    .fixed_btm_btn {
      bottom: 2rem;
    }

    h1.main-tit {
      font-size: 2rem;
    }

    .sample-q-con {
      max-width: 1000px;
    }

    .sample-q-con .readingtest-con .readingtest-wrap .reading-a-con .reading-a-wrap {
      margin: 0.1rem 0;
    }

    .start_btn {
      margin: 1rem auto;
    }

    .readingtest-con .readingtest-wrap .reading-a-con .reading-a-wrap {
      margin: 0.3rem 0;
    }

    &.guide-con {
      .meta-header.test-header {
        padding: 0rem 6rem 0rem 8rem;
      }
      .readingtest-con {
        .readingtest-wrap {
          .reading-q-con {
            h1 {
              font-size: 1rem;
            }
          }
          .reading-a-con {
            .reading-a-wrap {
              margin: 0.5rem 0;
              .reading-a-num {
                padding: 0.5rem 1rem;
              }
            }
          }
        }
      }
    }
  }

  .meta-report-con {
    .userInfo-con {
      .userInfo.left {
        width: 40%;
      }

      .userInfo.right {
        width: 25%;
      }
    }

    .report-top .part-con:nth-child(1) h1 {
      font-size: 2.6rem;
    }

    .report-top .part-con:nth-child(1) h4 {
      font-size: 1.5rem;
    }

    .report-top .part-con:nth-child(2) img {
      width: 100%;
      max-width: 350px;
    }

    .report-top .part-con:nth-child(2) div.donut-wrap div h3 {
      font-size: 1.3rem;
    }

    .report-top .part-con:nth-child(2) div.donut-wrap div h2 {
      font-size: 3rem;
    }

    .report-middle-new .card-con div.progress-con .progress-wrap span {
      width: 25%;
    }
  }
}

@media screen and (max-width: 1300px) {
  .meta-con {
    &.guide-con {
      .meta-header.test-header {
        top: 0;
        .question-wrap {
          margin-left: 0;
        }
      }
      .readingtest-con {
        .readingtest-wrap {
          .reading-q-con {
            h1 {
              font-size: 1rem;
            }
          }
          .reading-a-con {
            .reading-a-wrap {
              margin: 0.2rem 0;
              .reading-a-num {
                padding: 0.5rem 1rem;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1280px) /*and (max-height: 800px) */ {
  .meta-con.meta-full-con {
    width: 100%;

    .arrow-wrap {
      bottom: 1.5%;
      right: 0;
    }

    .btn {
      &.fixed_btm_btn {
        width: 14rem;
        height: 6rem;
        span {
          font-size: 1.5rem;
        }
      }
    }

    & {
      .btn.confirm_btn.finished_btn {
        width: 25%;
        a {
          bottom: 0;
        }
      }
    }
  }

  .meta-con {
    //  height: 100vh;

    &.register-con {
      width: 95%;
      height: auto;

      .inner-header {
        padding: 0 2rem;
      }

      #student-register tbody tr td .alert-label-noTime {
        bottom: -3rem;
      }
    }

    &.register-con {
      /* survey */
      .survey-wrap {
        margin-top: 1.5rem;
        width: 100%;
        max-width: 1150px;

        div {
          display: block;
        }

        &.single-survey {
          display: block;
        }

        h2.survey-q {
          font-size: 1rem;
          justify-content: start;
          align-items: center;
          font-weight: 600;
          span.circle-num {
            width: 40px;
            height: 40px;
            padding: 0.5rem 1rem;
          }
        }

        .survey-sub-q {
          //  margin-left: 2.8rem;
          flex-direction: column;
          margin-top: 1rem;
          margin-bottom: 0rem;
          h2 {
            font-size: 0.9rem;
            width: auto;
            margin-left: 0;
          }

          .survey-a {
            width: 100%;
            margin-left: 0;
          }

          .partInput.survey-a {
            margin: 0 0.5rem;
            max-width: 200px;
            text-align: center;
            font-size: 1rem;
            height: 35px;

            & + span {
              align-items: center;
              align-self: center;
              font-size: 1rem;
              max-width: 50px;
            }
          }

          &:nth-child(3) {
            .survey-a {
              display: flex;
            }
          }
        }

        .survey-a {
          margin-left: 3.3rem;
          .ant-radio-wrapper {
            margin: 0 0px;
            align-items: center;
            justify-content: center;
          }
          span.ant-radio {
            & + * {
              font-size: 1rem;
            }
          }

          .ant-radio-inner {
            transform: scale(1.3);
          }
        }
      }
    }

    &.guide-con {
      .exam-type-con {
        .test-wrap {
          &.ant-tooltip-open {
            .ant-tooltip-open {
              img {
                position: relative;
                top: -0.7rem;
              }
            }
          }
        }
      }
    }
  }

  .meta-con {
    &.register-con {
      .inner-header {
        h2 {
          font-size: 1.2rem;
        }
      }

      #student-register {
        tbody {
          tr {
            td {
              .ant-form-item-label {
                margin-bottom: 0;
              }
            }
          }
        }
      }
    }

    .inner-header {
      top: 0rem;
      width: 100%;
      max-width: 800px;

      h2 {
        font-size: 1.4rem;
      }
    }

    .inner-header.login-header {
      h2 {
        font-size: 2.2rem;
      }
    }

    .meta-header.onlyTxt-header {
      top: 0rem;
      h1.main-tit {
        font-size: 2.6rem;
        margin-top: 0rem;
      }
    }

    h1.main-tit {
      font-size: 2rem;
      margin-top: 1.5rem;
    }

    .metalogin-wrap {
      padding: 0rem 1rem;
      margin: 2rem 0 4rem;

      h1 {
        font-size: 2rem;
        margin-bottom: 3rem;
      }

      .ant-form-item {
        width: 80%;
        margin-left: auto;
        margin-right: auto;
      }

      .ant-form-item-label {
        text-align: right;
        max-width: 100%;
        width: 120px;
      }

      .ant-form-item-label > label {
        margin-top: 0.2rem;
        font-size: 1.3rem;
      }

      .ant-form-item-control {
        display: flex;
        /*flex-grow: 1;*/
        width: 70%;
      }

      .ant-form-item-control-input {
        width: 100%;
        margin: 0;

        .partInput {
          width: 40%;

          &:nth-child(1) {
            width: 40%;
            font-size: 0.9rem;
          }
        }
      }

      .ant-form-item-control-input-content {
        flex: none;

        input.partInput {
          font-size: 0.9rem;
          width: 50%;
        }
      }

      .btn {
        width: 100%;
        max-width: 30%;
        height: 50%; /*
        margin: 1rem auto 0;*/
        a {
          font-size: 1.2rem;
          position: relative;
          top: 0rem;
        }
        span {
          font-size: 1.2rem;
        }
      }
    }

    .btn.fixed_btn {
      bottom: 8rem;
    }

    /* userinfo */
    .userInfo-wrap {
      max-width: 1600px;
      margin: 1rem auto 0;
    }

    #student-register {
      width: 100%;
      max-width: 100%;
      margin: 0rem auto 3rem;
      display: flex;
      height: auto;
      justify-content: start;
      align-items: baseline;

      tbody {
        tr {
          td {
            display: block;

            &:nth-child(2) {
              margin-left: 0;
            }

            .ant-form-item {
              align-items: center;
              margin-bottom: 1.5rem;
              margin-right: 2rem;
            }

            .alert-label-noTime {
              font-size: 0.7rem;
              bottom: -1.5rem;
            }

            .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
            .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
              font-size: 0.9rem;
            }
          }

          &:nth-child(1) {
            td:nth-child(2) {
              .ant-form-item-control {
                .ant-form-item-control-input-content {
                  input.partInput {
                    &:nth-child(1) {
                      width: 110px;
                      font-size: 0.9rem;
                    }
                    &:nth-child(2) {
                      width: 120px;
                      font-size: 0.9rem;
                    }
                  }
                }
              }
            }
          }

          &:nth-child(2) {
            td:nth-child(2) {
              .ant-form-item-control {
                .ant-form-item-control-input-content {
                  input.partInput {
                    font-size: 0.9rem;
                    &:nth-child(1) {
                      width: 60px;
                    }
                  }
                }
              }
            }
          }

          &:nth-child(3) {
            td:nth-child(1) {
              .ant-form-item-control {
                .plainInput.gradeInput {
                  width: 70%;
                  font-size: 1rem;
                }
              }
            }

            td:nth-child(2) {
              .ant-form-item-control {
                .plainInput.gradeInput {
                  width: 200px;
                  font-size: 1rem;
                }
              }
            }
          }
        }
      }

      .ant-form-item-label > label {
        font-size: 1rem;
        font-weight: 600;
      }

      .ant-form-item-label > label::before {
        content: '●';
        font-size: 0.9rem;
        color: #1462a1;
        margin: 0 0.2rem 3px 0.2rem;
      }

      .divided-form {
        width: 100%;
        max-width: 1100px;
        display: flex;
        flex-direction: row;

        &:nth-child(4) {
          width: 500px;
          left: 1rem;
          position: relative;
        }

        .ant-col-xs-24 {
          margin-left: 3px;
        }

        .ant-form-item {
          width: 100%;
          max-width: 600px;
          margin: 1rem 0.5rem;
        }

        .common-form.threeInput-form {
          input.partInput {
            width: 23%;
          }
        }

        .ant-form-item-label > label {
          font-size: 1.2rem;
        }

        .ant-col-sm-16 {
          width: 100%;
        }

        input.plainInput {
          width: 55%;
          text-align: center;
        }

        &:nth-child(3) {
          .common-form {
            &:nth-child(2) {
              .ant-form-item {
                position: relative;
                left: -5rem;
              }
            }
          }
        }
      }

      .threeInput-form {
        input.partInput {
          width: 20rem;
        }
      }

      .common-form {
        margin: 1rem auto;
        width: 600px;
      }
      .plain-form {
        /*
        width: 64%;
        max-width: 800px;*/
        width: 90%;
        max-width: 1100px;
        display: flex;
        /* justify-content: center; */

        .ant-form-item {
          max-width: 500px;
          width: 100%;
        }

        .ant-col-sm-8 {
          flex: none;
          width: 100%;
          max-width: 165px;
          text-align: left;
        }

        .ant-form-horizontal .ant-form-item-control {
          margin-left: 24px;
        }

        .ant-form-item-control-input-content {
          width: 100%;
          max-width: 500px;
        }
      }

      .partInput_wrap {
        margin-left: 0rem;
        width: 202px;
        max-width: 100%;
      }

      .ex-span {
        position: absolute;
        width: 100%;
        max-width: 120px;
        margin-left: 10px;
        margin-top: 5px;
      }

      input.plainInput {
        width: 50%;
        max-width: 400px;
        text-align: center;
      }

      input.partInput {
        /* width: 50px;
        margin: 0 1rem 0 0rem;*/
        text-align: center;

        width: 120px;
        margin: 0 4px 0 0rem;

        &:nth-child(1) {
          width: 90px;
        }

        &:nth-child(2),
        &:nth-child(3) {
          width: 80px;
        }
      }

      .btn {
        margin: 3rem auto;
        background: #c6edfe;
        color: #1462a1;
        border-radius: 30px;
        width: 150px;
        max-width: 100%;
        text-align: center;
        padding: 1rem;
        cursor: pointer;

        position: absolute;
        bottom: -1.5rem;
        left: 42rem;
      }

      .btn a {
        color: #1462a1;
        text-decoration: none;
        font-weight: 600;
        font-size: 1.3rem;
        text-align: center;
        margin: 0 auto;
      }
    }

    .btn {
      width: 100%;
      max-width: 160px;
      height: 100%;
      max-height: 50px;
      a {
        font-size: 1.1rem;
      }

      &.fixed_btn {
        bottom: 2rem;
        display: flex;
        align-items: center;
      }

      &.fixed_btm_btn {
        bottom: 0;
      }
    }

    /* survey */
    .survey-wrap {
      max-height: 100%;
      margin-top: 0;
      width: 90%;

      h2.survey-q {
        font-size: 1rem;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 600;
        span.circle-num {
          width: 40px;
          height: 40px;
          padding: 1rem;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }

      .survey-sub-q {
        margin-left: 2.8rem;
        h2 {
          font-size: 1rem;
        }

        &:nth-child(3) {
          .survey-a {
            margin-right: 0;
          }
        }

        h2 {
          font-size: 1rem;
          width: 87%;
          margin-left: 0.5rem;
        }

        .partInput.survey-a {
          width: auto;
          margin: 0 0.5rem;
          text-align: center;
          font-size: 1rem;
          max-width: 40px;
          max-height: 35px;

          & + span {
            width: auto;
            align-items: center;
            align-self: center;
            font-size: 0.9rem;
            max-width: 50px;
          }
        }
      }

      .survey-a {
        width: auto;
        .ant-radio-wrapper {
          margin: 0 3px;
          align-items: center;
          justify-content: center;
        }
        span.ant-radio {
          & + * {
            /*
            width: auto;
            font-size: 0.9rem;
            padding: 10px 0px 10px 6px;*/

            width: 75px;
            font-size: 1rem;
            color: #1561a0;
            margin: 0rem 0 0rem;
            text-align: center;
            padding: 10px 4px 10px 0px;
          }
        }

        .ant-radio-inner {
          transform: scale(1);
        }
      }

      &.single-survey {
        margin-top: 1rem;
        height: auto;
        max-height: 100%;
      }

      .survey-a {
        margin-left: auto;
      }

      .survey-sub-q {
        h2 {
          width: 100%;
        }

        .partInput.survey-a {
          width: auto;
        }
      }
    }

    /* metaexam type */
    .meta-header.type-header {
      width: 1000px;
      max-width: 100%;
      margin: 0 auto 1rem;
      top: 0;
      left: 0;
      h2 {
        font-size: 1.3rem;
        margin-top: 0.5rem;
        position: relative;
        top: 0rem;
      }
    }

    .meta-header {
      top: 0;
    }

    .exam-type-con {
      width: auto;
      height: 500px;
      .test-wrap {
        margin: 0 1rem;
        div {
          img {
            transform: scale(1);
          }
        }

        h3 {
          font-size: 1.5rem;
          margin: 1rem 0 1rem;
        }

        .meta-chkImg {
          width: 30px;
          margin: 0 auto;
        }

        &.no-chk {
          margin: 0rem 1rem 2rem;
        }
      }
    }

    /* exam info */
    .type-reading-con.finished-con {
      margin-top: 0;
      h3 {
        font-size: 1.5rem;
      }
    }

    /* sample question */
    .sample-q-con {
      height: 100%;
      max-height: 530px;
      width: calc(90% - 3rem);
      max-width: 1500px;

      .meta-header.readingTest-header {
        margin: 5rem auto 0;
      }

      .readingtest-con .readingtest-wrap .reading-a-con .reading-a-wrap {
        margin: 0.2rem 0;
      }

      .process-btn-con {
        width: 100%;
        display: flex;
        margin: 1rem 0 5.5rem;
      }

      .meta-header.test-header {
        .burger-wrap {
          img {
            width: 10%;
            margin-right: 5px;
          }
          h2 {
            font-size: 1rem;
          }
        }
      }

      .meta-header.readingTest-header h1.main-tit {
        font-size: 1rem;
      }

      .readingtest-con {
        .readingtest-wrap {
          .reading-a-con {
            .reading-a-wrap {
              max-height: 40px;
              height: 100%;
              align-items: center;
              .reading-a-num {
                width: 50px;
                padding: 0.5rem;
                h3 {
                  font-size: 1.3rem;
                }
              }
              .reading-a {
                h3 {
                  font-size: 1rem;
                }
              }
            }
          }
        }
      }
    }
    .btn.confirm_btn {
      margin: 1rem auto 0;
      align-items: center;
      max-height: 50px;
      span {
        font-size: 1.5rem;
      }
    }

    /* test sheet - readingtest.js */
    .meta-header.test-header {
      margin: 0 auto;
      padding: 0.5rem 3rem;

      .burger-wrap {
        img {
          width: 10%;
          margin-right: 10px;
        }
        h2 {
          width: 150px;
          font-size: 1.3rem;
          margin-bottom: 0.2rem;
        }
      }

      h2 {
        font-size: 1.2rem;
      }

      h2.num {
        width: 100px;
      }

      .time-wrap {
        h2 {
          &.num {
            margin: 0;
          }
        }
      }

      .question-wrap {
        margin-left: 1.5rem;
        h2 {
          width: 110px;
        }
      }
    }

    .meta-header.readingTest-header {
      h1.main-tit {
        font-size: 1.5rem;
        margin-top: 0rem;
        margin-bottom: 0;
      }
    }

    .readingtest-con {
      .readingtest-wrap.passage-con {
        font-size: 1rem;
        padding: 1rem;
      }

      .readingtest-wrap {
        .reading-q-con {
          padding: 1rem 1rem;
          h1 {
            font-size: 1.1rem;
          }
        }

        .reading-a-con {
          .reading-a-wrap {
            height: auto;
            max-height: 100%;
            align-items: center;
            margin: 0.5rem 0;
            .reading-a {
              display: flex;
              align-items: center;
              width: 22rem;
              h3 {
                margin-top: 0;
                font-size: 1rem;
                text-align: left;
              }
            }

            .reading-a-num {
              padding: 0.3rem;
              width: 70px;
              height: auto;
              min-height: calc(11vh - 3rem);
              display: flex;
              justify-content: center;
              align-items: center;
              h3 {
                font-size: 1.3rem;
              }

              .checkedImg {
                width: 80%;
                max-width: 80px;
              }
            }
          }
        }
      }
    }
    .ant-tooltip-inner {
      font-size: 1rem;
    }

    .btn.confirm_btn {
      margin: 1rem auto 0;
      align-items: center;
      max-height: 50px;
      padding: 0;
      a {
        font-size: 1.3rem;
      }
    }
    /*metacon finished */
    /*metacon - guide-con start*/
    &.guide-con {
      .exam-type-con {
        .test-wrap {
          &.ant-tooltip-open {
            div {
              a {
                h3 {
                  margin: 1rem 0 0.3rem;
                }
              }
            }

            .ant-tooltip-open {
              img {
                width: 36px;
              }
            }
          }
        }
      }
    }
  }

  /* MetaReport.js */
  .meta-report-con {
    width: 100%;
    height: 100%;

    .lexile-logo-con {
      background: #c0e5fc;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 2rem 0;
      margin-bottom: 2rem;

      img {
        max-width: 500px;
      }
    }

    .userInfo-con {
      width: 100%;
      display: flex;
      .userInfo {
        display: inline-flex;
        flex-direction: column;
        &.left {
          margin-right: auto;
          width: 33%;
          display: flex;
          flex-direction: row;
          align-items: end;
          margin-bottom: 0.5rem;
          h2 {
            margin: 0 1rem 0 1rem;
            color: #2e76b3;
            font-size: 1rem;
          }
        }

        &.right {
          margin-left: auto;
          width: 25%;
          justify-content: end;

          .ant-row {
            .ant-col {
              border: 1px solid #b3dbfc;
              padding: 0.3rem;
              text-align: center;
              background: #fff;
              color: #2e76b3;
            }

            .ant-col-4 {
            }
          }

          .user-table {
            display: table;
            div {
              display: table-cell;
            }
          }
        }
      }
    }

    .report-top {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin: 0.5rem 0 3rem;
      background: #fff;
      padding: 6rem 1rem;
      border: 1px solid #b3dbfc;
      .part-con {
        width: 40%;
        display: flex;
        justify-content: center;

        &:nth-child(1) {
          flex-direction: column;
          position: relative;
          left: 4rem;
          top: -1rem;
          transform: translate(10px, 10px);
          text-align: center;

          img {
            width: 100%;
            position: relative;
            top: -7rem;
            left: -3rem;
          }

          h1 {
            font-size: 2.6rem;
            font-weight: 700;
            position: relative;
            top: -1.5rem;
            margin-bottom: 0;
          }

          h4 {
            font-size: 1.4rem;
            margin-top: 0rem;
            color: #2e76b3;
          }
        }

        &:nth-child(2) {
          div.donut-wrap {
            position: relative;
            left: -2rem;
            top: -2rem;
            div {
              position: absolute;
              top: 55.5%;
              left: 52%;
              transform: translate(-50%, -50%);
              h3 {
                font-size: 1.3rem;
                color: #2d75b3;
                margin-bottom: 0;
              }

              h2 {
                font-size: 3rem;
                font-weight: 700;
              }
            }
          }
        }

        .ant-progress {
          width: 300px !important;
          height: 300px !important;
          margin-left: -4rem;
          .ant-progress-inner {
            width: 100% !important;
            height: 100% !important;
          }
        }
      }
      .ant-table-content {
        table {
          table-layout: fixed !important;
          text-align: center;
        }
      }
    }
    /* report top end */

    .report-middle-new {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 2rem 0rem 0rem;

      .card-con {
        display: flex;
        flex-direction: row;
        width: 100%;
        margin: 1rem 0;
        border: 1px solid #ddd;

        &:nth-child(1) {
          background: #fcbf15;
        }
        &:nth-child(2) {
          background: #09a5e2;
        }
        &:nth-child(3) {
          background: #90ce4f;
        }
        &:nth-child(4) {
          background: #cf88d8;
        }

        .bg {
          padding: 1rem;
          margin: 1rem 0;
          width: 40%;
          flex-direction: row;
          display: flex;
          justify-content: center;

          div.subject-con {
            flex-direction: row;
            display: flex;
            background: #fff;
            justify-content: space-between;
            align-items: center;
            border-radius: 20px;
            width: 100%;
            padding: 1rem 2rem;
            h3 {
              font-size: 1.2rem;
              margin-bottom: 0;
            }
          }
        }

        div.progress-con {
          background: #fff;
          width: 60%;
          flex-direction: column;
          display: flex;
          margin-left: 1rem;
          padding: 1rem 2rem;

          .progress-wrap {
            flex-direction: row;
            display: flex;
            align-items: center;

            span {
              padding: 0.5rem 0.3rem;
              width: 25%;
              font-weight: 600;
              max-width: 100px;
            }

            &.ur-score {
              color: #2d75b3;
            }
            &.avg-score {
              color: #b68a17;
            }
            &.avg-dev {
              color: #759b5d;
            }
          }
        }
      }
    }

    .bookUrl-con {
      padding: 2rem 0rem;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 2rem auto;

      div {
        border: 1px solid #b3dbfc;
        padding: 0rem 2rem 2rem;
        background: #fff;
        width: 100%;
        max-width: 1360px;
        display: flex;
        flex-direction: column;
        margin: 0 auto;
      }

      * {
        margin-left: auto;
      }

      img {
        margin: 2rem 2rem 1rem auto;
      }

      a {
        margin-top: 0rem;
      }
    }

    .result-table-con {
      padding: 0;
      .ant-table {
        width: 100%;
        max-width: 1360px;
        border: 1px solid #b3dbfc;
      }
      .ant-table-thead > tr > th,
      .ant-table-tbody > tr > td {
        text-align: center;
      }

      .ant-table-thead > tr > th {
        background: #67b7fb;
        color: #fff;
        font-size: 1.1rem;
      }

      .ant-table-tbody > tr > td {
        font-weight: 600;
        font-size: 1rem;
      }

      .ant-table-tbody > tr > td:nth-child(1),
      .ant-table-tbody > tr > td:nth-child(6) {
        color: #2e76b3;
      }

      .ant-table-tbody > tr > td:nth-child(2),
      .ant-table-tbody > tr > td:nth-child(3),
      .ant-table-tbody > tr > td:nth-child(4) {
        color: #528233;
      }

      .ant-table-tbody > tr > td:nth-child(5) {
        color: red;
      }

      .ant-table-thead
        > tr
        > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
        background-color: rgba(255, 255, 255, 0.5);
      }
    }

    .report-footer-con {
      display: flex;
      margin: 1rem auto;
      flex-direction: row;
      width: 100%;
      max-width: 1000px;
      padding: 1rem 2.5rem 0rem;

      h5:nth-child(1) {
        margin-right: auto;
      }
      h5:nth-child(2) {
        color: #2d75b3;
        margin-left: auto;
      }
    }
  }
}

@media screen and (max-width: 1280px) and (aspect-ratio: 16 / 10) {
  .meta-con {
    &.meta-full-con {
      .btn {
        &.fixed_btm_btn {
          border-radius: 50px;
          padding: 2rem 1rem;
        }
      }
    }

    #student-register {
      tbody {
        tr {
          &:nth-child(3) {
            td {
              .ant-form-item-control-input {
                .ant-form-item-control-input-content {
                  .ant-select-selector {
                    // width: 200px;
                  }
                }
              }
            }
          }
          td {
            width: 400px;
          }
        }
      }
    }

    &.register-con {
      /* survey */
      .survey-wrap {
        margin-top: 1.5rem;
        width: 100%;
        max-width: 1150px;

        div {
          display: block;
        }
        h2.survey-q {
          font-size: 1rem;
          justify-content: start;
          align-items: center;
          font-weight: 600;
          span.circle-num {
            width: 40px;
            height: 40px;
            padding: 0.5rem 1rem;
          }
        }

        .survey-sub-q {
          //  margin-left: 2.8rem;
          flex-direction: column;
          h2 {
            font-size: 1rem;
            width: auto;
            margin-left: 0;
          }

          .survey-a {
            width: 100%;
            margin-left: 0;
          }

          .partInput.survey-a {
            margin: 0 0.5rem;
            max-width: 200px;
            text-align: center;
            font-size: 1rem;
            height: 35px;

            & + span {
              align-items: center;
              align-self: center;
              font-size: 1rem;
              max-width: 50px;
            }
          }

          &:nth-child(3) {
            .survey-a {
              display: flex;
            }
          }
        }

        .survey-a {
          margin-left: 3.3rem;
          .ant-radio-wrapper {
            margin: 0 0px;
            align-items: center;
            justify-content: center;
          }
          span.ant-radio {
            & + * {
              font-size: 1rem;
            }
          }

          .ant-radio-inner {
            transform: scale(1.3);
          }
        }
      }
    }
  }
}

@media (max-width: 1200px) {
  .meta-con {
    h1.main-tit {
      font-size: 2rem;
      margin: 0rem auto;
    }

    .arrow-wrap {
      bottom: -2.5%;
      right: 24%;
      position: absolute;
    }

    &.guide-con {
      .readingtest-con {
        .readingtest-wrap {
          .reading-a-con {
            .reading-a-wrap {
              margin: 0.5rem 0;
              .reading-a-num {
                padding: 0rem 1rem;
              }
            }
          }
        }
      }
    }

    .exam-type-con {
      width: auto;
      height: auto;
    }
  }

  .meta-con {
    &.guide-con {
      .readingtest-con {
        .readingtest-wrap {
          .reading-q-con {
            margin: 1rem 0 0;
          }
        }
      }
    }
  }
}

@media (max-width: 1100px) {
  .meta-con {
    &.guide-con {
      .meta-header.test-header {
        padding: 0 2rem 0 4rem;
      }
    }

    &.register-con {
      height: auto;
      margin: 3rem auto;
    }
  }
}

@media screen and (max-width: 1280px) and (aspect-ratio: 16 / 9) {
  .meta-con {
    &.register-con {
      height: auto;
    } 
  }
}

@media screen and (max-width: 1280px) and (orientation: landscape) {
  .meta-con {
    &.register-con {
      margin: 3rem auto 0;
    }

    .readingtest-con {
      height: calc(85vh - 10rem);
    }

    .process-btn-con {
      margin: 1rem auto 0;
    }
  }
}

@media screen and (device-width: 1280px) and (max-width: 1280px) and (aspect-ratio: 16 / 10) {
  .meta-con.meta-full-con {
    .btn.confirm_btn.finished_btn {
      width: 25%;
    }
  }
  .meta-con {
    .inner-header.login-header {
      max-width: 100%;
    }

    .metalogin-wrap {
      width: 40rem;
      height: 23rem;

      .ant-form-item {
        align-items: center;
      }

      h1 {
        font-size: 2.4rem;
        margin-bottom: 3rem;
      }

      .ant-form-item-label > label {
        font-size: 1.2rem;
        width: 120px;
      }

      .ant-form-item-control {
        width: 60%;
      }

      .ant-form-item-control-input .partInput {
        font-size: 1.2rem;
      }

      .btn {
        width: 250px;
      }

      a {
        font-size: 2.3rem;
      }
    }

    .inner-header {
      width: calc(90% - 20rem);
      display: flex;
      margin: 1rem auto;
      h2 {
        font-size: 1.4rem;
      }
    }

    .metaheader {
      margin: 0 auto 0;
    }

    .meta-header h1.main-tit {
      font-size: 2rem;
      margin-top: 1rem;
      margin-bottom: 1rem;
      top: 0rem;
      width: 100%;
    }

    #student-register {
      /* max-width: 1200px;*/
      width: 100%;
      max-width: 850px;
      margin: 0 auto 0;
      display: flex;
      justify-content: center;
      tbody {
        tr {
          td {
            .ant-form-item {
              margin-right: 0rem;
              margin-bottom: 1.5rem;
              align-items: center;

              .ant-form-item-control {
                .ant-form-item-control-input-content {
                  input.partInput {
                    width: 70px;
                  }
                }
              }
            }

            .ant-picker-input > input {
              font-size: 1rem;
            }
          }
        }

        tr:nth-child(1) {
          td:nth-child(2) {
            .ant-form-item-control {
              .ant-form-item-control-input-content {
                input.partInput {
                  &:nth-child(1) {
                    width: 108px;
                    font-size: 0.9rem;
                  }

                  &:nth-child(2) {
                    width: 95px;
                    font-size: 0.9rem;
                  }
                }
              }
            }
          }
        }

        tr:nth-child(2) {
          td:nth-child(1) {
            .ant-form-item-control {
              .ant-form-item-control-input-content {
                input.partInput {
                  &:nth-child(1) {
                    width: 90px;
                  }
                }
              }
            }
          }

          td:nth-child(2) {
            .ant-form-item-control {
              .ant-form-item-control-input-content {
                input.partInput {
                  &:nth-child(1) {
                    width: 70px;
                    font-size: 0.9rem;
                  }
                }
              }
            }
          }
        }
      }

      input.plainInput {
        width: 50%;
        max-width: 400px;
        text-align: center;
        font-size: 1rem;
      }

      .ant-form-item-label > label {
        font-size: 1.1em;
      }

      .ant-form-item:nth-child(2) {
        margin: 1rem 0 3rem;
      }
    }

    .confirm_btn.fixed_btn {
      position: absolute;
      bottom: 8.5rem;
    }

    .btn {
      /*
      width: 250px;
      max-width: 100%;
      text-align: center;
      padding: 2rem 1rem;*/
      width: 100%;
      max-width: 150px;
      height: 100%;
      max-height: 50px;
      a {
        font-size: 1.1rem;
        position: relative;
        bottom: 0;
      }

      &.request_btn {
        a {
          font-size: 1.1rem;
          position: relative;
          bottom: 0rem;
        }
      }

      &.confirm_btn {
        max-height: 60px;
        span {
          font-size: 1.5rem;
        }
      }

      &.confirm_btn.finished_btn {
        a {
          bottom: 0;
        }
      }

      &.confirm_btn.start_btn {
        max-height: 60px;
        width: 15%;
        max-width: 300px;
        a {
          bottom: 0;
        }
      }
    }

    .survey-wrap {
      h2.survey-q {
        font-size: 1rem;
        span.circle-num {
          width: 50px;
          height: 50px;
          padding: 1rem 0.5rem;
        }
      }

      .ant-radio-wrapper {
        align-items: center;
      }

      .survey-sub-q {
        margin-left: 4rem;
        h2 {
          font-size: 1rem;
        }

        .partInput.survey-a {
          width: auto;
          font-size: 1rem;
          max-width: 40px;
          padding: 0;
        }

        .partInput.survey-a + span {
          align-items: center;
          align-self: center;
          max-width: 100px;
          font-size: 1rem;
        }
      }

      .survey-a {
        width: auto;
        span.ant-radio + * {
          width: 75px;
          font-size: 1rem;
          color: #1561a0;
          margin: 0rem 0 0rem;
          text-align: center;
          padding: 10px 4px 10px 0px;
        }

        .ant-radio {
          transform: scale(1.2);
        }
      }
    }

    .survey-wrap.single-survey {
      margin-top: 1rem;
      height: auto;
      display: block;
      max-height: 100%;
    }

    .meta-header.type-header {
      margin: 0 auto 0;
      h2 {
        font-size: 1.3rem;
        margin-top: 1rem;
        position: relative;
        top: 0rem;
      }
    }

    .exam-type-con {
      .test-wrap {
        margin: 0 1.6rem;
        a {
          img {
            transform: scale(1);
            margin: 0 auto;
            display: block;
          }
          h3 {
            font-size: 1.6rem;
            text-align: center;
            margin: 1rem auto 0.8rem;
          }
        }

        .meta-chkImg {
          width: 30px;
        }
      }
    }

    .type-reading-con {
      h1 {
        font-size: 3.5rem;
      }

      h3 {
        font-size: 1.6rem;
      }
    }

    .meta-header.readingTest-header {
      h1.main-tit {
        font-size: 1.2rem;
      }
    }

    .sample-q-con {
      max-width: 1500px;

      .meta-header.readingTest-header {
        h1.main-tit {
          font-size: 1.3rem;
          margin-bottom: 0;
        }
      }
      .meta-header.test-header {
        padding: 1rem 2.8rem 1rem 5rem;

        .burger-wrap {
          h2 {
            width: 450px;
            font-size: 1.4rem;
          }
          img {
            width: 5%;
          }
        }
        h2 {
          font-size: 1.4rem;
        }

        .time-wrap {
          h2 {
            width: 100px;
            font-size: 1.2rem;
            margin: auto 0;
          }
        }

        .question-wrap {
          h2 {
            font-size: 1.2rem;
            width: 120px;
            margin: auto 0;
          }
        }
      }

      .readingtest-con {
        .readingtest-wrap {
          &.passage-con {
            font-size: 1.4rem;
          }
          .reading-q-con {
            h1 {
              font-size: 1.2rem;
            }
          }
          .reading-a-con {
            .reading-a-wrap {
              margin: 0.4rem 0;
              align-items: center;
              .reading-a-num {
                width: 60px;
                padding: 0.4rem;
                h3 {
                  font-size: 1.4rem;
                }
              }
              h3 {
                font-size: 1.4rem;
                margin-top: 0;
              }
            }
          }
        }
      }
    }

    .readingTest-header {
      h1.main-tit {
        margin-bottom: 0;
      }
    }

    .readingtest-con {
      .readingtest-wrap {
        &.passage-con {
          font-size: 1.8rem;
        }
        .reading-q-con {
          h1 {
            font-size: 1.8rem;
          }
        }
        .reading-a-con {
          .reading-a-wrap {
            margin: 1rem 0;
            align-items: center;
            .reading-a-num {
              width: 80px;
              padding: 1rem;
              h3 {
                font-size: 2rem;
              }
            }

            .reading-a {
              padding: 0.5rem;
            }
            h3 {
              font-size: 2rem;
            }
          }
        }
      }
    }

    &.meta-full-con {
      .btn {
        a {
          bottom: 0rem;
        }
      }

      .ant-tooltip-placement-topRight {
        .ant-tooltip-arrow {
          transform: scale(5);
          bottom: -1.5rem;
        }
      }
      .ant-tooltip-inner {
        font-size: 1.2rem;
        min-width: 250px;
        text-align: center;
        padding: 1rem;
      }
    }

    .meta-header.test-header {
      width: 100%;
      display: flex;
      background: #e3f6fd;
      margin: 0 auto;
      padding: 0.5rem 5rem 0.5rem 7rem;

      h2 {
        font-size: 1.2rem;
        margin-bottom: 0;

        &.num {
          margin: 0;
        }
      }

      .burger-wrap {
        h2 {
          width: 250px;
        }
      }
      .time-wrap {
        h2 {
          margin-right: 0;
          width: 80px;
        }
      }

      .question-wrap {
        margin-left: 1.5rem;
        h2 {
          margin-right: 0;
          width: 100px;
        }
      }
    }

    .readingtest-con {
      .readingtest-wrap.passage-con {
        font-size: 1.2rem;
      }

      .readingtest-wrap {
        .reading-q-con {
          padding: 1rem 1rem;

          h1 {
            font-size: 1.2rem;
            padding: 0;
          }
        }

        .reading-a-con {
          .reading-a-wrap {
            align-items: center;
            margin: 0.5rem 0;
            .reading-a-num {
              width: 60px;
              padding: 0.5rem;
              h3 {
                font-size: 1.2rem;
              }
            }

            .reading-a {
              h3 {
                font-size: 1rem;
              }
            }
          }
        }
      }
    }

    .meta-header.onlyTxt-header {
      h1.main-tit {
        font-size: 2.5rem;
      }
    }
    .report-footer-con {
      font-size: 1rem;
    }
  }
}

@media screen and (device-width: 1280px) and (max-width: 1280px) and (aspect-ratio: 16 / 9) {
  .meta-con {
    .meta-header.test-header {
      padding: 0.5rem 5rem;

      div.time-wrap {
        h2 {
          width: 80px;
          &:nth-child(1) {
            width: 50px;
          }
          &:nth-child(2) {
            margin: 0 1rem;
          }
        }
      }

      .question-wrap {
        margin-left: 0;
        h2 {
          width: 90px;
          &:nth-child(1) {
            width: 50px;
          }
          &:nth-child(2) {
            margin: 0 2rem;
          }
        }
      }
    }

    .arrow-wrap {
      bottom: -1.5%;
    }

    #student-register {
      /* max-width: 1200px;*/
      width: 100%;
      max-width: 850px;
      margin: 3rem auto 2rem;
      display: flex;

      .ant-input-search.plainInput input {
        font-size: 1rem;
      }
      tbody {
        tr {
          td {
            .ant-form-item {
              margin-right: 0rem;
              margin-bottom: 2rem;
              align-items: center;

              .ant-form-item-control {
                .ant-form-item-control-input-content {
                  input.partInput {
                    width: 80px;
                    font-size: 0.9rem;
                  }
                }
              }
            }

            .ant-picker-input > input {
              font-size: 0.9rem;
            }
          }
        }

        tr:nth-child(1) {
          td:nth-child(2) {
            .ant-form-item-control {
              .ant-form-item-control-input-content {
                input.partInput {
                  &:nth-child(1) {
                    width: 110px;
                    font-size: 0.9rem;
                  }

                  &:nth-child(2) {
                    width: 120px;
                    font-size: 0.9rem;
                  }
                }
              }
            }
          }
        }

        tr:nth-child(2) {
          td:nth-child(1) {
            .ant-form-item-control {
              .ant-form-item-control-input-content {
                input.partInput {
                  &:nth-child(1) {
                    width: 85px;
                    font-size: 0.9rem;
                  }
                }
              }
            }
          }

          td:nth-child(2) {
            .ant-form-item-control {
              .ant-form-item-control-input-content {
                input.partInput {
                  &:nth-child(1) {
                    width: 80px;
                    font-size: 0.9rem;
                  }
                }
              }
            }
          }
        }
      }

      input.plainInput {
        width: 50%;
        max-width: 400px;
        text-align: center;
        font-size: 1rem;
      }

      .ant-form-item-label > label {
        font-size: 1.05rem;
      }

      .ant-form-item:nth-child(2) {
        margin: 1rem 0 3rem;
      }
    }

    .survey-wrap {
      .survey-sub-q {
        &:nth-child(3) {
          .survey-a {
            width: 45%;
          }
        }
      }
    }

    .readingtest-con {
      .readingtest-wrap {
        .reading-a-con {
          .reading-a-wrap {
            .reading-a-num {
              padding: 0.3rem 0.5rem;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1200px) and (device-width: 1200px) and (aspect-ratio: 16 / 9) {
  .meta-con {
    .inner-header {
      top: 1rem;
      width: 100%;
      max-width: 1700px;

      h2 {
        font-size: 2.6rem;
      }
    }

    .inner-header.login-header {
      margin: 1rem 0 0;
      h2 {
        font-size: 2.6rem;
      }
    }

    h1.main-tit {
      font-size: 1.7rem;
      margin-top: 0rem;
    }

    .metalogin-wrap {
      height: 100%;
      max-height: 400px;
      max-width: 700px;
      width: 100%;

      padding: 2rem 1rem;
      margin: 0rem 0 1rem;

      h1 {
        font-size: 2.3rem;
        margin: 0rem auto 1.5rem;
      }

      .ant-form-item {
        width: 100%;
        max-width: 530px;
      }

      .ant-form-item-label {
        width: 180px;
        text-align: left;
        max-width: 100%;
      }

      .ant-form-item-label > label {
        margin-top: 0.5rem;
        font-size: 1.3rem;
      }

      .ant-form-item-control-input {
        width: 100%;
        max-width: 100%;
      }
      .ant-form-item-control-input-content {
        flex: none;

        input.partInput {
          font-size: 1.5rem;
        }
      }

      .btn {
        width: 100%;
        max-width: 200px;
        height: 50%;
        max-height: 60px;
        margin: 1rem auto 0;
        a {
          font-size: 1.5rem;
          position: relative;
          top: 0rem;
        }
      }
    }

    /* userinfo */
    .userInfo-wrap {
      max-width: 1600px;
      margin: 1rem auto 0;
    }
    #student-register {
      max-width: 1700px;
      height: 100%;
      margin: 1rem auto;

      .ant-form-item-label > label {
        font-size: 1.3rem;
      }

      .common-form {
        margin: 0rem auto;
      }

      .plain-form {
        width: 100%;
        max-width: 1800px;

        .ant-col-sm-8 {
          max-width: 200px;
        }

        .ant-col-sm-16 {
          max-width: 73%;
        }

        .ant-form-item {
          max-width: 1350px;
          align-items: center;
          margin: 0.5rem 0;
        }

        .ant-form-item-control-input-content {
          width: 100%;
          max-width: 1500px;
        }
      }

      input.partInput {
        width: 100px;
        margin: 0 7px 0 0rem;
        max-width: 100%;
        font-size: 1.5rem;
      }

      .partInput_wrap {
        width: 100%;
        max-width: 100%;
        display: flex;
      }

      .divided-form {
        max-width: 1100px;
        width: 100%;
        justify-content: space-around;

        .ant-form-item {
          width: 100%;
          max-width: 550px;
        }

        .ant-form-item-label > label {
          font-size: 1.3rem;
        }

        .ant-col-sm-16 {
          width: 100%;
          max-width: 240px;
        }
      }

      input.plainInput {
        margin-top: -0.5rem;
        font-size: 1.25rem;
        width: 100%;
      }

      .ex-span {
        max-width: 200px;
        font-size: 1rem;
        position: relative;
      }

      .ant-picker {
        width: 100%;
        max-width: 400px;
        font-size: 2rem;
      }

      .ant-picker-input > input {
        font-size: 1.3rem;
      }
    }

    .btn {
      width: 100%;
      max-width: 150px;
      height: 100%;
      max-height: 55px;
      a {
        font-size: 1.5rem;
      }

      &.fixed_btn {
        bottom: 1rem;
        display: flex;
        align-items: center;
      }
    }

    /*survey*/
    .survey-wrap {
      height: 50%;
      max-height: 600px;
      width: 100%;
      max-width: 1150px;
      h2.survey-q {
        font-size: 1.3rem;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 600;
        span.circle-num {
          width: 50px;
          height: 50px;
          padding: 0.8rem;
        }
      }

      .survey-sub-q {
        margin-left: 4.8rem;
        margin-top: 0.5rem;
        margin-bottom: 0.3rem;
        h2 {
          font-size: 1.2rem;
          width: 82%;
        }

        .partInput.survey-a {
          width: 10%;
          margin: 0 0rem;
          max-width: 200px;
          text-align: center;
          font-size: 1.2rem;
          padding: 0;
          height: 40px;

          & + span {
            width: 10%;
            align-items: center;
            align-self: center;
            font-size: 1.1rem;
            max-width: 50px;
          }
        }
      }

      .survey-a {
        .ant-radio-wrapper {
          margin: 0 5px;
          align-items: center;
          justify-content: center;
        }
        span.ant-radio {
          & + * {
            font-size: 1rem;
            padding: 10px 10px;
          }
        }

        .ant-radio-inner {
          transform: scale(1.3);
        }
      }

      &.single-survey {
        height: 45%;
        max-height: 250px;
        margin-top: -8rem;
      }
    }

    /* metaexam type */
    .meta-header.type-header {
      width: 100%;
      max-width: 850px;
      margin: 0 auto;
      h2 {
        font-size: 1.3rem;
        margin-top: 1rem;
        position: relative;
        top: 2rem;
      }
    }
    .exam-type-con {
      .test-wrap {
        margin: 0 1rem;
        a {
          img {
            transform: scale(1);
          }
        }

        h3 {
          font-size: 1.5rem;
          margin: 1rem 0 1rem;
        }

        .meta-chkImg {
          width: 35px;
          margin: 0 auto;
        }

        &.no-chk {
          margin: 0rem 1rem 2rem;
        }
      }
    }

    /* */
    .meta-header.onlyTxt-header {
      font-size: 2rem;
      margin-top: -4rem;
    }

    .type-reading-con {
      width: 100%;
    }

    .finished-con {
      h1 {
        margin: -3rem 0 2rem;
      }

      h3 {
        font-size: 1.3rem;
      }
    }

    /* sample question */
    .sample-q-con {
      height: calc(100vh - 16rem);
      max-height: 100%;
      width: calc(90% - 3rem);
      max-width: 1500px;

      .meta-header.test-header {
        .burger-wrap {
          img {
            width: 8%;
            margin-right: 5px;
            margin-top: 0;
          }
          h2 {
            font-size: 1rem;
          }
        }
      }

      .meta-header.test-header div.time-wrap {
        padding-left: 2rem;
      }

      .meta-header.test-header div.question-wrap {
        padding: 0.25rem;
      }
      .meta-header.test-header {
        padding: 0rem 5rem;
        height: calc(10vh - 2rem);
      }
      .meta-header.readingTest-header {
        margin: 2rem auto 0rem;
        h1.main-tit {
          font-size: 1rem;
        }
      }
      .readingtest-con {
        .readingtest-wrap {
          .reading-q-con {
            margin: 0;
            padding: 1rem 1rem;
            h1 {
              font-size: 0.8rem;
            }
          }
          .reading-a-con {
            .reading-a-wrap {
              max-height: 30px;
              height: 100%;
              align-items: center;
              .reading-a-num {
                width: 30px;
                padding: 0rem;
                h3 {
                  font-size: 0.7rem;
                }
              }
              .reading-a {
                h3 {
                  font-size: 1rem;
                }
              }
            }
          }

          &.passage-con {
            margin: 0rem 3rem 1rem 1rem;
          }
        }
      }

      .process-btn-con {
        margin: 1rem 0 1rem;
        img {
          width: 100%;
          max-width: 70px;
        }
      }
    }
    .btn.confirm_btn.start_btn {
      margin: 0.5rem auto 0;
      align-items: center;
      max-height: 45px;
      a {
        font-size: 1.5rem;
      }
    }

    /* test sheet - readingtest.js */

    .meta-header {
      margin: 1rem auto 2rem;
    }
    .meta-header.test-header {
      margin: 0 auto;
      padding: 0.3rem 5rem;
      .burger-wrap {
        img {
          width: 8%;
          margin-right: 10px;
          margin-top: 0;
        }
        h2 {
          width: 300px;
          font-size: 1.3rem;
        }
      }
      h2 {
        font-size: 1.1rem;
        width: 75px;
        padding: 0.2rem 0;
        margin: 0.2rem 0rem 0.2rem -1rem;
      }
      h2.num {
        padding: 0.2rem 0;
        margin: 0rem 0rem 0rem -1rem;
      }

      .question-wrap {
        h2 {
          width: 90px;
        }
      }
    }

    .meta-header.readingTest-header {
      h1.main-tit {
        font-size: 1.2rem;
        margin-top: 1rem;
      }
    }

    .readingtest-con {
      height: calc(100vh - 20rem);
      align-items: center;
      .readingtest-wrap.passage-con {
        font-size: 1.1rem;
        padding: 1rem;
        margin: 0rem 3rem 1rem 0.5rem;
      }

      .readingtest-wrap {
        .reading-q-con {
          margin: 0;
          padding: 1rem 1rem;
          h1 {
            font-size: 1rem;
          }
        }

        .reading-a-con {
          .reading-a-wrap {
            height: 40px;
            max-height: 100%;
            align-items: center;
            margin: 0.35rem 0;
            .reading-a {
              display: flex;
              align-items: center;
              h3 {
                margin-top: 0;
                font-size: 1rem;
              }
            }

            .reading-a-num {
              padding: 0.3rem;
              width: 50px;
              h3 {
                font-size: 1.2rem;
              }

              .checkedImg {
                width: 80%;
                max-width: 80px;
              }
            }
          }
        }
      }
    }

    .process-btn-con {
      margin: 0 1rem 1rem 1rem;
      width: calc(100% - 3rem);
      img {
        max-width: 90px;
      }
    }
    .ant-tooltip-inner {
      font-size: 1rem;
    }

    .btn.confirm_btn {
      margin: 1rem auto 0;
      align-items: center;
      max-height: 60px;
      a {
        font-size: 1.3rem;
      }
    }
    /*metacon finished */
  }

  .meta-report-con {
    .report-top {
      .part-con {
        &:nth-child(2) {
          div.donut-wrap {
            position: relative;
            left: -2rem;
            div {
              position: absolute;
              top: 55.5%;
              left: 52%;
              transform: translate(-50%, -50%);
              h3 {
                font-size: 1.1rem;
                color: #2d75b3;
                margin-bottom: 0;
              }

              h2 {
                font-size: 3rem;
                font-weight: 700;
              }
            }
          }
        }
      }
    }

    .report-footer-con {
      display: flex;
      margin: 1rem auto;
      flex-direction: row;
      width: 100%;
      max-width: 950px;
      padding: 1rem 2.5rem 0rem;

      h5:nth-child(1) {
        margin-right: auto;
      }
      h5:nth-child(2) {
        color: #2d75b3;
        margin-left: auto;
      }
    }
  }
}

@media (max-width: 1200px) {
  .meta-con {
    &.guide-con {
      .meta-header {
        &.type-header {
          width: 800px;
        }
      }
    }
    .metalogin-wrap {
      .ant-form-item-label {
        padding-right: 0;
      }
      .ant-form-item-control-input {
        .partInput:nth-child(1) {
          width: 37%;
        }
      }
    }

    #student-register {
      overflow: auto scroll;
      .divided-form:nth-child(3) .common-form:nth-child(2) .ant-form-item {
        position: relative;
        left: -4.3rem;
      }
    }

    .btn.fixed_btn {
      bottom: 1rem;
    }

    .btn.confirm_btn {
      span {
        font-size: 1.4rem;
      }
    }

    .sample-q-con {
      max-height: 530px;
    }

    .meta-header {
      top: 0;
      margin: 0 auto;
    }

    .meta-header.type-header {
      width: 900px;
      h2 {
        font-size: 1rem;
        margin-top: 0;
      }
    }

    .meta-header.test-header {
      .burger-wrap {
        h2 {
          width: 150px;
          font-size: 1.3rem;
        }
      }

      .question-wrap {
        h2 {
          width: 95px;
        }
      }
    }

    .meta-header.onlyTxt-header {
      h1.main-tit {
        font-size: 2rem;
      }
    }

    .sample-q-con {
      .process-btn-con {
        img {
          width: 70%;
        }
      }
    }
  }
}

@media (max-width: 1199px) {
  .meta-con {
    .inner-header.login-header {
      h2 {
        font-size: 2.5rem;
      }
    }

    .metalogin-wrap {
      .ant-form-item-control-input {
        width: 85%;
        max-width: 100%;
      }

      .ant-form-item-control-input-content {
        input.partInput {
          font-size: 1rem;
        }
      }
      .btn {
        a {
          font-size: 1.05rem;
        }
      }
    }

    .ant-form-item-control-input-content {
      input.partInput {
        font-size: 0.9rem;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .meta-con {
    &.guide-con {
      .meta-header.test-header {
        padding: 0rem 2rem 0rem 3rem;
        h2 {
          &.num {
            padding: 0.5rem 0rem 0.5rem 0rem;
            margin: 1rem auto;
          }
        }
      }

      .meta-header.readingTest-header {
        margin: 0 auto 0;
        h1.main-tit {
          font-size: 1.2rem;
        }
      }

      .type-reading-con {
        &.finished-con {
          h1 {
            margin: 0 0 1rem;
          }
        }
      }

      .readingtest-con {
        width: calc(100% - 2rem);
        .readingtest-wrap {
          height: calc(50vh - 5rem);
          .reading-a-con {
            .reading-a-wrap {
              .reading-a-num {
                padding: 0 1rem;
              }
            }
          }
        }
      }

      .test-wrap {
        .arrow-wrap {
          width: 100px;
          height: 500px;
          position: absolute;
          left: 37%;
          top: 20%;
          transform: translate(0%, 0%) rotate(-30deg);
          .guide-arrow {
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 45px 77.9px 45px 0;
            border-color: transparent #fabe78 transparent transparent;
            line-height: 0px;
            _border-color: #ffffff #fabe78 #ffffff #ffffff;
            _filter: progid:DXImageTransform.Microsoft.Chroma(color='#ffffff');
            position: relative;
            bottom: 16%;
            right: 39%;
            transform: translate(-40%, -18%);

            .arrow-body {
              background: #fabe78;
              color: #333;
              width: 100%;
              min-width: 150px;
              height: 50px;
              display: flex;
              justify-content: center;
              align-items: center;
              position: relative;
              z-index: 2;
              top: -1.6rem;
              left: 4rem;
              font-weight: 600;
              font-size: 1.2rem;
            }
          }
        }
      }

      .readingtest-con {
        height: /* calc(100vh - 20rem); */ auto;
        align-items: center;
        .readingtest-wrap.passage-con {
          font-size: 1rem;
          padding: 1rem;
          margin: 2rem 3rem 2rem 0.5rem;
        }

        .readingtest-wrap {
          .reading-q-con {
            margin-top: 0;
            padding: 0.5rem 0.5rem;
            h1 {
            }
          }

          .reading-a-con {
            .reading-a-wrap {
              height: 35px;
              max-height: 100%;
              align-items: center;
              margin: 0.25rem 0;
              .reading-a {
                display: flex;
                align-items: center;
                padding: 0;
                h3 {
                  margin-top: 0;
                  font-size: 1rem;
                }
              }

              .reading-a-num {
                padding: 0.45rem;
                width: 40px;
                h3 {
                  font-size: 0.85rem;
                }

                .checkedImg {
                  width: 80%;
                  max-width: 80px;
                }
              }
            }
          }
        }
      }

      .process-btn-con {
        margin: 0.5rem 1rem 0rem 1rem;
        width: calc(100% - 3rem);
        img {
          max-width: 90px;
        }

        a {
          img {
            width: 70%;
          }
        }
      }
      .ant-tooltip-inner {
        font-size: 1rem;
      }

      .btn.confirm_btn {
        margin: 1rem auto 0;
        align-items: center;
        max-height: 50px;
        a {
          font-size: 1.3rem;
        }
      }
    }

    &.register-con {
      #student-register {
        tbody {
          tr {
            &:nth-child(3) {
              td {
                .ant-form-item-control {
                  width: 200px;
                }
              }
            }
          }
        }
      }
    }

    .meta-header {
      &.test-header {
        padding: 1rem 3rem;
      }

      &.login-header {
        margin: 1rem auto;
      }
    }
  }
}

@media screen and (max-width: 1024px) and (aspect-ratio: 16 / 9) {
  .meta-con {
    &.register-con {
      margin: 2.5rem auto;
    }
  }
}

/* ios */
@media only screen and (max-width: 1200px) and (orientation: portrait) {
  .meta-con {
    .meta-header.test-header {
      padding: 1rem 2rem;
      margin-bottom: 3rem;
      h2.num {
        margin: 0;
      }
    }

    .report-footer-con {
      width: 90%;
    }
  }
}

@media only screen and (max-width: 1024px) {
  .meta-con {
    &.register-con {
      .inner-header {
        &.survey-header {
          margin: 1rem 0;
          width: 100%;
        }
      }

      #student-register {
        tbody {
          tr {
            td {
              .ant-form-item {
                margin-bottom: 1.5rem;
              }
            }
          }
        }
      }
    }

    &.meta-full-con {
      .inner-header {
        &.login-header {
          .login-top-logo {
            width: 200px;
            margin-bottom: 0;
            max-width: 100%;
            min-width: auto;
          }
        }
      }

      .metalogin-wrap {
        padding: 0;
        width: 30rem;
        height: 15rem;
        margin: 1rem auto 2rem;

        h1 {
          font-size: 1.6rem;
          margin: 0 auto 2rem;
        }

        .ant-form-item {
          width: 450px;
          margin-bottom: 1rem;
        }

        .ant-form-item-label {
          padding-right: 0;
          max-width: 100%;
          width: 120px;
          text-align: right;
        }

        .ant-form-item-label > label {
          margin-top: 0;
          margin-left: 0;
          font-size: 1rem;
        }

        .btn {
          margin: 0 auto;
          display: flex;
          justify-content: center;
          align-items: center;

          width: 150px;
          max-width: 100%;
          height: 35px;
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) {
  .meta-con {
    .meta-header {
      &.test-header {
        .question-wrap {
          h2 {
            font-size: 1rem;
          }
        }
      }
      &.readingTest-header {
        width: calc(100% - 3rem);
        margin: 1rem auto;
      }
    }

    .readingtest-con {
      .readingtest-wrap {
        &.passage-con {
          overflow-y: scroll !important;
          margin: 1rem 3rem 1rem 1rem;
        }
      }
    }
  }
}

@media only screen and (max-width: 1024px) and (orientation: landscape) {
  .meta-con {
    #student-register {
      .ant-form-item-label > label {
        font-size: 0.8rem;
      }
      input.partInput {
        &:nth-child(1) {
          width: 70px;
        }

        &:nth-child(2),
        &:nth-child(3) {
          width: 60px;
        }
      }

      .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
      .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
        font-size: 0.8rem;
      }

      tr {
        td {
          .ant-form-item {
            margin-bottom: 2.5rem;
          }
        }
      }
    }
  }
}

/* air portrait */
@media only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
  .meta-con {
    &.guide-con {
      .arrow-wrap {
        bottom: 0rem;
        width: calc(55% - 11vw);
      }
    }

    #student-register {
      tbody {
        tr {
          &:nth-child(3) {
            td {
              &:nth-child(2) {
                .ant-form-item-control {
                  .plainInput {
                    &.gradeInput {
                      width: 40%;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .readingtest-con {
      width: calc(100% - 0rem);
      height: auto;
    }
  }
}

/* pro */
@media only screen and (min-width: 1024px) and (max-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) {
  .meta-con {
    &.guide-con {
      .arrow-wrap {
        bottom: 3rem;
        width: calc(55% - 11vw);
      }

      .meta-header {
        &.type-header {
          width: 1000px;
        }
      }

      .exam-type-con {
        width: auto;
      }

      .readingtest-con {
        .readingtest-wrap {
          //   height: calc(32vh - 2rem);
        }
      }
    }

    &.register-con {
      height: 100%;
      .meta-header {
        margin: 2rem auto 0;
        h1 {
          margin-bottom: 0;
        }
      }

      .inner-header {
        &.survey-header {
          // margin: 1rem auto 0;
          margin: 1rem auto 1rem;
        }
      }

      #student-register {
        height: auto;
        margin: 1rem auto 3rem;
        overflow: visible;
        justify-content: center;
        // width: 350px;
        tbody {
          tr {
            // width: 550px;
            td {
              .ant-form-item {
                //   width: 500px;
                margin-left: auto;
                margin-right: auto;
                margin-bottom: 1.4rem;
              }

              .ant-form-item-label {
                width: 100%;
                text-align: left;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
  .meta-con {
    &.guide-con {
      .meta-header {
        &.type-header {
          width: 750px;
        }
      }

      .test-wrap {
        .arrow-wrap {
          top: 30%;
        }
      }
    }

    &.register-con {
      #student-register {
        tbody {
          tr {
            td {
              .ant-form-item-control-input {
                width: 300px;
              }
            }
          }
        }
      }
    }
    .readingtest-con {
      .readingtest-wrap {
        height: calc(45vh - 2rem);
        &.passage-con {
          // height: calc(32vh - 2rem);
          img {
            width: auto;
            height: 85%;
            max-width: 100%;
            max-height: 100%;
            margin: 0 auto;
            display: block;
          }
        }
        .reading-a-con {
          .reading-a-wrap {
            .reading-a-num {
              min-height: calc(8vh - 3rem);
            }
          }
        }
      }
    }
  }
}

/* Portrait */
@media only screen and (min-width: 1024px) and (max-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
  .meta-con {
    width: 95%;
    height: 100%;
    .inner-header {
      max-width: 900px;
    }

    &.register-con {
      #student-register {
        tbody {
          tr {
            &:nth-child(1) {
              td {
                &:nth-child(2) {
                  .ant-form-item-control {
                    .ant-form-item-control-input-content {
                      input.partInput {
                        &:nth-child(1) {
                          width: 110px;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .meta-header {
      &.test-header {
        padding: 1rem;
      }
    }

    .readingtest-con {
      height: auto;
    }
  }
}

/* ----------- iPad Pro 10.5" ----------- */
/* Portrait */
@media only screen and (min-width: 834px) and (max-width: 834px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
  .meta-con {
    width: 95%;
    height: 100%;
    .inner-header {
      max-width: 750px;
      h1 {
        &.main-tit {
          font-size: 1.5rem;
        }
      }
      h2 {
        font-size: 1.3rem;
      }
    }

    #student-register {
      margin: 0rem auto 0;
      .ant-form-item-label > label {
        font-size: 1rem;
      }

      input.partInput {
        &:nth-child(2) {
          width: 75px;
        }
        &:nth-child(3) {
          width: 75px;
        }
      }

      tbody {
        tr {
          td {
            .ant-form-item {
              margin-right: 1rem;
            }
          }

          &:nth-child(1) {
            td {
              &:nth-child(2) {
                .ant-form-item-control {
                  .ant-form-item-control-input-content {
                    input.partInput {
                      font-size: 0.8rem;
                      &:nth-child(1) {
                        width: 108px;
                      }
                      &:nth-child(2) {
                        width: 100px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 820px) and (orientation: portrait) {
  .meta-con {
    &.guide-con {
      .meta-header {
        &.type-header {
          width: 600px;
        }
      }

      .exam-type-con {
        .test-wrap {
          width: 150px;
          display: flex;
          align-items: center;

          .arrow-wrap {
            height: 100px;
            left: 35%;
            top: 43%;
          }
        }
      }

      .readingtest-con {
        margin: 3rem auto;
      }
    }

    &.register-con {
      height: auto;
    }

    #student-register {
      display: flex;
      flex-direction: row;
      height: auto;

      input.plainInput {
        width: 100px;
        max-width: 100%;
      }

      tbody {
        display: flex;
        margin: 0 auto;
        flex-direction: column;
        align-items: inherit;
        justify-content: center;
        tr {
          &:nth-child(2) {
            .ant-form-item-control {
              .ant-form-item-control-input-content {
                input.partInput {
                  &:nth-child(1) {
                  }
                }
              }
            }
          }
          td {
            display: block;
            width: 100%;

            &:nth-child(2) {
              margin-left: 0;
            }

            .ant-form-item-label {
              width: 100%;
              text-align: left;
            }
          }
        }
      }
    }

    .survey-wrap {
      div {
        display: block;
      }

      h2.survey-q {
        justify-content: start;
      }

      .survey-sub-q {
        margin-left: 3rem;
        &:nth-child(3) {
          .survey-a {
            width: 100%;
          }
        }
        .survey-a {
          width: 100%;
        }
      }

      .survey-a {
        width: 100%;
        margin-left: 3.3rem;
      }

      &.single-survey {
        display: block;
      }
    }

    &.meta-full-con {
      .exam-type-con {
        width: 450px;
        justify-content: center;
      }
    }

    .meta-header {
      &.type-header {
        margin: 0 auto 1rem;
      }
    }
  }
}

/* ipad air */
@media only screen and (min-width: 820px) and (max-width: 1180px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
  .meta-con {
    height: 100%;
    .inner-header {
      width: 600px;
      max-width: 100%;
    }

    #student-register {
      display: flex;
      flex-direction: row;

      input.plainInput {
        font-size: 1rem;
        max-width: 100%;
        width: 150px;
      }

      input.partInput {
        font-size: 1rem;
        &:nth-child(1) {
          width: 75px;
        }
      }

      .ant-picker-input > input {
        font-size: 1rem;
      }

      tbody {
        display: flex;
        margin: 0 auto;
        flex-direction: column;
        align-items: inherit;
        justify-content: center;
        tr {
          td {
            display: block;
            width: 100%;

            &:nth-child(2) {
              margin-left: 0;
            }

            .ant-form-item {
              margin-right: 0;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1100px) {
  .meta-con {
    .metalogin-wrap {
      width: 43rem;
      height: 18rem;
      padding: 2rem;
      margin: 1rem 0 2rem;
    }

    .process-btn-con {
      margin-top: 1rem;
    }
  }
}

@media screen and (max-width: 950px) {
  .meta-con {
    .exam-type-con {
      .test-wrap {
        margin: 0 0rem;
        div {
          img {
            transform: scale(0.7);
          }
        }
      }
    }

    #student-register tbody tr td .alert-label-noTime {
      bottom: -2.5rem;
    }
  }
}

@media screen and (max-width: 950px) and (orientation: landscape) {
  .meta-con {
    &.guide-con {
      .exam-type-con {
        .test-wrap {
          .arrow-wrap {
            height: 100px;
            left: 30%;
          }
        }
      }
      .readingtest-con {
        height: auto;
        .readingtest-wrap {
          height: 200px;
          &.passage-con {
            margin: 0 3rem 0 0.5rem;
          }
        }
      }
    }

    &.meta-full-con {
      height: auto;
    }

    .readingtest-con {
      height: auto;
    }
    .type-reading-con {
      height: 100%;
      h3 {
        font-size: 1rem;
      }
    }

    .meta-header {
      &.readingTest-header {
        margin: 2rem auto 0;
        h1.main-tit {
          margin: 1rem auto;
          font-size: 1.3rem;
        }
      }
    }

    .exam-type-con {
      width: auto;

      .test-wrap {
        h3 {
          margin: -1rem 0 1rem;
        }

        .meta-chkImg {
          width: 20px;
        }
      }
    }
  }
}

@media (max-width: 800px) /* and (max-height: 1200px) */ {
  .meta-con.meta-full-con {
    width: 100%;
    height: auto;
    min-height: 100vh;
  }

  .meta-con {
    height: 100vh;
    .inner-header {
      top: 11rem;
      width: 100%;
      max-width: 500px;

      h2 {
        font-size: 1.2rem;
      }
    }

    .inner-header.login-header {
      margin: 0rem 0 0;
      width: 100%;
      max-width: 100%;

      h2 {
        font-size: 2rem;
      }
    }

    .meta-header.onlyTxt-header {
      top: 0rem;
      transform: translate(0, 0);
      h1.main-tit {
        font-size: 2rem;
        margin-top: 0;
      }
    }

    h1.main-tit {
      font-size: 2rem;
      margin-top: 1.5rem;
    }

    .metalogin-wrap {
      height: 70%; /*
      max-height: 500px;
      max-width: 1000px;*/
      width: 80%;
      padding: 0;
      margin: 0;

      h1 {
        font-size: 2rem;
        margin: 2rem auto 2rem;
      }

      .ant-form-item {
        width: 98%;
        max-width: 98%;
      }

      .ant-form-item-label {
        text-align: left;
        margin-bottom: 8px;
        width: 29%;
      }

      .ant-form-item-label > label {
        margin-top: 0.5rem;
        font-size: 1.2rem;
        margin-left: 2rem;
      }

      .ant-form-item-control-input {
        width: 85%;
        max-width: 100%;
        margin: 0 auto;
      }
      .ant-form-item-control-input-content {
        flex: none;
        margin: 0 auto;

        input.partInput {
          font-size: 1.4rem;
        }
      }

      .btn {
        width: 100%;
        height: 50%;
        a {
          font-size: 1rem;
          position: relative;
          top: 0rem;
        }
      }
    }

    /* userinfo */
    &.register-con {
      .inner-header {
      }
    }

    .userInfo-wrap {
      max-width: 1600px;
      margin: 1rem auto 0;
    }
    #student-register {
      margin: 1rem auto;
      .ant-form-item-label > label {
        font-size: 1rem;
      }

      .common-form {
        margin: 0.2rem auto;
      }

      .plain-form {
        width: 100%;
        max-width: 1800px;

        .ant-col-sm-8 {
          max-width: 200px;
        }

        .ant-col-sm-16 {
          max-width: 73%;
        }

        .ant-form-item {
          max-width: 1350px;
          align-items: normal;
          flex-direction: inherit;
        }

        .ant-form-item-control-input-content {
          width: 100%;
          max-width: 1500px;
        }
      }

      input.partInput {
        width: 85px;
        margin: 0 7px 0 0rem;
        max-width: 100%;
        font-size: 1.5rem;
      }

      .partInput_wrap {
        width: 100%;
        max-width: 100%;
        display: flex;
      }

      .divided-form {
        max-width: 870px;
        width: 100%;
        justify-content: space-around;
        flex-direction: inherit;

        .ant-form-item {
          width: 100%;
          max-width: 550px;
        }

        .ant-form-item-label > label {
          font-size: 1.3rem;
          width: 290px;
        }

        .ant-col-sm-16 {
          width: 100%;
          max-width: 400px;
          max-width: 60%;
        }

        .ant-col-xs-24 {
          margin-left: -8px;
        }
      }

      input.plainInput {
        margin-top: -0.5rem;
        font-size: 1.5rem;
        width: 100%;
      }

      .ex-span {
        max-width: 70px;
        font-size: 0.9rem;
        position: relative;
        width: 100%;
      }

      .ant-picker {
        width: 100%;
        max-width: 400px;
        font-size: 2rem;
      }

      .ant-picker-input > input {
        font-size: 1.3rem;
      }
    }

    .btn {
      width: 100%;
      max-width: 200px;
      height: 100%;
      max-height: 50px;
      a {
        font-size: 1.1rem;
      }

      &.fixed_btn {
        bottom: 8rem;
        display: flex;
        align-items: center;
      }

      &.fixed_btm_btn {
        bottom: 5rem;
      }
    }

    /*survey*/
    .survey-wrap {
      margin-top: 1.5rem;
      width: 100%;
      max-width: 1150px;
      h2.survey-q {
        font-size: 1rem;
        justify-content: start;
        align-items: center;
        font-weight: 600;
        span.circle-num {
          width: 40px;
          height: 40px;
          padding: 0.5rem 1rem;
        }
      }

      .survey-sub-q {
        //  margin-left: 2.8rem;
        flex-direction: column;
        h2 {
          font-size: 0.9rem;
          width: auto;
          margin-left: 0;
        }

        .survey-a {
          width: 100%;
          margin-left: 0;
        }

        .partInput.survey-a {
          width: 10%;
          margin: 0 0.5rem;
          max-width: 200px;
          text-align: center;
          font-size: 1rem;

          & + span {
            width: 10%;
            align-items: center;
            align-self: center;
            font-size: 1rem;
            max-width: 50px;
          }
        }

        &:nth-child(3) {
          .survey-a {
            display: flex;
          }
        }
      }

      .survey-a {
        margin-left: 3.3rem;
        .ant-radio-wrapper {
          margin: 0 0px;
          align-items: center;
          justify-content: center;
        }
        span.ant-radio {
          & + * {
            font-size: 1rem;
          }
        }

        .ant-radio-inner {
          transform: scale(1.3);
        }
      }
    }

    /* metaexam type */
    &.guide-con {
      .meta-header.type-header {
        width: 500px;
      }

      .test-wrap {
        .arrow-wrap {
          width: 100px;
          height: 100px;
          position: absolute;
          left: 37%;
          top: 0%;
        }
      }
    }
    .meta-header.type-header {
      width: 100%;
      max-width: 550px;
      margin: 2rem auto;
      top: 2rem;
      h2 {
        font-size: 1.3rem;
        margin-top: 0rem;
        position: relative;
        top: 0rem;
      }
    }
    .exam-type-con {
      flex-direction: column;
      width: 100%;
      justify-content: center;
      align-items: center;
      display: inline-table;
      height: 250px;

      position: relative;
      top: 0;

      .test-wrap {
        margin: 1rem 0rem;
        width: 50%;
        flex-direction: column;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        a {
          img {
            transform: scale(0.8);
          }
        }

        h3 {
          font-size: 1.3rem;
          margin: -1rem 0 0.5rem;
        }

        .meta-chkImg {
          width: 30px;
          margin: 0 auto;
        }

        &.no-chk {
          margin: 0rem;
        }
      }
    }

    /* exam info */
    .type-reading-con.finished-con {
      margin-top: 0;
      h3 {
        font-size: 1.5rem;
      }
    }

    /* sample question */
    .sample-q-con {
      height: 100%;
      max-height: 600px;
      width: calc(90% - 3rem);
      max-width: 1500px;

      .meta-header.readingTest-header {
        margin: 5rem auto 0;
      }

      .readingtest-con .readingtest-wrap .reading-a-con .reading-a-wrap {
        margin: 0.2rem 0;
      }

      .process-btn-con {
        width: 100%;
        display: flex;
        margin: 1rem 0 5.5rem;
      }

      .meta-header.test-header {
        padding: 0;
        .burger-wrap {
          img {
            width: 10%;
            margin-right: 5px;
          }
          h2 {
            font-size: 1.5rem;
          }
        }

        h2.num {
          width: 50px;
        }

        .question-wrap h2 {
          width: 80px;
        }
      }

      .meta-header.readingTest-header h1.main-tit {
        font-size: 1rem;
      }
      .readingtest-con {
        .readingtest-wrap {
          .reading-a-con {
            .reading-a-wrap {
              max-height: 40px;
              height: 100%;
              align-items: center;
              .reading-a-num {
                width: 50px;
                padding: 0.5rem;
                h3 {
                  font-size: 1.3rem;
                }
              }
              .reading-a {
                h3 {
                  font-size: 1rem;
                }
              }
            }
          }
        }
      }
    }
    .btn.confirm_btn {
      margin: 1rem auto 0;
      align-items: center;
      max-height: 50px;
      a {
        font-size: 1.5rem;
      }
    }

    /* test sheet - readingtest.js */
    .meta-header.test-header {
      margin: 0 auto;
      padding: 1rem 3rem;
      .burger-wrap {
        img {
          width: 10%;
          margin-right: 5px;
          margin-left: 10px;
        }
        h2 {
          width: 300px;
          font-size: 1.5rem;
          margin-bottom: 0.2rem;
        }

        h2.num {
          width: 80px;
        }
      }

      h2 {
        font-size: 1.3rem;
      }

      .time-wrap {
        h2 {
          width: 85px;
        }
      }

      .question-wrap {
        h2 {
          font-size: 1.3rem;
          width: 120px;
        }
      }
    }

    .meta-header.readingTest-header {
      width: calc(100% - 2rem);
      margin: 0px auto -10px;
      h1.main-tit {
        font-size: 1.15rem;
        margin-top: 0rem;
      }
    }

    .readingtest-con {
      flex-direction: column;
      width: 100%;
      height: calc(90vh - 10rem);
      .readingtest-wrap.passage-con {
        font-size: 1.15rem;
        padding: 1.5rem;
        width: 95%;
      }

      .readingtest-wrap {
        width: 95%;
        margin: -14px auto 0;
        &.passage-con {
          padding: 1rem;
          margin: 3rem 1rem 2rem 1rem;
        }

        .reading-q-con {
          padding: 1rem 1rem;
          h1 {
            font-size: 1.2rem;
          }
        }

        .reading-a-con {
          .reading-a-wrap {
            // height: 40px;
            height: auto;
            max-height: 100%;
            align-items: center;
            margin: 0.5rem 0;
            .reading-a {
              width: 100%;
              display: flex;
              align-items: center;
              word-break: break-all;
              h3 {
                margin-top: 0;
                font-size: 1rem;
              }
            }

            .reading-a-num {
              padding: 0.3rem;
              width: 70px;
              min-height: calc(11vh - 3rem);
              h3 {
                font-size: 1rem;
              }

              .checkedImg {
                width: 80%;
                max-width: 80px;
              }
            }
          }
        }
      }
    }
    .ant-tooltip-inner {
      font-size: 1rem;
    }

    .process-btn-con {
      margin: 1rem 0 0;
    }

    .btn.confirm_btn {
      margin: 27rem auto 0;
      align-items: center;
      max-height: 60px;
      a {
        font-size: 1.3rem;
      }
    }
    /*metacon finished */
  }

  /* MetaReport.js */
  .meta-report-con {
    width: 100%;
    height: 100%;

    .lexile-logo-con {
      background: #c0e5fc;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 2rem 0;
      margin-bottom: 2rem;

      img {
        max-width: 450px;
      }
    }

    .userInfo-con {
      width: 100%;
      display: flex;
      .userInfo {
        display: inline-flex;
        flex-direction: column;
        &.left {
          margin-right: auto;
          width: 45%;
          display: flex;
          flex-direction: row;
          align-items: end;
          margin-bottom: 0.3rem;
          h2 {
            margin: 0 1rem 0 1rem;
            color: #2e76b3;
            font-size: 1.2rem;
          }
        }

        &.right {
          margin-left: auto;
          width: 25%;
          justify-content: end;

          .ant-row {
            .ant-col {
              border: 1px solid #b3dbfc;
              padding: 0.3rem;
              text-align: center;
              background: #fff;
              color: #2e76b3;
            }

            .ant-col-4 {
            }
          }

          .user-table {
            display: table;
            div {
              display: table-cell;
            }
          }
        }
      }
    }

    .report-top {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin: 0.5rem 0 0rem;
      background: #fff;
      padding: 4rem 0rem 1rem;
      border: 1px solid #b3dbfc;
      .part-con {
        width: 40%;
        display: flex;
        justify-content: center;

        &:nth-child(1) {
          flex-direction: column;
          position: relative;
          left: 3rem;
          transform: translate(10px, 10px);
          text-align: center;

          img {
            width: 100%;
            position: relative;
            top: -6rem;
            left: -1rem;
          }

          h1 {
            font-size: 2.2rem;
            font-weight: 700;
            position: relative;
            top: -3.5rem;
            left: 0rem;
            margin-bottom: 0;
          }

          h4 {
            font-size: 1.2rem;
            margin-top: 0rem;
            color: #2e76b3;
            top: -2.5rem;
            left: 0rem;
            position: relative;
          }
        }

        &:nth-child(2) {
          div.donut-wrap {
            position: relative;
            left: -2rem;
            div {
              position: absolute;
              top: 53.5%;
              left: 52%;
              transform: translate(-50%, -50%);
              h3 {
                font-size: 1rem;
                color: #2d75b3;
                margin-bottom: 0;
              }

              h2 {
                font-size: 2.3rem;
                font-weight: 700;
              }
            }
          }
        }

        .ant-progress {
          width: 300px !important;
          height: 300px !important;
          margin-left: -4rem;
          .ant-progress-inner {
            width: 100% !important;
            height: 100% !important;
          }
        }
      }
      .ant-table-content {
        table {
          table-layout: fixed !important;
          text-align: center;
        }
      }
    }
    /* report top end*/
    .report-middle-new {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 2rem 0rem 0rem;

      .card-con {
        display: flex;
        flex-direction: row;
        width: 100%;
        margin: 1rem 0;
        border: 1px solid #ddd;

        &:nth-child(1) {
          background: #fcbf15;
        }
        &:nth-child(2) {
          background: #09a5e2;
        }
        &:nth-child(3) {
          background: #90ce4f;
        }
        &:nth-child(4) {
          background: #cf88d8;
        }

        .bg {
          padding: 1rem;
          margin: 1rem 0;
          width: 40%;
          flex-direction: row;
          display: flex;
          justify-content: center;

          div.subject-con {
            flex-direction: row;
            display: flex;
            background: #fff;
            justify-content: space-between;
            align-items: center;
            border-radius: 20px;
            width: 100%;
            padding: 1rem 2rem;
            h3 {
              font-size: 1.2rem;
              margin-bottom: 0;
            }
          }
        }

        div.progress-con {
          background: #fff;
          width: 60%;
          flex-direction: column;
          display: flex;
          margin-left: 1rem;
          padding: 1rem 2rem;

          .progress-wrap {
            flex-direction: row;
            display: flex;
            align-items: center;

            span {
              padding: 0.5rem 0.3rem;
              width: 38%;
              font-weight: 600;
              max-width: 100px;
              font-size: 1.05rem;
            }

            &.ur-score {
              color: #2d75b3;
            }
            &.avg-score {
              color: #b68a17;
            }
            &.avg-dev {
              color: #759b5d;
            }
          }
        }
      }
    }

    .bookUrl-con {
      padding: 2rem 0rem;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 2rem auto;

      div {
        border: 1px solid #b3dbfc;
        padding: 0rem 2rem 2rem;
        background: #fff;
        width: 100%;
        max-width: 1360px;
        display: flex;
        flex-direction: column;
        margin: 0 auto;
      }

      * {
        margin-left: auto;
      }

      img {
        max-width: 200px;
        margin: 2rem 0rem 1rem auto;
      }

      a {
        margin-top: 0rem;
        margin-right: 0;
        font-size: 1rem;
      }
    }

    .result-table-con {
      .ant-table {
        width: 100%;
        max-width: 1360px;
        border: 1px solid #b3dbfc;
      }
      .ant-table-thead > tr > th,
      .ant-table-tbody > tr > td {
        text-align: center;
      }

      .ant-table-thead > tr > th {
        background: #67b7fb;
        color: #fff;
        font-size: 1rem;
      }

      .ant-table-tbody > tr > td {
        font-weight: 600;
        font-size: 1.2rem;
      }

      .ant-table-tbody > tr > td:nth-child(1),
      .ant-table-tbody > tr > td:nth-child(6) {
        color: #2e76b3;
      }

      .ant-table-tbody > tr > td:nth-child(2),
      .ant-table-tbody > tr > td:nth-child(3),
      .ant-table-tbody > tr > td:nth-child(4) {
        color: #528233;
      }

      .ant-table-tbody > tr > td:nth-child(5) {
        color: red;
      }

      .ant-table-thead
        > tr
        > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
        background-color: rgba(255, 255, 255, 0.5);
      }
    }

    .report-footer-con {
      display: flex;
      margin: 1rem auto;
      flex-direction: row;
      width: 100%;
      max-width: 780px;
      padding: 1rem 2.5rem 0rem;
      font-size: 1.05rem;

      h5:nth-child(1) {
        margin-right: auto;
      }
      h5:nth-child(2) {
        color: #2d75b3;
        margin-left: auto;
      }
    }
  }
}

@media screen and (max-width: 820px) and (orientation: portrait) {
  .meta-con {
    width: 100%;

    .metalogin-wrap {
      width: 36rem;
    }

    #student-register {
      // height: 550px;
      //  width: 400px;
      margin: 3rem auto 3rem;
    }

    .readingtest-con {
      // height: calc(90vh - 10rem);

      .readingtest-wrap {
        &.passage-con {
          //  height: auto;
          margin: 1rem auto;
        }

        .reading-a-con {
          .reading-a-wrap {
            .reading-a-num {
              height: auto;
              min-height: 100%;
            }
          }
        }
      }
    }

    .process-btn-con {
      img {
        width: 85px;
      }
    }
  }
}

@media screen and (max-width: 800px) and (orientation: portrait) {
  .meta-con {
    width: 100%;

    &.guide-con {
      .readingtest-con {
        height: auto;
        margin: 0 auto;
        .readingtest-wrap {
          height: auto;
          &.passage-con {
            height: calc(50vh - 5rem);
          }
        }
      }
    }

    &.register-con {
      height: auto;
      position: relative;
      margin: 4rem auto 4rem;
    }

    h1.main-tit {
      margin: 2rem auto;
    }

    #student-register {
      display: flex;
      flex-direction: row;
      // width: calc(70vw - 10rem);
      // height: auto;
      // height: 500px;

      input.plainInput {
        font-size: 1rem;
        max-width: 100%;
        width: 100px;
      }

      input.partInput {
        font-size: 1rem;
        &:nth-child(1) {
          width: 75px;
        }

        &:nth-child(2),
        &:nth-child(3) {
          width: 70px;
        }
      }

      .ant-picker {
        font-size: 1rem;
      }

      .ant-picker-input > input {
        font-size: 1rem;
      }

      .ant-form-item-control-input-content {
        width: 250px;
      }

      tbody {
        display: flex;
        margin: 0 auto;
        flex-direction: column;
        align-items: inherit;
        justify-content: center;
        tr {
          td {
            display: block;
            width: 100%;

            &:nth-child(2) {
              margin-left: 0;
            }

            .ant-form-item {
              margin-right: 0;
              margin-bottom: 1rem;
            }

            .ant-form-item-label {
              width: 100%;
              text-align: left;
              margin-bottom: 0.5rem;
            }
          }
        }
      }
    }

    .meta-header {
      &.readingTest-header {
        margin: 2rem auto 0rem;
      }
    }

    .readingtest-con {
      .readingtest-wrap {
        margin: 0 auto;

        .reading-a-con {
          .reading-a-wrap {
          }
        }

        &.passage-con {
          height: auto;
          img {
            height: auto;
            margin: 0 auto;
            max-width: 100%;
            max-height: 300px;
            display: block;
            object-fit: contain;
          }
        }
      }
    }

    &.register-con {
      .survey-wrap {
        .survey-sub-q {
          &:nth-child(3) {
            .survey-a {
            }
          }

          .partInput.survey-a,
          .partInput.survey-a + span {
            //width: 20%;
          }
        }
      }
    }

    .survey-wrap {
      .survey-sub-q {
        &:nth-child(3) {
          .survey-a {
            //  width: 70%;
          }
        }
      }
      .survey-a {
        margin-left: 3.3rem;
      }

      &.single-survey {
        display: block;
      }
    }

    .process-btn-con {
      margin: 1rem 0 2rem;
      div {
        img {
          width: 100px;
        }
      }
    }

    .report-footer-con {
      position: relative;
      width: 90%;
      left: 0;
      flex-direction: row;
      font-size: 1rem;
      margin: 1rem auto;
      padding: 0 2rem;

      h5 {
        &:nth-child(2) {
          margin-left: 0;
        }
      }
    }
  }
}

/* for iphone 13 mini landscape */
@media screen and (max-width: 800px) and (orientation: landscape) {
  .meta-con {
    &.register-con {
      .inner-header {
        width: 700px;
        h2 {
          font-size: 1.2rem;
        }
      }
    }

    #student-register {
      .ant-form-item-label > label {
        font-size: 0.85rem;
      }

      tbody {
        tr {
          &:nth-child(1) {
            td {
              &:nth-child(2) {
                .ant-form-item-control {
                  .ant-form-item-control-input-content {
                    input.partInput {
                      &:nth-child(1) {
                        font-size: 0.9rem;
                      }
                      &:nth-child(2) {
                        //    width: 93px;
                      }
                    }
                  }
                }
              }
            }
          }

          &:nth-child(4) {
            td {
              .ant-row {
                .ant-col.ant-form-item-control {
                  .ant-form-item-control-input {
                    width: 150px;
                  }
                }
              }
            }
          }
          td {
            .ant-form-item {
              margin-right: 0;
            }
          }
        }
      }

      .ant-picker-input > input {
        font-size: 0.8rem;
      }

      input.plainInput {
        width: 50%;
        font-size: 0.9rem;
        margin-top: 0;
      }

      input.partInput {
        font-size: 0.9rem;
        &:nth-child(1) {
          width: 60px;
        }
        &:nth-child(2) {
          width: 60px;
        }
        &:nth-child(3) {
          width: 60px;
        }
      }
    }

    .survey-wrap {
      .survey-a {
        margin-left: auto;
      }

      .survey-sub-q {
        h2 {
          width: 100%;
        }

        .partInput.survey-a {
          width: auto;

          & + span {
            width: 20%;
          }
        }
      }
    }

    .readingtest-con {
      height: auto;
      min-height: 850px;
      overflow: auto;
      .readingtest-wrap {
        &.passage-con {
          height: auto;
          min-height: 200px;
          margin: 0rem 1rem 2rem 1rem;
        }
      }
    }
  }
}

@media (max-width: 1138px) {
  .meta-con {
    .metalogin-wrap {
      padding: 2rem 0;
      .ant-form-item {
      }

      .ant-form-item-control-input-content {
        input.partInput {
          font-size: 1rem;
        }
      }
    }

    #student-register {
      .ant-input-search.plainInput {
        width: 69%;
      }
    }
  }
}

@media (max-width: 1024px) {
  .meta-con .inner-header.login-header {
    margin: 1rem 0;
  }

  .meta-con {
    &.register-con {
      #student-register tbody tr td .alert-label-noTime {
        bottom: -2rem;
      }
    }

    .metalogin-wrap {
      .ant-form-item {
        width: 85%;
      }
      .ant-form-item-control {
        width: 70%;
      }

      .ant-form-item-control-input {
        width: 100%;
        max-width: 100%;

        .partInput:nth-child(1) {
          width: 35%;
          font-size: 1rem;
        }
      }

      .ant-form-item-control-input-content {
        .ant-picker-input {
          input {
            font-size: 1.05rem;
          }
        }
      }
    }

    .survey-wrap {
      .survey-sub-q {
        .partInput.survey-a {
          font-size: 0.9rem;
          max-width: 35px;
          max-height: 35px;
        }
        .partInput.survey-a + span {
          font-size: 0.9rem;
        }
      }
    }

    .meta-header.type-header {
      width: 85%;
      margin: 1rem auto 0;
      top: 0;
      max-width: 100%;
      h2 {
        font-size: 1rem;
      }
    }

    .exam-type-con {
      .test-wrap {
        a {
          img {
            width: 10rem;
          }
        }
      }
    }

    .btn.fixed_btn {
      bottom: 1rem;
    }

    .meta-header.test-header {
      .burger-wrap {
        img {
          width: 15%;
        }
        h2 {
          width: 170px;
          font-size: 1rem;
        }
      }

      h2 {
        width: 80px;
        font-size: 1rem;
        margin-bottom: 0;
      }

      .time-wrap {
        h2 {
          margin-right: 0;
        }
      }

      .question-wrap {
        h2 {
          margin-bottom: 0;
          margin-right: 0;
          font-size: 1rem;

          &.num {
            width: 70px;
            margin: 0;
          }
        }
      }

      & {
        .time-wrap {
          h2 {
            width: 60px;
          }
        }
      }

      & {
        .question-wrap {
          h2 {
            width: 85px;
            margin-bottom: 0;
          }
        }
      }
    }

    .sample-q-con {
      .readingtest-con {
        .readingtest-wrap.passage-con {
          height: calc(55vh - 7rem);
        }
      }
    }
  }

  .meta-report-con {
    .userInfo-con {
      .userInfo.left {
        width: 40%;
      }
    }

    .report-top {
      .part-con {
        &:nth-child(1) {
          h1 {
            font-size: 2.4rem;
          }
          h4 {
            font-size: 1.2rem;
          }
        }

        &:nth-child(2) {
        }
      }
    }

    .report-middle-new {
      padding: 0;
    }

    .result-table-con {
      padding: 0;
    }
  }
}

@media screen and (device-width: 1024px) and (max-width: 1024px) and (aspect-ratio: 16/10) {
  .meta-con {
    .inner-header.login-header {
      top: 12%;
      position: absolute;
    }

    .metalogin-wrap {
      .ant-form-item-control-input {
        .partInput:first-child {
          width: 35%;
        }
      }
    }

    .exam-type-con {
      .test-wrap {
        div {
          img {
            transform: scale(1.2);
          }
        }
      }
    }
  }

  .meta-con.meta-full-con {
  }
}

@media screen and (device-width: 1024px) and (max-width: 1024px) and (aspect-ratio: 16 / 9) {
  .meta-con {
    .inner-header {
      top: 1rem;
      width: 100%;
      max-width: 1700px;

      h2 {
        font-size: 2.2rem;
      }
    }

    .inner-header.login-header {
      margin: 0.5rem 0 0;
      h2 {
        font-size: 2.4rem;
      }
    }

    h1.main-tit {
      font-size: 1.7rem;
      margin-top: 0rem;
    }

    .metalogin-wrap {
      height: 100%;
      max-height: 250px;
      max-width: 550px;
      width: 100%;
      padding: 1rem 1rem;
      margin: 0.5rem 0 0rem;

      h1 {
        font-size: 2rem;
        margin: 0rem auto 1.5rem;
      }

      .ant-form-item {
        width: 100%;
        max-width: 450px;
        margin-bottom: 1rem;
        align-items: center;
      }

      .ant-form-item-label {
        width: 120px;
        text-align: left;
        max-width: 100%;
      }

      .ant-form-item-label > label {
        margin-top: 0rem;
        font-size: 1.3rem;
      }

      .ant-form-item-control-input {
        width: 100%;
        max-width: 100%;
      }

      .ant-form-item-control-input-content {
        flex: none;

        input.partInput {
          font-size: 1.5rem;

          &:nth-child(1) {
            width: 42%;
            font-size: 0.9rem;
          }

          &:nth-child(2) {
            font-size: 0.9rem;
          }
        }
      }

      .btn {
        width: 100%;
        max-width: 150px;
        height: 50%;
        max-height: 55px;
        margin: 1rem auto 0;
        a {
          font-size: 1.3rem;
          position: relative;
          top: 0rem;
        }
        span {
          font-size: 1.3rem;
          position: relative;
          top: 0rem;
        }
      }
    }

    /* userinfo */
    .userInfo-wrap {
      max-width: 1600px;
      margin: 1rem auto 0;
    }

    #student-register {
      width: 100%;
      max-width: 900px;
      height: auto;
      margin: 0 auto 0.5rem;
      display: flex;
      justify-content: center;

      .ant-input-search.plainInput {
        width: 69%;
      }

      input.partInput {
        font-size: 0.9rem;
      }

      .ant-input-search.plainInput input {
        font-size: 0.9rem;
      }

      tbody {
        tr {
          td {
            &:nth-child(2) {
              margin-left: 1rem;
            }

            .ant-form-item {
              margin-bottom: 1.3rem;
              margin-right: 1rem;

              .ant-form-item-control .ant-form-item-control-input-content input.partInput {
                width: 60px;
              }
            }
          }

          &:nth-child(1) {
            td:nth-child(2) {
              .ant-form-item-control {
                .ant-form-item-control-input-content {
                  input.partInput {
                    &:nth-child(1) {
                      width: 110px;
                    }
                    &:nth-child(2) {
                      width: 130px;
                    }
                  }
                }
              }
            }
          }

          &:nth-child(2) {
            td:nth-child(2) {
              .ant-form-item-control {
                .ant-form-item-control-input-content {
                  input.partInput {
                    &:nth-child(1) {
                      width: 50px;
                    }
                  }
                }
              }
            }
          }

          &:nth-child(3) {
            td:nth-child(2) {
              .ant-form-item-control {
                .plainInput.gradeInput {
                  width: 200px;
                  font-size: 1rem;
                }
              }
            }
          }
        }
      }

      .ant-input-serach.plainInput {
        width: 69%;
      }

      .ant-picker {
        width: 100%;
        max-width: 200px;
        font-size: 0.9rem;
      }

      .ant-form-item-label > label {
        font-size: 0.9rem;
        font-weight: 600;
      }

      .ant-form-item-label > label::before {
        content: '●';
        font-size: 1.5rem;
        color: #1462a1;
        margin: 0 0.2rem 3px 0.2rem;
      }

      .divided-form {
        width: 100%;
        max-width: 1100px;
        display: flex;
        flex-direction: row;

        &:nth-child(4) {
          width: 500px;
          left: 1rem;
          position: relative;
        }

        .ant-col-xs-24 {
          margin-left: 3px;
        }

        .ant-form-item {
          /*
          width: 45%;
          max-width: 600px;
          margin: 1rem 0.5rem;*/

          width: 100%;
          max-width: 600px;
          margin: 1rem 0.5rem;
        }

        .common-form.threeInput-form {
          input.partInput {
            width: 23%;
          }
        }

        .ant-form-item-label > label {
          font-size: 1.2rem;
        }

        .ant-col-sm-16 {
          width: 100%;
        }

        input.plainInput {
          width: 55%;
          text-align: center;
        }

        &:nth-child(3) {
          .common-form {
            &:nth-child(2) {
              .ant-form-item {
                position: relative;
                left: -5rem;
              }
            }
          }
        }
      }

      .threeInput-form {
        input.partInput {
          width: 20rem;
        }
      }

      .common-form {
        margin: 1rem auto;
        width: 600px;
      }

      .plain-form {
        /*
        width: 64%;
        max-width: 800px;*/
        width: 90%;
        max-width: 1100px;
        display: flex;
        /* justify-content: center; */

        .ant-form-item {
          max-width: 500px;
          width: 100%;
        }

        .ant-row .ant-form-item {
        }

        .ant-col-sm-8 {
          flex: none;
          width: 100%;
          max-width: 165px;
          text-align: left;
        }

        .ant-form-horizontal .ant-form-item-control {
          margin-left: 24px;
        }

        .ant-form-item-control-input-content {
          width: 100%;
          max-width: 500px;
        }
      }

      .ant-picker-input > input {
        font-size: 0.9rem;
      }

      .partInput_wrap {
        margin-left: 0rem;
        width: 202px;
        max-width: 100%;
      }

      .ex-span {
        position: absolute;
        width: 100%;
        max-width: 120px;
        margin-left: 10px;
        margin-top: 5px;
      }

      input.plainInput {
        width: 60%;
        max-width: 100px;
        text-align: center;
        font-size: 0.9rem;
      }

      input.partInput {
        /* width: 50px;
        margin: 0 1rem 0 0rem;*/
        text-align: center;

        width: 120px;
        margin: 0 7px 0 0rem;

        &:nth-child(1) {
          width: 80px;
        }

        &:nth-child(2),
        &:nth-child(3) {
          width: 60px;
        }
      }

      .btn {
        margin: 3rem auto;
        background: #c6edfe;
        color: #1462a1;
        border-radius: 30px;
        width: 150px;
        max-width: 100%;
        text-align: center;
        padding: 1rem;
        cursor: pointer;

        position: absolute;
        bottom: -1.5rem;
        left: 42rem;
      }

      .btn a {
        color: #1462a1;
        text-decoration: none;
        font-weight: 600;
        font-size: 1.3rem;
        text-align: center;
        margin: 0 auto;
      }
    }

    .btn {
      width: 100%;
      max-width: 150px;
      height: 100%;
      max-height: 50px;
      a {
        font-size: 1rem;
      }

      &.fixed_btn {
        bottom: 1rem;
        display: flex;
        align-items: center;
      }

      &.confirm_btn {
        span {
          font-size: 1.5rem;
        }
      }
    }

    /*survey*/
    .survey-wrap {
      width: 100%;
      max-width: 1150px;

      h2.survey-q {
        font-size: 1.1rem;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 600;
        span.circle-num {
          width: 40px;
          height: 40px;
          padding: 0.5rem;
          margin-right: 0.5rem;
        }
      }

      .survey-sub-q {
        margin-left: 2.8rem;
        margin-top: 0rem;
        margin-bottom: 0rem;

        &:nth-child(3) {
          .survey-a {
            width: 90%;
            margin-right: 0;
          }
        }

        h2 {
          font-size: 1rem;
          width: 88%;
        }

        .partInput.survey-a {
          width: 10%;
          margin: 3px 0rem;
          max-width: 200px;
          text-align: center;
          font-size: 1rem;
          padding: 0;
          height: 30px;

          & + span {
            width: 10%;
            align-items: center;
            align-self: center;
            font-size: 0.9rem;
            max-width: 50px;
          }
        }
      }

      .survey-a {
        .ant-radio-wrapper {
          margin: 0 5px;
          align-items: center;
          justify-content: center;
        }
        span.ant-radio {
          & + * {
          }
        }

        .ant-radio-inner {
          transform: scale(1.3);
        }
      }
    }

    /* metaexam type */
    .meta-header.type-header {
      width: 100%;
      max-width: 750px;
      margin: 0 auto;
      top: 0;
      h2 {
        font-size: 1rem;
        margin-top: 1rem;
        position: relative;
        top: 2rem;
      }
    }
    .exam-type-con {
      .test-wrap {
        margin: 0 0rem;
        div {
          img {
            transform: scale(0.8);
          }
        }

        h3 {
          font-size: 1.5rem;
          margin: 0rem 0 1rem;
        }

        .meta-chkImg {
          width: 25px;
          margin: 0 auto;
        }

        &.no-chk {
          margin: 0rem 0rem 2rem;
        }
      }
    }

    /* */
    .meta-header.onlyTxt-header {
      font-size: 2rem;
      margin-top: -4rem;
      top: 5rem;
      transform: translate(0, 0);
    }

    .type-reading-con {
      width: 100%;
    }

    .finished-con {
      h1 {
        /*
        margin: -3rem 0 2rem;*/
        margin: -1rem 0 1rem;
      }

      h3 {
        font-size: 1.3rem;
      }
    }

    /* sample question */
    .sample-q-con {
      height: 100%;
      max-height: calc(100vh - 14.8rem);
      width: calc(90% - 3rem);
      max-width: 1500px;

      .meta-header.test-header {
        .burger-wrap {
          img {
            width: 20px;
            margin-right: 25px;
          }
          h2 {
            font-size: 1rem;
          }
        }

        .time-wrap {
          h2.num {
            width: 70px;
            padding: 0.2rem 0;
            margin: 0rem 0rem 0rem -1rem;
          }
        }

        .question-wrap h2 {
          width: 60px;
          margin: 0 -0.3rem 0 1.3rem;
        }
      }

      .meta-header.test-header div.time-wrap {
        padding-left: 2rem;
      }

      .meta-header.test-header div.question-wrap {
        padding: 0rem;
      }
      .meta-header.test-header {
        padding: 0rem 0rem;
        height: calc(10vh - 2rem);

        h2 {
          font-size: 0.8rem;
          margin: 0rem 0rem 0rem -1rem;

          width: 60px;
        }
      }
      .meta-header.readingTest-header {
        margin: 1.5rem auto 0rem;
        width: 100%;
        h1.main-tit {
          font-size: 1rem;
        }
      }
      .readingtest-con {
        width: 100%;
        .readingtest-wrap {
          margin-top: -1rem;
          .reading-q-con {
            margin: 0;
            padding: 1rem 1rem;
            h1 {
              font-size: 0.8rem;
            }
          }
          .reading-a-con {
            .reading-a-wrap {
              max-height: 30px;
              height: 100%;
              align-items: center;
              margin: 0.3rem 0;
              .reading-a-num {
                width: 30px;
                padding: 0rem;
                h3 {
                  font-size: 0.7rem;
                }
              }
              .reading-a {
                h3 {
                  font-weight: 500;
                  font-size: 0.8rem;
                }
              }
            }
          }

          &.passage-con {
            height: calc(50vh - 5rem);
            margin: 0rem 3rem 1rem 1rem;
            font-size: 0.5rem;
          }
        }
      }

      .process-btn-con {
        margin: 0rem 0 1rem;
        img {
          width: 100%;
          max-width: 70px;
        }
      }
    }
    .btn.confirm_btn.start_btn {
      margin: 0.5rem auto 0;
      align-items: center;
      max-height: 30px;
      a {
        font-size: 1rem;
      }
    }

    /* test sheet - readingtest.js */
    .meta-header {
      margin: 1rem auto 2rem;
    }
    .meta-header.test-header {
      margin: 0 auto;
      padding: 0.5rem 5rem;
      .burger-wrap {
        img {
          width: 10%;
          margin-right: 10px;
          margin-top: 0px;
        }
        h2 {
          width: 300px;
          font-size: 1.2rem;
        }
      }

      h2 {
        font-size: 1.1rem;
        width: 75px;
        padding: 0.2rem 0;
        margin: 0;
      }
      h2.num {
        width: 90px;
        padding: 0.2rem 0;
        margin: 0rem 0rem 0rem -1rem;
      }

      div.time-wrap {
        h2 {
          font-size: 1rem;
          width: 70px;
        }
      }

      div.question-wrap {
        margin-left: -1rem;
        h2 {
          width: 75px;
          margin: 0 -0rem 0 1.3rem;
        }
      }
    }

    .meta-header.readingTest-header {
      h1.main-tit {
        font-size: 1.2rem;
        margin-top: 0rem;
        margin-bottom: 1rem;
      }
    }

    .readingtest-con {
      height: calc(100vh - 20rem);
      align-items: center;
      .readingtest-wrap.passage-con {
        font-size: 1rem;
        padding: 1rem;
        margin: 0rem 3rem 1rem 0.5rem;
      }

      .readingtest-wrap {
        .reading-q-con {
          margin-top: -2rem;
          padding: 0.5rem 0.5rem;
          h1 {
            font-size: 0.9rem;
          }
        }

        .reading-a-con {
          .reading-a-wrap {
            height: 35px;
            max-height: 100%;
            align-items: center;
            margin: 0.25rem 0;
            .reading-a {
              display: flex;
              align-items: center;
              h3 {
                margin-top: 0;
                font-size: 1rem;
              }
            }

            .reading-a-num {
              padding: 0.45rem;
              width: 40px;
              h3 {
                font-size: 0.85rem;
              }

              .checkedImg {
                width: 80%;
                max-width: 80px;
              }
            }
          }
        }
      }
    }

    .process-btn-con {
      margin: 0.5rem 1rem 0rem 1rem;
      width: calc(100% - 3rem);
      img {
        max-width: 90px;
      }
    }
    .ant-tooltip-inner {
      font-size: 0.8rem;
    }

    .btn.confirm_btn {
      margin: 1rem auto 0;
      align-items: center;
      max-height: 50px;
      a {
        font-size: 1.3rem;
      }
    }
    /*metacon finished */

    .type-reading-con.finished-con {
      h1 {
        margin: 1rem 0 1.5rem;
      }
      h3 {
        font-size: 1.2rem;
      }
    }
  }

  /* MetaReport.js */
  .meta-report-con {
    width: 100%;
    height: 100%;

    .lexile-logo-con {
      background: #c0e5fc;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 2rem 0;
      margin-bottom: 2rem;

      img {
        max-width: 500px;
      }
    }

    .userInfo-con {
      width: 100%;
      display: flex;
      .userInfo {
        display: inline-flex;
        flex-direction: column;
        &.left {
          margin-right: auto;
          width: 36%;
          display: flex;
          flex-direction: row;
          align-items: end;
          margin-bottom: 0.3rem;
          h2 {
            margin: 0 1rem 0 1rem;
            color: #2e76b3;
            font-size: 1rem;
          }
        }

        &.right {
          margin-left: auto;
          width: 25%;
          justify-content: end;

          .ant-row {
            .ant-col {
              border: 1px solid #b3dbfc;
              padding: 0.3rem;
              text-align: center;
              background: #fff;
              color: #2e76b3;
            }

            .ant-col-4 {
            }
          }

          .user-table {
            display: table;
            div {
              display: table-cell;
            }
          }
        }
      }
    }

    .report-top {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin: 0.5rem 0 3rem;
      background: #fff;
      padding: 3rem 1rem 2rem;
      border: 1px solid #b3dbfc;
      .part-con {
        width: 40%;
        display: flex;
        justify-content: center;

        &:nth-child(1) {
          flex-direction: column;
          position: relative;
          left: 2rem;
          top: 1rem;
          transform: translate(10px, 10px);
          text-align: center;

          img {
            width: 90%;
            position: relative;
            top: -8rem;
            left: 0rem;
          }

          h1 {
            font-size: 1.8rem;
            font-weight: 700;
            position: relative;
            left: 1rem;
            top: -4.5rem;
            margin-bottom: 0;
          }

          h4 {
            font-size: 1rem;
            margin-top: 0rem;
            position: relative;
            color: #2e76b3;
            top: -3.3rem;
            left: 1rem;
          }
        }

        &:nth-child(2) {
          div.donut-wrap {
            position: relative;
            left: -2rem;
            top: 0;
            div {
              position: absolute;
              top: 55.5%;
              left: 52%;
              transform: translate(-50%, -50%);
              h3 {
                font-size: 1rem;
                color: #2d75b3;
                margin-bottom: 0;
              }

              h2 {
                font-size: 3rem;
                font-weight: 700;
              }
            }
          }
        }

        .ant-progress {
          width: 300px !important;
          height: 300px !important;
          margin-left: -4rem;
          .ant-progress-inner {
            width: 100% !important;
            height: 100% !important;
          }
        }
      }
      .ant-table-content {
        table {
          table-layout: fixed !important;
          text-align: center;
        }
      }
    }
    /* report top end */

    .report-middle-new {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 2rem 0rem 0rem;

      .card-con {
        display: flex;
        flex-direction: row;
        width: 100%;
        margin: 1rem 0;
        border: 1px solid #ddd;

        &:nth-child(1) {
          background: #fcbf15;
        }
        &:nth-child(2) {
          background: #09a5e2;
        }
        &:nth-child(3) {
          background: #90ce4f;
        }
        &:nth-child(4) {
          background: #cf88d8;
        }

        .bg {
          padding: 1rem;
          margin: 1rem 0;
          width: 40%;
          flex-direction: row;
          display: flex;
          justify-content: center;

          div.subject-con {
            flex-direction: row;
            display: flex;
            background: #fff;
            justify-content: space-between;
            align-items: center;
            border-radius: 20px;
            width: 100%;
            padding: 1rem 2rem;
            h3 {
              font-size: 1.2rem;
              margin-bottom: 0;
            }
          }
        }

        div.progress-con {
          background: #fff;
          width: 60%;
          flex-direction: column;
          display: flex;
          margin-left: 1rem;
          padding: 1rem 2rem;

          .progress-wrap {
            flex-direction: row;
            display: flex;
            align-items: center;

            span {
              padding: 0.5rem 0.3rem;
              width: 30%;
              font-weight: 600;
              max-width: 100px;
            }

            &.ur-score {
              color: #2d75b3;
            }
            &.avg-score {
              color: #b68a17;
            }
            &.avg-dev {
              color: #759b5d;
            }
          }
        }
      }
    }

    .bookUrl-con {
      padding: 2rem 0rem;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0rem auto;

      div {
        border: 1px solid #b3dbfc;
        padding: 0rem 2rem 2rem;
        background: #fff;
        width: 100%;
        max-width: 1360px;
        display: flex;
        flex-direction: column;
        margin: 0 auto;
      }

      * {
        margin-left: auto;
      }

      img {
        margin: 2rem 2rem 0rem auto;
      }

      a {
        margin-top: 0rem;
      }
    }

    .result-table-con {
      .ant-table {
        width: 100%;
        max-width: 1360px;
        border: 1px solid #b3dbfc;
      }
      .ant-table-thead > tr > th,
      .ant-table-tbody > tr > td {
        text-align: center;
      }

      .ant-table-thead > tr > th {
        background: #67b7fb;
        color: #fff;
        font-size: 1rem;
      }

      .ant-table-tbody > tr > td {
        font-weight: 600;
        font-size: 1rem;
      }

      .ant-table-tbody > tr > td:nth-child(1),
      .ant-table-tbody > tr > td:nth-child(6) {
        color: #2e76b3;
      }

      .ant-table-tbody > tr > td:nth-child(2),
      .ant-table-tbody > tr > td:nth-child(3),
      .ant-table-tbody > tr > td:nth-child(4) {
        color: #528233;
      }

      .ant-table-tbody > tr > td:nth-child(5) {
        color: red;
      }

      .ant-table-thead
        > tr
        > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
        background-color: rgba(255, 255, 255, 0.5);
      }
    }

    .report-footer-con {
      display: flex;
      margin: 1rem auto;
      flex-direction: row;
      width: 100%;
      max-width: 830px;
      padding: 1rem 2.5rem 0rem;

      h5:nth-child(1) {
        margin-right: auto;
      }
      h5:nth-child(2) {
        color: #2d75b3;
        margin-left: auto;
      }
    }
  }
}

@media screen and (device-width: 1024px) and (min-width: 1024px) {
  .meta-con {
    .survey-header {
      top: 0;
    }

    .inner-header {
      h2 {
        font-size: 1.3rem;
      }
    }

    .btn.fixed_btn {
      bottom: 1rem;
    }

    .exam-type-con {
      .test-wrap {
        margin: 0 0rem;
        div {
          img {
            transform: scale(0.8);
          }
        }

        h3 {
        }

        &.ant-tooltip-open {
          margin-left: 2rem;
          margin-bottom: 1rem;
          margin-right: 0.5rem;
          div {
            a {
              img {
                transform: scale(1);
                /*position: relative;
                top: -0.5rem;
                left: -1rem;*/
              }
              h3 {
                margin: 1rem 0 0.3rem;
                position: relative;
                top: 0.7rem;
              }
            }
          }

          .ant-tooltip-open {
            position: relative;
            top: 1rem;
            img {
              width: 40px;
              margin: -0.8rem auto 0;
            }
          }
        }
      }
    }

    .report-footer-con {
      margin: 2rem auto 0;
    }

    &.guide-con {
      .exam-type-con {
        .test-wrap {
          margin: 0 0rem;
          div {
            img {
              transform: scale(0.8);
            }
          }

          h3 {
          }

          &.ant-tooltip-open {
            margin-left: 2rem;
            margin-bottom: 1rem;
            margin-right: 0.5rem;
            div {
              a {
                img {
                  transform: scale(1);
                  /*position: relative;
                  top: -0.5rem;
                  left: -1rem;*/
                }
                h3 {
                  margin: 1rem 0 0.3rem;
                  position: relative;
                  top: 0.7rem;
                }
              }
            }

            .ant-tooltip-open {
              position: relative;
              top: 1rem;
              img {
                width: 45px;
                margin: -1rem auto 0;
                position: relative;
                top: -0.5rem;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) and (aspect-ratio: 16 / 10) {
  .meta-con {
    &.guide-con {
      .test-wrap {
        .arrow-wrap {
        }
      }

      .exam-type-con {
        .test-wrap {
          &:nth-child(2),
          &:nth-child(3),
          &:nth-child(4) {
            top: 0;
          }
        }
      }
    }
  }
}

@media (max-width: 960px) and (aspect-ratio: 16 / 9) {
  .meta-con {
    //    height: calc(100vh - 5.5rem);

    .btn {
      width: 100%;
      max-width: 150px;
      height: 100%;
      max-height: 45px;
      a {
        font-size: 1rem;
      }
    }

    .btn.fixed_btn {
      bottom: 0.5rem;
      display: flex;
      align-items: center;
    }

    .meta-header.test-header {
      .burger-wrap {
        img {
          margin-top: 0;
          width: 10%;
          margin-right: 10px;
        }
      }
    }

    .metalogin-wrap {
      .ant-form-item-control-input {
        justify-content: center;
        input.partInput {
          font-size: 1.25rem;
        }
      }
    }

    .survey-wrap.single-survey {
      height: 38%;
      max-height: 140px;
      margin-top: -4rem;
    }

    .sample-q-con {
      .meta-header.test-header {
        .burger-wrap {
          width: 100%;
          max-width: 145px;
        }

        .question-wrap {
          h2 {
            margin: 0 0 0 1.3rem;
          }
        }
      }
    }

    .sample-q-con .meta-header.test-header .burger-wrap img {
      width: 15px;
      margin: 10px 20px 10px 0;
    }

    .sample-q-con .meta-header.readingTest-header h1.main-tit {
      font-size: 0.6rem;
    }

    .process-btn-con {
      margin: 10px 1rem 0rem 1rem;
    }

    .btn.confirm_btn.start_btn {
      margin: 0.5rem auto 0;
      align-items: center;
      max-height: 50px;

      a {
        font-size: 1.35rem;
      }
    }
  }

  /* MetaReport.js */
  .meta-report-con {
    width: 100%;
    height: 100%;

    .lexile-logo-con {
      background: #c0e5fc;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 2rem 0;
      margin-bottom: 2rem;

      img {
        max-width: 400px;
      }
    }

    .userInfo-con {
      width: 100%;
      display: flex;
      .userInfo {
        display: inline-flex;
        flex-direction: column;
        &.left {
          margin-right: auto;
          width: 45%;
          display: flex;
          flex-direction: row;
          align-items: end;
          margin-bottom: 0rem;
          h2 {
            margin: 0 1rem 0 1rem;
            color: #2e76b3;
            font-size: 1rem;
          }
        }

        &.right {
          margin-left: auto;
          width: 30%;
          justify-content: end;

          .ant-row {
            .ant-col {
              border: 1px solid #b3dbfc;
              padding: 0.3rem;
              text-align: center;
              background: #fff;
              color: #2e76b3;
            }

            .ant-col-4 {
            }
          }

          .user-table {
            display: table;
            div {
              display: table-cell;
            }
          }
        }
      }
    }

    .report-top {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin: 0.5rem 0 3rem;
      background: #fff;
      padding: 1rem 1rem;
      border: 1px solid #b3dbfc;
      .part-con {
        width: 40%;
        display: flex;
        justify-content: center;

        &:nth-child(1) {
          flex-direction: column;
          position: relative;
          left: 3rem;
          transform: translate(0px, 0px);
          text-align: center;
          top: -1rem;
          img {
            width: 90%;
            position: relative;
            top: -1rem;
            left: 0rem;
          }

          h1 {
            font-size: 1.8rem;
            font-weight: 700;
            position: relative;
            top: 0rem;
            left: 0;
            margin: 1rem 1rem;
          }

          h4 {
            font-size: 1rem;
            margin-top: 0rem;
            color: #2e76b3;
            position: relative;
            top: -0.5rem;
            left: 0;
          }
        }

        &:nth-child(2) {
          div.donut-wrap {
            position: relative;
            left: -2rem;
            top: 0;
            div {
              position: absolute;
              top: 55.5%;
              left: 52%;
              transform: translate(-50%, -50%);
              h3 {
                font-size: 1rem;
                color: #2d75b3;
                margin-bottom: 0;
              }

              h2 {
                font-size: 3rem;
                font-weight: 700;
              }
            }
          }
        }

        .ant-progress {
          width: 300px !important;
          height: 300px !important;
          margin-left: -4rem;
          .ant-progress-inner {
            width: 100% !important;
            height: 100% !important;
          }
        }
      }
      .ant-table-content {
        table {
          table-layout: fixed !important;
          text-align: center;
        }
      }
    }
    /* report top end */

    .report-middle-new {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 0rem 0rem 0rem;

      .card-con {
        display: flex;
        flex-direction: row;
        width: 100%;
        margin: 1rem 0;
        border: 1px solid #ddd;

        &:nth-child(1) {
          background: #fcbf15;
        }
        &:nth-child(2) {
          background: #09a5e2;
        }
        &:nth-child(3) {
          background: #90ce4f;
        }
        &:nth-child(4) {
          background: #cf88d8;
        }

        .bg {
          padding: 1rem;
          margin: 1rem 0;
          width: 40%;
          flex-direction: row;
          display: flex;
          justify-content: center;

          div.subject-con {
            flex-direction: row;
            display: flex;
            background: #fff;
            justify-content: space-between;
            align-items: center;
            border-radius: 20px;
            width: 100%;
            padding: 1rem 2rem;
            h3 {
              font-size: 1.2rem;
              margin-bottom: 0;
            }
          }
        }

        div.progress-con {
          background: #fff;
          width: 60%;
          flex-direction: column;
          display: flex;
          margin-left: 1rem;
          padding: 1rem 2rem;

          .progress-wrap {
            flex-direction: row;
            display: flex;
            align-items: center;

            span {
              padding: 0.5rem 0.3rem;
              width: 35%;
              font-weight: 600;
              max-width: 100px;
            }

            &.ur-score {
              color: #2d75b3;
            }
            &.avg-score {
              color: #b68a17;
            }
            &.avg-dev {
              color: #759b5d;
            }
          }
        }
      }
    }

    .bookUrl-con {
      padding: 2rem 0rem;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0rem auto;

      div {
        border: 1px solid #b3dbfc;
        padding: 0rem 2rem 2rem;
        background: #fff;
        width: 100%;
        max-width: 1360px;
        display: flex;
        flex-direction: column;
        margin: 0 auto;
      }

      * {
        margin-left: auto;
      }

      img {
        margin: 2rem 2rem 1rem auto;
      }

      a {
        margin-top: -1rem;
      }
    }

    .result-table-con {
      .ant-table {
        width: 100%;
        max-width: 1360px;
        border: 1px solid #b3dbfc;
      }
      .ant-table-thead > tr > th,
      .ant-table-tbody > tr > td {
        text-align: center;
      }

      .ant-table-thead > tr > th {
        background: #67b7fb;
        color: #fff;
        font-size: 1rem;
      }

      .ant-table-tbody > tr > td {
        font-weight: 600;
        font-size: 1rem;
      }

      .ant-table-tbody > tr > td:nth-child(1),
      .ant-table-tbody > tr > td:nth-child(6) {
        color: #2e76b3;
      }

      .ant-table-tbody > tr > td:nth-child(2),
      .ant-table-tbody > tr > td:nth-child(3),
      .ant-table-tbody > tr > td:nth-child(4) {
        color: #528233;
      }

      .ant-table-tbody > tr > td:nth-child(5) {
        color: red;
      }

      .ant-table-thead
        > tr
        > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
        background-color: rgba(255, 255, 255, 0.5);
      }
    }

    .report-footer-con {
      display: flex;
      margin: 1rem auto;
      flex-direction: row;
      width: 100%;
      max-width: 750px;
      padding: 1rem 2rem 0rem;

      h5:nth-child(1) {
        margin-right: auto;
      }
      h5:nth-child(2) {
        color: #2d75b3;
        margin-left: auto;
      }
    }
  }
}

@media screen and (max-width: 1000px) {
  .meta-con {
    .inner-header {
      &.survey-header {
        h2 {
          font-size: 1.2rem;
        }
      }
    }
  }
}

/* for meta-user-info on mobile landscape */
@media screen and (max-width: 1000px) and (orientation: landscape) {
  .meta-con {
    &.register-con {
      margin: 3rem auto 0;

      .report-footer-con {
        position: relative;
        left: 0;
        bottom: 0;
      }

      #student-register {
        /* .ant-form-item-label > label {
          font-size: 0.85rem;
        }

        tbody {
          tr {
            &:nth-child(1) {
              td {
                &:nth-child(2) {
                  .ant-form-item-control {
                    .ant-form-item-control-input-content {
                      input.partInput {
                        &:nth-child(1) {
                          font-size: 0.9rem;
                          max-width: 100%;
                        }
                        &:nth-child(2) {
                          width: 150px;
                        }
                      }
                    }
                  }
                }
              }
            }

            &:nth-child(2) {
              .ant-form-item-control {
                .ant-form-item-control-input-content {
                  input.partInput {
                    font-size: 0.9rem;
                    &:nth-child(1) {
                      width: 90px;
                    }
                    &:nth-child(2) {
                      width: 60px;
                    }
                    &:nth-child(3) {
                      width: 60px;
                    }
                  }
                }
              }
            }

            &:nth-child(3) {
              td {
                &:nth-child(2) {
                  .ant-form-item-control {
                    .plainInput.gradeInput {
                      width: 40%;
                    }
                  }
                }
              }
            }

            &:nth-child(4) {
              td {
                .ant-row {
                  .ant-col.ant-form-item-control {
                    .ant-form-item-control-input {
                      width: 150px;
                    }
                  }
                }
              }
            }

            td {
              .ant-form-item {
                margin-right: 0;
                margin-bottom: 1.3rem;
              }
            }
          }
        }*/

        .ant-picker-input > input {
          //  font-size: 0.8rem;
        }

        input.plainInput {
          //  width: 100px;
          // font-size: 0.9rem;
          margin-top: 0;
          max-width: 100%;
        }
      }
    }
  }
}

/* ... and portrait */
@media screen and (max-width: 1000px) and (orientation: portrait) {
  .meta-con {
    .readingtest-con {
      .readingtest-wrap {
        .reading-a-con {
          .reading-a-wrap {
            .reading-a-num {
              min-height: 100%;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 950px) {
  .meta-con {
    .survey-wrap {
      width: 100%;
    }

    &.register-con {
      #student-register {
        tbody {
          // width: 90%;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .meta-con {
    .type-reading-con {
      height: 100%;
      h3 {
        font-size: 1.2rem;
      }
    }
  }
}

@media screen and (max-width: 768px) and (orientation: landscape) {
  .meta-con {
    &.guide-con {
      height: auto;
      .readingtest-con {
        min-height: 100%;
        height: 100%;
        .readingtest-wrap {
          &.passage-con {
            height: 180px;
            min-height: 100%;
            margin: 0 auto 0;
          }

          .reading-q-con {
            margin: 1rem 0 0;
          }
        }
      }
    }

    &.register-con {
      margin: 3rem auto 0;

      .report-footer-con {
        position: relative;
        left: 0;
        bottom: 0;
        width: 100%;
      }
    }

    #student-register {
      margin: 0 auto;
      input.plainInput {
        margin-top: 0;
        font-size: 1rem;
        max-width: 100%;
        width: 100px;
      }

      input.partInput {
        font-size: 1rem;
        &:nth-child(1) {
          width: 90px;
        }
      }

      .ant-picker-input > input {
        font-size: 1rem;
      }

      .ant-form-item-label > label {
        font-size: 1rem;
      }
    }
  }
}

@media screen and (max-width: 768px) and (orientation: portrait) {
  .meta-con {
    &.guide-con {
      .meta-header {
        &.type-header {
          width: 550px;
        }
      }

      .exam-type-con {
        display: flex;
        height: auto;

        .test-wrap {
          .arrow-wrap {
            top: 0;
            left: 70%;
          }
        }
      }

      .test-wrap {
        .arrow-wrap {
          width: 100px;
          height: 100px;
          position: absolute;
          left: 45%;
          top: 0;
          transform: translate(0%, 0%) rotate(-30deg);
        }
      }
    }

    .meta-header {
      &.type-header {
        margin: 0 auto 0;
        max-width: 100%;
      }

      &.test-header {
        margin: 0rem auto;
        padding: 0.5rem 0;

        .burger-wrap {
          h2 {
            font-size: 1rem;
            width: 120px;
            margin-left: 0;
            margin-bottom: 0;
          }
        }

        div.time-wrap {
          margin-right: 0;
          h2 {
            width: 70px;
          }
        }

        h2 {
          text-align: center;
        }

        .question-wrap {
          margin-right: 0.5rem;
          margin-left: 1rem;
          h2 {
            width: 80px;
            margin-right: 0;
          }
        }
      }
    }

    &.guide-con {
      .readingtest-con {
        margin: 0 auto;
      }
    }

    &.register-con {
      height: auto;
      position: relative;
      margin: 4rem auto 4rem;

      .meta-header {
        margin: 1rem auto;
      }

      #student-register {
        margin: 0 auto 1.5rem;
        tbody {
          tr {
            td {
              .ant-form-item-label {
                width: 100%;
                text-align: left;
                margin-bottom: 0.2rem;
              }

              .alert-label-noTime {
                bottom: -2rem;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 630px) {
  .meta-con {
    #student-register {
      tbody {
        justify-content: start;
      }
    }
  }
}

@media screen and (max-width: 580px) {
  .meta-con {
    width: 100%;
    height: 900px;

    #student-register {
      .ant-form-item-label > label {
        font-size: 1rem;
      }

      .ant-form-item-control-input {
        margin-left: 1rem;
      }

      tbody {
        tr {
          td {
            .ant-form-item {
              margin-bottom: 1.2rem;
            }
          }
        }
      }
    }

    &.register-con {
      .survey-wrap {
        .survey-sub-q {
          margin-left: 0;
          h2 {
            text-align: center;
          }

          &:nth-child(2) {
            .survey-a {
              display: flex;
              justify-content: space-around;
            }
          }
        }
      }
    }

    .survey-wrap {
      .survey-sub-q {
        .survey-a {
          span.ant-radio + * {
            // margin: 0 0.3rem 0 1rem;
          }
        }
      }
      .survey-a {
        span.ant-radio + * {
          width: 70px;
        }
      }
    }

    .btn {
      max-width: 150px;
      max-height: 45px;
    }

    .meta-header {
      &.onlyTxt-header {
        width: 100%;
      }
    }

    .type-reading-con {
      &.finished-con {
        h3 {
          font-size: 1rem;
        }
      }
    }

    .report-footer-con {
      position: relative;
      width: 100%;
      left: 0;
      flex-direction: column;
      font-size: 1rem;
      margin: 0 auto;

      h5 {
        &:nth-child(2) {
          margin-left: 0;
        }
      }
    }
  }
}

@media screen and (max-width: 450px) {
  .meta-con {
    &.register-con {
      width: 100%;
      height: auto;
      margin: 3rem auto;

      .meta-header {
        margin: 1rem auto;
      }

      .inner-header {
        width: 380px;
        margin: 1rem 0;

        &.survey-header {
          width: 380px;
        }
      }

      h1.main-tit {
        font-size: 1.5rem;
      }

      #student-register {
        tbody {
          tr {
            td {
              width: 330px;

              .ant-form-item {
                margin-bottom: 0.8rem;

                .ant-form-item-label {
                  margin-bottom: 0;
                  padding: 0 0 0.3rem;
                }
              }

              .alert-label-noTime {
                bottom: -1rem;
              }
            }
          }
        }
      }

      .survey-wrap {
        .survey-sub-q {
          margin-left: 0rem;
          h2 {
            text-align: center;
          }
          .survey-a {
            display: flex;
            justify-content: center;
          }
        }

        .survey-a span.ant-radio + * {
          font-size: 0.9rem;
        }

        .survey-sub-q .partInput.survey-a + span {
          font-size: 0.9rem;
        }
      }
    }

    #student-register {
      width: 100%;

      margin: 0 auto;
      height: auto;

      .ant-form-item-label > label {
        font-size: 0.8rem;
      }
      .ant-picker-input > input {
        font-size: 0.8rem;
      }

      input.partInput {
        &:nth-child(1) {
          width: 70px;
        }

        &:nth-child(2),
        &:nth-child(3) {
          width: 60px;
        }
      }

      tbody {
        tr {
          &:nth-child(3) {
            td {
              &:nth-child(1) {
                .ant-form-item-control {
                  .plainInput.gradeInput {
                    width: 85%;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 425px) and (orientation: portrait) {
  .meta-close {
    right: 2rem;
  }

  .meta-con {
    &.guide-con {
      .meta-header {
        &.test-header {
          padding: 0;
        }
      }

      .readingtest-con {
        .readingtest-wrap {
          &.passage-con {
            margin: 2rem 0;
          }
        }
      }
    }

    .inner-header {
      width: 100%;
    }

    .meta-header {
      &.test-header {
        div {
          h2 {
            font-size: 0.7rem;
          }
        }

        div.time-wrap {
          h2 {
            width: 60px;
          }
        }

        div.question-wrap {
          margin-right: 0;
          margin-left: 0;
          h2 {
            width: 60px;
            font-size: 0.7rem;
          }
        }
      }
    }

    &.register-con {
      #student-register {
        tbody {
          tr {
            td {
              .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
              .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
                font-size: 0.8rem;
                width: 150px;
              }
            }
          }
        }

        .ant-picker {
          font-size: 0.9rem;
          max-width: 200px;
        }

        input.plainInput {
          font-size: 0.9rem;
        }
      }

      .survey-wrap {
        margin-top: 3rem;
        div {
          display: block;
          width: 100%;
        }

        h2.survey-q {
          span.circle-num {
            width: 35px;
            height: 35px;
          }
        }

        .survey-sub-q {
          margin-left: 0;
          margin-bottom: 0;
          h2 {
            font-size: 0.9rem;
            text-align: center;
          }

          .partInput.survey-a + span {
            font-size: 0.9rem;
          }

          .survey-a {
            span.ant-radio + * {
              font-size: 0.9rem;
              margin: 0;
            }
          }

          &:nth-child(3) {
            .survey-a {
              width: auto;
            }

            .partInput.survey-a {
              max-width: 100%;
              width: 45px;
            }
          }
        }

        &.single-survey {
          display: block;
          width: 100%;
          margin-top: 3rem;
        }

        .survey-a {
          margin: 0 auto;
          display: flex;
          justify-content: center;

          .ant-radio-wrapper {
            width: 80px;
          }

          .ant-radio-inner {
            transform: scale(1);
          }

          span.ant-radio + * {
            font-size: 0.9rem;
          }
        }
      }
    }

    &.meta-full-con {
      .inner-header {
        &.login-header {
          margin: 2rem 0 0rem;
          .login-top-logo {
            min-width: auto;
            width: 250px;
            max-width: 100%;
          }
        }
      }

      .meta-header {
        &.type-header {
          width: auto;
          display: flex;
          flex-direction: column;
          div {
            &:last-child {
              margin-top: 0.5rem;
            }
          }
        }
      }

      .metalogin-wrap {
        width: 20rem;
        height: 25rem;
        padding: 2rem 1rem;
        h1 {
          margin: 0 auto 2rem;
        }

        .ant-form-item {
          width: 100%;
          display: block;
        }

        .ant-form-item-control {
          width: 100%;
        }

        .ant-form-item-label {
          margin-bottom: 0;
          width: auto;
        }

        .ant-form-item-label > label {
          font-size: 1rem;
          margin-top: 0;
          margin-left: 0;
        }

        .ant-form-item-control-input {
          .partInput {
            &:nth-child(1) {
              width: 120px;
            }
          }
        }
      }

      .login-btm-logo {
        width: 130px;
      }

      .exam-type-con {
        width: auto;
      }
    }
  }
}

@media screen and (max-width: 380px) {
  .meta-close {
    right: 0;
  }

  .meta-con {
    position: relative;

    &.register-con {
      width: 100%;
      height: auto;
      margin: 5rem auto;

      .inner-header {
        width: 300px;
        &.survey-header {
          width: 300px;
          margin: 1rem 0;
          padding: 0 2rem;
        }

        h2 {
          font-size: 1.1rem;
        }
      }

      #student-register {
        margin: 0rem auto 3rem;

        input.plainInput {
          font-size: 0.85rem;
        }

        .ant-form-item-label > label {
          font-size: 0.8rem;
        }
        .ant-picker-input > input {
          font-size: 0.9rem;
        }

        .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
        .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
          font-size: 0.8rem;
        }

        input.partInput {
          &:nth-child(1) {
            width: 65px;
          }

          &:nth-child(2),
          &:nth-child(3) {
            width: 60px;
          }
        }

        tbody {
          tr {
            &:nth-child(1) {
              td {
                &:nth-child(2) {
                  .ant-form-item-control {
                    .ant-form-item-control-input-content {
                      input {
                        &.partInput {
                          &:nth-child(2) {
                            width: 95px;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }

            td {
              width: 280px;
              .ant-form-item {
                font-size: 1rem;
                margin-bottom: 0.5rem;
              }

              .ant-form-item .ant-form-item-label {
                padding: 5px 0 !important;
              }

              .ant-picker-suffix {
                transform: scale(0.7);
              }

              .alert-label-noTime {
                bottom: -3rem;
                font-size: 0.7rem !important;
              }
            }
          }
        }
      }
    }

    &.register-con {
      .survey-wrap {
        margin-top: 1rem;
        div {
          display: block;
          width: 100%;
        }

        h2.survey-q {
          font-size: 0.9rem;
        }

        .survey-sub-q {
          margin-left: 0;
          margin-bottom: 0;
          margin-top: 1.5rem;

          h2 {
            font-size: 0.9rem;
            text-align: center;
          }

          .partInput.survey-a + span {
            width: 30px;
            max-width: 100%;
            font-size: 0.8rem;
          }
          .survey-a {
            span.ant-radio + * {
              font-size: 0.8rem;
            }
          }

          &:nth-child(3) {
            .survey-a {
              width: auto;
            }
          }
        }

        &.single-survey {
          display: block;
          width: 100%;
          margin-top: 2rem;
        }

        .survey-a {
          margin: 0 auto;
          display: flex;
          justify-content: center;

          .ant-radio-wrapper {
            width: 75px;
          }

          .ant-radio-inner {
            transform: scale(1);
          }

          span.ant-radio + * {
            font-size: 0.9rem;
          }
        }
      }
    }

    .meta-header {
      &.type-header {
        h2 {
          font-size: 0.8rem;
        }
      }
    }

    .exam-type-con {
      .test-wrap {
        margin: 0;
        h3 {
          margin: -0.5rem 0 0.5rem;
        }
        .meta-chkImg {
          width: 20px;
        }
      }
    }

    .btn {
      max-width: 140px;
      max-height: 40px;
      a {
        font-size: 1rem;
      }
    }

    .report-footer-con {
      display: block;
      width: 100%;
    }
  }
}

/* print */
@media print {
  * {
    overflow: visible !important;
  }

  @page {
    margin: 0;
  }

  .meta-report-con {
    .lexile-logo-con {
      background: #c0e5fc;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 2.4rem 0;
      margin-bottom: 0.5rem;

      img {
        max-width: 400px;
      }
    }

    .userInfo-con {
      width: 100%;
      display: flex;
      .userInfo {
        display: inline-flex;
        flex-direction: column;
        &.left {
          margin-right: auto;
          width: 30%;
          display: flex;
          flex-direction: row;
          align-items: end;
          margin-bottom: 0rem;
          h2 {
            margin: 0 1rem 0 1rem;
            color: #2e76b3;
            font-size: 0.7rem;
          }
        }

        &.right {
          margin-left: auto;
          width: 30%;
          justify-content: end;

          .ant-row {
            .ant-col {
              border: 1px solid #b3dbfc;
              padding: 0.3rem;
              text-align: center;
              background: #fff;
              color: #2e76b3;
            }

            .ant-col-4 {
            }
          }

          .user-table {
            display: table;
            div {
              display: table-cell;
            }
          }
        }
      }
    }

    .report-top {
    }

    .report-top .part-con:nth-child(1) {
      top: 0rem;
      h1 {
        font-size: 1.5rem;
      }

      h4 {
        font-size: 1.2rem;
      }
    }
    .report-top .part-con:nth-child(1) img {
      width: 70%;
      left: -2.5rem;
      top: -5.7rem;
    }

    .report-top .part-con:nth-child(2) img {
      width: 65%;
      position: relative;
      left: 2.5rem;
    }

    .report-top .part-con:nth-child(2) div.donut-wrap div {
      position: absolute;
      top: 53.5%;
      left: 50%;
      transform: translate(-50%, -50%);

      h3 {
        position: relative;
        left: -0.5rem;
        font-size: 0.8rem;
      }

      h2 {
        font-size: 1.8rem;
        position: relative;
        left: -0.5rem;
      }
    }

    .report-middle-new {
      padding: 0rem 0rem 0rem;

      .card-con div.progress-con .progress-wrap span {
        padding: 0.2rem 0.3rem;
        width: 10%;
        font-weight: 600;
        max-width: 70px;
        font-size: 0.8rem;
      }

      .card-con {
        margin: 0.3rem 0;
        height: 120px;
        border: none;

        .bg div.subject-con h3 {
          font-size: 1rem;
        }
      }
    }

    .bookUrl-con {
      padding: 0rem 0rem 0.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0.5rem auto 0.3rem;

      div {
        margin: 0;
        padding: 0.5rem 2rem 0.5rem;
      }

      img {
        width: 17%;
        margin-top: 0;
        top: 10px;
        margin-bottom: 0;
      }
      a {
        margin-top: 0;
        font-size: 0.8rem;
      }
    }
    .result-table-con {
      padding: 0;
      margin: 0 auto;
    }
  }

  .report-footer-con {
    position: relative;
    top: -1.6rem;
  }

  .meta-report-con .report-middle-new .card-con {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 80px;
  }

  .meta-report-con {
    .report-middle-new {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 0rem 0rem 0rem;

      .card-con {
        display: flex;
        flex-direction: row;
        width: 100%;
        margin: 0.2rem 0;
        border: none;

        &:nth-child(1) {
          background: #fcbf15;
        }
        &:nth-child(2) {
          background: #09a5e2;
        }
        &:nth-child(3) {
          background: #90ce4f;
        }
        &:nth-child(4) {
          background: #cf88d8;
        }

        .bg {
          padding: 1rem;
          margin: 0.2rem 0;
          width: 40%;
          flex-direction: row;
          display: flex;
          justify-content: center;

          div.subject-con {
            flex-direction: row;
            display: flex;
            background: #fff;
            justify-content: space-between;
            align-items: center;
            border-radius: 20px;
            width: 100%;
            padding: 0rem 2rem;
            h3 {
              font-size: 0.9rem;
              margin-bottom: 0;
            }
          }
        }

        div.progress-con {
          background: #fff;
          width: 60%;
          flex-direction: column;
          display: flex;
          margin-left: 1rem;
          padding: 0.2rem 0.3rem 0 0;

          .progress-wrap {
            flex-direction: row;
            display: flex;
            align-items: center;

            span {
              padding: 0rem 0 0 1rem;
              width: 25%;
              font-weight: 600;
              max-width: 100px;
              font-size: 0.7rem;
            }

            &.ur-score {
              color: #2d75b3;
            }
            &.avg-score {
              color: #b68a17;
            }
            &.avg-dev {
              color: #759b5d;
            }
          }
        }
      }
    }

    .result-table-con {
      .ant-table {
        width: 100%;
        max-width: 1360px;
        border: 1px solid #b3dbfc;
      }
      .ant-table-thead > tr > th,
      .ant-table-tbody > tr > td {
        text-align: center;
      }

      .ant-table-thead > tr > th {
        background: #67b7fb;
        color: #fff;
        font-size: 1rem;
      }

      .ant-table-tbody > tr > td {
        font-weight: 600;
        font-size: 1rem;
      }
    }
  }
}

/* New changes */
.phone_parts {
  max-width: 100px !important;
  float: left !important;
}
.phone_part_main {
  width: 100% !important;
}
.student_part_login .ant-form-item-label {
  width: 100% !important;
  text-align: center;
}
.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
}
.phone_inputs {
  width: 95% !important;
}
.name_parts1 {
  float: left;
}
.name_parts2 {
  float: right;
  position: absolute;
  right: 0px;
}
.name_parts {
  top: -16px;
}
.name_parts_main {
  position: relative;
}

.name_parts_main .ant-form-item-explain-connected {
  margin-top: -13px;
}

.w-english-bg {
  background: #e3fdf8 !important;
}

.reportInfo-con {
  width: 100%;
  display: flex;
  .reportInfo {
    display: inline-flex;
    flex-direction: column;
    margin-left: 6em;
    margin-right: 20px;
    border: 1px solid #a1d667;
    .ant-row {
      .ant-col {
        padding: 0.7rem;
        text-align: center;
        background: #fff;
        color: #2e76b3;
        border-right: 1px solid #a1d667;
      }
    }

    .user-table {
      display: table;
      div {
        display: table-cell;
      }
    }
  }
  .lx {
    flex-direction: column;
    border: 1px solid #9e9e9e;
    border-radius: 25%;
    flex-direction: row;
    align-items: center;
    .label {
      padding: 10px;
      font-weight: 700;
      text-align: center;
    }
    .value {
      padding: 10px;
      font-weight: 700;
      text-align: center;
      color: #2e76b3;
    }
  }

  .level {
    flex-direction: column;
    border: 1px solid #9e9e9e;
    border-radius: 25%;
    margin-left: 25px;
    flex-direction: row;
    align-items: center;
    .label {
      padding: 10px;
      font-weight: 700;
      text-align: center;
    }
    .value {
      padding: 10px;
      font-weight: 700;
      text-align: center;
      color: #2e76b3;
    }
  }
}

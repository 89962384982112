@media(max-width:1023px)
{
.listen-wrapper
{
    .listen-content-loudspeaker
    {
        flex: 0 0 10%;
        max-width: 10%;
        justify-content: start !important;
        position: static;
        margin-top: 0px;
    }
    .listen-content-input
    {
        flex: 0 0 100%;
        max-width: 100%;
        justify-content: start !important;
    }
    .listen-content-loudspeaker
    {
        .ant-image,
        .ant-image img
        {
            width:30px !important;
            height:30px !important;
        }
    }
    .listen-content-middle
    {
        padding:20px;
        padding-top: 0px;
    }
    .keytext-listen
    {
        position: relative !important;
        margin-bottom: 100px !important;
        
        button
        {
            float: right !important;
            width: 100px !important;
            height: auto !important;
            position: absolute;
            bottom: -70px;
            padding: 10px;
            border-radius: 6px;
            right: 0;
        }
        .keytext-listen-textinside
        {
            font-size: 24px !important;
            margin: 0px !important;
            padding: 10px !important; 
        }
       
    }
    .keyboardkeyboard
    {
        .hg-row
        {
            .hg-button
            {
                height: 42px;
                color: #5B9BD5;
                font-size: 16px;
            }
        }
    }
}
.listen-content-input h4.ant-typography
{
    font-size: 16px !important;
}
.listen-content-input 
{
    margin-bottom: 40px !important;
}
.tophaedingspace
{
    padding-left: 0px !important;
    padding-right: 0px !important;
    h2 
    {
        span 
        {
            margin-left: 10px;
        }
    }
}
}
@media (max-width:700px)
{
    .listen-content-loudspeaker
    {
        flex: 0 0 100%;
        max-width: 100%;
        justify-content: start !important;
        position: static;
        margin-top: 0px !important;
    }
    .listen-wrapper .keytext-listen
    {
        margin-left:0px;
    }
    .listen-content-loudspeaker,
    .listen-content-input
    {
        flex: 0 0 100% !important;
        max-width: 100% !important;
        justify-content: start !important;
    }
    .listen-border-space
    {
        flex: 0 0 100%;
        max-width: 100%;
    }

    .tophaedingspace
    {
        padding-left: 0px;
        padding-right: 0px;
    }
    .iocnitemslisten
    {
        flex: 0 0 100%;
        max-width: 100%;
        justify-content: left !important;
        padding-left: 10px !important;
        h4:nth-child(1)
        {
            margin-left: 0px !important;
        }
    }
    .iocnitemslisten h4.ant-typography {
        width: auto;
        float: left;
        position: relative;
        top: -8px;
    }
    .iocnitemslisten span.trigger {
        position: absolute;
        right: 0px;
        top: -16px;
        float: right;
    font-size: 25px !important;
    }
    .listen-wrapper .keyboardkeyboard .hg-row .hg-button {
        height: 40px;
        color: #5B9BD5;
        font-size: 16px;
        font-weight: 600;
    }
    .listen-wrapper .hg-theme-default .hg-row .hg-button:not(:last-child) {
        margin-right: 6px;
    }
    .keyboardkeyboard 
    {
        margin-bottom: 60px !important;
    }
    .listen-content-loudspeaker
    {
        position: absolute;
        top: 91px;
        left: 20px;
    }
    .listen-wrapper .listen-content-loudspeaker .ant-image img {
        width: 20px !important;
        height: 20px !important;
    }
    .listen-border-space
    {
        padding-right: 0px !important;
        padding-left: 0px !important;
    }
    .listen-content-loudspeaker {
        top: 80px;
        position:static !important;
        margin-top:0px !important;
    }
    .wordlist-slider
    {
        .ant-card-body
        {
            overflow: auto;
    height: calc(100% - 100px);
        }
    }
}
@media (max-width:450px)
{
    .listen-wrapper .keyboardkeyboard .hg-row .hg-button {
        height: 40px;
        color: #5B9BD5;
        font-size: 18px;
    }
    .listen-wrapper .keyboardkeyboard .hg-theme-default .hg-row .hg-button:not(:last-child) {
        margin-right: 4px !important;
    }
    .listen-wrapper .listen-content-input
    {
        padding-left: 0px;
    padding-right: 0px;
    }
 
    .listen-border-space
    {
        margin:0px !important;
    }
    .listen-wrapper .keytext-listen .keytext-listen-textinside {
        font-size: 18px !important;
    }
    .listen-wrapper .keytext-listen button {
        float: right !important;
        width: 75px !important;
        height: auto !important;
        position: absolute;
        bottom: -50px;
        padding: 5px;
        border-radius: 6px;
        right: 0;
    }
    .listen-wrapper .keytext-listen {
        position: relative !important;
        margin-bottom: 60px !important;
    }
    
    .tophaedingspace
    {
        padding-left:0px !important;
        padding-right:0px !important;
    }
    .iocnitemslisten h4.ant-typography
    {
        margin-right: 10px !important;
        margin-left: 0px !important;
    }
   .listen-wrapper .listen-content-loudspeaker .ant-image img {
        width: 20px !important;
        height: 20px !important;
    }
    .listen-box-container
    {
        padding:0px  !important;
    }
    .listen-content-loudspeaker {
        position: absolute;
        left: 10px;
    }
    .listen-border-space h2 span {
        margin-left: 10px !important;
    }
    
    .listen-content-middle
    {
        padding:0px !important;
        margin: 0px !important;
    }
    .listen-wrapper .keytext-listen .keytext-listen-textinside
    {
        font-size: 20px !important;
    }
    
    .listen-content-loudspeaker {
        top: 80px;
        position:static !important;
        margin-top: 0px !important;
    }
}

@media all and (max-width:1023px) and (orientation : landscape) {
    .wordlist-slider
    {
        overflow: auto;
    }
}

.word-board-option
{
    margin-top: 60px;
}
@media(max-width:900px)
{
    .quiz-fluid-container
    {
        
        .quiz-heading-containt
        {
            padding-left:0px !important;
            padding-right:0px !important;    
        }
        
        .word-board,
        .word-board-option
        {
            flex: 0 0 100%;
            max-width: 100%;
            padding-left:10px !important;
            padding-right:10px !important;
            margin-bottom: 40px;
        }
        .quiz-fluid-scroll
        {
            overflow-x: hidden !important;
            overflow-y: auto !important;
        }
        .imagequizspeaker
        {
            flex: 0 0 100%;
            max-width: 100%;
            padding-left: 20px !important;
            padding-right: 20px !important;
            margin-bottom: 40px;
            display: none !important;
            .ant-image
            {
                width: 40px;
                height: 40px;
                position: absolute;
                top: -40px;
                left: 0px;
                img 
                {
                    height: 25px !important;
                    width: 25px !important;
                } 
            }
        }
    }
    .word-board-option
    {
        margin:20px 0px !important;
        h4.ant-typography
        {
            margin:0px !important;
            width:100% !important;
            margin-bottom:15px !important;
            font-size:16px !important;
        }
    }
    .word-board-option > h4
    {
        padding:15px !important;
    }
}

@media(max-width:766px)
{
    .quiz-heading
        {
            flex: 0 0 100%;
            max-width: 100%;
        }
        .quiz-heading-listing
        {
            flex: 0 0 100%;
            max-width: 100%;
            justify-content: inherit !important;
            padding-left: 10px !important;
        }
}
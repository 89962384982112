.setting-main-maininfo
{   
    min-height: calc(100vh - 140px) !important;
    height: calc(100vh - 130px) !important;
    overflow: auto !important;
    .setting-main-frm
    {
        .setting-main-frm-row
        {
            .setting-main-frm-col
            {
                .ant-form-item
                {
                    .ant-form-item-label
                    {
                        width:30% !important;
                    }
                    .ant-form-item-control
                    {
                        width:70% !important;
                        span 
                        {
                            width: 100% !important;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width:1023px)
{
    .setting-main-maininfo
    {
        .setting-main-frm-row
        {
            .setting-main-frm-col
            {
                padding:0px !important;
                flex: 0 0 48% !important;
                max-width: 48% !important;
                margin-right: 4% !important;
                &:nth-child(2n + 2)
                {
                    margin-right:0px !important;
                }
            }
        }
    }
}
.setting-wrapper-main
{
    .setting-wrapper-halfwrap
    {
        min-height: calc(100vh - 138px) !important;
        height: calc(100vh - 138px) !important;
        .setting-form-items
        {
            .setting-input-search
            {
                margin-right: 10px;
            }
        }
    }
}
@media (max-width:1199px)
{
    .setting-popup-form
    {
        .ant-col
        {
            flex: 0 0 48% !important;
            max-width: 48% !important; 
            margin-right: 4% !important;
            &:nth-child(2n + 2)
            {
                margin-right: 0px !important;
            }
            .ant-row
            {
                flex: 0 0 100% !important;
                max-width: 100% !important;
                .ant-col
                {
                    flex: 0 0 100% !important;
                    max-width: 100% !important;
                }
            }
        }
    }
}
@media (max-width:900px)
{
    .settingaddmodule
    {
        width: 80% !important;
    }
}
@media (max-width:650px)
{
    .setting-main-maininfo
    {
        .setting-main-frm-row
        {
            .setting-main-frm-col
            {
                padding:0px !important;
                flex: 0 0 100% !important;
                max-width: 100% !important;
                margin-right: 0px !important;
                &:nth-child(2n + 2)
                {
                    margin-right:0px !important;
                }
            }
        }
    }
    .setting-main-maininfo
    {
        margin-top: 20px im !important;
    }
    .setting-wrapper-main
{
    .setting-wrapper-halfwrap
    {
       
        
        .setting-form-items
        {
            .setting-input-search
            {
                width: 100% !important;
                margin-bottom: 20px;
                margin-right: 0px !important;
                button 
                {
                    margin-right:0px !important;
                }
            }
        }
        button
        {
            margin-right: 10px !important;
            margin-bottom: 10px !important;
        }
    }
}
.settingaddmodule
{
    width: 90% !important;
}
}

@media (max-width:500px)
{
    .setting-popup-form
    {
        .ant-col
        {
            flex: 0 0 100% !important;
            max-width: 100% !important; 
            margin-right: 0px !important;
            &:nth-child(2n + 2)
            {
                margin-right: 0px !important;
            }
            .ant-row
            {
                flex: 0 0 100% !important;
                max-width: 100% !important;
                .ant-col
                {
                    flex: 0 0 100% !important;
                    max-width: 100% !important;
                }
            }
        }
    }
}
.tablebookstore-1s
{

}
.heading-top-bbokorder
{
    margin-bottom: 0.5em;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 600;
    font-size: 16px;
    line-height: 1.4;
    display: flex;
    border-bottom: 1px solid #dfdfdf;
    padding-bottom: 10px;
}
.table-t-two
{
    width: 100%;
    tr 
    {
        th 
        {
            background:#eeeeee;
            padding:6px 20px;
            border:1px solid #f3f3f3;
            font-size: 14px;
        }
        td 
        {
            padding:6px 20px;
            border:1px solid #f3f3f3;
            font-size: 14px;
            .ant-select 
            {
                width:200px;
            }
        }
    }
}
.table-t-four
{
    width: 100%;
    tr 
    {
        th 
        {
            background:#eeeeee;
            padding:6px 20px;
            border:1px solid #f3f3f3;
            font-size: 14px;
        }
        td 
        {
            padding:6px 20px;
            border:1px solid #f3f3f3;
            .ant-select 
            {
                width:200px;
                font-size: 14px;
            }
        }
    }
}

table.table-t-one-a
{
    width:100%;
    border:1px solid #f3f3f3;
    tr 
    {
        th 
        {
            background:#eeeeee;
            padding:6px 20px;
            border:1px solid #f3f3f3;
            font-size: 14px;
            padding: 6px 10px;
            text-align:center;
        }
    }
    tr 
    {
        td 
        {
            font-size: 14px;
            padding: 6px 10px;
            border:1px solid #f3f3f3;
            text-align:right;
            
        }
    }
}
table.table-t-one-b
{
    width:100%;
    border:1px solid #f3f3f3;
    margin-top: 20px;
    tr 
    {
        th 
        {
            background:#fdedd9;
            padding:6px 20px;
            border:1px solid #f3f3f3;
            font-size: 14px;
            padding: 6px 10px;
            text-align: left;
            &:last-child
            {
                color: #ea1414;
            }
        }
    }
    tr 
    {
        td 
        {
            font-size: 14px;
            padding: 12px 20px;
            font-weight: bold;
            text-align:center;
            position: relative;
            &.table-t-one-icon-a:before
            {
                content: '-';
                width: 20px;
                height: 20px;
                position: absolute;
                top: 14px;
                right: -11px;
                z-index: 999999;
                border: 1px solid #dfe3e7;
                border-radius: 50%;
                line-height: 16px;
                text-align: center;
                background:#f2f3f4;
                font-weight: bold;
            }
            &.table-t-one-icon-b:before
            {
                content: '+';
                width: 20px;
                height: 20px;
                position: absolute;
                top: 14px;
                right: -11px;
                z-index: 999999;
                border: 1px solid #dfe3e7;
                border-radius: 50%;
                line-height: 16px;
                text-align: center;
                background:#f2f3f4;
                font-weight: bold;
            }
            &.table-t-one-icon-c:before
            {
                content: '=';
                width: 20px;
                height: 20px;
                position: absolute;
                top: 14px;
                right: -11px;
                z-index: 999999;
                border: 1px solid #dfe3e7;
                border-radius: 50%;
                line-height: 16px;
                text-align: center;
                background:#f2f3f4;
                font-weight: bold;
            }
            &.table-t-one-icon-d
            {
                color: #ea1414;
            }
        }
    }
}
.table-t-three
{
    width:100%;
    border:1px solid #f3f3f3;
    margin-top: 20px; 
    tr 
    {
        th 
        {
            background:#eeeeee;
            padding:6px 20px;
            border:1px solid #f3f3f3;
            font-size: 14px;
            padding: 6px 10px;
            text-align:center;
        }
    }
    tr 
    {
        td 
        {
            padding: 0px 20px; 
            border: 1px solid #f3f3f3;
            input[type="text"]
            {
                height: 24px;
            }

        }
    }
    table 
    {
        width: 100%;
        tr 
        {
            td 
            {
                border: 0px;
            }
        }
    }
}
.table-t-three
{
    width: 100%;
}
.data-list-table-dv
{
    overflow: auto;
    height: calc(100vh - 200px);
    overflow-x: hidden;
}
.bbokorder-input
{
    display: flex;
    margin: 15px 0px;
    input 
    {
        width: 150px;
        margin-right: 15px;
    }
}
.inputname-full
{
    padding-bottom: 15px !important; 
}

.bookstore-tableright .ant-table-body
{
    min-height: calc(100vh - 350px) !important;
    height: calc(100vh - 350px) !important;
    overflow: auto !important;
}
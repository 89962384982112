.assign-bookstore-top-lbl
{
    label
    {
        margin-right: 15px;
        font-weight: 700; 
        color: #000; 
    }
}
.assign-bookstore-heading
{
    margin-bottom: 0.5em;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 600;
    font-size: 16px;
    line-height: 1.4;
    display: flex; 
    margin: 0px;
    padding-top: 4px;
}
.assign-bookstore-wrapper
{
    background-color: #deebf7;
    padding: 10px;
    margin: 20px auto;
}
.test-syllabus-wrapper.book-credit-assign .book-credit-assign-col
{
    height: auto !important;
}
.test-syllabus-wrapper.book-credit-assign .test-syllabus-wrapper-tb
{
    height:calc(100vh - 128px) !important;
    min-height:calc(100vh - 128px) !important;
    overflow-x: hidden !important;
}
.test-syllabus-wrapper.book-credit-assign
{
    h4 
    {
        padding-top: 0px !important;
    }
    .test-syllabus-box-b-tsb
    {
        h5 
        {
            text-align: left;
        }
        .ant-picker
        {
            width: 100% !important;
        }
    }
    .test-syllabus-box-c
    {
        align-items: start !important;
        h5 
        {
            text-align: left;
        }
        .recepits-box
        {
            border:2px solid #deebf7;
            height:auto;
            width: 100%;
            border-radius: 6px;
            padding: 10px;
            .recepits-box-mainheading
            {
                color: #638ecd;
                font-size: 16px;
                margin-bottom: 10px;
            }
            .recepits-box-subheading
            {
                color: #638ecd;
                font-size: 16px;
                padding-left: 15px;
                line-height: 32px;
            }
            .recipts-total
            {
                .recipts-total-a
                {
                    color: #638ecd;
                    font-size: 16px;
                }
                .recipts-total-b
                {
                    color: #638ecd;
                    font-size: 16px;
                    display:flex;
                    .clrone
                    {
                        color: #80b69d;
                    }
                    .clrtwo
                    {
                        color: #ff5140;
                    }
                }
            }
        }
        .recepits-box-buttons
        {
            width: 100%;
            display: flex;
            justify-content: end;
            button:last-child
            {
                background: #dae3f3;
                border-color: #cff2fe;
                color: #4c4985;
                font-size: 16px;
            }
            button:first-child
            {
                background: #f2f2f2;
                border-color: #dcdcdc;
                color: #4c4985;
                font-size: 16px;
                margin-right: 15px;
            }
        }
    }
    
}


.increaseandescrease-input
{
      display: flex;
      justify-content: center;
      .input-label
      {
        display: flex;
        align-items: center;
        margin-right: 15px;
        label
        {
            color: #3498db;
            font-weight: 600;  
        }
      }
      .value-button {
        display: inline-block;
        border: 1px solid #3498db;
        margin: 0px;
        width: 40px;
        height: 40px;
        text-align: center;
        vertical-align: middle;
        padding: 11px 0;
        background: #3498db;
        color: #FFF;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
      }
      
      .value-button:hover {
        cursor: pointer;
      }
      
      #decrease {
        margin-right: -4px;
        border-radius: 8px 0 0 8px;
        margin-top: -5px;
        font-size: 19px;
        line-height: 10px;
      }
      
      #increase {
        margin-left:2px;
        border-radius: 0 8px 8px 0;

        margin-top: -5px;
        font-size: 19px;
        line-height: 10px;
      }
      
      #input-wrap {
        margin: 0px;
        padding: 0px;
      }
      
      input#number {
        text-align: center;
        border: none;
        border-top: 1px solid #ddd;
        border-bottom: 1px solid #ddd;
        margin: 0px;
        width: 40px;
        height: 40px;
        color: #3498db;
        font-weight: bold;
        &:active,
        &:focus
        {
            outline: none;
        }
      }
      
      input[type=number]::-webkit-inner-spin-button,
      input[type=number]::-webkit-outer-spin-button {
          -webkit-appearance: none;
          margin: 0;
      }
}
.input-detail
{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    label 
    {
        color: #3498db;
        margin-right: 15px;
        
    }
    input[type='text']
    {
        color: #ed8067;
        font-size: 16px;
        font-weight: bold;
        border: 1px solid #f0f4fa;
        padding: 6px;
        border-radius: 6px;
        border-color: #f0f4fa;
        width: 100px;
        text-align: center;
        &:active,
        &:focus
        {
            outline: none;
        }
    }
}

.orderadmin-top-rightform
{
    margin-bottom: 10px;
}
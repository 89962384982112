.bookcredit-wrapper-main
{
    margin-top: 10px;
}

.bookcredit-wrapper-mainblock
{
    height: calc(100vh - 128px) !important;
    max-height: calc(100vh - 128px) !important;
    overflow: auto;
    overflow-x: hidden;
    border-radius: 6px;
    padding: 0px !important;
    background:transparent !important;
    .card-container
    {
        .ant-tabs-nav
        {
            margin:0px !important;
            .ant-tabs-nav-wrap
            {
                justify-content: end;
            }
            .ant-tabs-nav-wrap
            {
                .ant-tabs-nav-list
                {
                    .ant-tabs-tab
                    {
                        background:#d9d9d9;
                        border:0px;
                        margin-left: 10px;
                        margin-right: 5px;
                        .ant-tabs-tab-btn
                        {
                            color: #846a89;
                        }
                        &.ant-tabs-tab-active
                        {
                            background:#FFF;
                            color: #4f74cd;
                        }
                    }
                }
            }
        }
        .ant-tabs-content-holder
        {
            background:#FFF;
        }
    }
}
.bookcredit-top-head
{
    padding: 10px 10px;
}
.bookcredit-top-rightform
{
    .purchase-select-option
    {
        .ant-select
        {
            margin-right: 15px;
            width: 100px;
        }
    }
    label 
    {
        color: #657b9b;
        margin-right: 15px;
    }
    input[type="text"]
    {
        border: 2px solid #b8cae8;
        padding: 3px;
        margin-right: 15px;
        color: #728435;
        &:focus,
        &:active,
        &:focus-visible
        {
            border: 2px solid #b8cae8;
        }
    }
    button 
    {
        background: #70ad47;
        color: #FFF;
        border: 0px;
        padding: 5px 25px;
        font-size: 14px;
        font-weight: 600;
    }
}
.bookcredit-table-table
{
    margin: 0px 10px;
    .ant-table-container
    {
        .ant-table-body
        {
            height: calc(100vh - 320px) !important;
            min-height: calc(100vh - 320px) !important;
            overflow: auto;
            overflow-y: auto !important;
            
            .ant-switch
            {
                background: #70ad47;
            }
            .taglistcolumn
            {
                .ant-tag
                {
                    background:#eff5fb;
                    color: #6f6abc;
                    border: 1px solid #d5e5f4;
                    width: 120px;
                    text-align: center;
                }
            }
        }
    }
    .ant-table-thead > tr > th,
    .ant-table-tbody > tr > td
    {
        text-align: center !important;
    }
    .ant-table-thead > tr > th.book-clm-ls-two,
    .ant-table-tbody > tr > td.book-clm-ls-two
    {
        color: #70ad47;
        font-weight: 600;
    }
    .ant-table-tbody > tr > td.book-clm-ls-three
    {
        color: #7fa4d7;
    }
    .ant-table-tbody > tr > td.book-clm-ls-four
    {
        color: #ed8446;
    }
    .ant-table-tbody > tr > td.tabtwo-clm-ls-four
    {
        color: #ed8446;
        font-weight: 600;
    }
    .ant-table-tbody > tr > th.tabtwo-clm-ls-three,
    .ant-table-tbody > tr > td.tabtwo-clm-ls-three
    {
        color: #70ad47;
        font-weight: 600;
    }
}

.orderadmin-wrapper-mainblock,
.ordershipping-wrapper-mainblock
{
    height: calc(100vh - 128px);
    max-height: calc(100vh - 128px);
    overflow: auto; 
    overflow-x: hidden;
    border-radius: 6px;
    .zoom-top-head
    {
        margin-bottom: 15px;
    }
}

.orderadmin-top-rightform,
.ordershipping-wrapper-mainblock
{
    .purchase-select-option
    {
        .ant-select
        {
            margin-right: 15px;
            width: 100px;
        }
    }
    label 
    {
        color: #657b9b;
        margin-right: 15px;
    }
    input[type="text"]
    {
        border: 2px solid #b8cae8;
        padding: 3px;
        margin-right: 15px;
        color: #728435;
        font-weight: bold;
        &:focus,
        &:active,
        &:focus-visible
        {
            border: 2px solid #b8cae8;
        }
    }
    button 
    {
        background: #70ad47;
        color: #FFF;
        border: 0px;
        padding: 5px 25px;
        font-size: 14px;
        font-weight: 600;
    }
}

.orderadmin-table-table,
.ordershipping-table-table
{
    .ant-table-container
    {
        .ant-table-body
        {
            height: calc(100vh - 330px) !important;
            min-height: calc(100vh - 330px) !important;
            overflow: auto;
            overflow-y: auto !important;
            
            
            .taglistcolumn
            {
                .ant-tag
                {
                    background:#eff5fb;
                    color: #6f6abc;
                    border: 1px solid #d5e5f4;
                    width: 120px;
                    text-align: center;
                }
            }
        }
    }
    .ant-table-thead > tr > th,
    .ant-table-tbody > tr > td
    {
        text-align: center !important;
    }
    
}
.orderadmin-imagesec
{
    .ant-anchor-wrapper
    {
       .ant-anchor
       {
           .ant-anchor-ink
           {
               display: none;
           }
       }
    }
    display: flex;
    justify-content: end;
    align-items: center;
    a 
    {
        margin-right: 15px;
        img 
        {
            width: 35px;
        }
    }
}
.settingicon-table
{
    background: #deebf7;
    color: #2e6ca4;
    position: relative;
    width: 150px;
    padding: 5px;
    border-radius: 5px;
    text-align: right;
    margin-left: auto;
    font-weight: 600;
    &::before
    {
        content: '';
        background:url(../images/setting.png);
        position: absolute;
        width: 22px;
        height: 22px;
        top: 3px;
        left: 5px;
        background-size: contain;
        background-repeat: no-repeat;
    }
}


.settingpayadmin-wrapper-mainblock
{
    height: calc(100vh - 128px) !important;
    max-height: calc(100vh - 128px) !important;
    overflow: auto;
    overflow-x: hidden;
    border-radius: 6px;
    padding: 0px !important;
    background:transparent !important;
    .card-container
    {
        .ant-tabs-nav
        {
            margin:0px !important;
            .ant-tabs-nav-wrap
            {
                justify-content: end;
            }
            .ant-tabs-nav-wrap
            {
                .ant-tabs-nav-list
                {
                    .ant-tabs-tab
                    {
                        background:#d9d9d9;
                        border:0px;
                        margin-left: 10px;
                        margin-right: 5px;
                        .ant-tabs-tab-btn
                        {
                            color: #846a89;
                        }
                        &.ant-tabs-tab-active
                        {
                            background:#FFF;
                            color: #4f74cd;
                        }
                    }
                }
            }
        }
        .ant-tabs-content-holder
        {
            background:#FFF;
        }
    }
}

.settingpayadmin-top-head
{
    padding: 10px 10px;
}
.settingpayadmin-top-rightform
{
    .purchase-select-option
    {
        .ant-select
        {
            margin-right: 15px;
            width: 100px;
        }
    }
    label 
    {
        color: #657b9b;
        margin-right: 15px;
    }
    input[type="text"]
    {
        border: 2px solid #b8cae8;
        padding: 3px;
        margin-right: 15px;
        color: #728435;
        &:focus,
        &:active,
        &:focus-visible
        {
            border: 2px solid #b8cae8;
        }
    }
    button 
    {
        background: #70ad47;
        color: #FFF;
        border: 0px;
        padding: 5px 25px;
        font-size: 14px;
        font-weight: 600;
    }
}
.settingpayadmin-table-table
{
    margin: 0px 10px;
    .ant-table-container
    {
        .ant-table-body
        {
            height: calc(100vh - 320px) !important;
            min-height: calc(100vh - 320px) !important;
            overflow: auto;
            overflow-y: auto !important;
            
            .ant-switch
            {
                background: #70ad47;
            }
            .taglistcolumn
            {
                .ant-tag
                {
                    background:#eff5fb;
                    color: #6f6abc;
                    border: 1px solid #d5e5f4;
                    width: 120px;
                    text-align: center;
                }
            }
        }
    }
    .ant-table-thead > tr > th,
    .ant-table-tbody > tr > td
    {
        text-align: center !important;
    }
   
}

.privacy-container{
    padding: 2rem 4rem;

    img {
        width: 20%;
        display: block;
        margin: 0 auto 1rem;
    }

    .privacy-header{
        width: 100%;
        display: inline-flex;
        justify-content: center;
        align-items: center;

        h1{
            text-align: center;
            font-size: 2.3rem;
        }
    }
   

    .privacy-wrap{
        width: 80%;
        background: #fff;
        padding: 2rem;
        margin: 0.5rem auto;
        display: block;
        border-radius: 10px;

        p{
            margin-bottom: 0.5rem;
        }

        p.tit{
            text-indent: 0.5rem;
            margin: 1rem 0 1rem 0;
        }

        h4{
            font-weight: 600;
            font-size: 1.2rem;
            margin: 0.05rem 0 1rem;
        }

        dt{
            font-weight: 600;
            font-size: 1rem;
            margin: 1rem 0 0 0;
        }

         dd {
            text-indent: 0.5rem;
        }
    }

}




@media (max-width: 1440px){
    .privacy-container{
        padding: 2rem 4rem;

        img{
            width: 30%;
        }
        
        .privacy-header{
            h1{
                font-size: 1.5rem;
            }
        }

        .privacy-wrap{
        }
    }
}

@media (max-width: 1439px){
    .privacy-container{
        padding: 2rem 1rem;
        img{
            width: 30%;
        }
        
        .privacy-header{
            h1{
                font-size: 1.5rem;
            }
        }

        .privacy-wrap{
            width: 80%;
        }
    }
}

@media (max-width: 375px){
    .privacy-container{
        padding: 2rem 1rem;
        img{
            width: 40%;
        }
        
        .privacy-header{
            h1{
                font-size: 1.3rem;
            }
        }

        .privacy-wrap{
            width: 100%;
        }
    }
}

@media (max-width: 320px){
    .privacy-container{
        padding: 2rem 1rem;
        img{
            width: 45%;
        }
        
        .privacy-header{
            h1{
                font-size: 1.1rem;
            }
        }

        .privacy-wrap{
            width: 100%;

            h4{
                font-size: 1rem;
            }
        }
    }
}
@media(max-width:1023px)
{
    .voca-blankspace
    {
        display: none !important;
    }
    .voca-word
    {
        flex: 0 0 100%;
        max-width: 100%;
        margin-top: 40px;
    }
    .mainheading-voca
    {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }  
    .voca-fluid-container
    {
        overflow-y: auto;
    }  

}
@media(max-width:766px)
{
    .voca-blankspace
    {
        display: none !important;
    }
    .voca-word,
    .mainheading-voca-menu
    {
        flex: 0 0 100%;
        max-width: 100%;
    }
    .mainheading-voca
    {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
    .mainheading-voca-menu
    {
        justify-content: initial !important;
    }
    .mainheading-voca-menu span.trigger {
        position: absolute;
        right: 0px;
        top: -13px;
        float: right;
        font-size: 25px !important;
    }
    .voca-word .voca-word-inner
    {
        .flip-card-container
        {
            min-height:25vh;
        }
        .ant-card-body
        {
            padding:14px;
        }
    }
    .mainheadingtop-menulist
    {
        flex: 0 0 100%;
        max-width: 100%;
    }
}


// bg color: #644fec;

.my-masonry-grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  // margin-left: -30px; /* gutter size offset */
  width: auto;
}
.my-masonry-grid_column {
  padding-left: 0; /* gutter size */
  background-clip: padding-box;
}

.my-masonry-grid_column > div {
  /* change div to reference your elements you put in <Masonry> */
  margin-bottom: 30px;
}

.eBook-header {
  display: flex;
  width: 100%;

  .lib-searchitem {
    display: flex;
    .ant-menu-title-content {
      display: flex;
      align-items: center;
      .ant-form-item {
        margin-bottom: 0;
        margin-left: 0.5rem;
      }
    }
  }
}

.ebook-header-dropdown {
  li {
    padding: 1rem 1.5rem;
    span {
      a {
        font-size: 1rem;
      }
    }
  }
}

.circle-btn-con {
  display: flex;
  justify-content: space-between;
  width: auto;
  margin-right: auto;
  align-items: center;

  a {
    color: #644fec;
    font-weight: 700;
    margin: 0 1rem;
    width: 50px;
    height: 50px;
    border: 1px solid;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-size: 0.8rem;
  }
}

.wait-comment {
  font-size: 2rem;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-weight: 700;
}

.elib-header {
  width: 100%;
  background: #fff;
  position: fixed;
  top: 0;
  padding: 10px 30px;
  z-index: 999;

  .navbar-nav {
    display: flex;
    flex-direction: row;
    margin-bottom: 0;
    .nav-item {
      list-style: none;
      &:last-child {
        margin-left: auto;
        button {
          cursor: pointer;
          margin: 0 1rem;
        }
      }
      button {
        width: 30px;
        height: 30px;
        border: none;
      }
    }
  }
}

.library-con {
  width: 100%;
  min-height: 100vh;
  height: auto;
  position: relative;
  left: 0;
  // background: url(../images/bg_library.jpg) no-repeat;
  // background-image: linear-gradient(to left bottom, #6d1c75, #844c90, #9b75a9, #b59ec0, #d0c8d6);

  // gradient purple and apricot mixed
  // background-image: linear-gradient(to left bottom, #6d1c75, #9f3a6d, #bd656d, #ce927f, #dabda3);

  // gradient only purples
  // linear-gradient(to left bottom, #6d1c75, #8d4793, #ad6fb2, #cd97d2, #eec1f2);

  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;

  .wait-comment {
    font-size: 2rem;
  }

  .library {
    width: 1000px;
  }

  .select {
    width: 130px;
    height: 38px;
    padding: 0.5rem 2rem;
    color: #6eb2c3;
    border: 1px solid #6eb2c3;
    border-radius: 6px;
    font-size: 18px;
    font-weight: 700;
    // background: url(../images/icon/icon_select.png) 98% center/28px 28px no-repeat;
    background-color: #fff;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    display: inline-block;
    cursor: pointer;
  }

  .ant-dropdown-trigger {
    width: 130px;
    height: 60px;
    padding: 7px 20px;
    color: #6eb2c3;
    background: #fff;
    border: 1px solid #6eb2c3;
    border-radius: 6px;
    font-weight: 700;

    .ant-space-item {
      padding: 10px 0;
      font-weight: 600;
    }

    li {
      padding: 6px 10px;
      text-align: left;
      font-size: 18px;
      line-height: 36px;
      border-bottom: 1px solid #c8f0fa;
      cursor: pointer;
      transition: 0.3s;
    }

    .ant-dropdown-menu-title-content {
      color: #c8f0fa;
      font-size: 18px;
      line-height: 36px;
    }
  }

  /*
  .swiper-button-prev {
    background: url(../images/icon_arrow_big.png) no-repeat;
    position: absolute;
    left: 4rem;
    height: 100px;
    background-size: contain;
    background-position: center;
    transform: scale(1.5);
    border: none;
    cursor: pointer;
  }

  .swiper-button-next {
    background: url(../images/icon_arrow_big.png) no-repeat;
    background-position: center;
    -webkit-transform: rotate(180deg) scale(1.5);
    -ms-transform: rotate(180deg) scale(1.5);
    transform: rotate(180deg) scale(1.5);

    height: 100px;
    background-size: contain;

    position: absolute;
    right: 4rem;
    cursor: pointer;
    border: none;
  }

  .swiper-slide {
    background: transparent;
  }

  .swiper {
    width: 100%;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  */

  .sampleImgTest_image {
    &:after {
      position: absolute;
      top: -1.1rem;
      left: -1rem;
      width: 100px;
      height: 20px;
      background-image: url(../images/icon_checkpoint.png);
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      content: '';
      display: none;
    }
  }

  .swiper-slide {
    text-align: center;
    font-size: 18px;
    // height: calc((100% - 30px) / 2) !important;

    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }

  .lib-list-wrap {
    background: #fff;
    width: auto;
    height: auto;
    min-height: auto;

    .bookcover-con {
      box-sizing: border-box;
      break-inside: avoid;

      counter-increment: item-counter;
      margin: 1rem -3rem 1rem -1rem;

      display: flex;
      align-items: center;
      justify-content: center;

      div {
        // background-image: url(../images/libSample.jpg);
        background-position: center;
        background-repeat: no-repeat;

        // background-image: url(../images/bookframe.png);
        background-position: top;
        background-repeat: no-repeat;
        background-size: contain;

        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 0;
        // box-shadow: 0px 5px 4px 2px rgb(135 102 59 / 40%);

        position: relative;
        cursor: pointer;
        display: flex;
        justify-content: center;

        &.sampleImgTest_image {
          background-position: center;
          background-repeat: no-repeat;
          box-sizing: border-box;
          break-inside: avoid;
          padding: 10px 0;
          counter-increment: item-counter;
          background-size: cover;
          overflow: hidden;
        }

        img {
          max-width: 75%;
          border-radius: 30px;
          box-shadow: 0 5px 6px 1px rgb(135 102 59 / 40%);
        }

        a {
          position: absolute;
          bottom: 0;
          right: 3rem;
        }

        .starMark {
          width: 100%;
          display: block;
          position: relative;
          background-image: url(../images/icon-record.png);
          background-repeat: no-repeat;
          width: 60px;
          height: 60px;
          margin: 1rem;

          img {
            max-width: 60px;
            border-radius: 0;
            box-shadow: none;
            margin: 0.5rem 1rem 0.5rem;
            display: block;
          }
        }
        .favMark {
          position: absolute;
          bottom: 26px;
          left: 68px;
        }
        button {
          display: none;
          background: #fff;
          width: 100%;
          border: none;
          padding: 1rem;
          position: absolute;
          bottom: 0;
          cursor: pointer;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;

          &:hover {
            background: #6eb2c3;
            color: #fff;
          }
        }
      }
    }
  }
  .pagination-wrap {
    flex-basis: 80px;
  }

  /*
  .swiper-pagination-bullet {
    color: #fff;
    font-size: 1.1rem;
    background: none;
    margin: 0 0.5rem;
    font-weight: 700;
  }
 
  .swiper-button-prev:after,
  .swiper-rtl .swiper-button-next:after,
  .swiper-button-next:after,
  .swiper-rtl .swiper-button-prev:after {
    content: '' !important;
  }

  .swiper-horizontal > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic,
  .swiper-pagination-horizontal.swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
    bottom: 1rem;
    margin: 1.5rem 0;
  }

  .swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet,
  .swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
    margin: 0 var(--swiper-pagination-bullet-horizontal-gap, 20px);
  }
  .swiper-pagination-bullet,
  .swiper-pagination-bullet-active-main {
    opacity: 0.9;
  }

  .swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-prev-prev,
  .swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-prev,
  .swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-next,
  .swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-next-next {
    transform: scale(1);
  }
*/
  .swiper-pagination-bullet-active {
    color: #ffdb3d !important;
  }

  .ant-row {
    .ant-col {
      &.ant-col-24 {
        background: #fff;
        padding: 1rem;
        .ant-row {
          .ant-col-5 {
            background: #336699;
            margin: 0;
            max-width: 20%;
            flex: 0 0 20%;
          }
        }
      }
    }
  }

  .library {
    width: 1450px;
    &.__content {
      .library__box {
        /*
        .swiper {
          .swiper-wrapper {
            .swiper-slide {
              border: 2px solid;
              height: 30vh;
            }
          }
        }
        */
      }
    }
  }
}

/* pasted things */
.library {
  // min-height: calc(100vh - 25rem);
  padding-bottom: 25px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;

  .library__top.float__box {
    &.con_lv {
      padding: 15px 0;
    }
  }
}

.library__top {
  padding: 40px 0 5px;
  display: flex;
  justify-content: start;
  margin-left: 0;

  align-content: center;
  flex-wrap: wrap;

  & > a.headitems-add {
    display: flex;
    align-items: center;
  }
}

.library__span {
  display: inline-block;
  line-height: 36px;
  padding: 0 10px;
  color: #fff;
  background-color: #6eb2c3;
  border: 1px solid #fff;
  border-radius: 6px;
  font-size: 18px;
  font-weight: 700;
  text-align: center;
  vertical-align: middle;
}
.library .select {
  vertical-align: middle;
}
.library .float__right .select {
  margin-left: 20px;
}
.library .float__right .library__span {
  margin-left: 20px;
}
.library__box {
  background-color: #fff;
  //  -webkit-box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.25);
  //  box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.25);
  border-radius: 6px;
}
.library__content {
  position: relative;
  overflow-y: auto;
}
.library__list {
  padding: 0px;
  padding-top: 30px;
}
.library__list::after {
  content: '';
  display: block;
  width: calc(100% + 60px);
  height: 7px;
  margin-left: -30px;
  /*background-color: #f6f6f6;
  border-radius: 10px;
  -webkit-box-shadow: 3px 3px 10px 0 rgba(0,0,0,.25);
  box-shadow: 3px 3px 10px 0 rgba(0,0,0,.25);*/
  clear: both;
}
.library__list li {
  float: left;
  width: 18%;
  padding: 0 12px;
  margin-right: 2.5%;
}
.library__list li:last-child {
  margin-right: 0px;
}
.library__list li img {
  display: block;
}
.library__list_inner {
  position: relative;
  height: 220px;
  background-repeat: no-repeat;
  background-position: top center;
  background-size: contain;
  // box-shadow: 1px 1px 4px 0px rgb(135 102 59 / 40%);
}

.library__list_inner.check_point:after {
  content: '';
  display: block;
  position: absolute;
  width: 30px;
  height: 24px;
  top: -7px;
  transform: translateY(-70%);
  left: 22px;
}

.library__list_inner.check_complete {
  filter: brightness(0.65);
}
.library__list_btn {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}
.library__list_btn .btn__star {
  vertical-align: bottom;
}
.library__book_title {
  display: flex;
  padding: 6px;
  color: #000;
  background-color: #fff;
  font-size: 14px;
  text-align: center;
  height: 56px;
  border: 1px solid #f1f1f1;
  align-items: center;
  justify-content: center;
}
.library__list li {
  transition: 0.3s;
}
.library__list li:hover .library__list_inner .library__book_title {
  background-color: #6eb2c3;
  color: #fff;
}
.library .pagi__box {
  padding: 0 30px;
}

/*popup*/
.rabbit-popup-header {
  padding: 2rem 1.5rem 0 1.5rem;
}

.rabbit-popup-body {
  padding: 0 2rem;
}

.rabbit-popup-indent {
  margin-top: 0.5rem;
  // text-indent: 0.5rem;
  line-height: 1.6;
}

.rabbit-app-wrap {
  margin-top: 1.2rem;
  .app-wrap {
    padding: 10px 5px 5px 5px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
}

.popup-mark-star {
  font-size: 1.4rem;
  &:before {
    content: '* ';
    display: flex;
    position: absolute;
    left: 1.7rem;
  }
}

.popup-mark-x:before {
  content: '※ ';
  display: flex;
  position: absolute;
  left: 1rem;
}

@media screen and (max-width: 1650px) {
  .library-con {
    .library {
      width: 1250px;
      max-width: 100%;
    }

    .my-masonry-grid {
      margin-left: 0;
    }
  }
}

@media screen and (max-width: 1600px) and (aspect-ratio: 16 / 9) {
  .library-con {
    .library {
      width: 1000px;
    }
    .lib-list-wrap {
      .bookcover-con {
        margin: 1rem;
      }
    }
  }
}

@media screen and (max-width: 1500px) {
  .library-con {
    .library {
      width: 1000px;
      margin: 0 auto;
    }
    .lib-list-wrap {
      .bookcover-con {
        margin: 0rem;
      }
      .row {
        width: auto;
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .elib-layout {
    &:before {
      width: 200px;
      height: 200px;
    }
    &:after {
      width: 250px;
      height: 250px;
      top: 70%;
      right: -2rem;
    }

    .partimg-bg {
      &.banana {
        left: 0;
      }
    }
  }

  .library-con {
    margin: 4rem auto !important;
    .lib-list-wrap {
      .bookcover-con {
        div {
          img {
            max-width: 75%;
            border-radius: 20px;
          }

          &.sampleImgTest_image {
            a {
              .starMark {
                left: 0;
                bottom: 0;
                width: 40px;
                height: 40px;
              }
            }
          }
        }
      }
    }
  }

  .library__content {
    position: relative;
    height: auto !important;
    min-height: 100%;
  }
}

@media screen and (max-width: 1400px) {
  .library-con {
    .library {
      .section__inner {
        .library__content {
        }
      }
    }

    .lib-list-wrap {
      .row {
        display: block;
      }
    }
  }
}

@media screen and (max-width: 1280px) and (aspect-ratio: 16 / 10) {
  .library-con {
    .library {
      width: 980px;
    }
  }
}

@media screen and (max-width: 1280px) and (aspect-ratio: 16 / 9) {
  .library-con {
    .lib-list-wrap {
      .bookcover-con {
        margin: 1rem 0.5rem 0;
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .library-con {
    width: auto;
    .library {
      width: 975px;
      max-width: 100%;
    }
  }
}

@media screen and (max-width: 1150px) {
  .library-con {
    .library {
      width: auto;
      padding-bottom: 0;
    }

    .library__content {
      width: 930px;
      margin: 0 auto;
    }

    .library__top {
      &.float__box {
        margin-left: 0rem;
      }
    }

    .lib-list-wrap {
      background: #fff;
      justify-content: space-between;
      div.row {
        div.bookcover-con {
          display: inline-flex;
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) and (max-height: 768px) and (orientation: landscape) {
  .library-con {
    .lib-list-wrap {
      width: 800px;
    }
  }
}

@media screen and (max-width: 1024px) and (orientation: landscape) {
  .library-con {
    .lib-list-wrap {
      width: auto;
      // max-width: 800px;
      min-height: auto;
      height: 100%;
      div.row {
        div.bookcover-con {
          margin: 1rem;
          justify-content: center;

          div {
            button {
              font-size: 1rem;
              padding: 0.7rem;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) and (orientation: portrait) {
  .library-con {
    .library__content {
      height: auto !important;
    }
  }
}

@media screen and (max-width: 950px) and (orientation: landscape) {
  .elib-layout {
    &:before {
      display: none;
    }

    &:after {
      display: none;
    }
  }

  .eBook-header-con {
    li {
      span {
        div {
          font-size: 1rem !important;
        }
      }
    }
  }

  .library-con {
    .library {
      width: 600px;
    }

    .library__content {
      width: auto;
    }

    .lib-list-wrap {
      .bookcover-con {
        div {
          img {
            max-width: 75%;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 850px) and (orientation: landscape) {
  .library-con {
    .library__content {
      .lib-list-wrap {
        overflow-x: hidden;

        div.row {
          div.bookcover-con {
            margin: 1rem 0.3rem;
            overflow: hidden;

            div {
              img {
                max-width: 65%;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 850px) {
  .elib-layout {
    .partimg-bg {
      display: none;
    }

    &:before,
    &:after {
      display: none;
    }
  }

  .eBook-header-con {
    li {
      span {
        div {
          font-size: 1rem;
        }
      }
    }
  }

  .library-con {
    margin: 5rem auto 0;
    height: auto;
    min-height: 100%;

    .wait-comment {
      font-size: 1.6rem;
    }

    .library {
      width: auto;
      height: auto;
      min-height: 100%;
    }

    .library__top {
      justify-content: start;
      padding: 5px 0 5px;
    }

    .library__content {
      width: 100%;
      height: auto !important;
      margin: 0 auto;
      .library__box {
        padding: 10px 0;
      }
      .lib-list-wrap {
        div.row {
          div.bookcover-con {
            margin: 0rem 0.3rem;
            overflow: hidden;
          }
        }
      }
    }

    .lib-list-wrap {
      background: #fff;
      div {
        //      display: block;
      }

      div.row {
        div.bookcover-con {
          display: inline-flex;
          width: 200px;
          margin: 1.5rem 0;
          justify-content: center;

          div {
            &.sampleImgTest_image {
              &:after {
                top: 0;
                left: 0rem;
                width: 35px;
                height: 11px;
              }
            }

            &.starMark {
              width: auto;
              height: auto;
              bottom: 3rem;

              img {
                max-width: 25px;
              }
            }

            button {
              padding: 0.5rem 1rem;
              font-size: 0.8rem;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px) and (orientation: portrait) {
  .elib-layout {
    &:after {
      display: none;
    }
  }

  .library-con {
    margin: 2rem auto !important;
    .library__content {
      width: 100%;
      height: 100% !important;
      margin: 2rem auto 0;
    }

    .library {
      width: auto;
      height: auto;
      min-height: 90vh;
    }
  }
}

@media screen and (max-width: 700px) and (orientation: landscape) {
  .ebook-header {
    .ebook-header-con {
      ul {
        li {
          padding: 0 1rem;
        }
      }
    }
  }
  .library-con {
    .library__content {
      width: 580px;
      .library__box {
        padding: 0;
        margin-top: 1rem;
      }
      .lib-list-wrap {
        div.row {
          div.bookcover-con {
            overflow: hidden;

            div {
              &.sampleImgTest_image {
                div {
                  img {
                    max-width: 70%;
                  }
                }
              }

              &.starMark {
                width: auto;
                height: auto;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 700px) and (orientation: portrait) {
  .library-con {
    .lib-list-wrap {
      .bookcover-con {
        div {
          img {
            max-width: 70%;
          }
        }
      }
    }
  }

  .rabbit-popup-header {
    padding: 2rem 0 0 0;
  }

  .rabbit-popup-body {
    padding: 0rem 0 0 0;
  }

  .rabbit-popup-indent {
    text-indent: 0.1rem;
  }

  .rabbit-app-wrap {
    max-width: 100%;
    align-self: center;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif,
      'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    .app-wrap {
      // padding: '10px 5px 5px 5px',
      padding: 15px;
    }
  }
}

@media screen and (max-width: 500px) {
  .ebook-header-dropdown {
    li {
      span {
        a {
          font-size: inherit;
        }
      }
    }
  }

  .elib-layout {
    .partimg-bg {
      display: none;
    }

    &:before,
    &:after {
      display: none;
    }

    header {
      padding: 0 !important;

      & > div {
        padding-top: 0 !important;
      }

      .eBook-header {
        box-shadow: 0 32px 30px -30px rgba(53, 47, 47, 0.4);
        align-items: center !important;
      }
    }

    .eBook-header {
      position: absolute;
      top: 3rem;
      left: 0;
      align-items: center !important;

      .eBook-header-con {
        width: 100% !important;
        background: #fff;
        ul {
          li {
            &.lib-searchitem {
              .ant-form-item {
                width: 95px;
              }
            }
          }
        }
      }
    }

    .library-con {
      margin: 7rem auto !important;

      .library {
        width: auto;

        .float__right {
          .library__span {
            margin-top: 1rem;
          }
        }
      }

      .ant-dropdown-trigger {
        width: auto;
        height: auto;
        padding: 0;
        margin-top: 1rem;
        text-align: center;
      }

      .library__top {
        padding: 15px 0;
        flex-direction: column-reverse;
        &.float__box {
          margin-right: 1rem;
          margin-left: 1rem;
        }

        .float__right {
          display: flex;
          flex-direction: column;
          width: 100%;
        }

        & > a.headitems-add {
          margin: 0.5rem auto;
          width: 100%;
          justify-content: center;
        }
      }

      .library__content {
        width: auto;
        margin: 0 auto;
      }

      .swiper-button-prev {
        left: -0.5rem;
        transform: scale(1);
      }

      .swiper-button-next {
        right: -0.5rem;
        transform: scale(1) rotate(180deg);
      }

      .library__box {
        padding: 0 20px;
      }

      .lib-list-wrap {
        background: #fff;
        width: 100%;
        display: block;

        div.row {
          display: block;
          width: 100%;

          div.bookcover-con {
            display: inline-flex;
            justify-content: center;
            width: 180px;
            margin: 1rem;
          }
        }

        div {
          button {
            font-size: 0.85rem;
          }
        }
      }
    }
  }

  .rabbit-popup-header {
    margin-top: 1rem;
    font-size: 1rem;
    .popup-mark-star {
      padding-left: 0.5rem;
      &:before {
        left: 0.8rem;
        padding-right: 1rem;
        display: block;
      }
    }
  }

  .rabbit-popup-body {
    .popup-mark-x {
      padding-left: 0.3rem;
      &:before {
        left: -0.8rem;
        display: block;
      }
    }
  }
}

@media screen and (max-width: 425px) {
  .eBook-header-con {
    li {
      padding: 0 0.3rem;
      span {
        div {
          font-size: 0.8rem !important;
        }

        &.anticon-swap {
          svg {
            width: 1em !important;
            height: 1em !important;
          }
        }
      }
    }
  }

  .library-con {
    min-height: 100%;

    .wait-comment {
      font-size: 1.2rem;
    }

    .library__box {
      background-color: transparent;
    }

    .lib-list-wrap {
      .bookcover-con {
        div {
          img {
            max-width: 50%;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 375px) {
  .eBook-header {
    .eBook-header-con {
      ul {
        li {
          padding: 0 0.5rem;
          &.lib-searchitem {
            .ant-form-item {
              width: 90px;
            }
          }
        }
      }
    }
  }

  .library-con {
    .library {
      margin: 0 auto;
    }
  }

  .rabbit-popup-header {
    .popup-mark-start {
      font-size: 1.2rem;
    }
  }
}

/* new 2022/12/21 */
.elib-layout {
  .ant-layout-header {
    .ant-menu-vertical {
      border-right: none !important;
    }
  }

  .eBook-header-con {
    li {
      span {
        div {
          color: #644fec;
          font-weight: 700;
          font-size: 1.2rem;
        }

        &.anticon-swap,
        &.anticon-search {
          transform: rotate(90deg) scale(1.5);
          svg {
            width: 1em !important;
            height: 1em !important;
            fill: #644fec;
          }
        }
        .anticon-unordered-list {
          transform: scale(1.5);
        }
      }
    }

    .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
      background-color: #fff;
    }
  }
  .booksRadio {
    .ant-radio-group  {
      &.search-line {
        .ant-radio-wrapper {
          &.ant-radio-wrapper-checked {
            border-bottom: unset;
          }
        }
      }
      display: flex;
      padding: 20px 15px 0;
      .ant-radio {
        opacity: 0;
        display: none;
      }
    
      .ant-radio-wrapper {
        margin-left: 8px;
        span {
          &:last-child {
            font-size: 20px;
            color: #644fec;
            padding: 0;         
          }
        }
        &.ant-radio-wrapper-checked {
          border-bottom: 3px solid #644fec;
        }
      }
    }
  }
  .heart-icon {
    width: 50px;
    height: 35px;
  }

  .rank__wrap{position: relative;
  padding: 70px 16px 55px;
  background-color: #fff;
}
  .rank__btn{
    position: absolute;
    top: 67px;
    width: 40px;
    right: 0px;
    height: 65px;
    background-image: url(../images/icon_tail_left.png);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    background-color: transparent;
    z-index: 1;
  }
  .company-sider{
    width: 305px !important;
    min-width:  305px !important;
  }
  .active{
    right: 305px !important;
  }

  .rank__layer {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 1; 
}
.rank__close{position: absolute;
    top: 20px;
    right: 22px;
    width: 25px;
    height: 25px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    background-image: url(../images/icon_close_mint.png);
    background-color: transparent;
    border: none;
}
.score_box{
  display: flex;
  justify-content: space-between;
}
.rank__box {
  background-color: #fff;
  padding: 12px;
  margin-top: 12px;
  border-radius: 6px;
  -webkit-box-shadow: 0px 0px 7px 0 rgb(0 0 0 / 16%);
  box-shadow: 0px 0px 7px 0 rgb(0 0 0 / 16%);
  

  .rank__title {
    color: #000;
    font-size: 22px;
    font-weight: 700;
  }
  .rank__span {
    width: 62px;
    line-height: 30px;
    border: 1px solid #afafaf;
    border-radius: 10px;
    color: #727272;
    font-size: 17px;
    font-weight: 700;
    text-align: center;
  }
  .rank__bar {
    position: relative;
    height: 20px;
    margin-top: 14px;
    background-color: #c8f0fa;
    border-radius: 10px;
  }
  .rank__bar_width {
    position: absolute;
    left: 3px;
    top: 3px;
    max-width: calc(100% - 6px);
    height: 14px;
    background-color: #6eb2c3;
    border-radius: 10px;
  }
  .rank_progress_bar{
    height: 20px;
    margin-top: 14px;
    position: relative;
    background-color: #c8f0fa;
    border-radius: 10px;
    display: flex;
    align-items: center;
  }
  .value_bar{
    height: 14px;
    background-color: #6eb2c3 !important;
    border-radius: 20px;
  }
  .btn {
    display: block;
    margin: 16px auto 0;
    border: none;
} 
.btn__size--normal {
  height: 45px;
  line-height: 45px;
  padding: 0 15px;
  border-radius: 20px;
  font-size: 21px;
  width: auto;
}
.btn__color--blue {
  color: #fff;
  background-color: #6eb2c3;
}
.rank__title {
  color: #000;
  font-size: 22px;
  font-weight: 700;
}
.rank__title--center {
  display: block;
  color: #fd7468;
  text-align: center;
}
.rank__table {
  width: 100%;
  margin-top: 6px;
  td {
    padding: 10px 0 5px;
    font-size: 17px;
    font-weight: 700;
    text-align: center;
}
 th {
  height: 32px;
  border-top: 1px solid #707070;
  border-bottom: 1px solid #707070;
  font-size: 17px;
  font-weight: 700;
  text-align: center;
}
}
}
}

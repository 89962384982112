// modal
.cmaker-modal-con {
  position: absolute;
  top: 35%;
  width: 100%;
  display: grid;
  justify-content: center;
  max-width: 600px;
  border: 1px solid;
  text-align: center;
  background: #fff;
  justify-self: center;
  margin: 0 auto;
  z-index: 99;

  h1 {
    padding: 1rem;
    font-size: 1.4rem;
    margin: 1rem 0;
  }

  .body {
    display: grid;
    justify-content: center;
    align-items: center;
    grid-template-columns: 1.5fr 2fr;
    grid-template-rows: 1fr 2fr;

    legend {
      font-size: 1.2rem;
    }

    input[type='text'] {
      max-width: 100%;
      font-size: 1.2rem;
      border-radius: 10px;
      padding: 0.5rem 1rem;
    }
  }

  .footer {
    margin: 2rem 0;
    button {
      margin: 0 1rem;
    }
  }
}

// modal end

.cmaker-wrapper {
  display: grid;
  background: #9fd9df;
  width: 100%;
  height: auto;
  min-height: 100vh;
  padding-bottom: 2rem;
  .cmaker-container {
    max-width: 1440px;
    width: 100%;
    margin: 1rem auto 0;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin-bottom: 0;
      margin-block-end: 0;
    }

    .closeBtn {
      cursor: pointer;
      position: absolute;
      right: 0;
      display: flex;
      justify-content: end;
      margin-right: 1rem;
    }

    .option-con {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
    }

    &.top {
      margin: 0rem auto 3rem;
      padding-top: 3rem;
      width: 100%;
      align-items: center;

      &.init {
        .inner-wrap-new {
          display: grid;
          grid-template-columns: 1fr 5fr 1fr;
          padding: 1rem 0;
          background: #fff;
          align-items: center;
        }
      }

      &.dialogue {
        width: 100%;
        position: relative;
        display: inline-grid;
        justify-self: center;
        .inner-wrap-new {
          display: grid;
          // grid-template-columns: 1fr 5fr 1fr;
          padding: 2.5rem 3rem;
          background: #fff;
          align-items: center;
          position: relative;

          .multiple-input-wrap {
            display: flex;
            flex-direction: row;
            align-items: center;

            .ant-btn-primary {
              position: absolute;
              right: 0;
            }
          }

          .multiple-btn-wrap {
            display: flex;
            align-items: center;
            button {
              margin: 0 0.1rem;
            }

            .ant-select {
              width: 100%;
              max-width: 130px;
            }
          }

          .scroll-tab-wrap {
            display: flex;
            width: 100%;
            max-width: 1300px;
            position: relative;
            .scroll {
              overflow-x: auto;
              white-space: nowrap;
              width: auto;
              max-width: 1300px;
              margin: 0;
              padding: 0 3rem 0 0;
              div {
                display: inline-block;
                text-decoration: none;
                white-space: pre-line;
                text-align: center;
                width: auto;
                height: 100%;
                max-height: 50px;
                padding: 1rem 2rem;
                margin: 0 0 0rem 0rem;
                color: #252525;
                background: #fff;
                cursor: pointer;
                border-top: 1px solid #252525;
                border-right: 1px solid #252525;

                p {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  margin-bottom: 0;
                  margin-block-start: 0;
                  height: 100%;
                  white-space: pre;
                }

                &.active {
                  background: #252525;
                  color: #fff;
                  border-top: 1px solid #252525;
                }
              }

              &::-webkit-scrollbar-track {
                border-radius: 10px;
                visibility: hidden;
              }

              &::-webkit-scrollbar {
                width: 1px;
                background-color: #f5f5f5;
                visibility: hidden;
              }

              &::-webkit-scrollbar-thumb {
                border-radius: 10px;
                background-color: #1990ff;
              }
            }

            .plus-tab {
              width: 40px;
              height: 100%;
              border-top: 1px solid #252525;
              border-right: 1px solid #252525;
              border-left: 1px solid #252525;
              padding: 1rem 0;
              background: #252525;
              display: flex;
              justify-content: center;
              align-items: center;
              cursor: pointer;
              position: absolute;
              right: 0;
              opacity: 1;
              transition: all 0.3s ease;
              &:hover {
                opacity: 0.8;
                transition: all 0.3s ease;
              }
              svg {
                fill: #fff !important;
              }
            }
          }

          .middle-tab {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-right: auto;
            width: 100%;

            div {
              text-align: center;
              width: 130px;
              padding: 1rem 2rem;
              margin: 0 0 0rem 0rem;
              color: #252525;
              background: #fff;
              cursor: pointer;
              border-top: 1px solid #252525;
              border-right: 1px solid #252525;

              &.active {
                background: #252525;
                color: #fff;
                border-top: 1px solid #252525;
              }

              &.plus-tab {
                margin-left: auto;
                width: 40px;
                border-top: 1px solid #252525;
                border-right: 1px solid #252525;
                border-left: 1px solid #252525;
                padding: 1rem 0;
              }
            }

            &.inner-tab {
              margin-top: 0;
              justify-content: flex-start;

              div {
                max-width: 150px;

                &:last-child {
                  max-width: 80px;
                }
              }
            }
          }

          .middle-tab-btm-con {
            border: 2px solid;
            width: 100%;
            padding: 2.4rem 1rem;
            justify-content: flex-start;

            .con {
              border: 1px solid grey;
              width: 100%;
              padding: 2.5rem 0.5rem;
              display: flex;
              justify-content: center;
              align-items: start;
              flex-direction: column;

              .inner-wrap-new {
                width: 100%;
                padding: 1rem 0;
                grid-template-columns: 1fr 3.2fr 1fr;
                align-items: center;

                .ant-select:nth-child(1) {
                  margin: 0 auto;
                  width: 100px;
                }

                .multiple-btn-wrap {
                }
              }
            }
          }

          .stg-tit-wrap {
            display: grid;
            grid-template-columns: 1fr 5fr 1fr;
            padding: 1rem 0 3rem;
          }
        }

        .add-dno-con {
          width: 100%;
          border: 1px dashed grey;
          padding: 2.5rem 1rem;
          border-radius: 20px;
          margin: 2rem auto;
          cursor: pointer;
          h1 {
            text-align: center;
          }
        }

        .add-wrap {
          border-bottom: 1px solid grey;

          .inner-wrap-new {
            border-bottom: 1px solid grey;
          }
        }
      }
      .rotate-arrow {
        transform: rotate(-45deg);
      }

      h3 {
        font-size: 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;

        span {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      .wrap-tit {
        width: 200px;
        text-align: center;
        padding: 1rem 0;
        background: #252525;
        color: #fff;
      }
    }
  }
}

// aspect-ratio

@media screen and (max-width: 1500px) {
  .cmaker-wrapper {
    padding: 0 2rem;
    .cmaker-container {
      &.top {
        &.dialogue {
          .inner-wrap-new {
            padding: 2.5rem 1rem;
            .scroll-tab-wrap {
              .plus-tab {
                height: auto;
              }
            }
            .middle-tab-btm-con {
              .con {
                .inner-wrap-new {
                  padding: 1rem 1rem;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1336px) and (orientation: landscape) {
  .cmaker-wrapper {
    padding: 0 6rem;
  }
}

@media screen and (aspect-ratio: 4 / 3) {
  .cmaker-wrapper {
    padding: 0 2rem 2rem 2rem;
    .cmaker-container {
      &.top {
        &.dialogue {
          .inner-wrap-new {
            .scroll-tab-wrap {
              max-width: 800px;
            }

            .plus-tab {
              height: auto;
            }
          }
        }
      }
    }
  }
}

@media screen and (aspect-ratio: 16 / 9) {
  .cmaker-wrapper {
    padding: 0 4rem;
  }
}

@media screen and (aspect-ratio: 16 / 10) {
  .cmaker-wrapper {
    padding: 0 4rem;
  }
}

@media screen and (max-width: 1500px) and (aspect-ratio: 16 / 10) {
  .cmaker-wrapper {
    padding: 0 2rem;
  }
}

@media screen and (max-width: 820px) {
  .cmaker-wrapper {
    .cmaker-container {
      &.top {
        .wrap-tit {
          width: 150px;
        }
        &.dialogue {
          .inner-wrap-new {
            .scroll-tab-wrap {
              .scroll {
                max-width: 550px;
              }
            }
          }
        }
      }
    }
  }
}

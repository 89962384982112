
@media (max-width:1023px)
{
    
    
}
@media (max-width:1024px)
{
    .listenandrepeat-audio
    {
        margin-bottom: 60px;
    }
    .listenandrepeat
    {
        .listenandrepeat-content
        {
            flex: 0 0 100%;
            max-width: 100%;
            
        }
        
        
    }
    
    .listenandrepeat-audio
    {
        width: 100%;
        max-width: 100%;
        flex: 0 0 100%;
        margin-bottom: 60px;
        .flip-card-container
        {
            min-height: auto;
        }
        .flip-card-container .front
        {
            position: static;
            font-size: 22px;
        }
    }
   
}
.folderlisting-folderiocn
{
    .ant-image
    {
        width: 50px !important;
        margin-left: 30px;
    }
}
.folderbackbtn
{
    display:none;
}

@media(max-width:1200px)
{
.modalfolder-popup
{
    .ant-modal-body
    {
        form
        {
            .ant-col-12
            {
                flex: 0 0 50%;
                max-width: 50%;
                .ant-row
                {
                    .ant-col
                    {
                        flex: 0 0 100%;
                        max-width: 100%;
                        width:100%;
                        .ant-row 
                        {
                            margin-right:0px !important;
                            width:100% !important;
                        }
                    }
                }
            }
        }
    }
}
.menulisting-foldersection
{
    ul.selectallmenufolder
    {
        li 
        {
            div
            {
                font-size: 16px !important;
            }
            font-size: 16px !important;
            border-bottom: 0px !important;
            label
            {
                font-size: 16px !important;
            }
            .ant-space-item
            {
                &:first-child
                {
                    display: none;
                }
            }
        }
    }
}
.writeoraccess
{
    .nowrite-write-li
    {
        border-bottom: 0px;
        .nowrite-write-text
        {
            font-size: 16px !important;
            margin-left: 0px !important;
        }
    }
}
.file__menu__header .ant-switch-handle {
    width: 20px;
    height: 20px;
    left: 4px;
    top: 5px;
}
.file__menu__header .ant-switch-checked .ant-switch-handle {
    left: calc(100% - 24px - 2px);
}
}
@media(max-width:1000px)
{
.folderlisting
{
    overflow: hidden !important;
    max-height: initial !important;
    .folderlisting-folderiocn > div
    {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .folderlisting-folderiocn
    {
            .ant-image
            {
                width: 50px !important;
                margin-left: 30px;
            }
        span 
        {
            
            svg 
            {
                font-size:30px !important;
            }
        }
        h5
        {
            width: 400px !important;
            overflow:hidden;
            &:last-child
            {
                width: auto !important;
            }
        }
    }
    
}
.folder-wrapper
{
    .folder-wrapper-listing
    {
        flex-direction: column !important;
        flex:auto;
        max-width: inherit;
        width:33.33% !important;
        margin-top:30px;
    }
}
    .folder-wrapper-listing
    {
        h5
        {
            font-size: 14px !important;
            font-weight: 700 !important;
            margin-top: 10px !important;
        }
    }
}
@media(max-width:900px)
{
  
      .folder-breadcrumb-backbutton
      {
          display: block;
          margin-left: 10px;
      }
      .folder-breadcrumb
      {
        flex: 0 0 100%;
        max-width: 100%;
        .folderbackbtn
        {
            display: inline;
            background: #6c7479;
        }
        .ant-breadcrumb
        {
            display: inline-block;
            margin-left: 20px;
            span 
            {
                display: none;
                &:last-child
                {
                    display: block;
                    span
                    {
                        display: block;
                        &.ant-breadcrumb-separator
                        {
                            display: none;
                        }
                    }
                }
            }
            
        }
      }
}

@media(max-width:767px)
{
    .folderlisting .folderlisting-folderiocn h5:last-child {
        width: auto !important;
        display: none !important;
    }
    .folderlisting .folderlisting-folderiocn h5 {
        width: 100% !important;
        overflow: hidden;
    }
}
@media(max-width:600px)
{
    .folder-wrapper
    {
        .folder-wrapper-listing
        {
            flex-direction: column !important;
            flex:auto;
            max-width: inherit;
            width:50% !important;
            margin-top:30px;
        }
    }
    .horizontal-doot-menu
    {
        padding:0px !important;
    }
    
    .listmenuremovespacer
    {
        .spacer-main > div:nth-child(1)
        {
            display: none;
        }
    }
    
}
@media(max-width:400px)
{
.modalfolder-popup
{
    .ant-modal-body
    {
        form
        {
            .ant-col-12
            {
                flex: 0 0 100%;
                max-width: 100%;
                .ant-row
                {
                    .ant-col
                    {
                        flex: 0 0 100%;
                        max-width: 100%;
                        width:100%;
                        .ant-row 
                        {
                            margin-right:0px !important;
                            width:100% !important;
                        }
                    }
                }
            }
        }
    }
}
}
@media all and (min-height:501px) and (max-height:1200px)
{

    .folder-data-listing-empty
    {
        max-height: calc(100vh - 326px) !important;
        min-height: calc(100vh - 326px) !important;
    }
}
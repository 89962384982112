section.ant-layout {
  /* height: auto !important; */
}

div.smsManageWrap {
  height: 90vh;
  max-height: 1100px;
  margin: 17px 0px !important;
  padding-bottom: 20px !important;

  .ant-tabs {
    height: auto;
  }
}

.sms-tit {
  width: 200px;
  text-align: center;
  position: relative;
  top: 25px;
  left: 3%;
  font-weight: 600;
  font-size: 1.5rem;
  letter-spacing: 0.055rem;
}

.testTab {
  &.ant-tabs > .ant-tabs-nav {
    margin-left: auto;
    right: 20px;
    margin-top: 10px;
  }

  .ant-tabs-nav-wrap {
    top: -10px;
  }

  .ant-tabs-tab.ant-tabs-tab-active {
    margin: 0 0 0 32px;
  }

  .ant-tabs-tab {
    padding: 8px 27px;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    background: rgb(195, 196, 197);
  }

  .ant-tabs-tab + .ant-tabs-tab {
    margin: 0 0 0 10px;
    color: #2c2a2a;
  }

  .ant-tabs-tab.ant-tabs-tab-active {
    background: #f2f2f2 !important;
  }

  .ant-tabs-content-holder {
    background: #f2f2f2;
    /* margin: -10px 20px -93px;
        padding: 66px 25px 40px 10px; */
    margin: -9px 20px -102px;
    padding: 30px 71px 20px 60px;
  }

  .ant-tabs-content-top {
    position: relative;
    top: -13px;
  }

  .ant-tabs-nav {
    margin: 0;
  }
}

.msg-group-wrap {
  width: 100%;
}

.phoneBlkBg {
  padding: 40px 10px;
  background: #000;
  width: 80%;
  max-width: 500px;
  margin: 0 auto;
  border-radius: 20px;
  box-shadow: #333 0px 3px 11px 3px;
  position: relative;
}

.phoneWhiteBg {
  background: #fff;
  width: 92%;
  color: #333;
  margin: 0 auto;
  border-radius: 5px;

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    padding: 0 5px;
  }
}

textarea.ant-input.smsTxtArea {
  width: 90%;
  height: 400px;
  margin: 20px auto 10px;
  resize: none;
  display: block;
}

.ant-modal.smsModal-con {
  width: 50% !important;
  right: 0;
  margin: 0 0 0 auto;

  .ant-modal-content {
    background: transparent;
    box-shadow: none;

    .ant-modal-close-x svg {
      fill: #fff;
      stroke-width: 4em;
      font-size: 2rem;
      position: relative;
      top: 25px;
    }
  }

  .ant-modal-header,
  .ant-modal-footer {
    display: none;
  }
}

.smsModal-con .ant-row .sms-view-msg {
  border: 1px solid #d1d1d1;
  width: 95%;
  height: 30vh;
  margin: 10px auto;
  background: #fff;

  .ant-checkbox-wrapper .ant-checkbox {
    width: 33px;
    height: 30px;
    left: 10px;
    top: 10px;
  }

  .ant-checkbox-inner {
    width: 33px;
    height: 30px;
  }

  .ant-checkbox-checked .ant-checkbox-inner::after {
    left: 10px;
  }
}

.SmsChargeModal_con {
  .ant-modal-header,
  .ant-modal-footer {
    display: none;
  }
}

.SmsTxtHistory_table {
  width: 95%;
  margin: 30px auto 10px;
  padding-bottom: 45px;

  .ant-table-thead > tr > th {
    text-align: center;
    font-weight: 600;
  }

  .ant-table-tbody > tr > td {
    text-align: center;
  }

  /*
    .ant-table-pagination.ant-pagination{
        display: none;
    } */

  .ant-table-pagination-right {
    justify-content: center;
  }
}

.SmsChargeHistory_table {
  width: 95%;
  margin: 30px auto 10px;
  padding-bottom: 45px;

  .ant-table-thead > tr > th {
    text-align: center;
    font-weight: 600;
  }

  .ant-table-tbody > tr > td {
    text-align: center;
  }

  /*
    .ant-table-pagination.ant-pagination{
        display: none;
    } */

  .ant-table-pagination-right {
    justify-content: center;
  }
}

.SmsSendBoard_input {
  width: 30%;
  margin: 0 10px;
}

.SmsSendBoard_row {
  justify-content: center;
  position: relative;
  left: 0;

  & > div {
    width: 40%;
    text-align: center;
    /* margin: 10px 0;*/
    display: inline-flex;
    justify-content: center;
    background: #fff;
    padding: 10px 0px;
  }
}

.cancelBtn {
  background: #e24c1e;
  color: #fff;
  border-radius: 5px;

  &:hover {
    color: #e24c1e;
    background: #fff;
    border: 1px solid #e24c1e;
  }
}

.sendBtn {
  background: #0f71aa;
  color: #fff;
  border-radius: 5px;

  &:hover {
    color: #0f71aa;
    background: #fff;
    border: 1px solid #0f71aa;
  }
}

.DateTime_Wrap {
  padding: 0px 0px 13px;
  background: #fff;
  margin-bottom: 6px;
  position: relative;
  top: -11px;

  .ant-picker-calendar-header .ant-picker-calendar-mode-switch {
    display: none;
  }
}

.reserve-con {
  .ant-picker-input > input {
    letter-spacing: 0.1rem;
  }
}

.reserveWrap {
  padding: 10px 0px 13px;
  background: #fff;
  margin-bottom: 6px;
  position: relative;
  top: -11px;
}

.reserveOption_Divide {
  margin: 100px 0 0 0;

  &:before {
    content: '';
    border-top: 1px dashed #d2d6d8;
    display: block;
    width: 100%;
    position: absolute;
    left: 0px;
  }
}

.ant-row.reserveOptionWrap {
  margin: 10px 0;
  position: relative;
  top: 40px;
  display: inline-flex;
  width: 100%;
  justify-content: center;

  .ant-col {
    width: 100%;
    margin: 5px auto 0;
    text-align: center;
    justify-content: center;
  }

  .InputUsed {
    width: 25%;
    text-align: center;
    font-weight: 600;
    background: #fff;
    margin: 0 10px;
    padding: 5px;
    display: inline-flex;
    justify-content: center;
    border: 1px solid #ddd;
    position: relative;
    left: 10px;
  }

  .ToSendNum {
    color: #ff831d;
  }

  .ant-checkbox-wrapper {
    margin-left: 30px;
  }

  .toSendMsg_tit {
    position: relative;
    left: -8%;
  }
}

.ant-row.reserveBtnWrap {
  display: flex;
  justify-content: center;
  margin: 68px 0 15px;

  Button {
    margin: 0 10px;
  }

  .ant-table-selection {
    margin: 0 auto;
    display: block;
  }
}

.SmsSendBoard_row {
  span {
    font-weight: 600;
    padding: 5px !important;
  }

  .InputUsed {
    width: 10%;
    text-align: center;
    font-weight: 600;
    background: #fff;
    margin: 0 10px;
    padding: 5px !important;
  }

  .RemainNum {
    color: #ff831d;
  }

  .TxtRemainNum {
    color: green;
  }
}

.SmsSendBoard_row > .ant-col {
  margin: 1px 0 !important;
}

.SmsSendGroup_Wrap {
  position: relative;
  top: /* -10px; */ -1.7%;

  .ant-table-thead {
    .ant-checkbox-wrapper span.ant-checkbox {
      position: relative;
      left: 30px;
      z-index: 1;
    }
  }

  .ant-table-body {
    .ant-table-cell {
      &.ant-table-selection-column {
        width: 10%;
      }
      &.ant-table-selection-column span {
        position: relative;
        left: 15px;
        display: block;
        z-index: 99;
      }
    }

    & > tr > td {
      padding: 8px;
    }
  }

  .ant-checkbox-checked::after {
    border: none;
  }

  th.ant-table-cell {
    padding: 10px 0;
  }

  .ant-table-pagination.ant-pagination {
    display: none;
  }

  .ant-table table {
    text-align: center;
    margin: 20px 0 0 0;
    border: 1px solid rgb(228, 222, 222);
  }

  .ant-table-pagination-right {
    justify-content: center;
  }

  .ant-table-thead > tr > th {
    text-align: center;
    font-weight: 600;
  }

  .ant-select-selection-item {
    text-align: center;
  }
}

.smsGroup_Btn_Wrap {
  margin-top: 5px;

  a {
    margin: 5px 0;
    display: block;
    color: #555;
    background: #fafafa;
    padding: 8px;
    border: 1px solid #ddd;
    cursor: pointer;
  }

  .smsGroup_Btn {
    width: 95%;
    margin: 0 auto;
    text-align: center;
    .ant-select .ant-select-item-option-content {
      text-align: center;
    }
  }
}

.smsGroup_Btn {
  .ant-select-single {
    &:not(.ant-select-customize-input) .ant-select-selector {
      width: 100%;
      height: 39.5px;
      padding: 4px 11px !important;
      text-align: center;
    }
    &.ant-select-show-arrow {
      .ant-select-selection-item {
        font-size: 0.8rem;
      }
    }
  }
}

/*
.smsGroup_Btn .ant-select-single .smsGroup_Btn .ant-select-single.ant-select-open .ant-select-selection-item {
  font-size: 0.8rem;
  // need to check
}
*/

.smsGroup_Btn_Row {
  .ant-col {
    height: 45px;
  }
}

.dateCon {
  margin: 12px auto 70px;
  width: 80%;
  position: relative;
  left: 0;

  .DateTime_Wrap {
    max-width: 49.5%;
  }

  .ant-picker-content {
    table-layout: fixed;
    width: 100%;
  }
}

.SmsRadioBtn_Wrap {
  display: inline-flex;
  width: 94%;
  margin: 0 auto;

  .ant-radio-wrapper {
    flex-direction: column;
    width: 70px;
    margin: 0 auto 0;
  }

  .ant-radio {
    margin: 0 auto 10px;
    display: block;
  }

  .ant-radio-group {
    width: 90%;
    margin: 0 auto;
  }
}

.groupHeader {
  h3,
  Select {
    display: inline-flex;
  }

  .ant-table-wrapper {
    width: 100%;
    position: relative;
    left: 6%;
  }
}

.toSendGrp {
  margin: 0 auto;
  position: relative;
  width: 100%;
  display: block;
  top: 4px;
}

.sendMsgBtnWrap {
  justify-content: center;
  align-items: center;

  .smsIcn {
    cursor: pointer;
    width: 20px;
    margin: 0 5px;
  }

  .ant-col-8 {
    margin-right: 30px;
  }

  .ant-col-push-4 {
    left: 0;
    margin: 0 3px;
  }
}

.toSendGrp_con {
  position: relative;
  left: 1.5%;

  input {
    width: 41%;
    margin-left: auto;
    display: block;
    margin-bottom: 30px;
  }
}

.senderBox {
  width: auto;
  margin-top: 10px;
}

/* 3rd tab */
.chargeHistory_Wrap {
  h1 {
    text-align: center;
    font-weight: 600;
    font-size: 1.1rem;
  }

  .chargeHistory_Select {
    width: 50%;
  }

  .remainCash_wrap input {
    width: 40%;
    text-align: center;
    margin: 0 5px 0 10px;
  }
}

/* 2nd tab */
.history_Wrap {
  h1 {
    text-align: center;
    font-weight: 600;
    font-size: 1.1rem;
  }

  .history_Select {
    width: 50%;
  }

  .remainCash_wrap input {
    width: 30%;
    text-align: center;
    margin: 0 5px 0 10px;
  }
}

.btnToRight {
  display: none;
}

.btnToRight_subMenu {
  .ant-dropdown-menu-item-disabled,
  .ant-dropdown-menu-submenu-title-disabled,
  .ant-dropdown-menu-item-disabled:hover {
    cursor: default !important;
  }
}

.titForM {
  display: none;
}

/****************************** responsive ******************************/

@media /*(min-width: 1025px) and*/ (max-width: 1514px) {
  .phoneBlkBg {
    width: 85%;
    padding: 20px 5px;
    top: 0;
    .phoneWhiteBg {
      .ant-row:first-child {
        padding: 0 !important;
      }
    }
  }

  .testTab .ant-tabs-content-top {
    position: relative;
    top: -36px;
  }

  div.smsManageWrap .ant-tabs {
    position: relative;
  }

  .testTab .ant-tabs-content-holder {
    max-height: fit-content;
    // padding: 27px 38px 3px 40px;
  }

  .smsModal-con {
    position: relative;
  }

  .testTab .ant-tabs-content-holder {
    padding: 42px 59px 3px 59px;
  }

  .SmsSendGroup_Wrap .ant-table table {
    text-align: center;
    margin: 35px 0 8px 0;
  }

  .SmsSendBoard_row > div {
    width: 41%;
    text-align: center;
    margin: 3px 0;
  }

  .chargeHistory_Wrap {
    width: 100%;

    h1 {
      text-align: center;
      font-weight: 600;
      font-size: 1rem;
    }

    .ant-col-2 {
      max-width: 100%;
      flex: none;
    }

    .chargeHistory_Select {
      width: 70%;
      left: -30%;
      // no use
    }

    .ant-col-push-4 {
      left: 19.666667%;
    }

    .ant-picker {
      width: 100%;
    }

    .ant-col-push-7 {
      flex: 0 0 15.3%;
    }

    .ant-col-push-8 {
    }

    .ant-col-4.remainCash_wrap {
      max-width: 100%;
    }

    .remainCash {
      margin-left: 0;
    }
  }

  .history_Wrap {
    h1 {
      text-align: center;
      font-weight: 600;
      font-size: 1rem;
    }
    .ant-col-2 {
      max-width: auto !important;
      flex: none;
    }

    .history_Select {
      width: 100%;
      left: -70%;
    }

    .ant-col-push-7 {
      left: 29.16666667%;
      max-width: 13% !important;
    }
  }

  textarea.ant-input.smsTxtArea {
    width: 90%;
    height: 364px;
    margin: 20px auto 10px;
    resize: none;
    display: block;
  }

  .testTab .ant-tabs-content-holder {
    background: #f2f2f2;
    margin: -10px 20px 3px;
  }

  .SmsSendBoard_row {
    justify-content: center;
    position: relative;

    & > div {
      width: 40%;
      text-align: center;
      margin: 4px 0;
      display: inline-flex;
      justify-content: center;
      background: #fff;
      padding: 3px 0px;
    }

    .InputUsed {
      width: 18%;
    }
  }

  .ant-row.reserveOptionWrap .ant-col {
    width: 100%;
  }

  .picker-con {
    width: 80%;
  }

  .DateTime_Wrap {
    width: 100%;
    padding-left: 0;
    padding-right: 0;

    .ant-picker-date-panel .ant-picker-content {
      width: auto;
      position: relative;
    }

    .ant-picker-content {
      table-layout: unset;
      width: 100%;
    }
  }

  .dateCon {
    width: auto;
    margin: 0 auto;
    padding-right: 15px;
    top: 3%;
  }

  .DateTime_Wrap {
    padding: 10px 0px 0px;
    max-width: 59%;

    .ant-row:last-child {
      .SmsRadioBtn_Wrap {
        .ant-radio-group-outline {
          padding-bottom: 3px;
        }
      }
    }
  }

  .SmsSendBoard_row span {
    padding: 5px 0 !important;
    font-size: 0.8rem;
  }

  .ant-row.reserveBtnWrap Button {
    margin: 0 auto;
  }

  .ant-modal.smsModal-con {
    width: 70% !important;
  }

  .toSendGrp_tit {
    font-size: 0.8rem !important;
  }

  .SmsSendGroup_Wrap {
    position: relative;
    top: -2.7%;
  }

  .sendMsgBtnWrap {
    .ant-col-8 {
      .ant-btn {
        font-size: 0.6rem !important;
      }
    }
  }

  .sms-tit {
    width: 100%;
    top: 0;
    left: 0;
  }
}

@media screen and (max-width: 1400px) {
  .smsManageWrap {
    .testTab {
      .ant-tabs-content-top {
        top: 0;
      }
    }

    .phoneBlkBg {
      width: auto;
      max-width: 100%;
    }

    .toSendGrp_con {
      left: 2rem;
      .smsGroup_Btn_Wrap {
        margin-top: 0;
      }

      .ant-table-body {
        height: 380px;

        tr {
          td {
            font-size: 0.85rem;
          }
        }
      }
    }

    .sendMsgBtnWrap {
      justify-content: space-between;
    }

    .testTab.ant-tabs {
      & > .ant-tabs-nav {
        margin-bottom: 0;
      }

      .ant-tabs-content-holder {
        padding: 20px 59px 0px 59px;
      }
    }

    .SmsRadioBtn_Wrap {
      .ant-radio-wrapper {
        flex-direction: column-reverse;
      }
      .ant-radio-inner {
        position: relative;
        top: 0;
        left: 0;
        transform: translate(0, 0);
      }
    }

    .dateCon {
      width: auto;
      max-width: 26rem;
      left: 0;
      padding-left: 0;
      padding-right: 0;

      .DateTime_wrap {
        max-width: 60%;
        width: 100%;
      }

      .reserveWrap {
        padding: 0;
      }
    }
  }
}

@media (max-width: 1350px) and (orientation: landscape) {
  div.smsManageWrap .ant-tabs {
    height: auto;
  }
}

@media (min-width: 1239px) and (max-width: 1400px) and (orientation: landscape) {
  .SmsSendBoard_row span {
    font-size: 0.7rem;
  }

  .reserveOptionWrap span,
  .toSendMsg_tit,
  .dateCon h3,
  .reserve-con h3 {
    font-size: 0.75rem;
  }

  .chargeHistory_Wrap {
    h1 {
      text-align: center;
      font-weight: 600;
      font-size: 0.9rem;
    }
    .ant-col-2 {
      max-width: auto;
      flex: none;
    }
  }

  .history_Wrap {
    h1 {
      text-align: center;
      font-weight: 600;
      font-size: 0.9rem;
    }
    .ant-col-2 {
      max-width: auto;
      flex: none;
    }
  }

  .smsGroup_Btn .ant-select-single.ant-select-show-arrow .ant-select-selection-item {
    font-size: 0.7rem;
  }

  .smsGroup_Btn_Wrap {
    margin-top: -10px;
    font-size: 0.7rem;
  }

  .toSendGrp_tit {
    font-size: 0.85rem;
  }

  .SmsSendGroup_Wrap {
    margin-top: -20px;
  }

  .ant-row.reserveBtnWrap Button {
    margin: 0 auto;
    font-size: 0.7rem;
  }

  .SmsSendGroup_Wrap {
    position: relative;
    top: -0.7%;
  }

  .dateCon {
    width: 100%;
    margin: 0 auto;
    padding-left: 39px;
    padding-right: 15px;
    left: 6.5%;
  }

  .SmsSendBoard_row {
    justify-content: center;
    position: relative;
    left: 0%;

    & > div {
      width: 45%;
    }
  }

  .DateTime_Wrap,
  .reserveWrap {
    padding: 0px 0px 0px;
    top: -17px;
  }

  .SmsRadioBtn_Wrap {
    .ant-radio-wrapper {
      width: 70px;
    }
    .ant-radio-group {
      margin-bottom: 10px;
    }
  }

  .sendMsgBtnWrap {
    padding: 0;
  }

  .reserveOption_Divide {
    margin: 64px 0 0 0;
  }
}

@media (max-width: 1238px) {
  //
  .SmsSendBoard_row {
    justify-content: center;
    position: relative;
  }

  .SmsSendBoard_row > div {
    width: 44%;
  }

  .SmsSendBoard_row span {
    font-size: 0.7rem;
  }

  .smsGroup_Btn .ant-select-single.ant-select-show-arrow .ant-select-selection-item {
    font-size: 0.7rem;
  }

  .reserveOptionWrap span,
  .toSendMsg_tit,
  .dateCon h3,
  .reserve-con h3 {
    font-size: 0.75rem;
  }

  .smsGroup_Btn_Wrap a {
    font-size: 0.7rem;
  }

  .dateCon {
    width: 100%;
    margin: 0 auto;
    padding-left: 39px;
    padding-right: 15px;
    left: 5.5%;
  }

  .ant-row.reserveBtnWrap Button {
    margin: 0 auto;
    font-size: 0.7rem;
  }
}

/* 1st breakpoint in below*/
@media (max-width: 1024px) {
  .smsManageWrap {
    .phoneBlkBg {
      .phoneWhiteBg {
        .ant-col-push-5 {
          button.ant-btn-icon-only {
            margin-top: 10px;
          }
        }
      }
    }

    .toSendGrp_con {
      .ant-table-body {
        tr {
          td {
            font-size: 0.7rem;
          }
        }
      }
    }

    .SmsRadioBtn_Wrap {
      .ant-radio-wrapper {
        margin: 0 auto;
        top: 75px;
      }
    }
  }

  .ant-modal.smsModal-con {
    width: 94% !important;
    position: relative;
    left: 0;
  }

  .senderBox {
    width: auto;
    left: 0;
    position: relative;
    margin-top: 10px;
    font-size: 0.5rem;
  }

  .testTab .ant-tabs-content-holder {
    background: #f2f2f2;
    margin: -10px 20px -10px;
  }

  .chargeHistory_Wrap {
    // display: none;
  }

  .chargeHistory_Wrap h1 {
    text-align: center;
    font-weight: 600;
    font-size: 1rem;
  }

  /** 3rd **/
  .btnToRight {
    margin-left: auto;
    position: relative;
    display: block;
    right: 20px;
  }

  .history_Wrap {
    // display: none;
    width: 100%;
    justify-content: start;

    h1 {
      text-align: center;
      font-weight: 600;
      font-size: 1rem;
    }

    .ant-col-2 {
      width: auto;
    }

    .ant-col-push-6 {
      left: 15%;
      display: none;
    }

    .ant-col-push-9 {
      left: 30%;
    }

    .remainCash_wrap {
      max-width: 22%;
      flex: none;
    }

    .chargeHistory_Select {
      width: 69%;
    }
  }

  .smsGroup_Btn_Wrap {
    margin-top: 10px;

    .ant-col-12 {
      flex: 0 0 48%;
    }

    a {
      margin: 5px 0;
      display: block;
      padding: 8px;
    }

    .smsGroup_Btn {
      width: 95%;
      margin: 0 auto;
      text-align: center;
    }
  }

  .toSendGrp {
    left: 0;
    width: 100%;
  }

  .SmsSendGroup_Wrap {
    max-width: 95%;
    margin: 0 auto;
  }
}

@media (max-width: 1000px) {
  div.smsManageWrap {
    height: max-content;
    max-height: 3000px;

    .testTab.ant-tabs {
      .ant-tabs-content-holder {
        padding: 20px 0px 0px 0px;
        .ant-tabs-content-top {
          .row-container {
            flex-direction: column;
            align-items: center;

            .phone_con {
              max-width: 80%;
            }
          }
        }
      }
    }
  }

  .testTab {
    margin: 0 0 0 !important;

    .ant-tabs-content-holder {
      max-height: fit-content;
      height: auto;
    }
  }

  .row-container {
    margin: 0 auto;

    .phoneBlkBg {
      padding: 35px 10px;
      width: 92%;
    }

    .SmsSendBoard_row > div {
      width: 42.3% !important;
      left: -4%;
      // need to check
    }

    .ant-modal.smsModal-con {
      width: 94% !important;
      position: relative;
      left: 0%;
    }
  }

  .SmsSendBoard_row .InputUsed {
    width: auto;
  }

  .groupHeader .ant-table-wrapper {
    left: 0;
  }

  .SmsRadioBtn_Wrap .ant-radio-inner {
    top: 111px;
  }

  .chargeHistory_Wrap {
    //  display: none;
  }

  .DateTime_Wrap:before {
    display: none;
  }

  .history_Wrap {
    //  display: none;
    .ant-col-push-4 {
      left: 0;
    }
    .ant-col-4 {
      flex: none;
      margin-left: 0;
      margin-top: 0;
      left: 0;
      max-width: 21%;

      &:nth-child(1) {
        margin-left: auto;
      }

      &.remainCash_wrap span {
        display: inline-flex;
        flex: none;
        max-width: 100%;
        left: 0;
        position: relative;
      }
    }
  }

  .SmsRadioBtn_Wrap .ant-radio-inner {
    top: 75px;
  }

  .sendMsgBtnWrap {
    .ant-col-8 {
      position: relative;
      left: 2%;
    }

    .ant-col-push-4 {
      left: 14%;
      margin: 0 3px;
    }
  }

  .smsManageWrap {
    .ant-tabs {
      height: auto;
    }

    .dateCon {
      margin: 10px 0 0 0;
      width: 100%;
      max-width: 33rem;

      .DateTime_Wrap {
        //   max-width: 100%;
        left: 0;
      }
    }
  }

  .DateTime_Wrap {
    .ant-picker-content {
      table-layout: fixed;
    }
  }

  .ant-col-12.reserveWrap {
    max-width: 100%;
    margin: 0 auto;
    left: 0;
  }

  .row-container {
    .SmsSendBoard_row {
      & > div {
        width: 49% !important;
        left: 10px;
      }

      span {
        font-weight: 600;
        font-size: 11.1px;
      }
    }
  }

  .toSendGrp {
    left: 0;
    width: 100%;
  }

  .SmsSendGroup_Wrap {
    max-width: 95%;
    margin: 0 auto;
  }

  .reserve-con {
    .ant-col.picker-con {
      width: auto;
    }
  }

  .smsModal-con {
    .ant-row {
      .sms-view-msg {
        height: 15rem;
      }
    }
  }
}

@media (max-width: 900px) {
  .dateCon .DateTime_Wrap {
    max-width: 50%;
  }

  .dateCon {
    margin: 16px auto 0px;
    width: 68vw;
    left: 0;
    padding-left: 0;
    padding-right: 0;
    top: 0;
  }

  .SmsSendBoard_row > div {
    width: 50%;
  }

  .SmsRadioBtn_Wrap .ant-radio-inner {
    top: 75px;
  }

  .dateCon .ant-picker-content {
    table-layout: auto;
  }

  .senderBox {
    width: 70%;
    left: -19%;
    position: relative;
    margin-top: 10px;
  }
}

@media (max-width: 1200px) and (orientation: portrait) {
  .smsManageWrap {
    .testTab.ant-tabs {
      .ant-tabs-content-holder {
        padding: 20px 0px 0px 0px;
        .ant-tabs-content-top {
          .row-container {
            flex-direction: column;
            align-items: center;

            .phone_con {
              max-width: 80%;
            }
          }
        }
      }
    }

    .senderBox {
      left: 0;
    }

    .toSendGrp_con {
      max-width: 80%;
      left: 0;
      margin: 2rem 0 0;
    }

    .smsGroup_Btn_Wrap {
      margin-left: 0;
    }

    .time_con {
      max-width: 100%;
      left: 0;

      .SmsSendBoard_row {
        & > div {
          width: 50% !important;
          left: 0;
        }
      }
    }

    .sendMsgBtnWrap {
      .ant-col-8 {
        width: auto;
        left: 0;
      }
    }
  }
}

@media (max-width: 1200px) and (orientation: landscape) {
  .smsManageWrap {
    .testTab.ant-tabs {
      .ant-tabs-content-holder {
        padding: 20px 45px 0px 30px;
      }
    }

    .phoneBlkBg {
      .phoneWhiteBg {
        .ant-col-push-5 {
          left: 15%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }

    .sendMsgBtnWrap {
      flex-flow: inherit;
      .ant-col-8 {
        .ant-btn {
          margin-left: 0 !important;
        }
      }
    }

    .toSendGrp_con {
      left: 0;
      width: 100%;
      max-width: 80%;

      .ant-table-thead > tr th,
      .ant-table-body > tr > td {
        font-size: 0.8rem;
      }
    }

    .row-container {
      .ant-col-10 {
        &.ant-col-push-1 {
          left: 0;
          &.time_con {
            max-width: 100%;
          }
        }
      }
    }
  }
}

@media (max-width: 500px) {
  .testTab {
    .ant-tabs-tab {
      &:nth-child(1).ant-tabs-tab-active {
        margin: 0;
      }
    }

    .ant-tabs-content-holder {
      margin: 0;
    }
  }

  .toSendGrp_con input {
    width: 40%;
    margin: 0 29px 0 auto;
  }

  .toSendGrp {
    margin: 17px auto 0;
    position: relative;
    left: 52%;
    width: 40%;
  }

  .testTab.ant-tabs > .ant-tabs-nav {
    margin-top: 25px;
    right: 0;
    margin-left: 0;
  }

  .smsManageWrap {
    .testTab {
      .ant-tabs-nav {
        .ant-tabs-nav-wrap {
          justify-content: center;
        }
      }
    }
  }

  .sendMsgBtnWrap {
    .ant-col-8 {
      margin-right: 0;
      .ant-btn {
        padding: 0 4px;
      }
    }

    .ant-col-push-4 {
      left: -29%;
      margin: 0 4px;
    }

    .smsIcn {
      width: 100%;
    }
  }

  .reserveOption_Divide {
    margin: 46px 0 0 0;
  }

  .chargeHistory_Wrap {
    h1 {
      text-align: center;
      font-weight: 600;
      font-size: 1rem;
    }

    .ant-col-2 {
      width: auto;
      max-width: 100%;
      flex: none;
      left: 0;
      margin: 0 1rem;
    }

    .ant-col-3 {
      flex: none;
      left: 0;
      margin-top: 1rem;
    }

    .ant-col-4 {
      &.remainCash_wrap {
        flex: none;
        max-width: 100%;
        left: 0;
        width: 14rem;
        margin-top: 1rem;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .remainCash {
      margin-left: 0;
    }
  }

  .history_Wrap {
    h1 {
      text-align: center;
      font-weight: 600;
      font-size: 1rem;
    }

    .ant-col-2 {
      max-width: 100%;
      width: auto;
      &.historyDate_Wrap {
        left: 2rem;
      }
    }

    .ant-col-3 {
      flex: none;
      left: 0;
    }

    .ant-col-push-9 {
      left: 1%;
      max-width: 100%;
    }

    .ant-col-push-7 {
      left: 1%;
      margin: 10px 0;
    }

    .remainCash_Wrap {
      display: inline-flex !important;
      max-width: none !important;
      flex: none;

      input {
        width: 30%;
        text-align: center;
        margin: 0 5px 0 10px;
        left: 0%;
      }

      span {
        display: inline-flex;
      }
    }
  }

  .smsGroup_Btn_Row {
    display: block;
  }

  .smsGroup_Btn_Wrap {
    margin-top: 10px;
    margin-left: 0;

    .ant-row {
      display: block !important;
    }

    .ant-col-12 {
      max-width: 100%;
      flex: none;
    }

    a {
      margin: 5px 0;
      display: block;
      padding: 8px;
    }

    .smsGroup_Btn {
      width: 95%;
      margin: 0 auto;
      text-align: center;
    }
  }

  .toSendGrp {
    left: 0;
    width: 100%;
  }

  .SmsSendGroup_Wrap {
    max-width: 95%;
    margin: 0 auto;
  }

  .smsModal-con {
    .ant-row {
      display: block;
    }

    .ant-col-7 {
      flex: none;
      max-width: 89%;
    }
  }

  .time_con {
    .dateCon {
      max-width: 100%;
      .DateTime_Wrap {
        top: 0;
      }
      .ant-col-12.reserveWrap {
        max-width: 50%;
        top: 0;
      }
    }
  }
}

@media (max-width: 425px) {
  .SmsRadioBtn_Wrap .ant-radio-inner {
    top: 80px;
  }

  .testTab {
    margin: 60px 0 0px;

    .ant-tabs-content-holder {
      background: #f2f2f2;
      margin: -10px -1px -24px;
      padding: 40px 25px 40px 10px;
    }
  }

  textarea.ant-input.smsTxtArea {
    height: 340px;
  }

  .row-container {
    .ant-col-8 {
      max-width: 100%;
      width: 100%;
    }

    .ant-col-6 {
      width: 100%;
      max-width: 100%;
      position: relative;
      top: 30px;
    }

    .ant-col-12 {
      flex: none;
    }

    .SmsSendBoard_row > div {
      width: 88%;
    }
  }

  .DateTime_Wrap,
  .dateCon {
    padding: 10px 0;
    max-width: 100%;
  }

  .SmsSendBoard_row span {
    font-weight: 600;
    padding: 5px !important;
    font-size: 0.7rem;
  }

  .sendMsgBtnWrap {
    .ant-col-8 {
      left: 30%;
    }
    .ant-col-push-4 {
      left: 5%;
      margin: 35px 16px;
    }
  }

  .senderBox {
    left: 50%;
    position: relative;
    margin-top: 10px;
    // width: 100% !important;
  }

  .smsModal-con {
    .ant-row {
      left: -4%;
      flex-flow: column wrap;
      position: relative;

      .sms-view-msg {
        width: 100%;
      }
    }

    .ant-col-7 {
      display: block;
      flex: 100%;
      max-width: 100%;
      width: 100%;
    }
  }

  .chargeHistory_Wrap {
    .ant-col-3 {
      flex: none;
    }
  }

  .history_Wrap {
    display: flex;
    width: 100%;
    justify-content: start;
    //  display: none;

    .ant-col-3 {
      flex: none;
    }

    .ant-col-4 {
      max-width: 15rem;
      flex: none;
      margin-left: 1rem;
      margin-top: 15px;
      left: 0;

      &.remainCash_wrap {
        left: 0;
        margin-top: 0.3rem;
      }
    }

    input {
      width: 100%;
      margin: 0 auto;
    }

    .ant-col-2.historyDate_Wrap {
      width: 50%;
      margin: 0 auto;
      left: 10%;
    }

    .remainCash_wrap {
      margin-left: 1rem;
    }
  }

  .smsmanage-modal {
    width: 100% !important;
    .ant-modal-body {
      .ant-row {
        row-gap: 25px !important;
        .ant-col.ant-col-18 {
          .ant-radio-group {
            .ant-radio-wrapper {
              margin: 0 0.5rem 0.5rem;
            }
          }
        }
      }
    }
  }

  .smsGroup_Btn_Row {
    display: block;
  }

  .smsGroup_Btn_Wrap {
    margin-top: 10px;
    margin-left: 0;

    .ant-row {
      display: block !important;
    }

    .ant-col-12 {
      max-width: 100%;
      flex: none;
    }

    a {
      margin: 5px 0;
      display: block;
      padding: 8px;
    }

    .smsGroup_Btn {
      width: 95%;
      margin: 0 auto;
      text-align: center;
    }
  }

  .toSendGrp {
    left: 0;
    width: 100%;
  }

  .SmsSendGroup_Wrap {
    max-width: 95%;
    margin: 0 auto;
  }

  .sendMsgBtnWrap .ant-col-8 {
    margin-right: 0;
  }
}

@media (max-width: 375px) {
  .smsManageWrap {
    .testTab {
      &.ant-tabs {
        margin-top: 2.5rem !important;
      }

      .ant-tabs-tab {
        padding: 8px 20px;
        &:nth-child(1) {
          margin-left: 0.5rem;
        }
      }
    }
  }

  .row-container .ant-col-10 {
    display: block;
    flex: none;
    max-width: 100%;
    margin: 53px auto 0;
  }

  .smsGroup_Btn_Row {
    display: block;
  }

  .smsGroup_Btn_Wrap {
    margin-top: 10px;
    margin-left: 0;

    .ant-row {
      display: block !important;
    }

    .ant-col-12 {
      max-width: 100%;
      flex: none;
    }

    a {
      margin: 5px 0;
      display: block;
      padding: 8px;
    }

    .smsGroup_Btn {
      width: 95%;
      margin: 0 auto;
      text-align: center;
    }
  }

  .toSendGrp {
    left: 0;
    width: 100%;
  }
}

$red: #c00000;
$orange: #ea701c;
$paleyellow: #ffffd9;
$yellow: #ffc000;
$skyblue: #dbf3fb;
$darkblue: #5399d9;
$midblue: #cff1f9;
$paleblue: #dbf3fb;
$paleblue2: #eff5fb;
$purple: #a42cca;
$palepurple: #dae3f3;
$palefff: #fbfbfb;

.vocatool-con {
  width: 100%;
  height: 100vh;
  background: #00b1ff;
  overflow: auto;

  h1,
  h2,
  h3,
  h4,
  h5,
  p {
    margin-bottom: 0;
  }

  // header start
  header {
    margin: 0 1rem;
    width: 100%;
    max-width: 1600px;
    display: flex;
    margin: 3rem auto 0;
    align-items: center;
    div {
      background: #ffff;
      width: auto;
      justify-content: space-around;
      display: flex;
      margin: 0 1rem;
      padding: 1rem;
      align-items: center;
      border-radius: 20px;

      h3 {
        font-weight: 700;
      }

      &.lesson-tit {
        h3 {
          color: $purple;
        }
      }

      &.tit {
        h3 {
          color: $yellow;
        }
      }

      &.user-info-con {
        background: transparent;
        margin-left: auto;
        h3 {
          color: #fff;
          font-size: 1.1rem;
        }

        .close {
          border-radius: 50%;
          width: 50px;
          height: 50px;
          span.anticon {
            svg {
              transform: scale(3);
              fill: $orange;
            }
          }
        }
      }
    }
  }
  // header end

  .vocatool-body {
    max-width: 1600px;
    width: 100%;
    background: #fff;
    border-radius: 20px;
    border: 6px solid $yellow;
    margin: 1rem auto 0;
    // height: 100%;
    display: grid;
    //  grid-template-columns: 3fr 2fr 1fr;
    grid-template-columns: 5fr 1fr;
    padding: 0.5rem 1rem;
    gap: 1rem;
    height: 730px;

    &.no-process-bar {
      grid-template-columns: auto;
    }

    .nav-btm {
      text-align: center;
      width: 100%;
      display: grid;
      grid-template-columns: 1fr 3fr 1fr;
      padding: 1rem 0;
      margin: 1rem 0;

      a {
        &.prev {
          span {
            &.anticon {
              svg {
                transform: rotate(0deg) scale(3.5);
                fill: grey !important;
              }
            }
          }
        }
        &.checkprev.prev {
          transform: rotate(0deg);
        }
        span {
          &.anticon {
            svg {
              transform: scale(3.5);
            }
          }
        }
      }

      .pager {
        display: flex;
        flex-direction: row;
        justify-self: center;
        justify-content: space-between;
        width: 100%;
        max-width: 250px;

        span {
          display: flex;
          justify-content: space-around;
          width: 100%;
          align-items: center;
          a {
            &.active {
              h3 {
                font-size: 1.3rem;
                font-weight: 600;
                color: $darkblue;
              }
            }
            h3 {
              font-size: 1.15rem;
              font-weight: 500;
              color: $darkblue;
            }
          }
        }
      }
    }

    .innner {
      height: 700px;
      .tool-header {
      }
    }

    // start VocaFlashcard.js
    .flashcard-voca-con {
      display: grid;
      grid-template-columns: 3fr 2fr;
      height: 100%;
      gap: 2rem;
      .question-con {
        border: 2px solid #ffc000;
        border-radius: 10px;
        width: 100%;
        //   height: 100%;
        position: relative;
        padding: 1rem;
        display: grid;
        grid-template-rows: 3fr 1fr;
        gap: 2rem;
        .num {
          position: absolute;
          right: 0;
          padding: 1rem;
          margin: 1rem 2rem 0 0;
          background: #ffe4c9;
          z-index: 9999;
        }
        .inner {
          &.top {
            display: grid;
            //  height: 100%;
            // grid-template-rows: 3.5fr 2fr;
            //   background-image: url('https://culp.cloubot.com/images/lexilelogo.png');
            position: relative;
            margin-bottom: 2rem;

            width: 100%;
            position: relative;
            height: 100%;
            margin: 0 1rem;

            padding: 0 1rem;

            img {
              width: 100%;
              height: auto;
              display: flex;
              align-self: center;
              justify-self: center;
            }

            .word {
              display: grid;
              grid-template-rows: 1fr 1fr;
              width: 100%;
              height: 100%;
              flex-direction: column;
              position: absolute;
              z-index: 99;
              // visibility: hidden;

              .wordbox {
                width: 100%;
                //  height: 250px;
                display: flex;
                align-items: center;
                justify-self: center;
                justify-content: center;

                &.en {
                  background: #fff;
                  h2 {
                    color: $red;
                  }
                }
                &.kr {
                  background: $paleblue;
                  h2 {
                    color: $darkblue;
                  }
                }
                h2 {
                  font-weight: 800;
                  font-size: 2.6rem;
                }
              }
            }
          }

          &.bottom {
            height: 100px;
            width: auto;
            display: flex;
            justify-content: space-around;
            align-items: center;
            text-align: center;
            padding: 1rem 5rem;
            background: #97a6f3;
            border-radius: 20px;
            margin: auto 1rem;
            div {
              width: 60px;
              height: 60px;
              border-radius: 50%;
              border: 3px solid $paleblue;
              background: #fff;
              display: flex;
              justify-content: center;
              align-items: center;
              cursor: pointer;
              span.anticon {
                svg {
                  transform: scale(3);
                }

                &.prev-btn {
                  transform: rotate(-180deg);
                }
              }
              .speaker-btn {
                svg {
                  //  fill: #fff !important;
                }
              }

              .prev-btn {
                svg {
                  transform: scale(3) rotate(-180deg);
                }
              }
              .play-btn {
                svg {
                  fill: $yellow !important;
                }
              }
            }
          }
        }
      }

      .wordlist-con {
        background: $skyblue;
        border-radius: 10px;
        width: 100%;
        //    height: 100%;
        text-align: center;
        overflow-y: auto;
        padding: 1rem 0;

        &.ks {
          background: $palepurple;
        }
        h2 {
          margin: 2rem auto;
          font-size: 2rem;
          font-weight: 700;
          color: $purple;
        }
        .inner {
          display: grid;
          align-items: center;
          justify-content: center;
          gap: 1rem;
          .word-box {
            border: 2px solid #90daf4;
            border-radius: 10px;
            padding: 1rem 0.5rem;
            background: #ffff;
            width: 300px;

            &.correct {
              background: beige;
              h3 {
                color: red;
              }
            }
          }
        }
      }
    }

    .progress-con {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      align-self: start;
      .inner {
        height: 100%;
        display: flex;
        align-items: center;
        margin-top: 3rem;
        // margin: 1.5rem auto;
        .pentagon {
          position: relative;
          width: 82px;
          height: 50px;
          box-sizing: content-box;
          border-width: 50px 5px 0;
          border-style: solid;
          border-color: $darkblue $darkblue;
          background: $darkblue;
          display: flex;
          justify-content: center;
          align-items: center;
          top: -1rem;

          &:before {
            content: '';
            position: absolute;
            height: 0;
            width: 0;
            top: -50px;
            transform: rotate(180deg);
            left: -4px;
            border-width: 0 45px 35px;
            border-style: solid;
            border-color: transparent transparent #fff;
          }

          &:after {
            content: '';
            position: absolute;
            height: 0;
            width: 0;
            top: 50px;
            transform: rotate(180deg);
            left: -4px;
            border-width: 0 45px 35px;
            border-style: solid;
            border-color: transparent transparent $darkblue;
          }

          &.first {
            position: relative;
            width: 82px;
            height: 50px;
            box-sizing: content-box;
            border-width: 50px 5px 0;
            border-style: solid;

            display: flex;
            justify-content: center;
            align-items: center;
            top: -1rem;

            h3 {
              position: absolute;
              top: 0;
              font-size: 1.4rem;
              cursor: default;
            }
            &:before {
              content: '';
              position: absolute;
              height: 0;
              width: 0;
              top: -50px;
              transform: rotate(180deg);
              left: -4px;
              border-width: 0 45px 35px;
              border-style: solid;
              border-color: transparent transparent $darkblue;
            }
            &:after {
              content: '';
              position: absolute;
              height: 0;
              width: 0;
              top: 50px;
              transform: rotate(180deg);
              left: -4px;
              border-width: 0 45px 35px;
              border-style: solid;
              border-color: transparent transparent $darkblue;
            }

            // first + active
            &.active {
              border-color: $yellow $yellow;
              background: $yellow;

              h3 {
                position: absolute;
                top: 0;
                font-size: 1.4rem;
                cursor: default;
              }
              &:before {
                position: absolute;
                height: 0;
                width: 0;
                top: -50px;
                transform: rotate(180deg);
                left: -4px;
                border-width: 0 45px 35px;
                border-style: solid;
                border-color: transparent transparent $yellow;
              }
              &:after {
                content: '';
                position: absolute;
                height: 0;
                width: 0;
                top: 50px;
                transform: rotate(180deg);
                left: -4px;
                border-width: 0 45px 35px;
                border-style: solid;
                border-color: transparent transparent $yellow;
              }
            }
          }

          &.active {
            border-color: $yellow $yellow;
            background: $yellow;

            h3 {
              position: absolute;
              top: 0.5rem;
              font-size: 1.4rem;
              cursor: default;
            }
            &:before {
              position: absolute;
              height: 0;
              width: 0;
              top: -50px;
              transform: rotate(180deg);
              left: -4px;
              border-width: 0 45px 35px;
              border-style: solid;
              border-color: transparent transparent #fff;
            }
            &:after {
              content: '';
              position: absolute;
              height: 0;
              width: 0;
              top: 50px;
              transform: rotate(180deg);
              left: -4px;
              border-width: 0 45px 35px;
              border-style: solid;
              border-color: transparent transparent $yellow;
            }
          }

          h3 {
            position: absolute;
            top: 0.5rem;
            font-size: 1.4rem;
            text-align: center;
            cursor: default;
          }
        }
      }
    }
    // end VocaFlashcard.js

    // start VocaCheck.js
    .check-con {
      position: relative;
      border: 2px solid $yellow;
      border-radius: 20px;
      header.tool-header {
        display: flex;
        justify-content: space-between;
        margin-top: 0;
        h2 {
          margin-left: 3rem;
        }
        .num {
          width: 120px;
          position: relative;
          top: 0;
          right: 0;
          padding: 1rem;
          margin: 1rem 2rem 0 0;
          background: #ffe4c9;
          z-index: 99;
        }
      }

      .check-q-wrap {
        margin: 0 auto;
        display: grid;
        padding: 2rem 3rem;
        .q {
          &.q-row {
            width: 100%;
            margin: 1rem 0;
            display: grid;
            grid-template-columns: 1fr 1.5fr 1fr;
            padding: 0;
            .left-wrap {
              width: 100%;
              display: grid;
              grid-template-columns: 1fr 2fr auto;
              align-items: center;
              justify-content: center;
              .q-num {
                border: 2px solid $darkblue;
                width: 45px;
                height: 45px;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 0.5rem;
                font-size: 1.2rem;
                margin: 0 auto;
                h3 {
                  color: $darkblue;
                }
              }
              .q-word-box {
                background-color: #fff;
                outline: 5px solid $darkblue;
                border-radius: 10px;
                text-align: center;
                font-size: 1.2rem;
                max-width: 250px;
                h3 {
                  -webkit-box-shadow: inset 0px 0px 0px 3px $paleblue;
                  -moz-box-shadow: inset 0px 0px 0px 3px $paleblue;
                  box-shadow: inset 0px 0px 0px 3px $paleblue;
                  border-radius: 10px;
                  width: 100%;
                  padding: 1rem 0;
                  text-align: center;
                  cursor: default;
                }
              }
              .dot {
                margin: 0 auto;
                padding: 1rem;
                span.circle {
                  display: flex;
                  width: 10px;
                  height: 10px;
                  border-radius: 50%;
                  border: 2px solid $darkblue;
                  justify-self: center;
                  justify-content: center;
                  align-items: center;
                }
              }
            }

            .right-wrap {
              width: 100%;
              display: grid;
              grid-template-columns: auto 70%;
              align-items: center;
              justify-content: space-evenly;
              .q-num {
                width: 45px;
                height: 45px;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 0.5rem;
                font-size: 1.2rem;
                margin: 0 auto;
              }
              .q-word-box {
                background-color: $paleyellow;
                outline: 5px solid $yellow;
                border-radius: 10px;
                text-align: center;
                font-size: 1.2rem;
                max-width: 180px;

                h3 {
                  -webkit-box-shadow: inset 0px 0px 0px 3px $paleyellow;
                  -moz-box-shadow: inset 0px 0px 0px 3px $paleyellow;
                  box-shadow: inset 0px 0px 0px 3px $paleyellow;
                  border-radius: 10px;
                  padding: 1rem 0;
                  cursor: default;
                }
              }
              .dot {
                margin: 0 auto;
                padding: 1rem;

                span.circle {
                  display: flex;
                  width: 10px;
                  height: 10px;
                  border-radius: 50%;
                  border: 2px solid $yellow;
                  justify-self: center;
                  justify-content: center;
                  align-items: center;
                }
              }
            }

            .center-wrap-for-line {
              height: 100%;
            }
          }
        }
      }

      .nav-btm {
        .checkprev {
          transform: rotate(0deg);
        }
      }
    }
    // end VocaCheck.js

    // start VocaFlashcardTyping.js
    .flashcard-voca-con {
      .question-con {
        .inner {
          &.top {
            background-image: none;
            display: grid;
            align-items: center;
            margin: 0;
            .type-wrap {
              padding: 0 2rem;

              h1 {
                font-size: 1.6rem;
                margin-bottom: 1rem;
              }
              .type-inner {
                .top {
                  display: grid;
                  grid-template-columns: 4fr 1fr;
                  background: $paleblue;
                  align-items: center;
                  padding: 1rem 0;
                  div {
                    &.answer-wrap {
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      padding: 0.5rem 0;
                      margin: 0 1rem;

                      .inner {
                        background: #fff;
                        width: 100%;
                        padding: 0.5rem 0;
                        width: 100%;
                        max-width: calc(100% - 2rem);
                        display: flex;
                        flex-wrap: wrap;
                        white-space: pre-wrap;

                        p {
                          font-size: 1.4rem;
                          display: flex;
                          text-align: left;
                          padding: 0 1rem;
                        }
                      }
                    }
                  }

                  .chk-btn {
                    width: 100px;
                    height: 45px;
                    border-radius: 15px;
                    background: $darkblue;
                    color: #fff;
                    font-weight: 600;
                    font-size: 1.1rem;
                  }
                }

                .btm {
                  .result-wrap {
                    display: grid;
                    width: 400px;
                    grid-template-columns: 1fr 1fr;
                    margin: 1rem 1rem;
                    div {
                      display: flex;
                      align-items: center;
                      justify-content: space-between;
                      text-align: center;
                      .box {
                        background: $paleblue;
                        width: 70px;
                        padding: 0.5rem;
                        text-align: center;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin-left: 1rem;
                      }
                      h3 {
                        font-size: 1.3rem;
                        color: $darkblue;
                        flex-grow: 1;
                        text-align: center;

                        &:nth-child(2) {
                          text-align: right;
                        }
                      }
                      h5 {
                        font-size: 1.3rem;
                        color: $darkblue;
                        margin-left: 1rem;
                        flex-grow: 1;
                      }
                    }
                  }
                }
              }
            }
          }
          &.bottom {
          }
        }
      }
      &.typing {
      }
    }
    // end VocaFlashcardTyping.js

    //quiz
    .flashcard-voca-con {
      &.quiz {
        width: 100%;
        grid-template-columns: auto;
        //   grid-template-rows: 1fr 2fr;
        display: grid;
        gap: 0;
        position: relative;
        .num-wrap {
          position: absolute;
          width: 100%;
          height: 100%;
          .num {
            position: absolute;
            right: 0;
            padding: 1rem;
            margin: 1rem 2rem 0 0;
            background: #ffe4c9;
            z-index: 99;
          }
        }

        .quiz-inner {
          border: 2px solid #ffc000;
          position: relative;
          border-radius: 15px;
          display: grid;
          width: 100%;
          height: 100%;
          align-self: center;
          justify-content: center;
          justify-self: center;
          grid-template-rows: auto 1rem;
          grid-template-columns: 1fr;
          margin-bottom: 1rem;

          .inner.for-q {
            display: grid;
            align-items: center;

            .question-con {
              display: block;
              grid-template-rows: auto;
              grid-template-columns: auto;
              gap: 0;
              justify-content: center;
              max-width: 1000px;
              margin: 0 auto;
              border: none;
              h1 {
                display: flex;
                align-items: end;
                font-size: 1.4rem;
              }

              .inner {
                background: $paleyellow;
                border: 2px solid $yellow;
                padding: 2rem;
                margin: 1rem auto 0;
                height: 100%;
                border-radius: 20px;
                h2 {
                  display: flex;
                  align-items: center;
                  font-size: 1.6rem;
                }
              }
            }

            .answer-con {
              padding: 0 3rem;
              width: 100%;
              max-width: 750px;
              margin: 0 auto;
              .row {
                display: flex;
                align-items: center;
                cursor: pointer;
                margin: 1.5rem auto;
                width: 100%;
                justify-content: start;
                .q-num {
                  border: 3px solid $darkblue;
                  width: 60px;
                  height: 60px;
                  border-radius: 50%;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  background: #fff;
                  position: absolute;
                  h2 {
                    font-size: 1.45rem;
                    color: $darkblue;
                  }
                }

                .q-word {
                  border-radius: 35px;
                  padding: 1rem 0rem 1rem 5rem;
                  border: 3px solid $darkblue;
                  background: $paleblue2;
                  width: 100%;

                  h3 {
                    font-size: 1.4rem;
                  }
                }
              }
            }
          }

          .nav-btm {
            margin: 0;
            grid-template-columns: 1fr 1fr;
          }
        }
      }
    }
    //quiz end

    // ks
    .flashcard-voca-con {
      &.ks-con {
        .question-con {
          .inner {
            &.top {
              display: grid;
              align-items: center;

              h1 {
                font-size: 1.4rem;
              }

              .question-wrap {
                border: 2px solid $darkblue;
                display: grid;
                grid-template-rows: 1fr 1fr;
                padding: 0 2rem;

                .q {
                  margin: 1rem 0 1rem;
                  display: grid;
                  position: relative;

                  .num {
                    position: absolute;
                    right: 0;
                    top: 0rem;
                    padding: 1rem;
                    margin: -0.5rem 2rem 2rem;
                    background: #ffe4c9;
                    z-index: 99;
                  }
                }

                .inner {
                  display: grid;
                  grid-template-columns: auto 2fr;
                  align-items: center;
                  margin-top: 2rem;
                  padding: 0 1rem;

                  .icn {
                    width: 60px;
                    height: 60px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    svg {
                      transform: scale(3.5);
                      fill: $darkblue !important;
                    }
                  }

                  .sentence {
                    border: 3px solid $darkblue;
                    padding: 1rem;
                    border-radius: 20px;
                    background: $paleblue;
                  }
                }
              }

              .repeat-wrap {
                .inner {
                  margin: 1rem auto;
                  .icn {
                    width: 60px;
                    height: 60px;
                    border-radius: 50%;
                    border: 2px solid $yellow;
                    font-size: 1.3rem;
                    font-weight: 600;
                    color: $yellow;
                  }

                  .sentence {
                    margin-left: 0.5rem;
                    background: #fff;
                    border: 2px solid $yellow;
                  }
                }
              }
            }
          }
        }
      }
    }
    //ks end

    // Unscramble
    .unscramble-con {
      width: 100%;

      .question-con {
        .inner {
          &.top {
            margin: 1rem 0 0;
            .progress-bar {
              display: grid;
              width: 100%;
              grid-template-columns: auto 10%;
              justify-self: end;
              .bar {
                width: 400px;
                height: 30px;
                display: flex;
                align-self: center;
                justify-self: end;
                background: $yellow;
                border-radius: 20px;
                box-shadow: 10px 10px 21px 0px rgba(168, 161, 161, 0.75);
                -webkit-box-shadow: 10px 10px 21px 0px rgba(168, 161, 161, 0.75);
                -moz-box-shadow: 10px 10px 21px 0px rgba(168, 161, 161, 0.75);
              }
              .num {
                position: relative;
                margin: 0;
                width: 75px;
                background: none;

                h3 {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  font-size: 1.5rem;
                  margin-left: 1rem;
                }
              }
            }
          }

          &.bottom {
            width: 100%;
            display: block;
            padding: 2rem 1rem;
            max-width: 1000px;
            margin: 0 auto;
            h1 {
              font-size: 1.2rem;
              font-weight: 600;
              &.tit-kr {
                font-weight: 600;
                text-align: center;
                font-size: 1.5rem;
                margin-top: 4.5rem;
              }
            }

            .answer-sheet-box {
              margin: 2rem 0;
              .top {
                display: flex;
                justify-content: center;
                border: 3px solid $darkblue;
                padding: 2rem 1rem;
                border-radius: 15px;

                .inner {
                  display: flex;
                  justify-content: center;

                  div {
                    width: 200px;
                    height: 80px;
                    border-radius: 15px;
                    background: $paleblue;
                    margin: 0 1rem;
                  }
                }
              }

              .bottom {
                margin-top: 6rem;
                display: flex;
                justify-content: center;
                border: 3px solid $darkblue;
                border: 3px solid $darkblue;
                padding: 2rem 1rem;
                border-radius: 15px;
                position: relative;

                box-shadow: 10px 10px 21px 0px rgba(168, 161, 161, 0.75);
                -webkit-box-shadow: 10px 10px 21px 0px rgba(168, 161, 161, 0.75);
                -moz-box-shadow: 10px 10px 21px 0px rgba(168, 161, 161, 0.75);

                div {
                  width: 200px;
                  height: 80px;
                  border-radius: 15px;
                  background: #dbf3fb;
                  display: flex;
                  margin: 0 1rem;
                  justify-content: center;
                  align-items: center;
                  cursor: pointer;
                }

                &:before {
                  content: '보기';
                  font-weight: 600;
                  width: 100px;
                  height: 50px;
                  background: $darkblue;
                  color: #fff;
                  text-align: center;
                  display: flex;
                  position: absolute;
                  top: 0;
                  align-items: center;
                  justify-content: center;
                  left: 0;
                  border-top-left-radius: 10px;
                  border-bottom-right-radius: 20px;
                }
              }
            }
          }
        }
      }
    }
    // Unscramble end

    // Dictation start
    // background: $paleblue;

    .dictation-con {
      height: 100%;
      display: grid;
      grid-template-columns: 1.5fr 1fr;

      .left-inner {
        margin-right: 1rem;
        margin-left: 0.5rem;
        .inner {
          background: #fff;
          height: calc(100% - 0.5rem);
          .question-con {
            padding: 2rem 4rem;
            height: 100%;
            h1 {
              font-size: 1.4rem;
            }

            .paragraph-inner {
              border: 3px solid $yellow;
              height: 100%;
              max-height: 500px;
              padding: 2rem 3rem;
              margin: 1rem 0;
              overflow-y: auto;
              p {
                line-height: 2.3;
                letter-spacing: 0.035rem;
                font-size: 1.25rem;
              }
            }
          }
        }
      }

      .right-inner {
        background: $midblue;
        display: grid;
        grid-template-rows: auto 3fr 1fr;
        margin: 0 auto 0.5rem;
        padding: 0 3rem;
        h1 {
          color: $purple;
          font-size: 2rem;
          text-align: center;
          font-weight: 700;
          margin: 1rem auto 0.5rem;
        }

        .result-wrap {
          background: #fff;
          border-radius: 15px;
          width: 100%;
          border: 2px solid $darkblue;
          margin: 1rem auto 1rem;
          padding: 0 3rem;
          display: flex;
          flex-direction: column;
          justify-content: center;
          .result-box {
            border-radius: 15px;
            background: #fff;
            padding: 1rem 1.5rem;
            display: grid;
            grid-template-columns: 1fr 4fr;
            align-items: center;
            h3 {
              font-weight: 600;
              margin-right: 1rem;
            }

            .num-box {
              width: 100%;
              background: $paleblue;
              padding: 1rem 1rem;
            }
          }
        }

        .bottom {
          width: auto;
          display: flex;
          justify-content: space-around;
          align-items: center;
          text-align: center;
          padding: 1rem 3rem;
          background: #97a6f3;
          border-radius: 20px;
          margin: 2rem 1rem;
          div {
            width: 60px;
            height: 60px;
            border-radius: 50%;
            border: 3px solid $paleblue;
            background: #fff;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            span.anticon {
              svg {
                transform: scale(3);
              }

              &.prev-btn {
                transform: rotate(-180deg);
              }
            }
            .speaker-btn {
              svg {
                //  fill: #fff !important;
              }
            }

            .play-btn {
              svg {
                fill: $yellow !important;
              }
            }
          }
        }
      }
    }

    // Dictation end
    // quiz tool
    &.quiz-tool {
      grid-template-columns: auto;
      .quiz {
        justify-self: center;
        grid-template-columns: 100%;
        grid-template-rows: auto 1fr;

        .quiz-inner {
          margin: 0.5rem 0;
          max-width: 1000px;

          .left-wrap {
            border: 2px solid #5399d9;
            margin-right: 1rem;
          }

          .inner.top.for-q {
            .question-con {
              .inner {
                background: transparent;
                border: none;
                padding: 2rem 0 0;
                h2 {
                  color: $darkblue;
                  font-size: 2rem;
                }
              }
            }
          }

          .nav-btm {
            margin: 2rem 0;
            grid-template-columns: 1fr 3fr 1fr;

            span {
              .anticon {
                svg {
                  cursor: pointer;
                  transform: scale(3.5);
                  fill: #ed7d31;
                }
              }
            }
          }
        }

        // type-c
        &.typec {
          grid-template-rows: 1fr;

          .quiz-inner {
            max-width: 100%;
            grid-template-rows: 1fr;
            padding: 0 2rem;
            border: none;
            h1 {
              font-size: 1.45rem;
              padding-bottom: 1rem;
            }
            .wrap {
              display: grid;
              grid-template-rows: 1fr;
              grid-template-columns: 1fr 1fr;
            }
            .left-wrap {
              display: grid;
              padding: 0 4rem;
              h1 {
                font-size: 1.4rem;
                padding: 2rem 1rem 0;
              }
              .inner.top.for-q {
                height: 100%;
                .q-img {
                  background-image: url('https://culp.cloubot.com/images/lexilelogo.png');
                  background-repeat: no-repeat;
                  background-size: contain;
                  background-position: center center;
                  position: relative;
                  width: 100%;
                  height: 150px;
                }
              }
            }

            .right-wrap {
              padding: 1rem 2rem;
              height: 100%;
              border: 2px solid $yellow;

              .question-con {
                border: none;
              }
              .answer-con {
                padding: 0 3rem;
                width: 100%;
                max-width: 750px;
                margin: 0 auto;
                .row {
                  display: flex;
                  align-items: center;
                  cursor: pointer;
                  margin: 1.5rem auto;
                  width: 100%;
                  justify-content: start;
                  .q-num {
                    border: 3px solid $darkblue;
                    width: 60px;
                    height: 60px;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background: #fff;
                    position: absolute;
                    h2 {
                      font-size: 1.45rem;
                      color: $darkblue;
                    }
                  }

                  .q-word {
                    border-radius: 35px;
                    padding: 1rem 0rem 1rem 5rem;
                    border: 3px solid $darkblue;
                    background: $paleblue2;
                    width: 100%;

                    h3 {
                      font-size: 1.4rem;
                    }
                  }
                }
              }
            }
          }

          .nav-btm {
            margin: 2rem 0;
            grid-template-columns: 1fr;
            justify-content: center;

            span {
              .anticon {
                svg {
                  cursor: pointer;
                  transform: scale(6);
                  fill: #ed7d31;
                }
              }
            }
          }
        }
      }
      &.type-tool {
        padding: 1rem 4rem;
        background: $paleblue;

        .typec {
          .quiz-inner {
            .left-wrap {
              background: #fff;
            }

            .right-wrap {
              background: #fff;
            }
          }
        }
      }
    }
  }
}

// responsive

@media screen and (max-width: 1600px) {
  .vocatool-con {
    display: grid;
    header {
      max-width: 1250px;
      margin: 1rem auto 0;
    }
    .vocatool-body {
      max-width: 1250px;
      margin: 1rem auto 0;
      height: 750px;

      &.full-width {
        max-height: 750px;
        height: auto;
        margin: 0 auto 1rem;
      }

      .innner {
        .flashcard-voca-con {
          &.typing {
            .question-con {
              .inner {
                &.top {
                  .type-wrap {
                    padding: 0;
                    .type-inner {
                      .top {
                        div {
                          &.answer-wrap {
                            padding: 0.5rem 0;
                            margin: 0 1rem;
                            .inner {
                              padding: 0.5rem 0;
                            }
                          }
                        }
                        .chk-btn {
                          margin: 0 1rem;
                        }
                      }
                    }
                  }
                }
              }
            }
          }

          &.quiz {
            overflow-y: auto;
            position: relative;
            height: 100%;
            .num-for-min {
            }
            .num-wrap {
              position: absolute;
              .num {
                padding: 0.5rem 1rem;
                margin: 1rem 2rem 0 0;
              }
            }
            .quiz-inner {
              margin-bottom: 0;
              .inner {
                padding: 0 2rem;
                &.for-q {
                  .question-con {
                    max-width: 900px;
                    padding: 1rem 1rem 0 1rem;
                    h1 {
                      font-size: 1.2rem;
                    }
                    .inner {
                      padding: 1rem 2rem;
                      h2 {
                        font-size: 1.3rem;
                      }
                    }
                  }

                  .answer-con {
                    margin: 0 auto;
                    .row {
                      margin: 1rem auto;
                      .q-num {
                        width: 50px;
                        height: 50px;
                      }
                      .q-word {
                        h3 {
                          font-size: 1rem;
                        }
                      }
                    }
                  }
                }
              }
            }

            &::-webkit-scrollbar-track {
              border-radius: 10px;
              background-color: #f5f5f5;
            }

            &::-webkit-scrollbar {
              width: 10px;
              background-color: #fff;
            }

            &::-webkit-scrollbar-thumb {
              border-radius: 10px;
              background-color: $darkblue;
            }
          }

          &.ks-con {
            .question-con {
              .inner {
                &.top {
                  h1 {
                    font-size: 1.2rem;
                  }

                  .question-wrap {
                    .q {
                      .num {
                        margin: -0.5rem 0rem 2rem;
                      }
                    }
                    .inner {
                    }
                  }
                }
              }
            }
          }
        }
      }

      .progress-con {
        .inner {
          height: 80px;
          .pentagon {
            top: -2rem;
            height: 39px;
            border-width: 35px 4px 0;

            &.first {
              height: 25px;
              h3 {
                top: -1rem;
                font-size: 1.1rem;
              }
              &:before {
                left: -4px;
                border-width: 0 45px 35px;
                top: -35px;
              }

              &:after {
                top: 25px;
                left: -4px;
                border-width: 0px 45px 35px;
              }
            }

            &.first.active {
              height: 25px;
              &:before {
                left: -4px;
                border-width: 0 45px 35px;
                top: -35px;
              }

              &:after {
                top: 25px;
                left: -4px;
                border-width: 0px 45px 35px;
              }
              &.active {
                h3 {
                  top: -1rem;
                  font-size: 1.1rem;
                }
              }
            }

            &.active {
              &:before {
                left: -4px;
                border-width: 0 45px 35px;
                top: -35px;
              }

              &:after {
                top: 39px;
                left: -4px;
                border-width: 0px 45px 35px;
              }

              h3 {
                top: 1rem;

                font-size: 1.1rem;
              }
            }

            &:before {
              left: -4px;
              border-width: 0 45px 35px;
              top: -35px;
            }

            &:after {
              top: 39px;
              left: -4px;
              border-width: 0px 45px 35px;
            }

            h3 {
              font-size: 1.1rem;
              top: 1rem;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1440px) {
  .vocatool-con {
    overflow: initial;
    header {
      max-width: 1100px;
      margin: 1rem auto 0;
    }
    .vocatool-body {
      max-width: 1100px;
      margin: 0 auto 1rem;
      // height: 690px;
      height: 600px;
      &.full-width {
        height: 600px;
        max-height: 100%;
      }
      .innner {
        height: 100%;
      }

      .flashcard-voca-con {
        // height: 650px;
        height: 100%;
        max-height: 570px;
        .question-con {
          .inner {
            &.bottom {
              //common
              padding: 1rem 2rem;
            }
          }
        }
        .wordlist-con {
          h2 {
            margin: 1rem auto 2rem;
            font-size: 1.45rem;
          }
        }
        &.typing {
          .question-con {
            .inner {
              &.top {
                .type-wrap {
                  padding: 0;
                  .type-inner {
                    .top {
                      div {
                        &.answer-wrap {
                          padding: 0.5rem 0;
                          margin: 0 1rem;
                          .inner {
                            max-width: 100%;
                            padding: 0.5rem 0;
                            p {
                              font-size: 1.1rem;
                              display: flex;
                              text-align: left;
                              padding: 0 1rem;
                              letter-spacing: 0.03rem;
                              line-height: 1.65;
                            }
                          }
                        }
                      }
                      .chk-btn {
                        margin: 0 1rem;
                      }
                    }
                  }
                }
              }
            }
          }
        }

        &.ks-con {
          .question-con {
            .inner {
              &.top {
                h1 {
                  font-size: 1.1rem;
                  span {
                    font-size: 1rem;
                  }
                }

                .question-wrap {
                  padding: 0 1rem;
                  .q {
                    .num {
                      padding: 0.5rem 1rem;
                      margin: -0.5rem 0rem 2rem;
                    }
                  }
                  .inner {
                  }
                }

                .repeat-wrap {
                  overflow-y: auto;
                  height: 150px;
                  .inner {
                    .icn {
                      width: 50px;
                      height: 50px;
                      font-size: 1.2rem;
                    }
                    .sentence {
                      h1 {
                        font-size: 1.1rem;
                      }
                      h2 {
                        font-size: 1rem;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      //chk-con
      .check-con {
        header.tool-header {
          .num {
            width: 80px;
          }
        }
        .check-q-wrap {
          padding: 0 3rem;
          .right-wrap {
            h3 {
              font-size: 1rem;
            }
          }

          .q {
            &.q-row {
              .left-wrap {
                .q-word-box {
                  max-height: 60px;
                  overflow-y: auto;
                  h3 {
                    font-size: 1rem;
                  }
                }
              }
              .right-wrap {
                .q-word-box {
                  max-height: 60px;
                  overflow-y: auto;
                  max-width: 130px;
                  h3 {
                    font-size: 1rem;
                  }
                }
              }
            }
          }
        }
      }

      //quiz
      &.quiz-tool {
        max-height: 600px;
        &.type-tool {
          .flashcard-voca-con {
            &.quiz {
              &.typec {
                .quiz-inner {
                  margin: 1rem 0;
                  align-items: center;
                  h1 {
                    padding: 0;
                  }
                  .left-wrap {
                    border: 2px solid $darkblue;
                    margin-right: 1rem;
                  }
                  .right-wrap {
                    .question-con {
                      grid-template-rows: auto;
                    }

                    .answer-con {
                      .row {
                        margin: 1rem auto;
                        .q-num {
                          width: 45px;
                          height: 45px;
                          h2 {
                            font-size: 1.25rem;
                          }
                        }
                        .q-word {
                          padding: 1rem 0rem 1rem 4rem;
                          h3 {
                            font-size: 1rem;
                          }
                        }
                      }
                    }
                  }
                }

                .nav-btm {
                  margin: 1rem auto;
                  span {
                    background: #fff;
                    width: 90px;
                    height: 90px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 50%;
                    justify-self: center;

                    svg {
                    }
                  }
                }
              }
            }
          }
        }
      }

      // unscramble

      .unscramble-con {
        .question-con {
          .inner {
            &.top {
              margin: 1rem 0 0;
              .progress-bar {
                .bar {
                  width: 300px;
                  height: 15px;
                }
                .num {
                  position: relative;
                  margin: 0;
                  width: 55px;
                  background: none;

                  h3 {
                    font-size: 1.1rem;
                    margin-left: 1rem;
                  }
                }
              }
            }

            &.bottom {
              width: 100%;
              padding: 1rem 1rem;
              max-width: 1000px;
              h1 {
                font-size: 1.15rem;
                &.tit-kr {
                  font-size: 1.45rem;
                  margin: 2rem auto;
                }
              }

              .answer-sheet-box {
                margin: 0.5rem 0;
                .top {
                  padding: 1.5rem 1rem;

                  .inner {
                    div {
                      width: 100px;
                      height: 50px;
                      margin: 0 1rem;
                    }
                  }
                }

                .bottom {
                  margin-top: 1rem;
                  padding: 2rem 1rem;
                  border-radius: 15px;
                  position: relative;

                  box-shadow: 10px 10px 21px 0px rgba(168, 161, 161, 0.75);
                  -webkit-box-shadow: 10px 10px 21px 0px rgba(168, 161, 161, 0.75);
                  -moz-box-shadow: 10px 10px 21px 0px rgba(168, 161, 161, 0.75);

                  div {
                    width: 100px;
                    height: 50px;
                    border-radius: 15px;
                    background: #dbf3fb;
                    display: flex;
                    margin: 0 1rem;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;
                  }

                  &:before {
                    content: '보기';
                    font-weight: 600;
                    width: 100px;
                    height: 50px;
                    background: $darkblue;
                    color: #fff;
                    text-align: center;
                    display: flex;
                    position: absolute;
                    top: 0;
                    align-items: center;
                    justify-content: center;
                    left: 0;
                    border-top-left-radius: 10px;
                    border-bottom-right-radius: 20px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1280px) and (aspect-ratio: 16 /  10) {
  .vocatool-con {
    .vocatool-body {
      .flashcard-voca-con {
        .question-con {
          .inner {
            &.top {
              .type-wrap {
                h1 {
                  font-size: 1.4rem;
                }
              }
            }
          }
        }

        .wordlist-con {
          h2 {
            font-size: 1.4rem;
          }
        }
      }
      &.quiz-tool {
        &.type-tool {
          .flashcard-voca-con {
            &.quiz {
              &.typec {
                .quiz-inner {
                  margin: 1rem 0;
                  align-items: center;
                  h1 {
                    padding: 0;
                  }
                  .left-wrap {
                    border: 2px solid $darkblue;
                    margin-right: 1rem;
                  }
                  .right-wrap {
                    .question-con {
                      grid-template-rows: auto;
                    }

                    .answer-con {
                      .row {
                        margin: 1rem auto;
                        .q-num {
                          width: 50px;
                          height: 50px;
                          h2 {
                            font-size: 1.25rem;
                          }
                        }
                        .q-word {
                          h3 {
                            font-size: 1rem;
                          }
                        }
                      }
                    }
                  }
                }

                .nav-btm {
                  margin: 0 auto;
                  span {
                    background: #fff;
                    width: 90px;
                    height: 90px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 50%;
                    justify-self: center;

                    svg {
                    }
                  }
                }
              }
            }
          }
        }
      }
      .dictation-con {
        .left-inner {
          .inner {
            .question-con {
              padding: 2rem 2rem;
              max-height: 550px;
              .paragraph-inner {
                max-height: 400px;
                p {
                  line-height: 1.8;
                  letter-spacing: 0.035rem;
                  font-size: 1.2rem;
                }
              }
            }
          }
        }

        .right-inner {
          padding: 0 1rem;
          max-height: 550px;
          .bottom {
            div {
              margin: 0 0.2rem;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .vocatool-con {
    header {
      max-width: 1000px;
      margin: 1rem auto 0;
    }
    .vocatool-body {
      max-width: 1000px;
      margin: 0 auto 0;
      height: 600px;
      .innner {
        height: 100%;

        .flashcard-voca-con {
          height: 650px;

          .question-con {
            .inner {
              &.bottom {
                //common
                padding: 1rem 2rem;
                margin: auto 1rem;
              }
            }
          }

          &.flashcard {
            height: 550px;
            .question-con {
              max-height: 520px;
            }
          }

          &.typing {
            height: 550px;
            .question-con {
              max-height: 520px;
              .inner {
                &.top {
                  .type-wrap {
                    padding: 0;
                    h1 {
                      font-size: 1.3rem;
                    }
                    .type-inner {
                      .top {
                        div {
                          &.answer-wrap {
                            padding: 0.5rem 0;
                            margin: 0 1rem;
                            .inner {
                              padding: 0.5rem 0;
                            }
                          }
                        }
                        .chk-btn {
                          margin: 0 1rem;
                        }
                      }
                    }
                  }
                }
              }
            }

            .wordlist-con {
              max-height: 520px;

              h2 {
                font-size: 1.6rem;
              }

              .inner {
                display: block;
                width: 100%;
                padding: 0 2rem;
                .word-box {
                  margin: 1rem 0;
                  width: 100%;
                }
              }
            }
          }

          &.ks-con {
            .question-con {
              .inner {
                &.top {
                  margin: 0;
                  h1 {
                    font-size: 1rem;
                    white-space: pre;
                  }

                  .question-wrap {
                    padding: 0 1rem;
                    margin-top: 1rem;
                    .q {
                      .num {
                        padding: 0.5rem 1rem;
                        margin: -0.5rem 0rem 2rem;
                      }
                      .inner {
                        .sentence {
                          h2 {
                            font-size: 1rem;
                          }
                        }
                      }
                    }
                  }

                  .repeat-wrap {
                    overflow-y: auto;
                    height: 150px;
                    .inner {
                      .icn {
                        width: 50px;
                        height: 50px;
                        font-size: 1.2rem;
                      }
                      .sentence {
                        h1 {
                          font-size: 1.1rem;
                        }
                        h2 {
                          font-size: 1rem;
                        }
                      }
                    }
                  }
                }
              }
            }
          }

          .wordlist-con {
            &.ks {
            }
            h2 {
              font-size: 1.6rem;
            }

            .inner {
              display: block;
              width: 100%;
              padding: 0 2rem;
              .word-box {
                margin: 1rem 0;
                width: 100%;
              }
            }
          }
        }

        .check-con {
          .check-q-wrap {
            padding: 0 1rem;
            .q {
              &.q-row {
                grid-template-columns: 1fr 1fr 1fr;
                .right-wrap {
                  grid-template-columns: auto 55%;
                }
              }
            }
          }
        }
      }

      .nav-btm {
        padding: 0;
        margin: 2rem auto 0;
        a {
          &.prev {
          }
          span.anticon {
            svg {
              // transform: scale(2);
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .vocatool-con {
    header {
      max-width: 1000px;
      margin: 1rem auto 0;
    }
    .vocatool-body {
      max-width: 1000px;
      margin: 1rem auto 0;
      height: 100%;
      max-height: 550px;

      .innner {
        .flashcard-voca-con {
          &.flashcard {
            height: 100%;
            max-height: 550px;
            .wordlist-con {
              max-height: 520px;
              .inner {
              }
            }
          }

          .question-con {
            max-height: 590px;
          }

          &.ks-con {
            height: 100%;
            max-height: 400px;
            .question-con {
              grid-template-rows: 2fr 1fr;
              max-height: 430px;
              .inner {
                &.top {
                  margin: 0;
                  h1 {
                    font-size: 1rem;
                  }

                  .question-wrap {
                    padding: 0 1rem;
                    margin-top: 1rem;
                    max-height: 200px;
                    overflow: auto;
                    .q {
                      margin: 1rem 0 1rem;
                      display: grid;
                      position: relative;
                      .num {
                        padding: 0.5rem 1rem;
                        margin: -0.5rem 0rem 2rem;
                      }
                      .inner {
                        display: grid;
                        grid-template-columns: auto 2fr;
                        align-items: center;
                        margin-top: 2rem;
                        padding: 0 1rem;
                        .sentence {
                          h2 {
                            font-size: 1rem;
                          }
                        }
                      }
                    }
                  }

                  .repeat-wrap {
                    //  overflow-y: auto;
                    height: 150px;
                    .inner {
                      .icn {
                        width: 50px;
                        height: 50px;
                        font-size: 1.2rem;
                      }
                      .sentence {
                        h1 {
                          font-size: 1.1rem;
                        }
                        h2 {
                          font-size: 1rem;
                        }
                      }
                    }
                  }
                }
              }
            }

            .wordlist-con {
              &.ks {
                height: 430px;
              }
            }
          }

          &.quiz {
            height: 100%;
            max-height: 550px;
          }
        }

        .wordlist-con {
          h2 {
            // font-size: 1.6rem;
          }

          .inner {
            display: block;
            width: 100%;
            padding: 0 2rem;
            .word-box {
              margin: 1rem 0;
              width: 100%;
            }
          }
        }
        .check-con {
          .check-q-wrap {
            padding: 0 1rem;
            .q {
              &.q-row {
                .right-wrap {
                  grid-template-columns: auto 55%;
                  h3 {
                    font-size: 1rem;
                  }
                }
              }
            }
          }
        }

        .progress-con {
          .inner {
            //pentagon

            .pentagon {
              height: 30px;
              &.first.active {
                height: 30px;

                &:before {
                  left: -4px;
                  border-width: 0 45px 35px;
                }

                &.after {
                  top: 28px;
                  left: -4px;
                  border-width: 0 45px 35px;
                }
              }
              &:before {
                left: -4px;
                border-width: 0 45px 35px;
              }

              &.after {
                top: 28px;
                left: -4px;
                border-width: 0 45px 35px;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) and (orientation: portrait) {
  .vocatool-con {
    display: flex;
    flex-direction: column;
    header {
      max-width: 720px;
      //  min-height: 100%;
      grid-template-rows: auto 1fr;
      margin: 2rem auto 0;
      div {
        width: auto;
        padding: 1rem 0.5rem;
        margin: 0 0.5rem;
        h3 {
          font-size: 1rem;
        }
      }
      &.tool-header {
        max-width: 100%;
        .num {
          width: 80px;
        }
      }
    }

    .vocatool-body {
      max-width: 720px;
      overflow-y: auto;
      height: 100%;
      max-height: 100%;
      .innner {
        height: auto;
        display: grid;
        // grid-auto-columns: 1fr;
        .flashcard-voca-con {
          &.flashcard {
            height: 100%;
            display: grid;
            max-height: 100%;
            grid-template-columns: auto;
          }

          &.typing {
            height: 100%;
            max-height: 1000px;
            display: block;
          }
        }
        .check-con {
          header {
            &.tool-header {
              .num {
                width: 80px;
                padding: 0.5rem;
              }
            }
            h2 {
              margin-left: 1rem;
              font-size: 1rem;
            }
          }

          .check-q-wrap {
            .q {
              &.q-row {
                .q-word-box {
                  h3 {
                    overflow: auto;
                    white-space: pre-line;
                  }
                }

                .left-wrap {
                  .q-num {
                    margin: 0 1rem 0 0;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 820px) and (orientation: portrait) {
  .vocatool-con {
    padding: 0 1rem;
    grid-template-rows: auto 1fr;
    gap: 1rem;

    header {
      margin: 2rem auto 0;
    }

    .vocatool-body {
      height: 100%;
      max-height: 100%;
    }
  }
}

.ticket-menu-tab-main
{
    .wrapper-tab-ticket
    {
        button
        {
            &.ticket-tab
            {
                    margin-left: 10px;
                    padding: 6px 20px;
                    height: auto;
                    border-radius: 6px 6px 0px 0px;
                    background: #edf3fb;
                    border: 1px solid #a4d0e9;
                    border-bottom: 0px;
                    
                a
                {

                }
                &:hover
                {
                    background: #a4d0e9;
                    color: #FFF;
                }
            }
            
        }
    }
}

@media(max-width:1100px)
{
    .ticket-menu-tab-main
    {
        margin-top: 0px;
    }
    .form-wrapper-ticket
    {
        .form-row-ticket
        {
            .form-ticket-sec-a
            {
                flex: 0 0 47%;
                max-width: 47%;
                margin-right:6%;
                &:last-child
                {
                    margin-right:0px;
                }
                .form-ticket-heading
                {

                }
                .form-ticket-row-main
                {
                    width: 100%;
                    .form-ticket-col-main-lbl
                    {
                        flex: 0 0 30%;
                        max-width: 30%;
                        label
                        {
                            margin-right: 10px;
                        }
                    }
                    .form-ticket-col-main-field
                    {
                        flex: 0 0 70%;
                        max-width: 70%;
                        .ant-select
                        {
                            width: 100% !important;
                        }
                        .ant-cascader-picker
                        {
                            width: 100% !important;
                        }
                        .ant-input-group
                        {
                            
                            .ant-form-item
                            {
                                width: 50%;  
                            }
                        }
                        &.optiontwo
                        {
                            .ant-radio-group
                            {
                                display:block !important;
                                label.ant-radio-button-wrapper
                                {
                                    width: 50% !important;
                                }
                            }
                            
                        }
                        &.optiontwo-select-input
                        {
                            .ant-input-group
                            {
                                .ant-select,
                                .ant-row
                                {
                                    width:50% !important;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .tickettable
    {
        .ant-table
        {
            overflow: auto;
            .ant-table-container
            {
                width: 1200px;
            }
        }
    }
}
@media(max-width:767px)
{
    .ticket-menu-tab-main
    {
        margin-top: 10px !important;
        display: block !important;
        h4 
        {
            display: block;
            width: 100%;
            margin-bottom: 20px;
        }
        .wrapper-tab-ticket
        {
            display: block;
            width: 100%;
            button
            {
                margin-left: 0px !important;
                margin-right: 20px;
                &:last-child
                {
                    margin-right: 0px;
                }
            }
        }
    }
}
@media(max-width:600px)
{
    .ticket-menu-tab-main
    {
        
        
        .wrapper-tab-ticket
        {
            
            button
            {
                margin: 0px;
                border-radius: 0px !important;
                width: 45%;
                margin-bottom: 20px;
                margin-right: 10%;
                border: 1px solid #a4d0e9 !important;
                &:nth-child(2n+2)
                {
                    margin-right:0px;
                }
            }
        }
    }
    .form-wrapper-ticket .form-row-ticket .form-ticket-sec-a
    {
        flex: 0 0 100%;
        max-width: 100%;
        margin-right: 0%;
    }
}
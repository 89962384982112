.tail {
  &__section {
    margin: 0;
    border: 0;
    font: inherit;
    vertical-align: baseline;

    img {
      max-width: 100%;

      &.active {
        filter: grayscale(1);
      }
    }
    &.active {
      .tail__layer {
        display: block;
      }

      .tail__box {
        bottom: 0;
      }
    }
  }

  &__box {
    position: absolute;
    width: 100%;
    bottom: 0;
    background-color: #eef1f7;
    z-index: 10;
    transition: bottom 0.4s;
    padding: 10px;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;

    .section__inner {
      &::after {
        content: '';
        display: block;
        clear: both;
      }
    }
  }

  &__btn {
    position: absolute;
    top: -45px;
    right: 16px;
    width: 75px;
    height: 45px;
    background: url(../images/icon_tail.png) center center / cover no-repeat;
    border: 0;
  }

  &__title {
    float: left;
    padding: 20px 0;

    img {
      width: 50%;
    }
  }

  &__layer {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 1;
  }

  &__process {
    float: right;

    li {
      display: inline-block;
      position: relative;
      width: 66px;
      margin-right: 40px;

      &:last-child {
        margin-right: 0;

        &::after {
          display: none;
        }
      }

      &::after {
        content: '';
        display: inline-block;
        position: absolute;
        top: 26px;
        right: -32px;
        width: 28px;
        height: 20px;
        background: url(../images/icon_blue_arrow.png) center center / cover no-repeat;
      }
    }
  }
}
